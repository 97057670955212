import { Layout } from "antd";
import styled from "styled-components";

const { Header } = Layout;

export const HeaderContainer = styled(Header)`
  margin-top: 0 !important;
  height: auto;

  background: ${({ theme }) => theme.colors.secondary[50]} !important;
  box-shadow: 0px 4px 5px 0px rgba(113, 130, 183, 0.1);
  z-index: 3;
  .content {
    padding: 12px 24px;
  }
  .hello {
    color: var(--secondary-grey-700, #707eae);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    margin-bottom: unset;
  }
  .grayColor {
    color: ${({ theme }) => theme.colors.gray[400]};
  }

  .icons {
    color: #a3aed0;
    font-size: 40px;
  }
  .notice {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;
  }
  .avatar {
    /* width: 400px; */
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
  .username {
    color: ${({ theme }) => theme.colors.secondaryText};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .userRole {
    color: ${({ theme }) => theme.colors.primary[600]};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-scroll-number {
    margin: 6px 6px;
    box-shadow: unset;
  }
  .ant-avatar {
    border: 3px solid ${({ theme }) => theme.colors.gray[400]};
  }
`;
