import { Row } from "antd";
import { FC } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { BankingOperationsContainer } from "./style";
import useLanguage from "src/store/language";
import { useBankingOperations } from "./context";
import { UploadFileForm } from "./components/UploadFileForm";
import { UploadFileTable } from "./components/Table";

export const BankingOperations: FC = () => {
  const {
    value: { editMode },
  } = useBankingOperations()!;
  const { words } = useLanguage();

  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.bankingOperation.editBankingOperationHeaderInfo
            : words.orders.bankingOperation.bankingOperationHeaderInfo
        }
      />
      <Row justify={"center"}>
        <BankingOperationsContainer span={18}>
          <UploadFileForm />
          <UploadFileTable />
        </BankingOperationsContainer>
      </Row>
    </div>
  );
};
