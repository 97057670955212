import { Flex, Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { OrdersContainer } from "./style";
import { TabsProps } from "antd/lib";
import { IOrdersTabs } from "./models";
import { AddProforma } from "./components/addProforma";
import { OrdersTable } from "./components/ordersTable";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { useOrder } from "./context";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import {
  AddProformPath,
  GetAllProformPath,
} from "src/services/Order/guardPath";

export const Orders: FC = () => {
  const [items, setItems] = useState<TabsProps["items"]>([]);
  const { checkActionPermission } = useCheckPermission();

  const { words } = useLanguage();
  const {
    value: { activeKey, editMode },
    dispatch: { setActiveKey },
  } = useOrder()!;
  const onChange = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    const AddProformPathHasPermission = checkActionPermission(AddProformPath);
    const GetAllProformPathHasPermission =
      checkActionPermission(GetAllProformPath);

    if (GetAllProformPathHasPermission) {
      setItems((prev) => {
        const newTab = {
          key: IOrdersTabs.table,
          label: (
            <span className="flex gap-[8px] justify-center items-center ]">
              <span className="material-icons text-[20px]">table_chart</span>
              {words.orders.ordersTable}
            </span>
          ),
          children: <OrdersTable />,
        };
        return prev ? [newTab, ...prev] : [newTab];
      });
      setActiveKey(IOrdersTabs.table);
    }
    if (AddProformPathHasPermission) {
      setItems((prev) => {
        const newItem = {
          key: IOrdersTabs.add,
          label: (
            <span className="flex gap-[8px] justify-center items-center">
              <span className="material-icons text-[20px]">add_box</span>
              {words.orders.newOrder}
            </span>
          ),
          children: <AddProforma />,
        };
        return prev ? [...prev, newItem] : [newItem];
      });
      !GetAllProformPathHasPermission && setActiveKey(IOrdersTabs.add);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrdersContainer>
      {editMode ? (
        <div>
          <HeaderProforma headerName={words.orders.editProformaInfo} />
          <Flex justify="center">
            <AddProforma />
          </Flex>
        </div>
      ) : (
        <Tabs items={items} onChange={onChange} activeKey={activeKey} />
      )}
    </OrdersContainer>
  );
};
