import { FC, useCallback, useEffect, useState } from "react";
import { ViewOriginContainer } from "../style";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Button, Col, Divider, Flex, Row, Skeleton, Spin } from "antd";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { OrderService } from "src/services/Order/order.service";
import {
  IGetOrigin,
  IGetOriginFile,
} from "src/services/Order/models/result.models";
import { AxiosRequestConfig } from "axios";
import downloadZipFile from "src/helper/downloadZipFile";
import { DocumentService } from "src/services/Document/document.service";
import { LoadingOutlined } from "@ant-design/icons";

export const ViewOrigin: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [originInfo, setOriginInfo] = useState<IGetOrigin>();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [getFileLoading, setGetFileLoading] = useState<boolean>(false);

  const warehousing = (warehousing?: boolean) => {
    console.log({ warehousing });
    let shownName =
      warehousing === undefined
        ? ""
        : warehousing
        ? words.global.hasIt
        : words.global.doesNotHave;
    return shownName;
  };

  const deliveryTerm = (deliveryTeam: number) => {
    let termName = "";
    switch (deliveryTeam) {
      case 1:
        termName = "FOB";
        break;
      case 2:
        termName = "EXW";
        break;
      case 3:
        termName = "CFR";
        break;
    }
    return termName;
  };

  const getOriginInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetOrigin } = new OrderService();
      const result = await GetOrigin(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setOriginInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const GetFile = async (file: IGetOriginFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    setGetFileLoading(true);
    try {
      const { GetOriginFile } = new OrderService();
      const result = await GetOriginFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
      setGetFileLoading(false);
    }
  };

  useEffect(() => {
    getOriginInfo();
  }, [getOriginInfo]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">{words.orders.origin.sourceInfo}</div>
      <Row
        className="w-full bg-[#f2f9f9] py-[24px] px-[16px]"
        gutter={[16, 24]}
      >
        <Col span={6} className="!font-medium">
          {words.basicInformation.suppliers.suppliers}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.origin.contractNo}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.origin.contractDate}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.basicInformation.countries.countries}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.origin.deliveryTerm}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.basicInformation.currencies.currencies}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.basicInformation.inspectionCompanies.inspectionCompanies}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.origin.warehousing}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Divider dashed className="!border-[#BFE2E0]" />
        <div className="flex gap-[16px]">
          <div className="text-[14px] w-[150px]">
            {words.orders.origin.originalDocuments}
          </div>
          <Skeleton.Image className="!w-[66px] !h-[66px]" />
        </div>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma headerName={words.orders.origin.viewOriginInfo} />
      <Flex justify="center">
        <ViewOriginContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">{words.orders.origin.sourceInfo}</div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={6} className="!font-medium">
                  {words.basicInformation.suppliers.suppliers}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {originInfo?.supplierName}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.origin.contractNo}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {originInfo?.contractNo}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.origin.contractDate}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {originInfo?.contractDate
                    ? dayjs(originInfo?.contractDate).format("YYYY-MM-DD")
                    : undefined}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.basicInformation.countries.countries}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {originInfo?.originCountryName}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.origin.deliveryTerm}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {originInfo?.deliveryTeam
                    ? deliveryTerm(originInfo?.deliveryTeam)
                    : undefined}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.basicInformation.currencies.currencies}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {originInfo?.currencyName}
                </Col>
                <Col span={6} className="!font-medium">
                  {
                    words.basicInformation.inspectionCompanies
                      .inspectionCompanies
                  }
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {originInfo?.insepectionName}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.origin.warehousing}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {warehousing(originInfo?.warehousing)}
                </Col>
                <Divider dashed className="!border-[#BFE2E0]" />
                <Col span={24}>
                  <div className="flex gap-[16px]">
                    <div className="text-[14px] w-[150px]">
                      {words.orders.origin.originalDocuments}
                    </div>

                    <div className="flex gap-[16px] ">
                      <Button
                        className={originInfo?.hasFile ? "" : "cursor-default"}
                        onClick={
                          originInfo?.hasFile
                            ? () => downloadAttachFile(originInfo?.id)
                            : () => {}
                        }
                        // loading={
                        //   originInfo?.hasFile
                        //     ? progress > 0
                        //       ? true
                        //       : false
                        //     : false
                        // }
                      >
                        {(originInfo?.hasFile && progress > 0) ||
                        getFileLoading ? (
                          <div className="relative w-[66px] h-[66px] flex justify-center items-center">
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="large"
                            />
                            <span className="absolute top-[38%] left-[40%] ">
                              {progress}
                            </span>
                          </div>
                        ) : (
                          <img
                            src={
                              originInfo?.hasFile
                                ? "/assets/images/fileUploaded.svg"
                                : "/assets/images/fileNotUpload.svg"
                            }
                            alt="fileUploaded"
                          />
                        )}
                      </Button>
                      <div className="flex flex-col gap-[6px] justify-center">
                        <div className="text-[14px]">
                          {originInfo?.hasFile
                            ? words.orders.origin.fileUploaded
                            : words.orders.origin.fileNotUploaded}
                        </div>
                        <div className="text-[10px]">
                          {originInfo?.hasFile
                            ? words.orders.origin.dlFile
                            : words.orders.origin.cantDlFile}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ViewOriginContainer>
      </Flex>
    </div>
  );
};
