import { FC, useEffect, useState } from "react";
import { Header } from "./components/header";
import OrderManagementDetails from "./components/orderManagementDetail";
import { ManagementOrderContent } from "./components/managementOrder";
import { useParams } from "react-router-dom";
import { IGetProformMain } from "src/services/Order/models/result.models";
import { OrderService } from "src/services/Order/order.service";

export const OrderManagement: FC = () => {
  const { orderId } = useParams();
  const [proformMain, setProformMain] = useState<IGetProformMain>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const getProformMain = async () => {
    if (!orderId) return undefined;
    setDataLoading(true);
    try {
      const { GetProformMain } = new OrderService();
      const response = await GetProformMain(+orderId);
      if (response && response.status === 200 && response.data) {
        setProformMain(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getProformMain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header />
      <OrderManagementDetails proformMain={proformMain ? proformMain : null} />
      <ManagementOrderContent
        proformMain={proformMain ? proformMain : null}
        dataLoading={dataLoading}
      />
    </div>
  );
};
