import { FC } from "react";
import { Rates } from "src/modules/Rates";
import { RatesProvider } from "src/modules/Rates/context";

export const RatesPage: FC = () => {
  return (
    <RatesProvider>
      <Rates />
    </RatesProvider>
  );
};
