import { FC } from "react";
import { InspectionDocHeader } from "./components/Header";
import { InspectionDocsContainer } from "./style";
import { InspectionDocsTable } from "./components/Table";
import { AddNewInspectionDoc } from "./components/AddNew";

export const InspectionInfoDocs: FC = () => {
  return (
    <div>
      <InspectionDocHeader />
      <div className="flex justify-center">
        <InspectionDocsContainer>
          <AddNewInspectionDoc />
          <InspectionDocsTable />
        </InspectionDocsContainer>
      </div>
    </div>
  );
};
