import { Col, Row } from "antd";
import styled from "styled-components";

export const ViewPODContainer = styled(Col)`
  display: flex;
  padding: 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .boxHolder {
    display: flex;
    padding: 8px 16px 32px 16px;
    gap: 10px;
    border-bottom: 1px dashed var(--color-Light-Mode-Border-3, #bfe2e0);
  }
  .activeBoxContainer {
    border-radius: 6px;
    border: 1px solid var(--color-Primary-Primary-600, #008f87);
    padding: 8px;
    color: var(--color-Primary-Primary-600, #008f87);
    text-align: center;
    cursor: pointer;
    /* Fa/Caption/C2.12.Medium */
    font-family: "Vazir FD";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .deActiveBoxContainer {
    padding: 8px;
    border-radius: 6px;
    border: 1px solid var(--color-Light-Mode-Disable-Disable-1, #cacaca);
    color: var(--color-Light-Mode-Disable-Disable-1, #cacaca);
    text-align: center;
    cursor: pointer;
    /* Fa/Caption/C2.12.Medium */
    font-family: "Vazir FD";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const FinancialTableContainer = styled(Row)`
  .tableHolder {
    display: flex;
    padding: 12px 12px 24px 12px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    background: var(--color-Base-Color-White, #fff);
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
  .totalHolder {
    border-radius: 8px;
    border: 1px solid var(--color-Primary-Primary-600, #008f87);
    display: flex;
    padding: 8px;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .success {
    color: ${(props) => props.theme.colors.success[700]};
  }
  .danger {
    color: ${(props) => props.theme.colors.danger[700]};
  }
`;
