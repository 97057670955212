import { App, Form } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import useLanguage from "src/store/language";
import { FormInstance } from "antd/lib";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAllCurrencyResult } from "src/services/BaseInfo/models/result.models";
import { OrderService } from "src/services/Order/order.service";
import { IAddOriginFinancial } from "src/services/Order/models/args.models";
import {
  IGetAlllOriginFinancial,
  IOriginFinancials,
} from "src/services/Order/models/result.models";
import { Dayjs } from "dayjs";

interface IContext {
  value: {
    editMode: boolean;
    getLoading: boolean;
    loading: boolean;
    currencyLoading: boolean;
    currency: IAllCurrencyResult[];
    originFinancial: IOriginFinancials[];
    originFinancialId: number | undefined;
    allOriginFinancial: IGetAlllOriginFinancial | undefined;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setCurrencyLoading: Dispatch<SetStateAction<boolean>>;
    setCurrency: Dispatch<SetStateAction<IAllCurrencyResult[]>>;
    setOriginFinancialId: Dispatch<SetStateAction<number | undefined>>;
  };
  func: {
    getCurrency: () => void;
    add: (values: IAddOriginFinancial) => void;
    update: (values: IAddOriginFinancial, id: number) => void;
    deleteOriginFinancial: (id: number) => void;
    getAlllOriginFinancial: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}
export const OriginFinancialContext = createContext<IContext | undefined>(
  undefined
);

export const OriginFinancialProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currency, setCurrency] = useState<IAllCurrencyResult[]>([]);
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(false);
  const [originFinancialId, setOriginFinancialId] = useState<number>();
  const [originFinancial, setOriginFinancial] = useState<IOriginFinancials[]>(
    []
  );
  const [allOriginFinancial, setAllOriginFinancial] =
    useState<IGetAlllOriginFinancial>();
  const [getLoading, setGetLoading] = useState<boolean>(false);

  ////////////////////////////////functions////////////////////////////////
  const getCurrency = useCallback(async () => {
    setCurrencyLoading(true);
    try {
      const { GetAllCurrency } = new BaseInfoService();
      const result = await GetAllCurrency();
      if (result && result.status === 200) {
        setCurrency(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrencyLoading(false);
    }
  }, []);

  const getAlllOriginFinancial = async () => {
    if (!orderId) return undefined;
    setGetLoading(true);
    try {
      const { GetAlllOriginFinancial } = new OrderService();
      const result = await GetAlllOriginFinancial(parseInt(orderId));
      if (result && result.status === 200) {
        setAllOriginFinancial(result.data);
        setOriginFinancial(result.data.originFinancials);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGetLoading(false);
    }
  };

  const add = async (values: IAddOriginFinancial) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddOriginFinancial } = new OrderService();
      const newValues = {
        ...values,
        date: (values.date as Dayjs).format("YYYY-MM-DD"),
      };
      const result = await AddOriginFinancial(parseInt(orderId), newValues);
      if (result && result.status === 200) {
        message.success(words.orders.originFinancial.originFinancialMessage);
        setOriginFinancialId(result.data);
        form.resetFields();
        getAlllOriginFinancial();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const update = async (values: IAddOriginFinancial, id: number) => {
    setLoading(true);
    try {
      const { UpdateOriginFinancial } = new OrderService();
      const newValues = {
        ...values,
        date: (values.date as Dayjs).format("YYYY-MM-DD"),
      };
      const result = await UpdateOriginFinancial(id, newValues);
      if (result && result.status === 200) {
        message.success(
          words.orders.originFinancial.updateOriginFinancialMessage
        );
        form.resetFields();
        getAlllOriginFinancial();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteOriginFinancial = async (id: number) => {
    setLoading(true);
    try {
      const { DeleteOriginFinancial } = new OrderService();
      const result = await DeleteOriginFinancial(id);
      if (result && result.status === 200) {
        message.success(
          words.orders.originFinancial.deleteOriginFinancialMessage
        );
        getAlllOriginFinancial();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const defaultValue: IContext = {
    value: {
      editMode,
      loading,
      currency,
      currencyLoading,
      originFinancial,
      originFinancialId,
      allOriginFinancial,
      getLoading,
    },
    dispatch: {
      setEditMode,
      setCurrency,
      setCurrencyLoading,
      setOriginFinancialId,
    },
    func: {
      getCurrency,
      add,
      getAlllOriginFinancial,
      update,
      deleteOriginFinancial,
    },
    form: { form },
  };

  return (
    <OriginFinancialContext.Provider value={defaultValue}>
      {children}
    </OriginFinancialContext.Provider>
  );
};

export const UseOriginFinancial = () => useContext(OriginFinancialContext);
