import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  UploadProps,
  message,
} from "antd";
import { FC, useEffect, useMemo } from "react";
import { useShipment } from "../../context";
import { GetProp } from "antd/lib";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { ROUTES } from "src/models/enums/routes";
import { Upload } from "antd/lib";
import { dummyRequest } from "src/helper/dummyRequest";
import { S2 } from "src/components/UiKit/Typography";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadFileFormContainer } from "../../style";
import { IShipmentTabs } from "../../models";
import { DataEntryModal } from "src/components/UiKit/DataEntryModal";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
interface IFormValues {
  shippingDocId: number;
  file: FileType;
}
export const UploadFileForm: FC = () => {
  const {
    value: {
      editMode,
      file,
      hasFile,
      uploadProgress,
      tableEditMode,
      openModal,
      loadings,
      allShippingDocs,
    },
    func: {
      addFileDetail,
      updateFileDetail,
      getAllShipmentInfo,
      addShippingDoc,
    },
    dispatch: {
      setFile,
      setEditMode,
      setActiveKey,
      setTableEditMode,
      setHasFile,
      setBackEditMode,
      setOpenMOdal,
    },
    form: { uploadFileForm },
  } = useShipment()!;
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const values = Form.useWatch([], uploadFileForm);

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
      setFile(undefined);
    }
    if (!file) {
      message.error("Please upload a file before submitting.");
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return isJpgOrPng;
  };
  const onClickCancel = () => {
    if (tableEditMode) {
      setTableEditMode(false);
      uploadFileForm?.resetFields();
      setFile(undefined);
      setHasFile(false);
      return;
    }
    setEditMode(false);
    setTableEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };
  const onClickReset = () => {
    uploadFileForm?.resetFields();
    setFile(undefined);
    setHasFile(false);
  };
  const onClickBack = () => {
    setActiveKey(IShipmentTabs.basicInfo);
    setBackEditMode(true);
  };

  const onFinish = (values: IFormValues) => {
    if (tableEditMode) {
      updateFileDetail(values.shippingDocId);
    } else {
      addFileDetail(values.shippingDocId);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getAllShipmentInfo();
  }, [getAllShipmentInfo, isEdited]);

  return (
    <UploadFileFormContainer>
      <Form form={uploadFileForm} onFinish={onFinish}>
        <Col span={24}>
          <S2 className="py-[16px] mb-[24px]">
            {editMode
              ? words.orders.shipment.shipmentUploadEditInfo
              : words.orders.shipment.shipmentUploadInfo}
          </S2>
        </Col>
        <Col span={24} className="py-[16px] mb-[24px]">
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                name={"shippingDocId"}
                label={words.orders.shipment.doc}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={words.global.select}
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                  loading={loadings.getAllShippingDocs}
                  options={allShippingDocs?.map((item, index) => ({
                    label: item.title,
                    value: item.id,
                  }))}
                  dropdownRender={(menu) => (
                    <>
                      <Space style={{ padding: "16px 8px 4px" }}>
                        <div
                          onClick={() => setOpenMOdal(true)}
                          className="cursor-pointer flex justify-center items-center"
                        >
                          <span className="material-icons text-[16px] text-[#001377]">
                            add
                          </span>
                          <div className="addHolder">
                            {words.orders.bankingOperation.addNewDocument}
                          </div>
                        </div>
                      </Space>
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                />
              </FormItem>
            </Col>
            <Col span={16}>
              <div className="uploadHolder">
                <div className="p-x[12px] flex flex-col justify-center gap-[12px]">
                  <div className="text-[14px] flex gap-[4px]">
                    {words.global.fileUpload}
                    <div className="text-[#EC2D30]">*</div>
                  </div>
                  <div className="text-[10px]">
                    {words.global.fileUploadInfo}
                  </div>
                </div>
                <div
                  className={
                    file || hasFile ? "w-[66px] h-[66px]" : `fileHolder`
                  }
                >
                  <FormItem
                    name="file"
                    label=""
                    rules={
                      !hasFile ? [{ required: true }] : [{ required: false }]
                    }
                    className="uploadContainer"
                  >
                    <Upload
                      customRequest={dummyRequest}
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      disabled={loadings.addShippingDoc || loadings.updateForm}
                    >
                      {loadings.addShippingDoc ? (
                        <div className="relative w-[66px] h-[66px] flex justify-center items-center">
                          <Spin
                            indicator={<LoadingOutlined spin />}
                            size="large"
                            className="flex justify-center items-center"
                          />
                          <span className="absolute top-[38%] left-[40%] ">
                            {uploadProgress}
                          </span>
                        </div>
                      ) : (
                        <Button
                          type={file || hasFile ? "text" : "default"}
                          icon={
                            file || hasFile ? (
                              <img
                                src={"/assets/images/fileUploaded.svg"}
                                alt="fileUploaded"
                              />
                            ) : (
                              <span className="material-icons flex justify-center items-center text-[20px]">
                                attach_file
                              </span>
                            )
                          }
                          className="text-[10px]"
                        >
                          {file || hasFile ? "" : words.global.add}
                        </Button>
                      )}
                    </Upload>
                  </FormItem>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="flex items-center !w-[100%] !h-[104px] ">
            {editMode || tableEditMode ? (
              <Row className="!w-full">
                <Col span={24}>
                  <div className="flex justify-end gap-[16px] h-full">
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                      disabled={tableEditMode}
                    >
                      <div className="!min-w-[85px] !h-[100%]">
                        <Button
                          type="default"
                          disabled={!file && isDisabledResetForm}
                          onClick={() => tableEditMode && onClickCancel()}
                        >
                          {tableEditMode
                            ? words.global.cancel
                            : words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      onClick={() => {
                        uploadFileForm?.submit();
                      }}
                      loading={
                        tableEditMode
                          ? loadings.updateFileDetail
                          : loadings.addShippingDoc
                      }
                      disabled={!file && isDisabledResetForm}
                    >
                      {tableEditMode ? words.global.saveEdit : words.global.add}
                    </Button>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row gutter={16} className="!w-full ">
                <Col span={12}>
                  <div className="flex justify-between h-full">
                    <div className="min-w-[85px]">
                      <Button type="default" onClick={onClickBack}>
                        {words.global.back}
                      </Button>
                    </div>
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] !h-[100%]">
                        <Button
                          type="default"
                          disabled={!file && isDisabledResetForm}
                        >
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="flex justify-between">
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      onClick={() => {
                        uploadFileForm?.submit();
                      }}
                      loading={loadings.addShippingDoc}
                      disabled={!file && isDisabledResetForm}
                    >
                      {words.global.add}
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      className="!min-w-[85px]"
                      onClick={() => {
                        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
                      }}
                    >
                      {words.global.end}
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
        <Divider dashed className="!border-[#BFE2E0] !mt-0 " />
        <DataEntryModal
          open={openModal}
          title={words.orders.shipment.addNewDocumentType}
          setIsModalOpen={setOpenMOdal}
          handleOk={addShippingDoc}
          label={words.orders.shipment.titleDocument}
          confirmLOading={loadings.addShippingDoc}
          confirmLoading={false}
        />
      </Form>
    </UploadFileFormContainer>
  );
};
