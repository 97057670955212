import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import useLanguage from "src/store/language";
import useOrderStore from "src/store/order";

type PageTitleKeys = keyof typeof ROUTES;
export type PageTitleKeysType = (typeof ROUTES)[PageTitleKeys];

export const usePageTitle = (path: PageTitleKeysType) => {
  const { words } = useLanguage();
  const { orderCode } = useOrderStore();
  const { orderId } = useParams();
  const pageTitles = {
    [ROUTES.home]: words.pageTitles.home,
    [ROUTES.login]: words.pageTitles.login,
    [ROUTES.design]: words.sidebarItems.design,
    [ROUTES.suppliers]: words.basicInformation.suppliers.suppliers,
    [ROUTES.basicInformation]: words.basicInformation.basicInformation,
    [ROUTES.editSupplier]: words.basicInformation.suppliers.suppliers,
    [ROUTES.viewSuppliers]: words.basicInformation.suppliers.suppliers,
    [ROUTES.inspectionCompanies]:
      words.basicInformation.inspectionCompanies.inspectionCompanies,
    [ROUTES.viewInspectionCompanies]:
      words.basicInformation.inspectionCompanies.inspectionCompanies,
    [ROUTES.editInspectionCompanies]:
      words.basicInformation.inspectionCompanies.inspectionCompanies,
    [ROUTES.insuranceCompanies]:
      words.basicInformation.insuranceCompanies.insuranceCompanies,
    [ROUTES.viewInsuranceCompanies]:
      words.basicInformation.insuranceCompanies.insuranceCompanies,
    [ROUTES.editInsuranceCompanies]:
      words.basicInformation.insuranceCompanies.insuranceCompanies,
    [ROUTES.transportCompanies]:
      words.basicInformation.transportCompanies.transportCompanies,
    [ROUTES.viewTransportCompanies]:
      words.basicInformation.transportCompanies.transportCompanies,
    [ROUTES.editTransportCompany]:
      words.basicInformation.transportCompanies.transportCompanies,
    [ROUTES.operatingBanks]:
      words.basicInformation.operatingBanks.operatingBanks,
    [ROUTES.viewOperatingBank]:
      words.basicInformation.operatingBanks.operatingBanks,
    [ROUTES.editOperatingBank]:
      words.basicInformation.operatingBanks.operatingBanks,
    [ROUTES.clearanceWorkers]:
      words.basicInformation.clearanceWorkers.clearanceWorkers,
    [ROUTES.viewClearanceWorkers]:
      words.basicInformation.clearanceWorkers.clearanceWorkers,
    [ROUTES.editClearanceWorkers]:
      words.basicInformation.clearanceWorkers.clearanceWorkers,
    [ROUTES.ports]: words.basicInformation.ports.ports,
    [ROUTES.countries]: words.basicInformation.countries.countries,

    [ROUTES.averagePrice]: words.basicInformation.averagePrice.averagePrice,
    [ROUTES.warehouses]: words.basicInformation.warehouses.warehouses,
    [ROUTES.viewWarehouses]: words.basicInformation.warehouses.warehouses,
    [ROUTES.editWarehouses]: words.basicInformation.warehouses.warehouses,
    [ROUTES.products]: words.basicInformation.products.products,
    [ROUTES.unitsOfMeasurement]:
      words.basicInformation.unitsOfMeasurement.unitsOfMeasurement,
    [ROUTES.currencies]: words.basicInformation.currencies.currencies,
    [ROUTES.vessels]: words.basicInformation.vessels.vessels,
    [ROUTES.CreateRole]: words.systemSetting.systemSetting,
    [ROUTES.CreateUser]: words.systemSetting.systemSetting,
    [ROUTES.AssignActionToRole]: words.systemSetting.systemSetting,
    [ROUTES.AssignRoleToUser]: words.systemSetting.systemSetting,
    [ROUTES.settings]: words.systemSetting.systemSetting,
    [ROUTES.orders]: words.orders.orders,
    [ROUTES.orderManagement]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.ViewOrder]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.origin]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewOrigin]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.vip]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewVip]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.addOrder]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewAddOrder]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.vesselArrive]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewVesselArrive]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.warehousing]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewWarehousing]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.customDeclaration]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewCustomDeclaration]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.Agriculture]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewAgriculture]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.originFinancial]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewOriginFinancial]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.rates]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewRates]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.insurance]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewInsurance]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.inspectionDocument]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewInspection]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.selling]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.sellingView]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.customsFormalities]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewCustomsFormalities]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.bankingOperations]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewBankingOperations]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.shipment]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewShipment]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.podFinancial]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewPOD]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.InspectionInfoDocs]:
      words.basicInformation.inspectionInfoDocs.inspectionInfoDocs,
    [ROUTES.customOperation]:
      words.basicInformation.customOperation.customOperation,
    [ROUTES.bankingDoc]: words.basicInformation.bankingDOC.bankingDOC,
    [ROUTES.shippingDoc]: words.basicInformation.shippingDoc.shippingDoc,
  };

  return pageTitles[
    Object.keys(pageTitles).find((key) =>
      key.replace(/[^a-zA-Z]+/g, "").includes(path.replace(/[^a-zA-Z]+/g, ""))
    ) as PageTitleKeysType
  ];
};
