import { Col, Flex, Row, Skeleton } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { OrderService } from "src/services/Order/order.service";
import dayjs from "dayjs";
import { IGetArgiculture } from "src/services/Order/models/result.models";
import { ViewAgricultureContainer } from "../style";

export const ViewAgriculture: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [agricultureInfo, setAgricultureInfo] = useState<IGetArgiculture>();

  const getAgricultureInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetArgiculture } = new OrderService();
      const result = await GetArgiculture(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setAgricultureInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    getAgricultureInfo();
  }, [getAgricultureInfo]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">
        {words.orders.agriculture.viewAAgriculture}
      </div>
      <Row
        className="w-full bg-[#f2f9f9] py-[24px] px-[16px]"
        gutter={[16, 24]}
      >
        <Col span={4} className="!font-medium">
          {words.orders.agriculture.ministryOfAgricultureFinalApproval}
        </Col>
        <Col span={4} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={4} className="!font-medium">
          {words.orders.agriculture.tenderingInBBK}
        </Col>
        <Col span={4} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={4} className="!font-medium">
          {words.orders.agriculture.ministryOfAgricultureApprovalLetter}
        </Col>
        <Col span={4} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma
        headerName={words.orders.agriculture.viewAAgricultureHeader}
      />
      <Flex justify="center">
        <ViewAgricultureContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">
                {words.orders.agriculture.viewAAgriculture}
              </div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={4} className="!font-medium">
                  {words.orders.agriculture.ministryOfAgricultureFinalApproval}
                </Col>
                <Col span={4} className="!font-normal text-[#4B4B4B]">
                  {agricultureInfo?.final
                    ? dayjs(agricultureInfo?.final).format("YYYY-MM-DD")
                    : "---"}
                </Col>
                <Col span={4} className="!font-medium">
                  {words.orders.agriculture.tenderingInBBK}
                </Col>
                <Col span={4} className="!font-normal text-[#4B4B4B]">
                  {agricultureInfo?.tradingBbk
                    ? dayjs(agricultureInfo?.tradingBbk).format("YYYY-MM-DD")
                    : "---"}
                </Col>
                <Col span={4} className="!font-medium">
                  {words.orders.agriculture.ministryOfAgricultureApprovalLetter}
                </Col>
                <Col span={4} className="!font-normal text-[#4B4B4B]">
                  {agricultureInfo?.letter
                    ? dayjs(agricultureInfo?.letter).format("YYYY-MM-DD")
                    : "---"}
                </Col>
              </Row>
            </>
          )}
        </ViewAgricultureContainer>
      </Flex>
    </div>
  );
};
