import { FC } from "react";
import { ShippingDoc } from "src/modules/ShippingDoc";
import { ShippingDocProvider } from "src/modules/ShippingDoc/context";

export const ShippingDocPage: FC = () => {
  return (
    <ShippingDocProvider>
      <ShippingDoc />
    </ShippingDocProvider>
  );
};
