import { ConfigProvider } from "antd";
import { FC, ReactNode } from "react";
import { OverrideToken } from "antd/es/theme/interface";
import { GlobalStyle } from "../styles/global.style";
import useLanguage from "src/store/language";
import { ThemeProvider } from "styled-components";
import { useTheme } from "src/theme";
import locale from "antd/locale/fa_IR";
import "dayjs/locale/fa";

interface IProps {
  children: ReactNode;
}

const ConfigProviders: FC<IProps> = ({ children }) => {
  const { isRtl } = useLanguage();
  const {
    theme: { colors },
  } = useTheme();
  const LightThemeComponents: OverrideToken = {
    Button: {
      borderRadius: 8,
      colorBgBase: colors.gray[100],
      fontSize: 12,
      contentFontSizeSM: 12,
      controlHeight: 42,
      paddingContentHorizontal: 24,
      colorBorder: colors.primary[300],
      colorBgContainer: colors.gray[100],
      colorText: colors.gray[600],
      colorBgContainerDisabled: colors.primary[400],
      colorTextDisabled: colors.secondary[300],
      primaryColor: colors.fixWhite,
      colorPrimaryBg: colors.primary[600],
      colorPrimaryActive: colors.primary[700],
      colorPrimary: colors.primary[600],
      colorPrimaryBorder: colors.primary[600],
      colorPrimaryHover: colors.primary[400],
      colorError: colors.danger[600],
      borderColorDisabled: colors.primary[400],
      defaultGhostColor: colors.gray[600],
      ghostBg: colors.white,
      defaultGhostBorderColor: colors.gray[100],
      defaultActiveBg: colors.gray[100],
      defaultActiveBorderColor: colors.gray[100],
      defaultActiveColor: colors.gray[300],
      defaultBg: colors.gray[100],
      defaultBorderColor: colors.gray[100],
      defaultColor: colors.gray[600],
      defaultHoverBg: colors.white,
      defaultHoverBorderColor: colors.gray[300],
      defaultHoverColor: colors.gray[500],
      defaultShadow: colors.gray[100],
      paddingInline: 8,
      paddingBlock: 8,
    },
    Input: {
      // colorTextPlaceholder: "#A3AED0",
      colorBgContainerDisabled: colors.gray[300],
      colorTextDisabled: colors.title,
      colorBgBase: colors.gray[100],
      colorPrimaryBg: colors.gray[100],
      colorBorderBg: colors.gray[100],
      colorBgContainer: colors.gray[100],
      borderRadius: 12,
      controlHeight: 48,
      colorBorder: colors.gray[100],
      hoverBg: colors.gray[100],
      hoverBorderColor: colors.gray[200],
      activeBg: colors.gray[100],
      activeBorderColor: colors.gray[600],
      activeShadow: "-4px 0px 5px 0px rgba(0, 140, 132, 0.1)",
      colorBgBlur: colors.gray[100],
      colorTextPlaceholder: colors.gray[500],
    },
    InputNumber: {
      colorBgContainerDisabled: colors.disable2,
      colorTextDisabled: colors.title,
      colorBgBase: colors.title,
      colorPrimaryBg: colors.title,
      colorBorderBg: colors.title,
      colorBgContainer: colors.gray[100],
      borderRadius: 12,
      controlHeight: 48,
      colorBorder: colors.gray[100],
      hoverBg: colors.gray[100],
      hoverBorderColor: colors.gray[200],
      activeBg: colors.gray[100],
      activeBorderColor: colors.gray[600],
      activeShadow: "-4px 0px 5px 0px rgba(0, 140, 132, 0.1)",
      colorTextPlaceholder: colors.gray[500],
    },
    // Dropdown: {
    //   colorPrimaryBg: "rgba(31, 34, 41, 0.9)",
    //   colorBgBase: "rgba(31, 34, 41, 0.9)",
    //   colorBgElevated: "rgba(31, 34, 41, 0.9)",
    //   colorText: "#949494",
    // },
    Select: {
      colorBgElevated: colors.white,
      colorTextPlaceholder: colors.gray[500],
      controlItemBgActive: colors.selected,
      colorBgContainerDisabled: colors.disable2,
      colorTextDisabled: colors.title,
      colorBgBase: colors.title,
      colorPrimaryBg: colors.title,
      colorBorderBg: colors.title,
      colorBgContainer: colors.gray[100],
      borderRadius: 12,
      controlHeight: 48,
      colorBorder: colors.gray[100],
      optionActiveBg: colors.gray[50],
      colorPrimaryHover: colors.gray[200],
      colorPrimaryActive: colors.title,
      colorPrimary: colors.gray[600],
    },
    Radio: {
      colorBgElevated: colors.white,
      colorTextPlaceholder: colors.secondaryText,
      controlItemBgActive: colors.selected,
      colorBgContainerDisabled: colors.disable2,
      colorTextDisabled: colors.title,
      colorBgBase: colors.title,
      colorPrimaryBg: colors.title,
      colorBorderBg: colors.title,
      colorBgContainer: colors.white,
      colorBorder: colors.gray[400],
      colorPrimaryHover: colors.primary[400],
      colorPrimaryActive: colors.title,
      colorPrimary: colors.primary[600],
      buttonBg: colors.white,
      buttonCheckedBg: colors.primary[400],
    },
    Typography: {
      fontSizeHeading1: 24,
      fontSizeHeading2: 20,
      fontSizeHeading3: 18,
      fontSizeHeading4: 16,
      fontSizeHeading5: 14,
      fontWeightStrong: 700,
      fontSize: 14,
      colorText: colors.title,
      // colorText: colors.gray[600],
      colorTextHeading: colors.title,
    },
    // Drawer: {
    //   colorBgLayout: "#282B34",
    //   colorBgContainer: "#282B34",
    //   colorBgElevated: "#282B34",
    //   colorText: "#D3D3D3",
    //   colorTextBase: "#D3D3D3",
    // },
    // Menu: {
    //   colorItemBgActive: "#26A69A",
    //   colorText: "#D3D3D3",
    //   colorItemBgSelected: "#24E3BF1A",
    //   radiusItem: 2,
    //   colorItemTextSelected: "#25E3BF",
    //   colorItemText: "#D3D3D3",
    //   colorSubItemBg: "transparent",
    // },
    Modal: {
      colorBgMask: colors.primary[100].concat("30"),
      titleColor: colors.title,
      titleFontSize: 14,
    },
    Tabs: {
      itemActiveColor: colors.primary[600],
      itemSelectedColor: colors.primary[600],
      itemHoverColor: colors.primary[400],
      itemColor: colors.disable4,
      titleFontSize: 18,
      cardHeight: 80,
      horizontalItemPadding: "24px",
      horizontalItemGutter: 8,
      inkBarColor: undefined,
      colorBorderSecondary: colors.secondary[300],
    },
    Table: {
      colorBgContainer: colors.white,
      colorText: colors.primaryText,
      colorTextHeading: colors.title,
      expandIconBg: colors.gray[300],
      rowExpandedBg: colors.gray[300],
      headerBg: colors.secondary[400],
      margin: 16,
      // cellPaddingBlock: 16,
      // cellPaddingInline: 16,
      // lineHeight: 3,
      headerColor: colors.title,
      headerSplitColor: colors.secondary[400],
      stickyScrollBarBg: colors.black,
      colorLink: colors.primary[600],
      colorPrimary: colors.primary[600],
      colorLinkActive: colors.primary[600],
      borderColor: colors.border2,
      fontSize: 12,
    },
    Divider: {
      colorSplit: colors.border6,
      marginLG: 12,
      boxShadow: "0 4 5 0 rgba(113, 130, 183, 0.10)",
    },
    DatePicker: {
      activeBg: colors.gray[100],
      hoverBg: colors.gray[100],
      colorBgContainer: colors.gray[100],
      controlHeight: 48,
      borderRadius: 12,
      colorBorder: colors.gray[100],
      activeBorderColor: colors.gray[600],
      activeShadow: "-4px 0px 5px 0px rgba(0, 140, 132, 0.1)",
      colorPrimaryHover: colors.gray[100],
      colorPrimary: colors.gray[600],
      colorPrimaryBorder: colors.gray[600],
      cellHoverBg: colors.gray[200],
      hoverBorderColor: colors.gray[200],
      colorTextPlaceholder: colors.gray[500],
    },
    Checkbox: {
      colorPrimary: colors.primary[600],
      colorPrimaryHover: colors.primary[500],
      colorPrimaryBorder: colors.primary[400],
    },
  };
  const { theme } = useTheme();
  const antdTheme = {
    components: LightThemeComponents,
  };
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider
        theme={antdTheme}
        direction={isRtl ? "rtl" : "ltr"}
        locale={locale}
      >
        <GlobalStyle />
        {children}
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default ConfigProviders;
