import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Space,
} from "antd";
import { FC, useEffect, useMemo } from "react";
import { UploadFileFormContainer } from "../style";
import { S2 } from "src/components/UiKit/Typography";
import { FormItem } from "src/components/UiKit/FormItem";
import { JalaliLocaleListener } from "antd-jalali";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useBankingOperations } from "../context";
import fa_IR from "antd/lib/locale/fa_IR";
import { IAddBankF } from "src/services/Order/models/args.models";
import { DataEntryModal } from "src/components/UiKit/DataEntryModal";
import { ROUTES } from "src/models/enums/routes";

export const UploadFileForm: FC = () => {
  const {
    value: { editMode, loadings, tableEditMode, openModal, allBankDocs },
    dispatch: { setTableEditMode, setOpenMOdal },
    form: { form },
    func: { add, update, getAllBankFs, addBankDoc },
  } = useBankingOperations()!;
  const { words } = useLanguage();
  const values = Form.useWatch([], form);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    if (tableEditMode) {
      setTableEditMode(false);
      form?.resetFields();
    } else {
      navigate(ROUTES.orderManagement.concat(`/${orderId}`));
    }
  };
  const onClickReset = () => {
    form?.resetFields();
  };

  const onFinish = (values: IAddBankF) => {
    if (tableEditMode) {
      update(values);
    } else {
      add(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getAllBankFs();
  }, [form, getAllBankFs, isEdited]);

  return (
    <UploadFileFormContainer>
      <Form form={form} onFinish={onFinish}>
        <Col span={24}>
          <S2 className="py-[16px] mb-[24px]">
            {editMode
              ? words.orders.bankingOperation.editBankingOperationInfo
              : words.orders.bankingOperation.bankingOperationInfo}
          </S2>
        </Col>
        <Col span={24} className="py-[16px] mb-[24px]">
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name={"bankDocId"}
                label={words.orders.bankingOperation.operationTitle}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={words.global.select}
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                  loading={loadings.allBankDocs}
                  options={allBankDocs?.map((item, index) => ({
                    label: item.title,
                    value: item.id,
                  }))}
                  dropdownRender={(menu) => (
                    <>
                      <Space style={{ padding: "16px 8px 4px" }}>
                        <div
                          onClick={() => setOpenMOdal(true)}
                          className="cursor-pointer flex justify-center items-center"
                        >
                          <span className="material-icons text-[16px] text-[#001377]">
                            add
                          </span>
                          <div className="addHolder">
                            {words.orders.bankingOperation.addNewDocument}
                          </div>
                        </div>
                      </Space>
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <ConfigProvider locale={fa_IR}>
                <JalaliLocaleListener />
                <FormItem
                  name={"date"}
                  label={words.orders.bankingOperation.date}
                  rules={[{ required: true }]}
                >
                  <DatePicker placeholder={words.global.select} />
                </FormItem>
              </ConfigProvider>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="flex items-center !w-[100%] !h-[104px] ">
            <Row className="!w-full">
              <Col span={24}>
                <div className="flex justify-end gap-[16px] h-full">
                  {tableEditMode || isEdited ? (
                    <div className="!min-w-[85px] !h-[100%]">
                      <Button type="default" onClick={onClickCancel}>
                        {words.global.cancel}
                      </Button>
                    </div>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] !h-[100%]">
                        <Button
                          type="default"
                          disabled={isDisabledResetForm}
                          onClick={onClickReset}
                        >
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  )}
                  <Button
                    className="!min-w-[85px]"
                    type="primary"
                    onClick={() => {
                      form?.submit();
                    }}
                    loading={tableEditMode ? loadings.update : loadings.add}
                    disabled={isDisabledResetForm}
                  >
                    {tableEditMode ? words.global.saveEdit : words.global.add}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Divider dashed className="!border-[#BFE2E0] !mt-0" />
        <DataEntryModal
          open={openModal}
          title={words.orders.bankingOperation.addNewDocumentType}
          setIsModalOpen={setOpenMOdal}
          handleOk={addBankDoc}
          label={words.orders.bankingOperation.titleDocument}
          confirmLOading={loadings.addBankDoc}
          confirmLoading={false}
        />
      </Form>
    </UploadFileFormContainer>
  );
};
