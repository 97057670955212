import _ from "lodash";
import { USER_Language } from "src/constants";
import Swal from "sweetalert2";

export const responseHandler = (res: any) => {
  const { response } = res;

  const language = localStorage.getItem(USER_Language);
  if (response?.status === 404) {
    Swal.fire({
      text: `${language && language === "Fa" ? "پیدا نشد" : "Not fount"}`,
      title: `${language && language === "Fa" ? "خطای 404" : "Error 404"}`,
      color: "#F04849",
      confirmButtonText: `${language && language === "Fa" ? "بستن" : "Close"}`,
      background: "#F4F7FE",
      iconHtml:
        '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
    });

    return;
  }

  if (response?.status === 401 || response?.status === 403) {
    Swal.fire({
      text: `${
        language && language === "Fa"
          ? "شما اجازه دسترسی ندارید."
          : "You do not have permission to access."
      }`,
      title: `${language && language === "Fa" ? "خطا" : "Error"}`,
      color: "#F04849",
      confirmButtonText: `${language && language === "Fa" ? "بستن" : "Close"}`,
      background: "#F4F7FE",
      iconHtml:
        '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
    });

    return;
  }

  if (response?.status >= 500) {
    Swal.fire({
      text: `${
        language && language === "Fa"
          ? "خطای پاسخ از سمت سرور"
          : "Response error from the server side"
      }`,
      title: `${language && language === "Fa" ? "خطا" : "Error"}`,
      color: "#F04849",
      confirmButtonText: `${language && language === "Fa" ? "بستن" : "Close"}`,
      background: "#F4F7FE",
      iconHtml:
        '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
    });
    return;
  }
  if (!response && res.code === "ERR_NETWORK") {
    Swal.fire({
      text: `${
        language && language === "Fa"
          ? "خطای اتصال به اینترنت، دوباره امتحان کنید."
          : "Internet connection error, try again."
      }`,
      title: `${language && language === "Fa" ? "خطا" : "Error"}`,
      color: "#F04849",
      confirmButtonText: `${language && language === "Fa" ? "بستن" : "Close"}`,
      background: "#F4F7FE",
      iconHtml:
        '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
    });
    return;
  }
  if (response.data) {
    if (response.data.errors) {
      Object.keys(response.data.errors).forEach((item: string) => {
        if (item) {
          return Swal.fire({
            text: `${
              _.isArray(item)
                ? response.data.errors[item].join(",")
                : response.data.errors[item]
            }`,
            title: `${language && language === "Fa" ? "خطا" : "Error"}`,
            color: "#F04849",
            confirmButtonText: `${
              language && language === "Fa" ? "بستن" : "Close"
            }`,
            background: "#F4F7FE",
            iconHtml:
              '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
          });
        }
      });
      return;
    } else {
      Object.keys(response.data).forEach((item: string) => {
        if (item) {
          return Swal.fire({
            text: `${
              _.isArray(item)
                ? response.data[item].join(",")
                : response.data[item]
            }`,
            title: `${language && language === "Fa" ? "خطا" : "Error"}`,
            color: "#F04849",
            confirmButtonText: `${
              language && language === "Fa" ? "بستن" : "Close"
            }`,
            background: "#F4F7FE",
            iconHtml:
              '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
          });
        }
      });
      return;
    }
  }

  return response;
};
