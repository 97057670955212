import { Divider, Typography } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";

export const BankingDOCHeader: FC = () => {
  const { words } = useLanguage();
  return (
    <div>
      <div className="flex flex-row gap-[12px] p-[24px] ">
        <span className="material-symbols-outlined">table_rows</span>
        <Typography className="!text-[16px] !font-bold">
          {words.basicInformation.bankingDOC.bankingDOCMiddleHeader}
        </Typography>
      </div>
      <Divider className="pb-[20px]" />
    </div>
  );
};
