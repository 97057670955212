import { Col, Table } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { IGetPODFinancialOther } from "src/services/Order/models/result.models";
import { usePOD } from "../../context";
import { UploadFileTableContainer } from "../../style";
import moment from "moment-jalaali";
import dayjs from "dayjs";

export const OtherInfoTable: FC = () => {
  const {
    form: { OtherInfoForm },
    dispatch: { setTableEditMode, setPodOtherId },
    value: { podFinancialOther, loadings },
    func: { deletePODOther },
  } = usePOD()!;
  const { words } = useLanguage();

  const Columns: ColumnsType<IGetPODFinancialOther> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.orders.pod.title,
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "274px",
    },
    {
      title: words.orders.pod.date,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "274px",
      render: (text) => (text ? moment(text).format("jYYYY.jMM.jDD") : "--"),
    },
    {
      title: words.orders.pod.amountOther,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "255px",
      render: (text: number) => text?.toLocaleString("en-US"),
    },
    {
      title: words.global.tools,
      key: "id",
      width: "130px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setTableEditMode(true);
              setPodOtherId(record.id);
              const newData = { ...record, date: dayjs(record.date) };
              OtherInfoForm?.setFieldsValue(newData);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>

          <DPopconfirm
            deleteClassName
            description={words.orders.pod.deleteDesc}
            onConfirm={async () => {
              await deletePODOther(record.id);
            }}
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];

  return (
    <UploadFileTableContainer gutter={[0, 24]}>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={loadings.getAllPODFinancialOther}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={podFinancialOther?.podFinancials}
          pagination={false}
        />
      </Col>
    </UploadFileTableContainer>
  );
};
