import { App, Form } from "antd";
import { RcFile } from "antd/es/upload";
import { FormInstance, UploadFile } from "antd/lib";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { uploadFile } from "src/helper/uploadZipFile";
import { ROUTES } from "src/models/enums/routes";
import { IAddVesselArrival } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import { OrderUrl } from "src/services/Order/urls";
import useLanguage from "src/store/language";

interface IContext {
  value: {
    editMode: boolean;
    deleteLoading: boolean;
    loading: boolean;
    hasFile: boolean;
    file: UploadFile | undefined;
    progress: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setHasFile: Dispatch<SetStateAction<boolean>>;
    setFile: Dispatch<SetStateAction<UploadFile | undefined>>;
  };
  func: {
    deleteVesselArrive: () => void;
    add: (values: IAddVesselArrival) => void;
    update: (values: IAddVesselArrival) => void;
    getVesselArriveInfo: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}

export const VesselArriveContext = createContext<IContext | undefined>(
  undefined
);

export const VesselArriveProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [vesselArriveId, setVesselArriveId] = useState<number>();
  const [file, setFile] = useState<UploadFile>();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  ////////////////////////////////functions////////////////////////////////
  const deleteVesselArrive = async () => {
    if (!orderId) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteVesselArrival } = new OrderService();
      const result = await DeleteVesselArrival(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.vesselArrive.deleteVesselArriveMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const newVesselArrivalFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.NewVesselArrivalFile}`
        ),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const add = async (values: IAddVesselArrival) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddVesselArrival } = new OrderService();
      const newValues = {
        eta: values.eta
          ? moment(
              (values?.eta as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        arrivalDate: values.arrivalDate
          ? moment(
              (values?.arrivalDate as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        berthingDate: values.berthingDate
          ? moment(
              (values?.berthingDate as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        dischargeStartDate:
          values.dischargeOperation && values.dischargeOperation[0]
            ? moment(
                (values?.dischargeOperation?.[0] as Dayjs).format(
                  "jYYYY-jMM-jDD"
                ),
                "jYYYY-jMM-jDD"
              ).format("YYYY-MM-DD")
            : null,
        dischargeEndDate:
          values.dischargeOperation && values.dischargeOperation[1]
            ? moment(
                (values?.dischargeOperation?.[1] as Dayjs).format(
                  "jYYYY-jMM-jDD"
                ),
                "jYYYY-jMM-jDD"
              ).format("YYYY-MM-DD")
            : null,
        deliveryDate: values.deliveryDate
          ? moment(
              (values?.deliveryDate as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
      };
      const result = await AddVesselArrival(parseInt(orderId), newValues);
      if (result && result.status === 200 && result.data) {
        setVesselArriveId(result.data);
        if (file) {
          const resultFile = await newVesselArrivalFile(result.data);
          if (resultFile) {
            navigate(ROUTES.orderManagement.concat(`/${orderId}`));
            message.success(words.orders.vesselArrive.vesselArriveMessage);
          }
        } else {
          navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          message.success(words.orders.vesselArrive.vesselArriveMessage);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateVesselArrivalFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.UpdateVesselArrivalFile}`
        ),
        extraData: { name: "testUpdate" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (values: IAddVesselArrival) => {
    if (!orderId || !vesselArriveId) return undefined;
    setLoading(true);
    try {
      const { UpdateVesselArrival } = new OrderService();
      const newValues = {
        eta: values.eta
          ? moment(
              (values?.eta as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        arrivalDate: values.arrivalDate
          ? moment(
              (values?.arrivalDate as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        berthingDate: values.berthingDate
          ? moment(
              (values?.berthingDate as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        dischargeStartDate:
          values.dischargeOperation && values.dischargeOperation[0]
            ? moment(
                (values?.dischargeOperation?.[0] as Dayjs).format(
                  "jYYYY-jMM-jDD"
                ),
                "jYYYY-jMM-jDD"
              ).format("YYYY-MM-DD")
            : null,
        dischargeEndDate:
          values.dischargeOperation && values.dischargeOperation[1]
            ? moment(
                (values?.dischargeOperation?.[1] as Dayjs).format(
                  "jYYYY-jMM-jDD"
                ),
                "jYYYY-jMM-jDD"
              ).format("YYYY-MM-DD")
            : null,
        deliveryDate: values.deliveryDate
          ? moment(
              (values?.deliveryDate as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
      };
      const result = await UpdateVesselArrival(parseInt(orderId), newValues);

      if (result && result.status === 200) {
        if (file) {
          let resultFile;
          if (hasFile) {
            resultFile = await updateVesselArrivalFile(vesselArriveId);
          } else {
            resultFile = await newVesselArrivalFile(vesselArriveId);
          }
          if (resultFile) {
            navigate(ROUTES.orderManagement.concat(`/${orderId}`));
            message.success(
              words.orders.vesselArrive.updateVesselArriveMessage
            );
          }
        } else {
          navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          message.success(words.orders.vesselArrive.updateVesselArriveMessage);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getVesselArriveInfo = useCallback(async () => {
    if (!orderId) return undefined;
    const { GetVesselArrival } = new OrderService();
    const result = await GetVesselArrival(parseInt(orderId));
    if (result && result.status === 200 && result.data) {
      const newData = {
        ...result.data,
        eta: result.data.eta ? dayjs(result.data.eta) : undefined,
        arrivalDate: result.data.arrivalDate
          ? dayjs(result.data.arrivalDate)
          : undefined,
        berthingDate: result.data.berthingDate
          ? dayjs(result.data.berthingDate)
          : undefined,
        dischargeOperation:
          result.data.dischargeStartDate || result.data.dischargeEndDate
            ? [
                dayjs(result?.data?.dischargeStartDate),
                dayjs(result?.data?.dischargeEndDate),
              ]
            : [],
        deliveryDate: result.data.deliveryDate
          ? dayjs(result.data.deliveryDate)
          : undefined,
      };
      setHasFile(result.data.hasFile);
      form.setFieldsValue(newData);
      setVesselArriveId(result.data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  const ContextValue: IContext = {
    value: { deleteLoading, editMode, file, hasFile, loading, progress },
    dispatch: { setEditMode, setFile, setHasFile },
    func: { add, deleteVesselArrive, getVesselArriveInfo, update },
    form: { form },
  };
  return (
    <VesselArriveContext.Provider value={ContextValue}>
      {children}
    </VesselArriveContext.Provider>
  );
};

export const useVesselArrive = () => useContext(VesselArriveContext);
