import { Row, Tabs } from "antd";
import { FC } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { UseInsurance } from "./context";
import { TabsProps } from "antd/lib";
import { IInsuranceTabs } from "./models";
import { AddInsuranceForm } from "./components/AddInsuranceForm";
import { InsuranceContainer } from "./style";
import { P1 } from "src/components/UiKit/Typography";
import { UploadFile } from "./components/UploadFile";

export const Insurance: FC = () => {
  const {
    value: { editMode, activeKey },
    dispatch: { setActiveKey },
  } = UseInsurance()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: IInsuranceTabs.basicInfo,
      label: (
        <P1 className="!text-[#8E8E8E]">{words.orders.insurance.basicInfo}</P1>
      ),
      children: <AddInsuranceForm />,
    },
    {
      key: IInsuranceTabs.addFile,
      label: (
        <P1 className="!text-[#8E8E8E]">{words.orders.insurance.fileUpload}</P1>
      ),
      children: <UploadFile />,
    },
  ];

  const onChange = (key: string) => {
    if (editMode) setActiveKey(key);
  };
  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.insurance.editInsuranceHeaderInfo
            : words.orders.insurance.insuranceHeaderInfo
        }
      />
      <Row justify={"center"}>
        <InsuranceContainer span={18}>
          <Tabs
            items={items}
            onChange={onChange}
            activeKey={activeKey}
            centered
          />
        </InsuranceContainer>
      </Row>
    </div>
  );
};
