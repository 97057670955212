import { FC } from "react";
import { CustomDeclaration } from "src/modules/CustomDeclaration";
import { CustomDeclarationContextProvider } from "src/modules/CustomDeclaration/context";

export const CustomDeclarationPage: FC = () => {
  return (
    <CustomDeclarationContextProvider>
      <CustomDeclaration />
    </CustomDeclarationContextProvider>
  );
};
