import { FC, useCallback, useEffect, useState } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Button, Col, Divider, Flex, Row, Skeleton, Spin } from "antd";
import { ViewVipContainer } from "../style";
import { useParams } from "react-router-dom";
import { IGetVip, IGetVipFile } from "src/services/Order/models/result.models";
import { OrderService } from "src/services/Order/order.service";
import { AxiosRequestConfig } from "axios";
import { DocumentService } from "src/services/Document/document.service";
import downloadZipFile from "src/helper/downloadZipFile";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";

export const ViewVip: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [vipInfo, setVipInfo] = useState<IGetVip>();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [getFileLoading, setGetFileLoading] = useState<boolean>(false);
  const getVipInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetVip } = new OrderService();
      const result = await GetVip(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setVipInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const GetFile = async (file: IGetVipFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    setGetFileLoading(true);
    try {
      const { GetVipFile } = new OrderService();
      const result = await GetVipFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
      setGetFileLoading(false);
    }
  };

  useEffect(() => {
    getVipInfo();
  }, [getVipInfo]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">{words.orders.vip.viewVipInformation}</div>
      <Row className="proformaDataHolder" gutter={[16, 24]}>
        <Col span={8} className="!font-medium">
          {words.orders.vip.issueDate}
        </Col>
        <Col span={16} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        {vipInfo?.remarks ? (
          <>
            <Col span={8} className="!font-medium">
              {words.orders.vip.description}
            </Col>
            <Col span={16} className="!font-normal text-[#4B4B4B]">
              <Skeleton.Input active className="!h-[22px]" />
            </Col>
          </>
        ) : (
          ""
        )}
        <Divider dashed className="!border-[#BFE2E0]" />
        <Col span={24}>
          <div className="flex gap-[16px]">
            <div className="text-[14px] w-[150px]">
              {words.orders.vip.vipDocument}
            </div>
            <div className="flex gap-[16px]">
              <div>
                <img src="/assets/images/fileUploaded.svg" alt="fileUploaded" />
              </div>
              <div className="flex flex-col gap-[6px] justify-center">
                <div className="text-[14px]">
                  {words.orders.origin.fileUploaded}
                </div>
                <div className="text-[10px]">{words.orders.origin.dlFile}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma headerName={words.orders.vip.viewVipInfo} />
      <Flex justify="center">
        <ViewVipContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">
                {words.orders.vip.viewVipInformation}
              </div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={8} className="!font-medium">
                  {words.orders.vip.issueDate}
                </Col>
                <Col span={16} className="!font-normal text-[#4B4B4B]">
                  {vipInfo?.date
                    ? dayjs(vipInfo?.date).format("YYYY-MM-DD")
                    : undefined}
                </Col>
                {vipInfo?.remarks ? (
                  <>
                    <Col span={8} className="!font-medium">
                      {words.orders.vip.description}
                    </Col>
                    <Col span={16} className="!font-normal text-[#4B4B4B]">
                      {vipInfo?.remarks}
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Divider dashed className="!border-[#BFE2E0]" />
                <Col span={24}>
                  <div className="flex gap-[16px]">
                    <div className="text-[14px] w-[150px]">
                      {words.orders.origin.originalDocuments}
                    </div>

                    <div className="flex gap-[16px] ">
                      <Button
                        className={vipInfo?.hasFile ? "" : "cursor-default"}
                        onClick={
                          vipInfo?.hasFile
                            ? () => downloadAttachFile(vipInfo?.id)
                            : () => {}
                        }
                        // loading={
                        //   vipInfo?.hasFile
                        //     ? progress > 0
                        //       ? true
                        //       : false
                        //     : false
                        // }
                      >
                        {(vipInfo?.hasFile && progress > 0) ||
                        getFileLoading ? (
                          <div className="relative w-[66px] h-[66px] flex justify-center items-center">
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="large"
                            />
                            <span className="absolute top-[38%] left-[40%] ">
                              {progress}
                            </span>
                          </div>
                        ) : (
                          <img
                            src={
                              vipInfo?.hasFile
                                ? "/assets/images/fileUploaded.svg"
                                : "/assets/images/fileNotUpload.svg"
                            }
                            alt="fileUploaded"
                          />
                        )}
                      </Button>
                      <div className="flex flex-col gap-[6px] justify-center">
                        <div className="text-[14px]">
                          {vipInfo?.hasFile
                            ? words.orders.origin.fileUploaded
                            : words.orders.origin.fileNotUploaded}
                        </div>
                        <div className="text-[10px]">
                          {vipInfo?.hasFile
                            ? words.orders.origin.dlFile
                            : words.orders.origin.cantDlFile}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ViewVipContainer>
      </Flex>
    </div>
  );
};
