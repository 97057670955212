import axios, { AxiosRequestConfig } from "axios";
import { DocumentUrl } from "./url";

export class DocumentService {
  async GetFile(UniqueSystemName: string, config?: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        DocumentUrl.Document.concat(
          `${UniqueSystemName}${DocumentUrl.GetFile}`
        ),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }
}
