import { FC } from "react";
import { SellingForm } from "./components/SellingForm";
import { SellingContainer } from "./style";
import { Row } from "antd";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { useSelling } from "./context";
import { SellingTable } from "./components/SellingTable";

export const Selling: FC = () => {
  const {
    value: { editMode },
  } = useSelling()!;
  const { words } = useLanguage();

  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.selling.editSellingHeaderInfo
            : words.orders.selling.sellingHeaderInfo
        }
      />
      <Row justify={"center"}>
        <SellingContainer span={22}>
          <SellingForm />
          <SellingTable />
        </SellingContainer>
      </Row>
    </div>
  );
};
