import { Col, Row } from "antd";
import styled from "styled-components";
export const OriginFinancialContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 24px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);

  .ant-form-item {
    margin-bottom: unset !important;
    height: 100% !important;
  }
`;
export const OriginFinancialFormContainer = styled(Row)`
  display: flex;
  gap: 24px;
  .totalHolder {
    border-radius: 8px;
    border: 1px solid var(--color-Primary-Primary-600, #008f87);
    display: flex;
    padding: 8px;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .buttonBorder {
    border-bottom: 1px dashed var(--color-Light-Mode-Border-3, #bfe2e0);
  }
`;
export const OriginFinancialTableContainer = styled(Row)`
  display: flex;
  gap: 24px;
  .tableHolder {
    display: flex;
    padding: 12px;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;
export const ViewOriginFinancialContainer = styled(Col)`
  padding: 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .totalHolder {
    border-radius: 8px;
    border: 1px solid var(--color-Primary-Primary-600, #008f87);
    display: flex;
    padding: 8px;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .tableHolder {
    display: flex;
    padding: 12px;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;
