import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { FC } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { inputNumberFormatter } from "src/helper/inputNumberFormatter";
const { Title, Paragraph, Text } = Typography;

export const DesignSystem: FC = () => {
  return (
    <div className="p-8">
      <Row gutter={[24, 24]} className={`bg-[#fff] rounded-[32px] p-5`}>
        <Col span={24}>
          <Title>Typography</Title>
          <Title copyable editable>
            h1. Ant Design
          </Title>
          <Title level={2}>h2. Ant Design</Title>
          <Title level={3}>h3. Ant Design</Title>
          <Title level={4}>h4. Ant Design</Title>
          <Title level={5}>h5. Ant Design</Title>
          <Divider />

          <Typography>
            <Title>Introduction</Title>

            <Paragraph>
              In the process of internal desktop applications development, many
              different design specs and implementations would be involved,
              which might cause designers and developers difficulties and
              duplication and reduce the efficiency of development.
            </Paragraph>

            <Paragraph>
              After massive project practice and summaries, Ant Design, a design
              language for background applications, is refined by Ant UED Team,
              which aims to
              <Text strong>
                uniform the user interface specs for internal background
                projects, lower the unnecessary cost of design differences and
                implementation and liberate the resources of design and
                front-end development
              </Text>
              .
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Form>
        <Row gutter={[24, 24]} className={`bg-[#fff] rounded-[32px] p-5 mt-5`}>
          <Col span={24}>Inputs</Col>
          <Col span={6}>
            <FormItem
              label="normal input"
              rules={[{ required: true }]}
              name={"normalInput"}
            >
              <Input />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              label="normal input disabled"
              rules={[{ required: true }]}
              name={"normalInputDisabled"}
            >
              <Input disabled />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="number input">
              <InputNumber
                changeOnWheel={false}
                controls={false}
                className="w-full"
                formatter={inputNumberFormatter}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="password input">
              <Input.Password />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="textarea input">
              <Input.TextArea />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="select input">
              <Select
                options={[
                  { label: "test", value: 1 },
                  { label: "test 2", value: 2 },
                  { label: "test 3", value: 3 },
                ]}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="radio ">
              <Radio.Group
                options={[
                  { label: "test", value: 1 },
                  { label: "test 2", value: 2 },
                  { label: "test 3", value: 3 },
                ]}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="radio ">
              <Radio.Group
                options={[
                  { label: "test", value: 1 },
                  { label: "test 2", value: 2 },
                  { label: "test 3", value: 3 },
                ]}
                optionType="button"
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="normal input" name={"normalInput"}>
              <DatePicker />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Row gutter={[24, 24]} className={`bg-[#fff] rounded-[32px] p-5 mt-5`}>
        <Col span={24}>Buttons</Col>

        <Col span={8}>
          <Button type="primary">primary</Button>
          <Button type="primary" disabled>
            primary disabled
          </Button>
        </Col>
        <Col span={8}>
          <Button type="primary" ghost>
            primary outline
          </Button>
          <Button type="primary" ghost disabled>
            primary outline disabled
          </Button>
        </Col>
        <Col span={8}>
          <Button type="dashed" ghost>
            dashed
          </Button>
          <Button type="dashed" disabled>
            dashed disabled
          </Button>
        </Col>
        <Col span={8}>
          <Button ghost>ghost</Button>
          <Button ghost disabled>
            ghost disabled
          </Button>
        </Col>

        <Col span={8}>
          <Button type="default">default</Button>
          <Button type="default" disabled>
            default disabled
          </Button>
        </Col>

        <Col span={8}>
          <Button type="primary" danger>
            primary danger
          </Button>
          <Button type="primary" danger disabled>
            primary danger disabled
          </Button>
        </Col>
        <Col span={8}>
          <Button danger>outline danger</Button>
          <Button danger disabled>
            outline danger disabled
          </Button>
        </Col>
        <Col span={8}>
          <Button type="text">text</Button>
          <Button type="text" disabled>
            text disabled
          </Button>
        </Col>
      </Row>

      <Row
        align={"middle"}
        justify={"center"}
        className={`bg-[#fff] rounded-[32px] p-5 mt-5`}
      >
        <Col span={22}>
          <Table
            columns={[{ title: "test", dataIndex: "test", key: "test" }]}
            dataSource={[{ test: 123 }]}
          />
        </Col>
      </Row>
    </div>
  );
};
