import { FC } from "react";
import { CustomsFormalities } from "src/modules/CustomsFormalities";
import { CustomsFormalitiesProvider } from "src/modules/CustomsFormalities/context";

export const CustomsFormalitiesPage: FC = () => {
  return (
    <CustomsFormalitiesProvider>
      <CustomsFormalities />
    </CustomsFormalitiesProvider>
  );
};
