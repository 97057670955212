import { App, Form } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import { IAddVip } from "src/services/Order/models/args.models";
import { FormInstance, UploadFile } from "antd/lib";
import { uploadFile } from "src/helper/uploadZipFile";
import { OrderUrl } from "src/services/Order/urls";
import { RcFile } from "antd/es/upload";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment-jalaali";

interface IContext {
  value: {
    editMode: boolean;
    deleteLoading: boolean;
    loading: boolean;
    hasFile: boolean;
    file: UploadFile | undefined;
    progress: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setHasFile: Dispatch<SetStateAction<boolean>>;
    setFile: Dispatch<SetStateAction<UploadFile | undefined>>;
  };
  func: {
    deleteVip: () => void;
    add: (values: IAddVip) => void;
    update: (values: IAddVip) => void;
    getVipInfo: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}
export const VipContext = createContext<IContext | undefined>(undefined);

export const VipContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [vipId, setVipId] = useState<number>();
  const [file, setFile] = useState<UploadFile>();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  ////////////////////////////////functions////////////////////////////////
  const deleteVip = async () => {
    if (!orderId) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteVip } = new OrderService();
      const result = await DeleteVip(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.vip.deleteVipMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const newVipFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(`${id}${OrderUrl.NewVipFile}`),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const add = async (values: IAddVip) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddVip } = new OrderService();
      const newValues = {
        ...values,
        date: moment(
          (values.date as Dayjs).format("YYYY-MM-DD"),
          "jYYYY-jMM-jDD"
        ).format("YYYY-MM-DD"),
      };
      const result = await AddVip(newValues, parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setVipId(result.data);
        if (file) {
          const resultFile = await newVipFile(result.data);
          if (resultFile) {
            navigate(ROUTES.orderManagement.concat(`/${orderId}`));
            message.success(words.orders.vip.addVipMessage);
          }
        } else {
          navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          message.success(words.orders.vip.addVipMessage);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateVipFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(`${id}${OrderUrl.UpdateVipFile}`),
        extraData: { name: "testUpdate" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (values: IAddVip) => {
    if (!orderId || !vipId) return undefined;
    setLoading(true);
    try {
      const { UpdateVip } = new OrderService();

      const newValues = {
        ...values,
        date: moment(
          (values.date as Dayjs).format("YYYY-MM-DD"),
          "jYYYY-jMM-jDD"
        ).format("YYYY-MM-DD"),
      };

      const result = await UpdateVip(newValues, parseInt(orderId));
      if (result && result.status === 200) {
        if (file) {
          const resultFile = await updateVipFile(vipId);
          if (resultFile) {
            navigate(ROUTES.orderManagement.concat(`/${orderId}`));
            message.success(words.orders.vip.updateVipMessage);
          }
        } else {
          navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          message.success(words.orders.vip.updateVipMessage);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getVipInfo = useCallback(async () => {
    if (!orderId) return undefined;
    const { GetVip } = new OrderService();
    const result = await GetVip(parseInt(orderId));
    if (result && result.status === 200 && result.data) {
      const newData = {
        ...result.data,
        date: result.data.date ? dayjs(result.data.date) : undefined,
      };
      setHasFile(result.data.hasFile);
      form.setFieldsValue(newData);
      setVipId(result.data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  const ContextValue: IContext = {
    value: { deleteLoading, editMode, file, hasFile, loading, progress },
    dispatch: { setEditMode, setFile, setHasFile },
    func: { add, deleteVip, getVipInfo, update },
    form: { form },
  };
  return (
    <VipContext.Provider value={ContextValue}>{children}</VipContext.Provider>
  );
};

export const UseVip = () => useContext(VipContext);
