import { Divider, ModalFuncProps } from "antd";
import { USER_Language } from "src/constants";
import { ILanguages } from "src/models/languages";
import En from "../constants/languages/en.json";
import Fa from "../constants/languages/fa.json";

export const docModalProps = (title: string) => {
  const savedLanguage =
    (localStorage.getItem(USER_Language) as ILanguages) || "Fa";
  const words = savedLanguage === "Fa" ? Fa : En;

  const props: ModalFuncProps = {
    title: (
      <div className="mb-[24px]">
        <div className="pt-[8px] pb-[16px] flex flex-col gap-[8px] justify-start items-end ">
          <div className="headerTitle">{words.global.dlDocTitle}</div>
          <div className="!text-[#8E8E8E]"> {title}</div>
        </div>
        <div>
          <Divider className="border-[#BFE2E0] m-0" />
        </div>
      </div>
    ),
    content: (
      <div className="border-[#BFE2E0] border-[1px] border-solid rounded-[20px] p-[48px] flex justify-center items-center mb-[32px]">
        {words.global.dlDoc}
      </div>
    ),
    icon: null,
    footer: (originNode, { CancelBtn }) => <CancelBtn />,
    cancelText: (
      <div className="!min-w-[155px] flex gap-[12px] justify-center items-center h-[56px]">
        <span className="material-icons text-[#4B4B4B] text-[24px]">
          check_circle_outline
        </span>
        <div className="!text-[#4B4B4B]"> {words.global.close}</div>
      </div>
    ),
    className: "docModalProp",
    width: "40%",
    height: "32%",
  };
  return props;
};
