import { Divider } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { T1 } from "src/components/UiKit/Typography";
import { ROUTES } from "src/models/enums/routes";
import useLanguage from "src/store/language";

export const Header: FC = () => {
  const { words } = useLanguage();
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex justify-between items-center px-[24px] ">
        <T1>{words.orders.orderManagement}</T1>
        <div
          className="flex flex-row gap-[12px] items-center cursor-pointer"
          onClick={() => {
            navigate(ROUTES.orders);
          }}
        >
          <T1 className="!text-[#006B65]">{words.global.backToTable}</T1>
          <span className="material-icons text-[#006B65] text-[24px]">
            keyboard_backspace
          </span>
        </div>
      </div>
      <Divider />
    </div>
  );
};
