import { useMemo } from "react";
import { IGetAlllOriginFinancial } from "src/services/Order/models/result.models";
import useLanguage from "src/store/language";

export const usePayment = (
  allOriginFinancial?: IGetAlllOriginFinancial | undefined
) => {
  const { words } = useLanguage();

  const paymentOptions = useMemo(
    () => [
      {
        key: 0,
        value: words.orders.originFinancial.commodityValue,
        amount: allOriginFinancial?.commodity,
      },
      {
        key: 1,
        value: words.orders.originFinancial.freightCost,
        amount: allOriginFinancial?.freight,
      },
      {
        key: 2,
        value: words.orders.originFinancial.demurrage,
        amount: allOriginFinancial?.demurrage,
      },
      {
        key: 3,
        value: words.orders.originFinancial.fee,
        amount: allOriginFinancial?.fee,
      },
      {
        key: 4,
        value: words.orders.originFinancial.other,
        amount: allOriginFinancial?.other,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allOriginFinancial]
  );
  return paymentOptions;
};
