export enum OrderUrl {
  Order = "Order/",
  GetAllProform = "Order/GetAllProform",
  DeleteProform = "/DeleteProform",
  GetProformMain = "/GetProformMain",
  AddProform = "Order/AddProform",
  GetProform = "/GetProform",
  UpdateProform = "/UpdateProform",
  AddOrigin = "/AddOrigin",
  NewOriginFile = "/NewOriginFile",
  GetOriginFile = "/GetOriginFile",
  GetOrigin = "/GetOrigin",
  UpdateOrigin = "/UpdateOrigin",
  UpdateOriginFile = "/UpdateOriginFile",
  DeleteOrigin = "/DeleteOrigin",
  DeleteOriginFile = "/DeleteOriginFile",
  AddVip = "/AddVip",
  NewVipFile = "/NewVipFile",
  GetVipFile = "/GetVipFile",
  GetVip = "/GetVip",
  UpdateVip = "/UpdateVip",
  UpdateVipFile = "/UpdateVipFile",
  DeleteVip = "/DeleteVip",
  DeleteVipFile = "/DeleteVipFile",
  AddOrderRegister = "/AddOrderRegister",
  GetOrderRegister = "/GetOrderRegister",
  UpdateOrderRegister = "/UpdateOrderRegister",
  DeleteOrderRegister = "/DeleteOrderRegister",
  AddCustomDeclaration = "/AddCustomDeclaration",
  NewCustomDeclarationFile = "/NewCustomDeclarationFile",
  GetCustomDeclarationFile = "/GetCustomDeclarationFile",
  GetCustomDeclaration = "/GetCustomDeclaration",
  UpdateCustomDeclaration = "/UpdateCustomDeclaration",
  UpdateCustomDeclarationFile = "/UpdateCustomDeclarationFile",
  DeleteCustomDeclaration = "/DeleteCustomDeclaration",
  DeleteCustomDeclarationFile = "/DeleteCustomDeclarationFile",
  AddVesselArrival = "/AddVesselArrival",
  NewVesselArrivalFile = "/NewVesselArrivalFile",
  GetVesselArrivalFile = "/GetVesselArrivalFile",
  GetVesselArrival = "/GetVesselArrival",
  UpdateVesselArrival = "/UpdateVesselArrival",
  UpdateVesselArrivalFile = "/UpdateVesselArrivalFile",
  DeleteVesselArrival = "/DeleteVesselArrival",
  DeleteVesselArrivalFile = "/DeleteVesselArrivalFile",
  AddWarehousing = "/AddWarehousing",
  GetWarehousing = "/GetWarehousing",
  UpdateWarehousing = "/UpdateWarehousing",
  DeleteWarehousing = "/DeleteWarehousing",
  AddArgiculture = "/AddArgiculture",
  GetArgiculture = "/GetArgiculture",
  UpdateArgiculture = "/UpdateArgiculture",
  DeleteArgiculture = "/DeleteArgiculture",
  AddOriginFinancial = "/AddOriginFinancial",
  GetOriginFinancial = "/GetOriginFinancial",
  GetAlllOriginFinancial = "/GetAlllOriginFinancial",
  UpdateOriginFinancial = "/UpdateOriginFinancial",
  DeleteOriginFinancial = "/DeleteOriginFinancial",
  AddRate = "/AddRate",
  GetRate = "/GetRate",
  UpdateRate = "/UpdateRate",
  DeleteRate = "/DeleteRate",
  AddInsuranceForm = "/AddInsuranceForm",
  GetInsuranceForm = "/GetInsuranceForm",
  UpdateInsuranceForm = "/UpdateInsuranceForm",
  DeleteInsuranceForm = "/DeleteInsuranceForm",
  AddInspecionForm = "/AddInspecionForm",
  NewInspecionFormFile = "/NewInspecionFormFile",
  GetInspecionFormFile = "/GetInspecionFormFile",
  GetAllInspectionForms = "/GetAllInspectionForms",
  GetInspecionForm = "/GetInspecionForm",
  UpdateInspecionForm = "/UpdateInspecionForm",
  UpdateInspecionFormFile = "/UpdateInspecionFormFile",
  DeleteInspecionForm = "/DeleteInspecionForm",
  DeleteInspecionFormFile = "/DeleteInspecionFormFile",
  AddInsuranceFileDetail = "/AddInsuranceFileDetail",
  NewInsuranceFile = "/NewInsuranceFile",
  GetInsuranceFile = "/GetInsuranceFile",
  GetInsuranceFileDetail = "/GetInsuranceFileDetail",
  GetAllInsuranceFileDetails = "/GetAllInsuranceFileDetails",
  UpdateInsuranceFileDetail = "/UpdateInsuranceFileDetail",
  UpdateInsuranceFile = "/UpdateInsuranceFile",
  DeleteInsuranceFile = "/DeleteInsuranceFile",
  DeleteInsuranceFileDetail = "/DeleteInsuranceFileDetail",
  AddSell = "/AddSell",
  GetAlllSell = "/GetAlllSell",
  UpdateSell = "/UpdateSell",
  DeleteSell = "/DeleteSell",
  AddCustomFormality = "/AddCustomFormality",
  NewCustomFormalityFile = "/NewCustomFormalityFile",
  GetAllCustomFormalitys = "/GetAllCustomFormalitys",
  GetCustomFormality = "/GetCustomFormality",
  GetCustomFormalityFile = "/GetCustomFormalityFile",
  UpdateCustomFormality = "/UpdateCustomFormality",
  UpdateCustomFormalityFile = "/UpdateCustomFormalityFile",
  DeleteCustomFormality = "/DeleteCustomFormality",
  DeleteCustomFormalityFile = "/DeleteCustomFormalityFile",
  AddBankF = "/AddBankF",
  GetAllBankFs = "/GetAllBankFs",
  GetBankF = "/GetBankF",
  UpdateBankF = "/UpdateBankF",
  DeleteBankF = "/DeleteBankF",
  AddShipment = "/AddShipment",
  GetShipment = "/GetShipment",
  UpdateShipment = "/UpdateShipment",
  DeleteShipment = "/DeleteShipment",
  AddShipmentFileDetail = "/AddShipmentFileDetail",
  NewShipmentFile = "/NewShipmentFile",
  GetShipmentFile = "/GetShipmentFile",
  GetShipmentFileDetail = "/GetShipmentFileDetail",
  GetAllShipmentFileDetails = "/GetAllShipmentFileDetails",
  UpdateShipmentFileDetail = "/UpdateShipmentFileDetail",
  UpdateShipmentFile = "/UpdateShipmentFile",
  DeleteShipmentFileDetail = "/DeleteShipmentFileDetail",
  DeleteShipmentFile = "/DeleteShipmentFile",
  AddPODFinancial = "/AddPODFinancial",
  GetPODFinancial = "/GetPODFinancial",
  GetPODFinancialResult = "/GetPODFinancialResult",
  UpdatePODFinancial = "/UpdatePODFinancial",
  DeletePODFinancial = "/DeletePODFinancial",
  AddPODFinancialOther = "/AddPODFinancialOther",
  GetAllPODFinancialOthers = "/GetAllPODFinancialOthers",
  GetPODFinancialOther = "/GetPODFinancialOther",
  UpdatePODFinancialOther = "/UpdatePODFinancialOther",
  DeletePODFinancialOther = "/DeletePODFinancialOther",
}
