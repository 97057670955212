import { FC } from "react";
import { CustomOperation } from "src/modules/CustomOperation";
import { CustomOperationProvider } from "src/modules/CustomOperation/context";

export const CustomOperationPage: FC = () => {
  return (
    <CustomOperationProvider>
      <CustomOperation />
    </CustomOperationProvider>
  );
};
