import { IPath } from "src/services/Identity/models";
import * as GuardPath from "../../../services/Order/guardPath";

export const OrderPagePath: IPath[] = [
  // GuardPathBaseInfo.AllCurrencyPath,
  // GuardPathBaseInfo.AllProductPath,
  // GuardPathBaseInfo.AllUnitPath,
  // GuardPathBaseInfo.AllBankPath,
  GuardPath.GetProformPath,
  GuardPath.UpdateProformPath,
  GuardPath.AddProformPath,
];
export const OrderManagementPagePath: IPath[] = [GuardPath.GetProformMainPath];

export const ProformPagePath: IPath[] = [
  GuardPath.AddProformPath,
  GuardPath.GetProformPath,
  GuardPath.UpdateProformPath,
];
export const OriginPagePath: IPath[] = [
  // GuardPathBaseInfo.AllSupplierPath,
  // GuardPathBaseInfo.AllCountryPath,
  // GuardPathBaseInfo.AllCurrencyPath,
  // GuardPathBaseInfo.AllInspectionPath,
  GuardPath.AddOriginPath,
  // GuardPath.DeleteOriginPath,
  GuardPath.GetOriginPath,
  GuardPath.UpdateOriginPath,
  // GuardPath.UpdateOriginFilePath,
  // GuardPath.NewOriginFilePath,
];
export const OriginFinancialPagePath: IPath[] = [
  // GuardPathBaseInfo.AllCurrencyPath,
  GuardPath.AddOriginFinancialPath,
  GuardPath.GetAlllOriginFinancialPath,
  GuardPath.UpdateOriginFinancialPath,
  // GuardPath.DeleteOriginFinancialPath,
];
export const BankingOperationPagePath: IPath[] = [
  GuardPath.AddBankFPath,
  GuardPath.GetAllBankFsPath,
  GuardPath.UpdateBankFPath,
  // GuardPath.DeleteBankFPath,
  // GuardPathBaseInfo.GetAllBankDocsPath,
  // GuardPathBaseInfo.AddBankDocPath,
];
export const VipPagePath: IPath[] = [
  GuardPath.AddVipPath,
  // GuardPath.NewVipFilePath,
  // GuardPath.UpdateVipFilePath,
  // GuardPath.DeleteVipPath,
  GuardPath.GetVipPath,
  GuardPath.UpdateVipPath,
];
export const OrderRegisterPagePath: IPath[] = [
  GuardPath.AddOrderRegisterPath,
  GuardPath.GetOrderRegisterPath,
  GuardPath.UpdateOrderRegisterPath,
  // GuardPath.DeleteOrderRegisterPath,
];
export const InsurancePagePath: IPath[] = [
  GuardPath.AddInsuranceFormPath,
  GuardPath.GetInsuranceFormPath,
  // GuardPath.GetAllInsuranceFileDetailsPath,
  GuardPath.UpdateInsuranceFormPath,
  // GuardPath.AddInsuranceFileDetailPath,
  // GuardPath.NewInsuranceFilePath,
  // GuardPath.NewInsuranceFilePath,
  // GuardPath.GetInsuranceFilePath,
  // GuardPath.GetInsuranceFileDetailPath,
  // GuardPath.DeleteInsuranceFileDetailPath,
  // GuardPathBaseInfo.AllInsuracePath,
];
export const ViewInsurancePagePath: IPath[] = [
  GuardPath.GetInsuranceFormPath,
  // GuardPath.GetAllInsuranceFileDetailsPath,
  // GuardPath.GetInsuranceFilePath,
];
export const InspectionPagePath: IPath[] = [
  GuardPath.AddInspecionFormPath,
  GuardPath.GetAllInspectionFormsPath,
  // GuardPath.NewInspecionFormFilePath,
  // GuardPath.UpdateInspecionFormFilePath,
  GuardPath.UpdateInspecionFormPath,
  // GuardPath.GetInspecionFormFilePath,
  // GuardPath.GetInspecionFormPath,
  // GuardPath.DeleteInspecionFormPath,
  // GuardPathBaseInfo.GetAllInspectionDocsPath,
  // GuardPathBaseInfo.AddInspectionDocPath,
];
export const ShipmentPagePath: IPath[] = [
  GuardPath.AddShipmentPath,
  GuardPath.GetShipmentPath,
  GuardPath.UpdateShipmentPath,
  // GuardPath.GetAllShipmentFileDetailsPath,
  // GuardPath.AddShipmentFileDetailPath,
  // GuardPath.NewShipmentFilePath,
  // GuardPath.GetShipmentFilePath,
  // GuardPath.GetShipmentFileDetailPath,
  // GuardPath.DeleteShipmentFileDetailPath,
  // GuardPath.UpdateShipmentFileDetailPath,
  // GuardPath.UpdateShipmentFilePath,
  // GuardPath.DeleteShipmentPath,
  GuardPath.UpdateShipmentPath,
  // GuardPathBaseInfo.AllVesselPath,
  // GuardPathBaseInfo.AllPortPath,
  // GuardPathBaseInfo.AllCurrencyPath,
  // GuardPathBaseInfo.AllUnitPath,
  // GuardPathBaseInfo.GetAllShippingDocsPath,
  // GuardPathBaseInfo.AddShippingDocPath,
];
export const ViewShipmentPagePath: IPath[] = [
  GuardPath.GetShipmentPath,
  // GuardPath.GetAllShipmentFileDetailsPath,
  // GuardPath.GetShipmentFilePath,
];
export const RatesPagePath: IPath[] = [
  GuardPath.AddRatePath,
  GuardPath.GetRatePath,
  GuardPath.UpdateRatePath,
  // GuardPath.DeleteRatePath,
];
export const PodPagePath: IPath[] = [
  GuardPath.AddPODFinancialPath,
  GuardPath.GetPODFinancialPath,
  GuardPath.UpdatePODFinancialPath,
  // GuardPath.UpdatePODFinancialOtherPath,
  // GuardPath.GetAllPODFinancialOthersPath,
  // GuardPath.AddPODFinancialOtherPath,
  // GuardPath.DeletePODFinancialOtherPath,
];
export const ViewPodPagePath: IPath[] = [
  GuardPath.GetPODFinancialPath,
  // GuardPath.GetAllPODFinancialOthersPath,
  // GuardPath.GetPODFinancialResultPath,
];
export const VesselArrivePagePath: IPath[] = [
  GuardPath.AddVesselArrivalPath,
  GuardPath.GetVesselArrivalPath,
  GuardPath.UpdateVesselArrivalPath,
  // GuardPath.NewVesselArrivalFilePath,
  // GuardPath.UpdateVesselArrivalFilePath,
];
export const CustomDeclarationPagePath: IPath[] = [
  GuardPath.AddCustomDeclarationPath,
  // GuardPath.NewCustomDeclarationFilePath,
  GuardPath.GetCustomDeclarationFilePath,
  GuardPath.UpdateCustomDeclarationPath,
  // GuardPath.UpdateCustomDeclarationFilePath,
  // GuardPath.DeleteCustomDeclarationPath,
];
export const CustomFormalitysPagePath: IPath[] = [
  GuardPath.GetAllCustomFormalitysPath,
  GuardPath.AddCustomFormalityPath,
  //  GuardPath.NewCustomFormalityFilePath,
  //  GuardPath.GetCustomFormalityFilePath,
  GuardPath.UpdateCustomFormalityPath,
  // GuardPath.UpdateCustomFormalityFilePath,
  // GuardPath.DeleteCustomFormalityPath,
  // GuardPath.GetCustomFormalityPath,
  // GuardPathBaseInfo.GetAllCustomOperationsPath,
  // GuardPathBaseInfo.AddCustomOperationPath,
];
export const warehousingPagePath: IPath[] = [
  GuardPath.GetWarehousingPath,
  GuardPath.AddWarehousingPath,
  GuardPath.UpdateWarehousingPath,
  // GuardPath.DeleteWarehousingPath,
  // GuardPathBaseInfo.AllWarehousePath,
  // GuardPathBaseInfo.AllUnitPath,
];
export const ArgiculturePagePath: IPath[] = [
  GuardPath.GetArgiculturePath,
  GuardPath.AddArgiculturePath,
  GuardPath.UpdateArgiculturePath,
  // GuardPath.DeleteArgiculturePath,
];
export const sellingPagePath: IPath[] = [
  GuardPath.AddSellPath,
  GuardPath.GetAlllSellPath,
  GuardPath.UpdateSellPath,
  // GuardPath.DeleteSellPath,
  // GuardPathBaseInfo.AllTransportPath,
];
