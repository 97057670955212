import { FC, useEffect, useState } from "react";
import { LoginContainer } from "./styles";
import { Button, Col, Form, Input, Skeleton } from "antd";
import useLanguage from "src/store/language";
import { IdentityService } from "src/services/Identity/Identity.service";
import { ILoginArgs } from "src/services/Identity/models";
import { useLogin } from "src/hook";
import FormItem from "antd/es/form/FormItem";

const Login: FC = () => {
  const { loginHandler } = useLogin();
  const { words } = useLanguage();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [captchaLoading, setCaptchaLoading] = useState<boolean>(true);
  const [captchaId, setCaptchaId] = useState<string>("");
  const [captchaImage, setCaptchaImage] = useState<string>("");
  const [timer, setTimer] = useState<number>(0);

  const getCaptcha = async () => {
    setCaptchaLoading(true);
    setCaptchaImage("");
    setCaptchaId("");
    try {
      const { GetCaptcha } = new IdentityService();
      const res = await GetCaptcha();
      if (res && res.data) {
        setCaptchaId(res.data.captchaId);
        setCaptchaImage("data:png" + ";base64," + res.data.image);
        form.setFieldValue("captchaValue", "");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCaptchaLoading(false);
    }
  };
  useEffect(() => {
    getCaptcha();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let timerId: any;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timerId = setInterval(() => {
      setTimer((prev) => {
        if (prev === 120) {
          getCaptcha();
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);
  const onFinish = async (values: ILoginArgs) => {
    try {
      setLoading(true);
      const { Login } = new IdentityService();
      const reqBody: ILoginArgs = { ...values, captchaId };
      const result = await Login(reqBody);
      if (result && result.data && result.status === 200) {
        loginHandler(
          result.data.accessToken,
          result.data.roles,
          result.data.paths
        );
      }
      // loginHandler(JSON.stringify(values), [JSON.stringify(values)]);
    } catch (err) {
      console.log(err);
      getCaptcha();
    } finally {
      setLoading(false);
    }
  };
  return (
    <LoginContainer gutter={[0, 32]}>
      <Col span={24} className="logo">
        <img src="/assets/images/logo.svg" alt="logo " />
      </Col>
      <Col span={24} className="flex flex-col gap-[24px] self-stretch">
        <Form wrapperCol={{ span: 24 }} form={form} onFinish={onFinish}>
          <FormItem name={"userName"} rules={[{ required: true }]}>
            <Input
              placeholder={words.login.username}
              className="input"
              onPressEnter={form.submit}
              prefix={
                <span className="material-icons text-[24px]">
                  person_outline
                </span>
              }
            />
          </FormItem>
          <FormItem name={"password"} rules={[{ required: true }]}>
            <Input.Password
              placeholder={words.login.password}
              className="inputPassword"
              onPressEnter={form.submit}
            />
          </FormItem>
          <div className="flex justify-between ">
            {captchaLoading ? (
              <div className="flex gap-[24px]">
                <Skeleton.Input active className="h-[56px] !w-[250px]" />
                <Skeleton.Input active className="h-[56px] !w-[56px]" />
              </div>
            ) : (
              <div className="flex justify-between items-center w-[100%] !h-[52px] gap-[24px]">
                <img
                  src={captchaImage}
                  alt="captcha"
                  className="input"
                  style={{ width: "250px", height: "56px" }}
                />
                <div
                  className="rounded-[8px] cursor-pointer !w-[56px] !h-[56px]  "
                  onClick={getCaptcha}
                >
                  <span className="material-icons text-[#024a94] input text-[24px] flex justify-center items-center">
                    refresh
                  </span>
                </div>
              </div>
            )}
          </div>
          <div>
            <Form.Item
              name={"captchaValue"}
              rules={[{ required: true }]}
              className="mt-[24px]"
            >
              <Input
                placeholder={words.login.captcha}
                onPressEnter={form.submit}
                className="input"
                prefix={
                  <span className="material-icons">closed_caption_off</span>
                }
              />
            </Form.Item>
          </div>
          <Button
            className="button"
            htmlType="button"
            loading={loading}
            onClick={form.submit}
          >
            {words.login.action}
          </Button>
        </Form>
      </Col>
    </LoginContainer>
  );
};
export default Login;
