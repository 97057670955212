import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { TableData } from "../models";
import { Form, FormInstance, message } from "antd";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import useLanguage from "src/store/language";
import { IGetAllCustomOperations } from "src/services/BaseInfo/models/result.models";

interface IContextCustomOperation {
  value: {
    tableData: IGetAllCustomOperations[];
    editMode: boolean;
    dataSource: TableData[];
    pagination: ITablePagination;
    productLoading: boolean;
    productId: number;
  };
  dispatch: {
    setTableData: Dispatch<SetStateAction<IGetAllCustomOperations[]>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setProductId: Dispatch<SetStateAction<number>>;
  };
  func: {
    getCustomOperationTableData: () => void;
    deleteCustomOperation: (id: number) => void;
  };
  form: { form: FormInstance | undefined };
}

const defaultCtxValue: IContextCustomOperation = {
  value: {
    tableData: [],
    editMode: false,
    dataSource: [],
    pagination: { current: 0, pageSize: 0, total: 0 },
    productId: -1,
    productLoading: false,
  },
  dispatch: {
    setTableData: () => {},
    setEditMode: () => {},
    setPagination: () => {},
    setProductId: () => {},
  },
  func: {
    deleteCustomOperation: (id: number) => {},
    getCustomOperationTableData: () => {},
  },
  form: { form: undefined },
};

export const CustomOperationContext =
  createContext<IContextCustomOperation>(defaultCtxValue);

export const CustomOperationProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const [tableData, setTableData] = useState<IGetAllCustomOperations[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<IGetAllCustomOperations[]>([]);
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  const [productId, setProductId] = useState<number>(-1);
  ///////////Functions////////////////////////////////////////////////////////////////
  const getCustomOperationTableData = useCallback(async () => {
    setProductLoading(true);
    const params =
      searchParams.toString() ||
      new URLSearchParams({
        Offset: pagination.current.toString(),
        Limit: pagination.pageSize.toString(),
      }).toString();
    try {
      const { GetAllCustomOperations } = new BaseInfoService();
      const result = await GetAllCustomOperations(params);
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  }, [searchParams]);

  const deleteCustomOperation = async (id: number) => {
    setProductLoading(true);
    try {
      const { DeleteCustomOperation } = new BaseInfoService();
      const result = await DeleteCustomOperation(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.inspectionInfoDocs
            .deleteInspectionInfoDocsMessage
        );
        getCustomOperationTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };
  const contextValue: IContextCustomOperation = {
    value: {
      tableData,
      editMode,
      dataSource,
      pagination,
      productId,
      productLoading,
    },
    dispatch: { setTableData, setEditMode, setPagination, setProductId },
    func: { deleteCustomOperation, getCustomOperationTableData },
    form: { form },
  };
  return (
    <CustomOperationContext.Provider value={contextValue}>
      {children}
    </CustomOperationContext.Provider>
  );
};

export const useCustomOperation = () => useContext(CustomOperationContext);
