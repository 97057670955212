import { Col } from "antd";
import styled from "styled-components";

export const VesselArriveContainer = styled(Col)`
  padding: 24px 32px;
  /* align-items: center; */
  gap: 24px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .checkboxHolder {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .uploadHolder {
    display: flex;
    width: 100%;
    height: 80px;
    padding: 8px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px dashed var(--color-Stroke-footer-buttons, #c9e9e6);
  }
  .fileHolder {
    display: flex;
    width: 66px;
    height: 66px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px dashed var(--color-Primary-Primary-700, #006b65);
  }
  .ant-upload-rtl {
    width: 100% !important;
    height: 100% !important;
  }
  .ant-upload {
    width: 100% !important;
    height: 100% !important;
  }
  .ant-btn-default {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: unset !important;
  }
  .ant-btn-text {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: unset !important;
  }

  .ant-btn-icon {
    margin: unset !important;
  }

  .ant-form-item {
    margin-bottom: unset !important;
    height: 100% !important;
  }
  .ant-form-item-row {
    height: 100% !important;
  }
`;
export const ViewVesselArriveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 16px 16px 32px 16px;
  gap: 32px;
  border-radius: 16px;
  background: var(--color-Base-Color-White, #fff);

  /* Body box */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .proformaDataHolder {
    display: flex;
    width: 100%;
    padding: 24px 16px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
    background: var(--color-Light-Mode-BG-Background-1, #f2f9f9);
  }

  .ant-btn {
    min-width: 66px !important;
    height: 66px !important;
    padding: unset !important;
  }
`;
