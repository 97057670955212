import { routesKeys } from "src/constants/routesKeys";
import { create } from "zustand";

interface IStore {
  orderCode: string;
  setOrderCode: (orderCode: string) => void;
}

const useOrderStore = create<IStore>((set) => ({
  orderCode: localStorage.getItem("orderCode") || "",
  setOrderCode: (orderCode: string) => {
    localStorage.setItem(routesKeys.orderCode, orderCode);
    set({ orderCode });
  },
}));

export default useOrderStore;
