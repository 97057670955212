import { FC } from "react";
import { PODfinancial } from "src/modules/PODfinancial";
import { PODProvider } from "src/modules/PODfinancial/context";

export const PODfinancialPage: FC = () => {
  return (
    <PODProvider>
      <PODfinancial />
    </PODProvider>
  );
};
