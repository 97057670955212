import { FC } from "react";
import { Warehousing } from "src/modules/Warehousing ";
import { WarehousingContextProvider } from "src/modules/Warehousing /context";

export const WarehousingPage: FC = () => {
  return (
    <WarehousingContextProvider>
      <Warehousing />
    </WarehousingContextProvider>
  );
};
