import { Divider } from "antd";
import { FC } from "react";
import { T1 } from "src/components/UiKit/Typography";
import useLanguage from "src/store/language";

export const CustomOperationHeader: FC = () => {
  const { words } = useLanguage();
  return (
    <div>
      <div className="flex flex-row gap-[12px] p-[24px] ">
        <span className="material-symbols-outlined">table_rows</span>
        <T1 className="!text-[16px] !font-bold">
          {words.basicInformation.customOperation.customOperationMiddleHeader}
        </T1>
      </div>
      <Divider className="pb-[20px] border-[#BFE2E0]" />
    </div>
  );
};
