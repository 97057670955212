import { App, Form } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  IAddPODFinancial,
  IAddPODFinancialOther,
} from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import { FormInstance } from "antd/lib";
import { IPODTabs } from "./models";

import {
  IGetAllPODFinancialOthers,
  IGetPODFinancial,
  IGetProformMain,
} from "src/services/Order/models/result.models";
import { ROUTES } from "src/models/enums/routes";

interface ILoadings {
  delete: boolean;
  addForm: boolean;
  updateForm: boolean;
  addShippingDoc: boolean;
  getPODFinancial: boolean;
  updatePODOther: boolean;
  deletePODOther: boolean;
  getAllShippingDocs: boolean;
  getAllPODFinancialOther: boolean;
}
interface IContext {
  value: {
    editMode: boolean;
    activeKey: string;
    tableEditMode: boolean;
    podFinancial: IGetPODFinancial | undefined;
    backEditMode: boolean;
    loadings: ILoadings;
    podFinancialOther: IGetAllPODFinancialOthers | undefined;
    podOtherId: number | undefined;
    podFormId: number | undefined;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setActiveKey: Dispatch<SetStateAction<string>>;
    setTableEditMode: Dispatch<SetStateAction<boolean>>;
    setBackEditMode: Dispatch<SetStateAction<boolean>>;
    setPodOtherId: Dispatch<SetStateAction<number | undefined>>;
  };
  func: {
    addForm: (values: IAddPODFinancial) => void;
    updateForm: (values: IAddPODFinancial) => void;
    addOther: (data: IAddPODFinancialOther) => void;
    updatePODOther: (data: IAddPODFinancialOther) => void;
    deletePODOther: (id: number) => void;
    getPODFinancial: () => void;
    getPODFinancialForm: () => void;
  };
  form: {
    OtherInfoForm: FormInstance<any> | undefined;
    basicInfoForm: FormInstance<any> | undefined;
  };
}
export const PODContext = createContext<IContext | undefined>(undefined);

export const PODProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [OtherInfoForm] = Form.useForm();
  const [basicInfoForm] = Form.useForm();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(IPODTabs.basicInfo);
  const [tableEditMode, setTableEditMode] = useState<boolean>(false);
  const [podFormId, setPodFormId] = useState<number>();
  const [podOtherId, setPodOtherId] = useState<number>();
  const [podFinancial, setPodFinancial] = useState<IGetPODFinancial>();
  const [podFinancialOther, setPodFinancialOther] =
    useState<IGetAllPODFinancialOthers>();
  const [backEditMode, setBackEditMode] = useState<boolean>(false);
  const [proformMain, setProformMain] = useState<IGetProformMain>({
    flags: ["Rate"],
    profitFx: 0,
    profitRial: 0,
    profitUsd: 0,
  });

  const [loadings, setLoadings] = useState<ILoadings>({
    delete: false,
    addForm: false,
    updateForm: false,
    addShippingDoc: false,
    getPODFinancial: false,
    updatePODOther: false,
    deletePODOther: false,
    getAllShippingDocs: false,
    getAllPODFinancialOther: false,
  });
  ////////////////////////////////functions////////////////////////////////
  const addForm = async (values: IAddPODFinancial) => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, addForm: true }));
    try {
      const { AddPODFinancial } = new OrderService();
      const result = await AddPODFinancial(parseInt(orderId), values);
      if (result && result.status === 200 && result.data) {
        message.success(words.orders.pod.podMessage);
        setPodFormId(result.data);
        getPODFinancial();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, addForm: false }));
    }
  };

  const updateForm = async (values: IAddPODFinancial) => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, updateForm: true }));
    try {
      const { UpdatePODFinancial } = new OrderService();
      const result = await UpdatePODFinancial(parseInt(orderId), values);
      if (result && result.status === 200) {
        message.success(words.orders.pod.updatePodMessage);
        setActiveKey(IPODTabs.other);
        getPODFinancial();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, updateForm: false }));
    }
  };

  const getPODFinancial = useCallback(async () => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, getPODFinancial: true }));
    try {
      const { GetPODFinancial } = new OrderService();
      const result = await GetPODFinancial(+orderId);
      if (result && result.status === 200 && result.data) {
        setPodFinancial(result.data);
      }
    } catch (err) {
      navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, getPODFinancial: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const getPODFinancialForm = useCallback(async () => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, getPODFinancial: true }));
    try {
      const { GetPODFinancial } = new OrderService();
      const result = await GetPODFinancial(+orderId);
      if (result && result.status === 200 && result.data) {
        basicInfoForm.setFieldsValue(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, getPODFinancial: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const addOther = async (data: IAddPODFinancialOther) => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, addShippingDoc: true }));
    try {
      const { AddPODFinancialOther } = new OrderService();

      const result = await AddPODFinancialOther(+orderId, data);
      if (result && result.status === 200 && result.data) {
        message.success(words.orders.pod.addOtherMessage);
        OtherInfoForm.resetFields();
        getAllPODFinancialOther();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, addShippingDoc: false }));
    }
  };

  const updatePODOther = async (data: IAddPODFinancialOther) => {
    if (!podOtherId) return undefined;
    setLoadings((prev) => ({ ...prev, updatePODOther: true }));
    try {
      const { UpdatePODFinancialOther } = new OrderService();

      const result = await UpdatePODFinancialOther(podOtherId, data);
      if (result && result.status === 200) {
        message.success(words.orders.pod.updateOtherMessage);
        getAllPODFinancialOther();
        OtherInfoForm.resetFields();
        setTableEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, updatePODOther: false }));
    }
  };

  const deletePODOther = async (id: number) => {
    if (!id) return undefined;
    setLoadings((prev) => ({ ...prev, deletePODOther: true }));
    try {
      const { DeletePODFinancialOther } = new OrderService();
      const result = await DeletePODFinancialOther(id);
      if (result && result.status === 200) {
        message.success(words.orders.pod.deletePodMessage);
        getAllPODFinancialOther();
      }
    } catch (err) {
    } finally {
      setLoadings((prev) => ({ ...prev, deletePODOther: false }));
    }
  };
  const getAllPODFinancialOther = useCallback(async () => {
    if (!orderId || activeKey !== IPODTabs.other) return undefined;
    setLoadings((prev) => ({ ...prev, getAllPODFinancialOther: true }));
    try {
      const { GetAllPODFinancialOthers } = new OrderService();
      const result = await GetAllPODFinancialOthers(+orderId);
      if (result && result.status === 200 && result.data) {
        setPodFinancialOther(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, getAllPODFinancialOther: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, orderId]);

  const getProformMain = useCallback(async () => {
    if (!orderId) return undefined;
    try {
      const { GetProformMain } = new OrderService();
      const response = await GetProformMain(+orderId);
      if (response && response.status === 200 && response.data) {
        setProformMain(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [orderId]);

  const [search] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  useEffect(() => {
    Promise.allSettled([getAllPODFinancialOther(), getProformMain()]);
  }, [getAllPODFinancialOther, getProformMain]);

  useEffect(() => {
    if (!proformMain?.flags.includes("Rate" || "Shipment")) {
      getPODFinancial();
    }
  }, [getPODFinancial, proformMain]);

  const defaultValue: IContext = {
    value: {
      editMode,
      activeKey,
      tableEditMode,
      podFinancial,
      backEditMode,
      loadings,
      podFinancialOther,
      podOtherId,
      podFormId,
    },
    dispatch: {
      setEditMode,
      setActiveKey,
      setTableEditMode,
      setBackEditMode,
      setPodOtherId,
    },
    func: {
      addForm,
      getPODFinancialForm,
      updateForm,
      addOther,
      updatePODOther,
      deletePODOther,
      getPODFinancial,
    },
    form: { basicInfoForm, OtherInfoForm },
  };

  return (
    <PODContext.Provider value={defaultValue}>{children}</PODContext.Provider>
  );
};

export const usePOD = () => useContext(PODContext);
