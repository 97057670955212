import { Col, Spin, Table } from "antd";
import { FC, useState } from "react";
import { S2 } from "src/components/UiKit/Typography";
import useLanguage from "src/store/language";
import { useShipment } from "../../context";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { UploadFileTableContainer } from "../../style";
import { IGetShipmentFileDetail } from "src/services/Order/models/result.models";
import { LoadingOutlined } from "@ant-design/icons";
import { useChangeParams } from "src/hook/auth/useParamsChange";

export const UploadFileTable: FC = () => {
  const {
    form: { uploadFileForm },
    dispatch: { setTableEditMode, setShipmentFileId, setPagination },
    value: { allShipmentInfo, loadings, progress, pagination },
    func: {
      deleteShipmentFileDetail,
      downloadAttachFile,
      getShipmentFileDetail,
    },
  } = useShipment()!;
  const { words } = useLanguage();
  const [dlFileId, setDlFileId] = useState<number>();
  const onChangeParams = useChangeParams();
  const Columns: ColumnsType<IGetShipmentFileDetail> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.orders.shipment.doc,
      dataIndex: "shippingDocName",
      key: "shippingDocName",
      align: "center",
      width: "803px",
    },
    {
      title: words.global.tools,
      key: "id",
      width: "130px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              downloadAttachFile(record?.shipmentFileId);
              setDlFileId(record.shipmentFileId);
            }}
            className="material-icons text-[16px] !text-[#02514C] cursor-pointer"
          >
            {loadings.downloadAttachFile &&
            dlFileId === record?.shipmentFileId ? (
              <div className="relative w-[16px] h-[16px] flex justify-center items-center">
                <Spin indicator={<LoadingOutlined spin />} size="large" />
                <span className="absolute top-[7%]  ">{progress}</span>
              </div>
            ) : (
              "file_download"
            )}
          </span>
          <span
            onClick={() => {
              getShipmentFileDetail(record.shipmentFileId);
              setTableEditMode(true);
              setShipmentFileId(record.shipmentFileId);
              uploadFileForm?.setFieldsValue(record);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <DPopconfirm
            deleteClassName
            description={words.orders.shipment.deleteDesc}
            onConfirm={() => deleteShipmentFileDetail(record.shipmentFileId)}
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];

  return (
    <UploadFileTableContainer gutter={[0, 24]}>
      <Col span={24}>
        <S2>{words.orders.shipment.tableDesc}</S2>
      </Col>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={loadings.allShipmentInfo}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={allShipmentInfo}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: pagination.total,
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page: number, pageSize) => {
              onChangeParams("Limit", pageSize.toString());
              onChangeParams(
                "Offset",
                pageSize !== pagination.pageSize ? "1" : pageSize.toString()
              );
              setPagination((prev) => ({
                ...prev,
                current: pageSize !== prev.pageSize ? 1 : page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </UploadFileTableContainer>
  );
};
