import { FC } from "react";
import { B2, O2, P1 } from "src/components/UiKit/Typography";
import styled from "styled-components";

interface IProps {
  payment: string;
  amount: number | undefined;
}
export const HeaderCards: FC<IProps> = ({ payment, amount }) => {
  return (
    <HeaderCardsContainer className="img">
      <img
        src="/assets/images/dollar.svg"
        alt="dollar"
        className="absolute left-0 bottom-0"
      />
      <P1 className="!text-[#4B4B4B]">{payment}</P1>
      <div className="flex gap-[8px] flex-col justify-center items-center">
        <B2 className="!text-[#8E8E8E]">{amount?.toLocaleString("en-US")}</B2>
        <O2 className="!text-[#008F87]">USD</O2>
      </div>
    </HeaderCardsContainer>
  );
};

const HeaderCardsContainer = styled.div`
  display: flex;
  padding: 24px;
  height: 122px;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 14px;
  background: var(--color-Base-Color-White, #fff);

  /* Table */
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
`;
