import { App, Form } from "antd";
import { FormInstance } from "antd/lib";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { IAddArgiculture } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";

interface IContext {
  value: {
    editMode: boolean;
    deleteLoading: boolean;
    loading: boolean;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    deleteAgriculture: () => void;
    add: (values: IAddArgiculture) => void;
    update: (values: IAddArgiculture) => void;
    getAgricultureInfo: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}

export const AgricultureContext = createContext<IContext | undefined>(
  undefined
);

export const AgricultureProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [agricultureId, setAgricultureId] = useState<number>();

  ////////////////////////////////functions////////////////////////////////
  const deleteAgriculture = async () => {
    if (!orderId) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteArgiculture } = new OrderService();
      const result = await DeleteArgiculture(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.agriculture.deleteAgricultureMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const add = async (values: IAddArgiculture) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddArgiculture } = new OrderService();
      const newValues = {
        final: values?.final
          ? moment(
              (values?.final as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        letter: values?.letter
          ? moment(
              (values?.letter as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        tradingBbk: values?.tradingBbk
          ? moment(
              (values?.tradingBbk as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
      };
      const result = await AddArgiculture(parseInt(orderId), newValues);
      if (result && result.status === 200 && result.data) {
        setAgricultureId(result.data);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
        message.success(words.orders.agriculture.agricultureMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const update = async (values: IAddArgiculture) => {
    if (!orderId || !agricultureId) return undefined;
    setLoading(true);
    try {
      const { UpdateArgiculture } = new OrderService();
      const newValues = {
        final: values?.final
          ? moment(
              (values?.final as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        letter: values?.letter
          ? moment(
              (values?.letter as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
        tradingBbk: values?.tradingBbk
          ? moment(
              (values?.tradingBbk as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
      };
      const result = await UpdateArgiculture(parseInt(orderId), newValues);
      if (result && result.status === 200) {
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
        message.success(words.orders.agriculture.updateAgricultureMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getAgricultureInfo = useCallback(async () => {
    if (!orderId) return undefined;
    try {
      const { GetArgiculture } = new OrderService();
      const result = await GetArgiculture(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        const newData = {
          ...result.data,
          final: result.data.final ? dayjs(result.data.final) : undefined,
          letter: result.data.letter ? dayjs(result.data.letter) : undefined,
          tradingBbk: result.data.tradingBbk
            ? dayjs(result.data.tradingBbk)
            : undefined,
        };
        form.setFieldsValue(newData);
        setAgricultureId(result.data.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  const ContextValue: IContext = {
    value: { deleteLoading, editMode, loading },
    dispatch: { setEditMode },
    func: { add, deleteAgriculture, getAgricultureInfo, update },
    form: { form },
  };
  return (
    <AgricultureContext.Provider value={ContextValue}>
      {children}
    </AgricultureContext.Provider>
  );
};

export const useAgriculture = () => useContext(AgricultureContext);
