import { FC } from "react";
import { CustomOperationHeader } from "./components/Header";
import { CustomOperationsContainer } from "./style";
import { CustomOperationsTable } from "./components/Table";
import { AddNewCustomOperation } from "./components/AddNew";

export const CustomOperation: FC = () => {
  return (
    <div>
      <CustomOperationHeader />
      <div className="flex justify-center">
        <CustomOperationsContainer>
          <AddNewCustomOperation />
          <CustomOperationsTable />
        </CustomOperationsContainer>
      </div>
    </div>
  );
};
