import useLanguage from "src/store/language";
import { IManagementCard } from "../models/interface";
import { ROUTES } from "src/models/enums/routes";
import { useParams } from "react-router-dom";
import * as GuardPermissions from "../../../components/Guard/permissions/orderPagePath";

export const useManagement = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const cards: IManagementCard = {
    orderInformationManagement: [
      {
        id: 1,
        name: `${words.orders.proforma}`,
        flag: "BasicInfo",
        endPointView: ROUTES.ViewOrder.concat(`/${orderId}`),
        endPointAdd: ROUTES.orders.concat(`/${orderId}`),
        guard: GuardPermissions.ProformPagePath,
      },
      {
        id: 2,
        name: `${words.orders.origin.origin}`,
        flag: "Origin",
        endPointView: ROUTES.viewOrigin.concat(`/${orderId}`),
        endPointAdd: ROUTES.origin.concat(`/${orderId}`),
        guard: GuardPermissions.OriginPagePath,
      },
      {
        id: 3,
        name: `${words.orders.financialOrigin}`,
        flag: "OriginFinancial",
        endPointView: ROUTES.viewOriginFinancial.concat(`/${orderId}`),
        endPointAdd: ROUTES.originFinancial.concat(`/${orderId}`),
        guard: GuardPermissions.OriginFinancialPagePath,
      },
      {
        id: 4,
        name: `${words.orders.bankInfo}`,
        flag: "BankingOperation",
        endPointView: ROUTES.viewBankingOperations.concat(`/${orderId}`),
        endPointAdd: ROUTES.bankingOperations.concat(`/${orderId}`),
        guard: GuardPermissions.BankingOperationPagePath,
      },
      {
        id: 5,
        name: `${words.orders.vip.vip}`,
        flag: "Vip",
        endPointView: ROUTES.viewVip.concat(`/${orderId}`),
        endPointAdd: ROUTES.vip.concat(`/${orderId}`),
        guard: GuardPermissions.VipPagePath,
      },
      {
        id: 6,
        name: `${words.orders.orderRecord}`,
        flag: "Order",
        endPointView: ROUTES.viewAddOrder.concat(`/${orderId}`),
        endPointAdd: ROUTES.addOrder.concat(`/${orderId}`),
        guard: GuardPermissions.OrderRegisterPagePath,
      },
      {
        id: 7,
        name: `${words.orders.insurance.insurance}`,
        flag: "Insurance",
        endPointView: ROUTES.viewInsurance.concat(`/${orderId}`),
        endPointAdd: ROUTES.insurance.concat(`/${orderId}`),
        guard: GuardPermissions.InsurancePagePath,
      },
      {
        id: 8,
        name: `${words.orders.inspection.inspection}`,
        flag: "Inspection",
        endPointView: ROUTES.viewInspection.concat(`/${orderId}`),
        endPointAdd: ROUTES.inspectionDocument.concat(`/${orderId}`),
        guard: GuardPermissions.InspectionPagePath,
      },
      {
        id: 9,
        name: `${words.orders.carry}`,
        flag: "Shipment",
        endPointView: ROUTES.viewShipment.concat(`/${orderId}`),
        endPointAdd: ROUTES.shipment.concat(`/${orderId}`),
        guard: GuardPermissions.ShipmentPagePath,
      },
      {
        id: 10,
        name: `${words.orders.feesRate}`,
        flag: "Rate",
        endPointView: ROUTES.viewRates.concat(`/${orderId}`),
        endPointAdd: ROUTES.rates.concat(`/${orderId}`),
        guard: GuardPermissions.RatesPagePath,
      },
      {
        id: 11,
        name: `${words.orders.financialDestination}`,
        flag: "PODFinancial",
        endPointView: ROUTES.viewPOD.concat(`/${orderId}`),
        endPointAdd: ROUTES.podFinancial.concat(`/${orderId}`),
        guard: GuardPermissions.PodPagePath,
      },
      {
        id: 12,
        name: `${words.orders.shipArrival}`,
        flag: "VesselArrival",
        endPointView: ROUTES.viewVesselArrive.concat(`/${orderId}`),
        endPointAdd: ROUTES.vesselArrive.concat(`/${orderId}`),
        guard: GuardPermissions.VesselArrivePagePath,
      },
      {
        id: 13,
        name: `${words.orders.statement}`,
        flag: "CustomDeclaration",
        endPointView: ROUTES.viewCustomDeclaration.concat(`/${orderId}`),
        endPointAdd: ROUTES.customDeclaration.concat(`/${orderId}`),
        guard: GuardPermissions.CustomDeclarationPagePath,
      },
      {
        id: 14,
        name: `${words.orders.customsFormalities.customsFormalities}`,
        flag: "CustomFormality",
        endPointView: ROUTES.viewCustomsFormalities.concat(`/${orderId}`),
        endPointAdd: ROUTES.customsFormalities.concat(`/${orderId}`),
        guard: GuardPermissions.CustomFormalitysPagePath,
      },
      {
        id: 15,
        name: `${words.orders.inventory}`,
        flag: "Warehousing",
        endPointView: ROUTES.viewWarehousing.concat(`/${orderId}`),
        endPointAdd: ROUTES.warehousing.concat(`/${orderId}`),
        guard: GuardPermissions.warehousingPagePath,
      },
      {
        id: 16,
        name: `${words.orders.deviceOpinion}`,
        flag: "Argicultur",
        endPointView: ROUTES.viewAgriculture.concat(`/${orderId}`),
        endPointAdd: ROUTES.Agriculture.concat(`/${orderId}`),
        guard: GuardPermissions.ArgiculturePagePath,
      },
      {
        id: 17,
        name: `${words.orders.sale}`,
        flag: "Sell",
        endPointView: ROUTES.sellingView.concat(`/${orderId}`),
        endPointAdd: ROUTES.selling.concat(`/${orderId}`),
        guard: GuardPermissions.sellingPagePath,
      },
    ],
  };

  return cards;
};
