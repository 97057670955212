import { App, Col, Flex, Row, Spin, Table } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { ViewCustomsFormalitiesContainer } from "../../style";
import { T1 } from "src/components/UiKit/Typography";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import {
  IGetAllCustomFormalitys,
  IGetCustomFormalityFile,
} from "src/services/Order/models/result.models";
import { OrderService } from "src/services/Order/order.service";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosRequestConfig } from "axios";
import { DocumentService } from "src/services/Document/document.service";
import downloadZipFile from "src/helper/downloadZipFile";
import { LoadingOutlined } from "@ant-design/icons";
import { docModalProps } from "src/helper/docModalProps";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import moment from "moment-jalaali";

export const ViewCustomsFormalities: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const { modal } = App.useApp();
  const onChangeParams = useChangeParams();

  const [progress, setProgress] = useState<number>(0);
  const [allCustomFormalitys, setAllCustomFormalitys] = useState<
    IGetAllCustomFormalitys[]
  >([]);
  const [allCustomFormalitysLoading, setAllCustomFormalitysLoading] =
    useState<boolean>(false);
  const [dlFileId, setDlFileId] = useState<number>();
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  ////////////////////////////////////////////////////////////////functions////////////////////////////////
  const getAllCustomFormalitys = useCallback(async () => {
    if (!orderId) return undefined;
    setAllCustomFormalitysLoading(true);
    try {
      const { GetAllCustomFormalitys } = new OrderService();
      const result = await GetAllCustomFormalitys(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setAllCustomFormalitys(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllCustomFormalitysLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const GetFile = async (file: IGetCustomFormalityFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    try {
      const { GetCustomFormalityFile } = new OrderService();
      const result = await GetCustomFormalityFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      modal.confirm(
        docModalProps(words.orders.customsFormalities.customsFormalities)
      );
      console.log(words.orders.customsFormalities.customsFormalities);
    } finally {
      setProgress(0);
    }
  };

  useEffect(() => {
    Promise.allSettled([getAllCustomFormalitys()]);
  }, [getAllCustomFormalitys]);

  const Columns: ColumnsType<IGetAllCustomFormalitys> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.orders.inspection.typeOfDocument,
      dataIndex: "customOperationTitle",
      key: "customOperationTitle",
      align: "center",
      width: "150px",
    },
    {
      title: words.orders.inspection.date,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "255px",
      render: (text, record) =>
        record.date
          ? moment(record.date as string).format("jYYYY-jMM-jDD")
          : "---",
    },
    {
      title: words.global.tools,
      key: "id",
      width: "130px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              downloadAttachFile(record?.id);
              setDlFileId(record.id);
            }}
            className="material-icons text-[16px] !text-[#02514C] cursor-pointer"
          >
            {progress > 0 && record.id === dlFileId ? (
              <div className="relative w-[16px] h-[16px] flex justify-center items-center">
                <Spin indicator={<LoadingOutlined spin />} size="large" />
                <span className="absolute top-[20%] left-[5%] ">
                  {progress}
                </span>
              </div>
            ) : (
              "file_download"
            )}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div>
      <HeaderProforma
        headerName={
          words.orders.customsFormalities.viewCustomsFormalitiesHeader
        }
      />
      <Row className="w-full" justify={"center"}>
        <ViewCustomsFormalitiesContainer span={13}>
          <Flex vertical gap={32}>
            <div className="px-[12px]">
              <T1>{words.orders.customsFormalities.viewCustomsFormalities}</T1>
            </div>
            <Row>
              <Col span={24} className="tableHolder">
                <Table
                  columns={Columns}
                  loading={allCustomFormalitysLoading}
                  scroll={{ x: "auto" }}
                  rowKey={getRowKey}
                  className="w-[100%]"
                  dataSource={allCustomFormalitys}
                  pagination={{
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                    total: pagination.total,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    onChange: (page: number, pageSize) => {
                      onChangeParams("Limit", pageSize.toString());
                      onChangeParams(
                        "Offset",
                        pageSize !== pagination.pageSize
                          ? "1"
                          : pageSize.toString()
                      );
                      setPagination((prev) => ({
                        ...prev,
                        current: pageSize !== prev.pageSize ? 1 : page,
                        pageSize,
                      }));
                    },
                  }}
                />
              </Col>
            </Row>
          </Flex>
        </ViewCustomsFormalitiesContainer>
      </Row>
    </div>
  );
};
