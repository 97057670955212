import { Col, Spin, Table } from "antd";
import { FC, useState } from "react";
import { S2 } from "src/components/UiKit/Typography";
import useLanguage from "src/store/language";
import { UseInsurance } from "../../context";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { UploadFileTableContainer } from "../../style";
import dayjs from "dayjs";
import { IGetInsuranceFileDetail } from "src/services/Order/models/result.models";
import moment from "moment-jalaali";
import { LoadingOutlined } from "@ant-design/icons";
import { useChangeParams } from "src/hook/auth/useParamsChange";

export const UploadFileTable: FC = () => {
  const {
    form: { uploadFileForm },
    dispatch: { setTableEditMode, setInsuranceFileId, setPagination },
    value: { allInsuranceInfo, allInsuranceInfoLoading, progress, pagination },
    func: { deleteInsuranceDetail, downloadAttachFile, getInsuranceFileDetail },
  } = UseInsurance()!;
  const { words } = useLanguage();
  const onChangeParams = useChangeParams();

  const [dlFileId, setDlFileId] = useState<number>();

  const Columns: ColumnsType<IGetInsuranceFileDetail> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.orders.insurance.title,
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "100px",
    },
    {
      title: words.orders.insurance.number,
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "100px",
      render: (text, record) => (record.number ? record.number : "---"),
    },
    {
      title: words.orders.insurance.date,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "255px",
      render: (text, record) =>
        record.date ? moment(record.date).format("jYYYY-jMM-jDD") : "---",
    },
    {
      title: words.global.tools,
      key: "id",
      width: "130px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              downloadAttachFile(record?.insuranceFileId);
              setDlFileId(record.insuranceFileId);
            }}
            className="material-icons text-[16px] !text-[#02514C] cursor-pointer"
          >
            {progress > 0 && dlFileId === record?.insuranceFileId ? (
              <div className="relative w-[16px] h-[16px] flex justify-center items-center">
                <Spin indicator={<LoadingOutlined spin />} size="large" />
                <span className="absolute top-[20%] left-[5%] ">
                  {progress}
                </span>
              </div>
            ) : (
              "file_download"
            )}
          </span>
          <span
            onClick={() => {
              getInsuranceFileDetail(record.insuranceFileId);
              setTableEditMode(true);
              setInsuranceFileId(record.insuranceFileId);
              const newData = {
                ...record,
                date: record.date ? dayjs(record.date) : undefined,
              };
              uploadFileForm?.setFieldsValue(newData);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <DPopconfirm
            deleteClassName
            description={words.orders.insurance.deleteDesc}
            onConfirm={() => deleteInsuranceDetail(record.insuranceFileId)}
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];

  return (
    <UploadFileTableContainer gutter={[0, 24]}>
      <Col span={24}>
        <S2>{words.orders.insurance.tableDesc}</S2>
      </Col>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={allInsuranceInfoLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={allInsuranceInfo}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: pagination.total,
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page: number, pageSize) => {
              onChangeParams("Limit", pageSize.toString());
              onChangeParams(
                "Offset",
                pageSize !== pagination.pageSize ? "1" : pageSize.toString()
              );
              setPagination((prev) => ({
                ...prev,
                current: pageSize !== prev.pageSize ? 1 : page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </UploadFileTableContainer>
  );
};
