import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {}
export const S1: FC<IProps & PropsWithChildren> = (props, { children }) => {
  return <Container {...props} />;
};

const Container = styled.div`
  color: ${(props) => props.theme.colors.title};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
