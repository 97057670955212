import { App, Form, UploadFile } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { IAddInspecionForm } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import { FormInstance } from "antd/lib";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IGetAllInspectionDocs } from "src/services/BaseInfo/models/result.models";
import { OrderUrl } from "src/services/Order/urls";
import { RcFile } from "antd/es/upload";
import { uploadFile } from "src/helper/uploadZipFile";
import {
  IGetAllInspectionForms,
  IGetInspecionFormFile,
} from "src/services/Order/models/result.models";
import { format } from "date-fns";
import { AxiosRequestConfig } from "axios";
import { DocumentService } from "src/services/Document/document.service";
import downloadZipFile from "src/helper/downloadZipFile";
import { docModalProps } from "src/helper/docModalProps";
import { ITablePagination } from "src/models/interfaces/pagination";

interface IContext {
  value: {
    editMode: boolean;
    loading: boolean;
    deleteLoading: boolean;
    inspectionDocsLoading: boolean;
    inspectionDocs: IGetAllInspectionDocs[];
    hasFile: boolean;
    file: UploadFile | undefined;
    progress: number;
    uploadProgress: number;
    tableEditMode: boolean;
    allInspectionForms: IGetAllInspectionForms[];
    allInspectionFormsLoading: boolean;
    inspectionFormId: number | undefined;
    openModal: boolean;
    addInspectionDocLoading: boolean;
    pagination: ITablePagination;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setFile: Dispatch<SetStateAction<UploadFile | undefined>>;
    setTableEditMode: Dispatch<SetStateAction<boolean>>;
    setInspectionFormId: Dispatch<SetStateAction<number | undefined>>;
    setHasFile: Dispatch<SetStateAction<boolean>>;
    setOpenMOdal: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    deleteInspection: (id: number) => void;
    add: (values: IAddInspecionForm) => void;
    update: (values: IAddInspecionForm) => void;
    getAllInspectionForms: () => void;
    AddInspectionDoc: (value: string) => void;
    downloadAttachFile: (id?: number) => void;
    getInspecionForm: (id: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
  };
}
export const InspectionContext = createContext<IContext | undefined>(undefined);

export const InspectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message, modal } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [inspectionDocs, setInspectionDocs] = useState<IGetAllInspectionDocs[]>(
    []
  );
  const [allInspectionForms, setAllInspectionForms] = useState<
    IGetAllInspectionForms[]
  >([]);
  const [allInspectionFormsLoading, setAllInspectionFormsLoading] =
    useState<boolean>(false);
  const [inspectionDocsLoading, setInspectionDocsLoading] =
    useState<boolean>(false);
  const [file, setFile] = useState<UploadFile>();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [tableEditMode, setTableEditMode] = useState<boolean>(false);
  const [inspectionFormId, setInspectionFormId] = useState<
    number | undefined
  >();
  const [openModal, setOpenMOdal] = useState<boolean>(false);
  const [addInspectionDocLoading, setAddInspectionDocLoading] =
    useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });

  ////////////////////////////////functions////////////////////////////////
  const getAllInspectionDocs = useCallback(async () => {
    setInspectionDocsLoading(true);
    try {
      const { GetAllInspectionDocs } = new BaseInfoService();
      const response = await GetAllInspectionDocs();
      if (response && response.status === 200 && response.data) {
        setInspectionDocs(response.data.records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setInspectionDocsLoading(false);
    }
  }, []);

  const newFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.NewInspecionFormFile}`
        ),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setUploadProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const add = async (values: IAddInspecionForm) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddInspecionForm } = new OrderService();
      const newValues = {
        ...values,
        date: values.date
          ? format(new Date(values.date as string), "yyyy-MM-dd")
          : null,
      };
      const result = await AddInspecionForm(parseInt(orderId), newValues);
      if (result && result.status === 200 && result.data) {
        if (file) {
          const resultFile = await newFile(result.data);
          if (resultFile) {
            message.success(words.orders.inspection.inspectionMessage);
            getAllInspectionForms();
            form.resetFields();
            setHasFile(false);
            setFile(undefined);
          }
        } else {
          message.success(words.orders.inspection.inspectionMessage);
          getAllInspectionForms();
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.UpdateInspecionFormFile}`
        ),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setUploadProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (values: IAddInspecionForm) => {
    if (!inspectionFormId) return undefined;
    setLoading(true);
    try {
      const { UpdateInspecionForm } = new OrderService();
      const newValues = {
        ...values,
        date: values.date
          ? format(new Date(values.date as string), "yyyy-MM-dd")
          : null,
      };
      const result = await UpdateInspecionForm(inspectionFormId, newValues);
      if (result && result.status === 200) {
        if (file) {
          let resultFile;
          if (hasFile) {
            resultFile = await updateFile(inspectionFormId);
          } else {
            resultFile = await newFile(inspectionFormId);
          }
          if (resultFile) {
            message.success(words.orders.inspection.updateInspectionMessage);
            getAllInspectionForms();
            form.resetFields();
            setHasFile(false);
            setFile(undefined);
            setTableEditMode(false);
          }
        } else {
          message.success(words.orders.inspection.updateInspectionMessage);
          getAllInspectionForms();
          form.resetFields();
          setHasFile(false);
          setFile(undefined);
          setTableEditMode(false);
        }
      }
    } catch (err) {
      console.log(err);
      setHasFile(false);
      setFile(undefined);
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  const getAllInspectionForms = useCallback(async () => {
    if (!orderId) return undefined;
    setAllInspectionFormsLoading(true);
    try {
      const { GetAllInspectionForms } = new OrderService();
      const result = await GetAllInspectionForms(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setAllInspectionForms(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllInspectionFormsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const getInspecionForm = async (id: number) => {
    if (!id) return undefined;
    try {
      const { GetInspecionForm } = new OrderService();
      const result = await GetInspecionForm(id);
      if (result && result.status === 200 && result.data) {
        setHasFile(result.data.hasFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const deleteInspection = async (id: number) => {
    if (!id) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteInspecionForm } = new OrderService();
      const result = await DeleteInspecionForm(id);
      if (result && result.status === 200) {
        message.success(words.orders.inspection.deleteInspectionMessage);
        getAllInspectionForms();
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const AddInspectionDoc = useCallback(async (value: string) => {
    if (!orderId) return undefined;
    setAddInspectionDocLoading(true);
    try {
      const { AddInspectionDoc } = new BaseInfoService();
      const result = await AddInspectionDoc({ title: value });
      if (result && result.status === 200) {
        message.success(words.orders.inspection.addInspectionDocMessage);
        setOpenMOdal(false);
        getAllInspectionDocs();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddInspectionDocLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetFile = async (file: IGetInspecionFormFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    try {
      const { GetInspecionFormFile } = new OrderService();
      const result = await GetInspecionFormFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      modal.confirm(docModalProps(words.orders.inspection.inspection));
    } finally {
      setProgress(0);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  useEffect(() => {
    Promise.allSettled([getAllInspectionForms(), getAllInspectionDocs()]);
  }, [getAllInspectionDocs, getAllInspectionForms]);
  const defaultValue: IContext = {
    value: {
      deleteLoading,
      editMode,
      loading,
      inspectionDocs,
      inspectionDocsLoading,
      file,
      hasFile,
      progress,
      tableEditMode,
      allInspectionForms,
      allInspectionFormsLoading,
      inspectionFormId,
      openModal,
      addInspectionDocLoading,
      pagination,
      uploadProgress,
    },
    dispatch: {
      setEditMode,
      setFile,
      setTableEditMode,
      setInspectionFormId,
      setHasFile,
      setOpenMOdal,
      setPagination,
    },
    func: {
      add,
      deleteInspection,
      getAllInspectionForms,
      update,
      AddInspectionDoc,
      downloadAttachFile,
      getInspecionForm,
    },
    form: { form },
  };

  return (
    <InspectionContext.Provider value={defaultValue}>
      {children}
    </InspectionContext.Provider>
  );
};

export const UseInspection = () => useContext(InspectionContext);
