import { Row } from "antd";
import { FC } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { CustomsFormalitiesContainer } from "./style";
import { UseCustomsFormalities } from "./context";
import { UploadFileForm } from "./components/UploadFileForm";
import { UploadFileTable } from "./components/Table";

export const CustomsFormalities: FC = () => {
  const {
    value: { editMode },
  } = UseCustomsFormalities()!;
  const { words } = useLanguage();

  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.customsFormalities.editCustomsFormalitiesHeaderInfo
            : words.orders.customsFormalities.customsFormalitiesHeaderInfo
        }
      />
      <Row justify={"center"}>
        <CustomsFormalitiesContainer span={18}>
          <UploadFileForm />
          <UploadFileTable />
        </CustomsFormalitiesContainer>
      </Row>
    </div>
  );
};
