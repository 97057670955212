import { FC, useEffect, useMemo } from "react";
import { OriginContainer } from "./style";
import {
  App,
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Flex,
  Form,
  GetProp,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { useOrigin } from "./context";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { deliveryTerm } from "./model";
import { UploadFile } from "antd/lib";
import { dummyRequest } from "src/helper/dummyRequest";
import { UploadChangeParam } from "antd/es/upload";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import { IAddOrigin } from "src/services/Order/models/args.models";
import en_US from "antd/lib/locale/en_US";
import { JalaliLocaleListener } from "antd-jalali";
import { S2 } from "src/components/UiKit/Typography";
import { LoadingOutlined } from "@ant-design/icons";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const Origin: FC = () => {
  const {
    value: {
      supplier,
      supplierLoading,
      country,
      countryLoading,
      currency,
      currencyLoading,
      inspection,
      inspectionLoading,
      editMode,
      file,
      checked,
      loading,
      deleteLoading,
      hasFile,
      progress,
    },
    dispatch: { setEditMode, setFile, setChecked },
    func: { deleteOrigin, add, update, getOriginInfo },
    form: { form },
  } = useOrigin()!;
  const { words } = useLanguage();
  const { Option } = Select;
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { message } = App.useApp();
  const values = Form.useWatch([], form);

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setFile([info.file]);
    }
  };
  const beforeUpload = (file: FileType) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
      setFile([]);
    }

    return isJpgOrPng;
  };
  const onChangeCheckbox = (value: boolean) => {
    setChecked(value);
  };
  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };
  const onClickReset = () => {
    form?.resetFields();
    setFile([]);
  };

  const onFinish = (values: IAddOrigin) => {
    if (editMode) {
      update(values);
    } else {
      add(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getOriginInfo();
  }, [getOriginInfo, isEdited]);
  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.origin.editSourceInfo
            : words.orders.origin.recordSourceInfo
        }
      />
      <Flex justify="center">
        <OriginContainer>
          <Form form={form} onFinish={onFinish}>
            <Col span={24}>
              <S2 className="py-[16px]">
                {editMode
                  ? words.orders.editInfo
                  : words.orders.origin.originInfo}
              </S2>
            </Col>
            <Col span={24} className="py-[16px]">
              <Row gutter={24}>
                <Col span={8}>
                  <FormItem
                    name={"supplierId"}
                    label={words.basicInformation.suppliers.suppliers}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={supplierLoading}
                    >
                      {supplier?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.name}>
                          <Space>{item.name}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"contractNo"}
                    label={words.orders.origin.contractNo}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={words.global.insert}
                      onPressEnter={form?.submit}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <ConfigProvider locale={en_US}>
                    <JalaliLocaleListener />
                    <FormItem
                      name={"contractDate"}
                      label={words.orders.origin.contractDate}
                      rules={[{ required: true }]}
                    >
                      <DatePicker placeholder={words.global.insert} />
                    </FormItem>
                  </ConfigProvider>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"originCountryId"}
                    label={words.basicInformation.countries.countries}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={countryLoading}
                    >
                      {country?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.faName}>
                          <Space>{item.faName}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"deliveryTeam"}
                    label={words.orders.origin.deliveryTerm}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                    >
                      {deliveryTerm?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.name}>
                          <Space>{item.name}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"currencyId"}
                    label={words.basicInformation.currencies.currencies}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={currencyLoading}
                    >
                      {currency?.map((item, index) => (
                        <Option
                          key={index}
                          value={item.id}
                          label={item.shortName}
                        >
                          <Space>{item.shortName}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"insepectionId"}
                    label={
                      words.basicInformation.inspectionCompanies
                        .inspectionCompanies
                    }
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={inspectionLoading}
                    >
                      {inspection?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.name}>
                          <Space>{item.name}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"warehousing"}
                    label={""}
                    className="checkboxHolder"
                  >
                    <Checkbox
                      onChange={(e) => onChangeCheckbox(e.target.checked)}
                      checked={checked}
                    >
                      {words.orders.origin.warehousing}
                    </Checkbox>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <div className="uploadHolder">
                    <div className="p-x[12px] flex flex-col justify-center">
                      <div className="text-[14px]">
                        {words.global.fileUpload}
                      </div>
                      <div className="text-[10px]">
                        {words.global.fileUploadInfo}
                      </div>
                    </div>
                    <div
                      className={
                        file.length > 0 || hasFile
                          ? "w-[66px] h-[66px]"
                          : `fileHolder`
                      }
                    >
                      <Upload
                        name="file"
                        onChange={handleChange}
                        customRequest={dummyRequest}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="relative w-[66px] h-[66px] flex justify-center items-center">
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="large"
                              className="flex justify-center items-center"
                            />
                            <span className="absolute top-[38%] left-[40%] ">
                              {progress}
                            </span>
                          </div>
                        ) : (
                          <Button
                            type={
                              file.length > 0 || hasFile ? "text" : "default"
                            }
                            icon={
                              file.length > 0 || hasFile ? (
                                <img
                                  src={"/assets/images/fileUploaded.svg"}
                                  alt="fileUploaded"
                                />
                              ) : (
                                <span className="material-icons flex justify-center items-center text-[20px]">
                                  attach_file
                                </span>
                              )
                            }
                            className="text-[10px]"
                          >
                            {file.length > 0 || hasFile ? "" : words.global.add}
                          </Button>
                        )}
                      </Upload>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider dashed className="!border-[#BFE2E0]" />
              <div
                className={`flex py-[24px]  ${
                  editMode ? "justify-between" : "justify-end"
                }`}
              >
                {editMode ? (
                  <div className="!min-w-[85px]">
                    <DeletePopconfirm
                      endPointName={words.orders.origin.deleteSourceInformation}
                      onConfirm={() => {
                        deleteOrigin();
                      }}
                      className="!min-w-[85px]"
                    >
                      <Button
                        danger
                        className="!min-w-[85px]"
                        loading={deleteLoading}
                      >
                        {words.global.delete}
                      </Button>
                    </DeletePopconfirm>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex gap-[16px] justify-end borderTop">
                  {editMode ? (
                    <div className="!min-w-[85px] h-[100%]">
                      <Button type="default" onClick={onClickCancel}>
                        {words.global.cancel}
                      </Button>
                    </div>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] h-[100%]">
                        <Button
                          type="default"
                          disabled={isDisabledResetForm && file.length === 0}
                        >
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  )}
                  <Button
                    className="!min-w-[85px]"
                    type="primary"
                    onClick={() => {
                      form?.submit();
                    }}
                    disabled={isDisabledResetForm}
                    loading={loading}
                  >
                    {editMode ? words.global.saveEdit : words.global.add}
                  </Button>
                </div>
              </div>
            </Col>
          </Form>
        </OriginContainer>
      </Flex>
    </div>
  );
};
