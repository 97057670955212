import { App, Form } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useLanguage from "src/store/language";
import { FormInstance } from "antd/lib";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAllTransportResult } from "src/services/BaseInfo/models/result.models";
import { OrderService } from "src/services/Order/order.service";
import { IAddEditSell } from "src/services/Order/models/args.models";
import { IGetAlllSell } from "src/services/Order/models/result.models";
import { ITablePagination } from "src/models/interfaces/pagination";

interface IContext {
  value: {
    editMode: boolean;
    tableEditMode: boolean;
    getLoading: boolean;
    loading: boolean;
    allTransportsLoading: boolean;
    allTransports: IAllTransportResult[];
    totalSelling: number | undefined;
    sellingId: number | undefined;
    allSelling: IGetAlllSell[];
    pagination: ITablePagination;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setTableEditMode: Dispatch<SetStateAction<boolean>>;
    setTransportersLoading: Dispatch<SetStateAction<boolean>>;
    setAllTransports: Dispatch<SetStateAction<IAllTransportResult[]>>;
    setSellingId: Dispatch<SetStateAction<number | undefined>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    getAllTransports: () => void;
    add: (values: IAddEditSell) => void;
    update: (values: IAddEditSell, id: number) => void;
    deleteSell: (id: number) => void;
    getAlllSelling: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}
export const SellingContext = createContext<IContext | undefined>(undefined);

export const SellingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [tableEditMode, setTableEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [allTransports, setAllTransports] = useState<IAllTransportResult[]>([]);
  const [allTransportsLoading, setTransportersLoading] =
    useState<boolean>(false);
  const [sellingId, setSellingId] = useState<number>();
  const [totalSelling, setTotalSelling] = useState<number>();
  const [allSelling, setAllSelling] = useState<IGetAlllSell[]>([]);
  const [getLoading, setGetLoading] = useState<boolean>(false);

  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  ////////////////////////////////functions////////////////////////////////
  const getAllTransports = useCallback(async () => {
    setTransportersLoading(true);
    try {
      const { GetAllTransport } = new BaseInfoService();
      const result = await GetAllTransport();
      if (result && result.status === 200) {
        setAllTransports(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTransportersLoading(false);
    }
  }, []);

  const getAlllSelling = useCallback(async () => {
    if (!orderId) return undefined;
    setGetLoading(true);
    try {
      const { GetAlllSell } = new OrderService();
      const result = await GetAlllSell(parseInt(orderId));
      if (result && result.status === 200) {
        setAllSelling(result.data.records.records);
        setTotalSelling(result.data.total);
        setPagination((prev) => ({
          ...prev,
          total: result.data.records.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGetLoading(false);
    }
  }, [orderId]);

  const add = async (values: IAddEditSell) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddSell } = new OrderService();

      const result = await AddSell(parseInt(orderId), values);
      if (result && result.status === 200) {
        message.success(words.orders.selling.sellingMessage);
        setSellingId(result.data);
        form.resetFields();
        getAlllSelling();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const update = async (values: IAddEditSell, id: number) => {
    setLoading(true);
    try {
      const { UpdateSell } = new OrderService();

      const result = await UpdateSell(id, values);
      if (result && result.status === 200) {
        message.success(words.orders.selling.updateSellingMessage);
        form.resetFields();
        getAlllSelling();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteSell = async (id: number) => {
    setLoading(true);
    try {
      const { DeleteSell } = new OrderService();
      const result = await DeleteSell(id);
      if (result && result.status === 200) {
        message.success(words.orders.selling.deleteSellingMessage);
        getAlllSelling();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const [search] = useSearchParams();
  const editId = search.get("editMode");
  useEffect(() => {
    if (orderId) {
      setEditMode(!!editId);
    }
    return () => {
      setEditMode(false);
    };
  }, [editId, orderId]);

  const defaultValue: IContext = {
    value: {
      editMode,
      loading,
      allTransports,
      allTransportsLoading,
      pagination,
      getLoading,
      allSelling,
      totalSelling,
      sellingId,
      tableEditMode,
    },
    dispatch: {
      setEditMode,
      setAllTransports,
      setTransportersLoading,
      setSellingId,
      setPagination,
      setTableEditMode,
    },
    func: {
      getAllTransports,
      add,
      getAlllSelling,
      update,
      deleteSell,
    },
    form: { form },
  };

  return (
    <SellingContext.Provider value={defaultValue}>
      {children}
    </SellingContext.Provider>
  );
};

export const useSelling = () => useContext(SellingContext);
