import { App, Form, FormInstance } from "antd";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import {
  IAllUnitResult,
  IAllWarehouseResult,
} from "src/services/BaseInfo/models/result.models";
import { IAddWarehousing } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";

interface IContext {
  value: {
    editMode: boolean;
    unit: IAllUnitResult[];
    unitLoading: boolean;
    warehouseLoading: boolean;
    warehouse: IAllWarehouseResult[];
    addLoading: boolean;
    deleteLoading: boolean;
    warehousingId: number | undefined;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    getUnit: () => void;
    getWarehouse: () => void;
    add: (values: IAddWarehousing) => void;
    update: (values: IAddWarehousing) => void;
    deleteWarehousing: () => void;
    getWarehousing: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}
export const WarehousingContext = createContext<IContext | undefined>(
  undefined
);

export const WarehousingContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [form] = Form.useForm();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { words } = useLanguage();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);
  const [unit, setUnit] = useState<IAllUnitResult[]>([]);
  const [warehouseLoading, setWarehouseLoading] = useState<boolean>(false);
  const [warehouse, setWarehouse] = useState<IAllWarehouseResult[]>([]);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [warehousingId, setWarehousingId] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  ////////////////////////////////////////////////////////////////function////////////////////////////////
  const getUnit = useCallback(async () => {
    setUnitLoading(true);
    try {
      const { GetAllUnit } = new BaseInfoService();
      const result = await GetAllUnit();
      if (result && result.status === 200) {
        setUnit(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUnitLoading(false);
    }
  }, []);

  const getWarehouse = useCallback(async () => {
    setWarehouseLoading(true);
    try {
      const { AllWarehouse } = new BaseInfoService();
      const result = await AllWarehouse();
      if (result && result.status === 200) {
        setWarehouse(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setWarehouseLoading(false);
    }
  }, []);

  const add = async (values: IAddWarehousing) => {
    if (!orderId) return undefined;
    setAddLoading(true);
    try {
      const { AddWarehousing } = new OrderService();
      const newData = {
        ...values,
        receiptDate: moment(
          (values.receiptDate as Dayjs).format("jYYYY-jMM-jDD"),
          "jYYYY-jMM-jDD"
        ).format("YYYY-MM-DD"),
      };
      const result = await AddWarehousing(parseInt(orderId), newData);
      if (result && result.status === 200) {
        setWarehousingId(result.data);
        message.success(words.orders.warehousing.warehousingMessage);
        form?.resetFields();
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddLoading(false);
    }
  };

  const update = async (values: IAddWarehousing) => {
    if (!orderId) return undefined;
    setAddLoading(true);
    try {
      const { UpdateWarehousing } = new OrderService();
      const newData = {
        ...values,
        receiptDate: moment(
          (values.receiptDate as Dayjs).format("jYYYY-jMM-jDD"),
          "jYYYY-jMM-jDD"
        ).format("YYYY-MM-DD"),
      };
      const result = await UpdateWarehousing(parseInt(orderId), newData);
      if (result && result.status === 200) {
        message.success(words.orders.warehousing.updateWarehousingMessage);
        form?.resetFields();
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddLoading(false);
    }
  };

  const getWarehousing = useCallback(async () => {
    if (!orderId) return undefined;
    try {
      const { GetWarehousing } = new OrderService();
      const result = await GetWarehousing(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        const newData = {
          ...result.data,
          receiptDate: result.data.receiptDate
            ? dayjs(result.data.receiptDate)
            : undefined,
        };
        form.setFieldsValue(newData);
        setWarehousingId(result.data.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const deleteWarehousing = async () => {
    if (!orderId) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteWarehousing } = new OrderService();
      const result = await DeleteWarehousing(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.warehousing.deleteWarehousingMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const editId = search.get("editMode");
  useEffect(() => {
    if (orderId) {
      setEditMode(!!editId);
    }
    return () => {
      setEditMode(false);
    };
  }, [editId, orderId]);

  const contextValue: IContext = {
    value: {
      unit,
      unitLoading,
      editMode,
      warehouse,
      warehouseLoading,
      addLoading,
      deleteLoading,
      warehousingId,
    },
    dispatch: { setEditMode },
    func: {
      getUnit,
      getWarehouse,
      add,
      update,
      deleteWarehousing,
      getWarehousing,
    },
    form: { form },
  };

  return (
    <WarehousingContext.Provider value={contextValue}>
      {children}
    </WarehousingContext.Provider>
  );
};

export const useWarehousing = () => useContext(WarehousingContext);
