import { FC, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import { SellingFormContainer } from "../style";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useSelling } from "../context";
import { B2, S2 } from "src/components/UiKit/Typography";
import TextArea from "antd/es/input/TextArea";
import { P3 } from "src/components/UiKit/Typography/P3";
import { IAddEditSell } from "src/services/Order/models/args.models";
import { inputNumberFormatter } from "src/helper/inputNumberFormatter";
import { omit } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
interface IFormValue extends IAddEditSell {
  amount: number;
}
export const SellingForm: FC = () => {
  const {
    value: {
      allTransports,
      allTransportsLoading,
      totalSelling,
      loading,
      sellingId,
      editMode,
      tableEditMode,
    },
    func: { getAllTransports, add, update },
    dispatch: { setTableEditMode },
    form: { form },
  } = useSelling()!;
  const navigate = useNavigate();
  const { orderId } = useParams();

  const { words } = useLanguage();
  const { Option } = Select;
  const values = Form.useWatch([], form);
  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    if (tableEditMode) {
      setTableEditMode(false);
      form?.resetFields();
    } else {
      navigate(ROUTES.orderManagement.concat(`/${orderId}`));
    }
  };
  const onClickReset = () => {
    form?.resetFields();
  };

  const amount = useMemo(() => {
    return +values?.weight * +values?.rate;
  }, [values]);
  useEffect(() => {
    if (amount) {
      form?.setFieldValue("amount", amount);
    }
  }, [amount, form]);

  const onFinish = (values: IFormValue) => {
    const newValue: IAddEditSell = {
      ...omit(values, "amount"),
      remarks: values.remarks || "",
    };
    if (editMode && sellingId) {
      update(newValue, sellingId);
    } else {
      add(newValue);
    }
  };

  useEffect(() => {
    getAllTransports();
  }, [getAllTransports]);
  return (
    <Form form={form} onFinish={onFinish} className="flex gap-[24px] flex-col">
      <SellingFormContainer>
        <Col span={24}>
          <S2 className="pt-[24px] pb-[16px]">
            {editMode
              ? words.orders.selling.editSellingInfo
              : words.orders.selling.sellingInfo}
          </S2>
        </Col>
        <Col span={24} className="w-full">
          <Row gutter={[24, 24]} className="py-[16px]">
            <Col span={8}>
              <FormItem
                name={"rate"}
                label={words.orders.selling.rate}
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  onPressEnter={form?.submit}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"weight"}
                label={words.orders.selling.weight}
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  onPressEnter={form?.submit}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                name={"transportId"}
                label={words.orders.selling.transport}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={words.global.select}
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                  loading={allTransportsLoading}
                >
                  {allTransports?.map((item, index) => (
                    <Option
                      key={index}
                      value={item.id}
                      label={item.companyName}
                    >
                      <Space>{item.companyName}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name={"amount"} label={words.orders.selling.amount}>
                <InputNumber
                  placeholder={words.global.insert}
                  onPressEnter={form?.submit}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  disabled
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>

            <Col span={16}>
              <FormItem name={"remarks"} label={words.orders.selling.remarks}>
                <TextArea
                  className="!h-[46px] !min-h-[46px] !max-h-[46px]"
                  placeholder={words.global.description}
                  onPressEnter={form?.submit}
                  autoSize={{ maxRows: 1, minRows: 1 }}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="flex gap-[16px] justify-end h-[104px] items-center buttonBorder">
            {editMode ? (
              <Button
                type="default"
                className="!min-w-[85px]"
                onClick={onClickCancel}
              >
                {words.global.cancel}
              </Button>
            ) : (
              <DPopconfirm
                description={words.global.descriptionPopConfirm}
                onConfirm={onClickReset}
              >
                <Button
                  type="default"
                  className="!min-w-[85px]"
                  disabled={isDisabledResetForm}
                >
                  {words.global.reset}
                </Button>
              </DPopconfirm>
            )}
            <Button
              className="!min-w-[85px]"
              type="primary"
              onClick={() => {
                form?.submit();
              }}
              loading={loading}
              disabled={isDisabledResetForm}
            >
              {editMode ? words.global.saveEdit : words.global.add}
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={18}>
              <S2 className="py-[8px]">{words.orders.selling.table}</S2>
            </Col>
            <Col span={6}>
              <div className="totalHolder">
                <Flex gap={16} align="center">
                  <B2 className="!text-[#008F87]">
                    {words.orders.selling.total}
                  </B2>
                  <Divider
                    type="vertical"
                    className="!border-[#CACACA] border-s-[2px] rounded h-[24px]"
                  />
                </Flex>
                <Flex gap={16} align="center">
                  <B2 className="text-[#044440]">
                    {totalSelling?.toLocaleString("fa-IR")}
                  </B2>
                  <P3 className="text-[#044440]">
                    {words.orders.selling.rial}
                  </P3>
                </Flex>
              </div>
            </Col>
          </Row>
        </Col>
      </SellingFormContainer>
    </Form>
  );
};
