import { ColumnsType } from "antd/es/table";
import { IGetAlllSell } from "src/services/Order/models/result.models";
import useLanguage from "src/store/language";

export const useViewColumns = () => {
  const { words } = useLanguage();

  const Columns: ColumnsType<IGetAlllSell> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.orders.selling.rate,
      dataIndex: "rate",
      key: "rate",
      align: "center",
      width: "268px",
      render: (text: number) => text.toLocaleString("en-US"),
    },
    {
      title: words.orders.selling.weight,
      dataIndex: "weight",
      key: "weight",
      align: "center",
      width: "268px",
      render: (text: number) => text.toLocaleString("en-US"),
    },
    {
      title: words.orders.selling.transport,
      dataIndex: "transportName",
      key: "transportName",
      align: "center",
      width: "134px",
    },
    {
      title: words.orders.selling.amount,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "134px",
      render: (text, record) => record.amount.toLocaleString("en-US"),
    },
  ];

  return [Columns];
};
