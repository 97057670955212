import { FC } from "react";
import { InspectionInfoDocs } from "src/modules/InspectionInfoDocs";
import { InspectionDocProvider } from "src/modules/InspectionInfoDocs/context";

export const InspectionInfoDocsPage: FC = () => {
  return (
    <InspectionDocProvider>
      <InspectionInfoDocs />
    </InspectionDocProvider>
  );
};
