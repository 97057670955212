import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

interface IProps extends React.HTMLAttributes<HTMLHeadingElement> {}
export const H5: FC<IProps & PropsWithChildren> = (props, { children }) => {
  return <Container {...props} />;
};

const Container = styled.h5`
  color: ${(props) => props.theme.colors.title};
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: unset;
`;
