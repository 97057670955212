import { FC, useCallback, useEffect, useState } from "react";
import { ViewViewAddOrderContainer } from "../style";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Col, Flex, Row, Skeleton } from "antd";
import { OrderService } from "src/services/Order/order.service";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { IGetOrderRegister } from "src/services/Order/models/result.models";

export const ViewAddOrder: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [orderInfo, setOrderInfo] = useState<IGetOrderRegister>();

  const fxReserve = (fxReserve: number) => {
    let fxReserveName = "";
    switch (fxReserve) {
      case 1:
        fxReserveName = words.orders.addOrder.barter;
        break;
      case 2:
        fxReserveName = words.orders.addOrder.cBI;
        break;
    }
    return fxReserveName;
  };
  const getOrderInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetOrderRegister } = new OrderService();
      const result = await GetOrderRegister(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setOrderInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    getOrderInfo();
  }, [getOrderInfo]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]"> {words.orders.addOrder.viewAddOrder}</div>
      <Row
        className="w-full bg-[#f2f9f9] py-[24px] px-[16px]"
        gutter={[16, 24]}
      >
        <Col span={6} className="!font-medium">
          {words.orders.addOrder.registrationNumber}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.addOrder.issueDate}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.addOrder.validityDate}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.addOrder.currencySupply}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma headerName={words.orders.addOrder.viewAddOrderHeader} />
      <Flex justify="center">
        <ViewViewAddOrderContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">
                {words.orders.addOrder.viewAddOrder}
              </div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={6} className="!font-medium">
                  {words.orders.addOrder.registrationNumber}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {orderInfo?.registerNo}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.addOrder.issueDate}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {dayjs(orderInfo?.registerDate).format("YYYY-MM-DD")}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.addOrder.validityDate}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {dayjs(orderInfo?.validatyDate).format("YYYY-MM-DD")}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.addOrder.currencySupply}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {orderInfo?.fxReserve
                    ? fxReserve(orderInfo?.fxReserve)
                    : undefined}
                </Col>
              </Row>
            </>
          )}
        </ViewViewAddOrderContainer>
      </Flex>
    </div>
  );
};
