import axios from "axios";
import { OrderUrl } from "./urls";
import { IDataModel, ITotalDataModel } from "../models";
import {
  IGetAllBankFs,
  IGetAllCustomFormalitys,
  IGetAllInspectionForms,
  IGetAlllOriginFinancial,
  IGetAlllSell,
  IGetAllPODFinancialOthers,
  IGetAllProforma,
  IGetArgiculture,
  IGetCustomDeclaration,
  IGetCustomDeclarationFile,
  IGetCustomFormality,
  IGetCustomFormalityFile,
  IGetInspecionForm,
  IGetInspecionFormFile,
  IGetInsuranceFile,
  IGetInsuranceFileDetail,
  IGetInsuranceForm,
  IGetOrderRegister,
  IGetOrigin,
  IGetOriginFile,
  IGetOriginFinancial,
  IGetPODFinancial,
  IGetPODFinancialOther,
  IGetPODFinancialResult,
  IGetProform,
  IGetProformMain,
  IGetRate,
  IGetShipment,
  IGetShipmentFile,
  IGetShipmentFileDetail,
  IGetVesselArrival,
  IGetVesselArrivalFile,
  IGetVip,
  IGetVipFile,
  IGetWarehousing,
} from "./models/result.models";
import {
  IAddCustomDeclaration,
  IAddOrderRegister,
  IAddOrigin,
  IAddProform,
  IAddVesselArrival,
  IAddVip,
  INewCustomDeclarationFile,
  IAddWarehousing,
  INewOriginFile,
  INewVesselArrivalFile,
  INewVipFile,
  IAddArgiculture,
  IAddOriginFinancial,
  IAddRate,
  IAddInsuranceForm,
  IAddInspecionForm,
  INewInspecionFormFile,
  IUpdateInspecionFormFile,
  IAddInsuranceFileDetail,
  INewInsuranceFile,
  IAddEditSell,
  IAddCustomFormality,
  INewCustomFormalityFile,
  IUpdateCustomFormalityFile,
  IAddBankF,
  IAddShipment,
  INewShipmentFile,
  IAddPODFinancial,
  IAddPODFinancialOther,
} from "./models/args.models";
import * as GuardPermissions from "./guardPath";
import { checkActionPermission } from "src/helper/checkPermissionFunc";

export class OrderService {
  ////////////proforma-service////////////////////////////////////////////////////////////////

  async GetProformMain(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetProformMainPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetProformMain>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetProformMain}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllProforma(queries?: string) {
    if (!checkActionPermission(GuardPermissions.GetAllProformPath)) {
      return undefined;
    }
    let endPoint: string = OrderUrl.GetAllProform;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IGetAllProforma[]>>(endPoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteProform(id: number) {
    if (!checkActionPermission(GuardPermissions.DeleteProformPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${id}${OrderUrl.DeleteProform}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddProform(data: IAddProform) {
    if (!checkActionPermission(GuardPermissions.AddProformPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<void>(OrderUrl.AddProform, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetProform(id: number) {
    if (!checkActionPermission(GuardPermissions.GetProformPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetProform>(
        OrderUrl.Order.concat(`${id}${OrderUrl.GetProform}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateProform(id: number, data: IAddProform) {
    if (!checkActionPermission(GuardPermissions.UpdateProformPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${id}${OrderUrl.UpdateProform}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////origin-service////////////////////////////////////////////////////////////////
  async AddOrigin(data: IAddOrigin, ProformId: number) {
    if (!checkActionPermission(GuardPermissions.AddOriginPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddOrigin}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewOriginFile(data: INewOriginFile, OriginId: number) {
    if (!checkActionPermission(GuardPermissions.NewOriginFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${OriginId}${OrderUrl.NewOriginFile}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetOrigin(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetOriginPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetOrigin>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetOrigin}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateOrigin(data: IAddOrigin, ProformId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateOriginPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.UpdateOrigin}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateOriginFile(data: INewOriginFile, OriginId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateOriginFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${OriginId}${OrderUrl.UpdateOriginFile}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteOrigin(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteOriginPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteOrigin}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteOriginFile(OriginId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteOriginFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${OriginId}${OrderUrl.DeleteOriginFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetOriginFile(OriginId: number | string) {
    if (!checkActionPermission(GuardPermissions.GetOriginFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetOriginFile[]>(
        OrderUrl.Order.concat(`${OriginId}${OrderUrl.GetOriginFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////vip-service////////////////////////////////////////////////////////////////
  async AddVip(data: IAddVip, ProformId: number) {
    if (!checkActionPermission(GuardPermissions.AddVipPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddVip}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewVipFile(data: INewVipFile, VipId: number) {
    if (!checkActionPermission(GuardPermissions.NewVipFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${VipId}${OrderUrl.NewVipFile}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetVipFile(VipId: number | string) {
    if (!checkActionPermission(GuardPermissions.GetVipFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetVipFile[]>(
        OrderUrl.Order.concat(`${VipId}${OrderUrl.GetVipFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetVip(ProformId: number | string) {
    if (!checkActionPermission(GuardPermissions.GetVipPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetVip>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetVip}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateVip(data: IAddVip, ProformId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateVipPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.UpdateVip}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateVipFile(data: INewVipFile, VipId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateVipFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${VipId}${OrderUrl.UpdateVipFile}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteVip(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteVipPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteVip}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteVipFile(VipId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteVipFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${VipId}${OrderUrl.DeleteVipFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////addOrder-service////////////////////////////////////////////////////////////////
  async AddOrderRegister(data: IAddOrderRegister, ProformId: number) {
    if (!checkActionPermission(GuardPermissions.AddOrderRegisterPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddOrderRegister}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetOrderRegister(ProformId: number | string) {
    if (!checkActionPermission(GuardPermissions.GetOrderRegisterPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetOrderRegister>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetOrderRegister}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateOrderRegister(data: IAddOrderRegister, ProformId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateOrderRegisterPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.UpdateOrderRegister}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteOrderRegister(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteOrderRegisterPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteOrderRegister}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////customDeclaration-service////////////////////////////////////////////////////////////////
  async AddCustomDeclaration(data: IAddCustomDeclaration, ProformId: number) {
    if (!checkActionPermission(GuardPermissions.AddCustomDeclarationPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddCustomDeclaration}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewCustomDeclarationFile(
    data: INewCustomDeclarationFile,
    CustomDeclarationId: number
  ) {
    if (!checkActionPermission(GuardPermissions.NewCustomDeclarationFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${CustomDeclarationId}${OrderUrl.NewCustomDeclarationFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetCustomDeclarationFile(CustomDeclarationId: number | string) {
    if (!checkActionPermission(GuardPermissions.GetCustomDeclarationFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetCustomDeclarationFile[]>(
        OrderUrl.Order.concat(
          `${CustomDeclarationId}${OrderUrl.GetCustomDeclarationFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetCustomDeclaration(ProformId: number | string) {
    if (!checkActionPermission(GuardPermissions.GetCustomDeclarationPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetCustomDeclaration>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetCustomDeclaration}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateCustomDeclaration(
    data: IAddCustomDeclaration,
    ProformId: number
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateCustomDeclarationPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(
          `${ProformId}${OrderUrl.UpdateCustomDeclaration}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateCustomDeclarationFile(
    data: INewCustomDeclarationFile,
    CustomDeclarationId: number
  ) {
    if (
      !checkActionPermission(GuardPermissions.UpdateCustomDeclarationFilePath)
    ) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${CustomDeclarationId}${OrderUrl.UpdateCustomDeclarationFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteCustomDeclaration(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteCustomDeclarationPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteCustomDeclaration}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteCustomDeclarationFile(CustomDeclarationId: number) {
    if (
      !checkActionPermission(GuardPermissions.DeleteCustomDeclarationFilePath)
    ) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${CustomDeclarationId}${OrderUrl.DeleteCustomDeclarationFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////vesselArrival-service////////////////////////////////////////////////////////////////
  async AddVesselArrival(ProformId: number, data: IAddVesselArrival) {
    if (!checkActionPermission(GuardPermissions.AddVesselArrivalPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddVesselArrival}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewVesselArrivalFile(
    VesselArrivalId: number,
    data: INewVesselArrivalFile
  ) {
    if (!checkActionPermission(GuardPermissions.NewVesselArrivalFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${VesselArrivalId}${OrderUrl.NewVesselArrivalFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetVesselArrivalFile(VesselArrivalId: number) {
    if (!checkActionPermission(GuardPermissions.GetVesselArrivalFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetVesselArrivalFile[]>(
        OrderUrl.Order.concat(
          `${VesselArrivalId}${OrderUrl.GetVesselArrivalFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetVesselArrival(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetVesselArrivalPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetVesselArrival>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetVesselArrival}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateVesselArrival(proformId: number, data: IAddVesselArrival) {
    if (!checkActionPermission(GuardPermissions.UpdateVesselArrivalPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${proformId}${OrderUrl.UpdateVesselArrival}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateVesselArrivalFile(
    VesselArrivalId: number,
    data: INewVesselArrivalFile
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateVesselArrivalFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${VesselArrivalId}${OrderUrl.UpdateVesselArrivalFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteVesselArrival(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteVesselArrivalPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteVesselArrival}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteVesselArrivalFile(VesselArrivalId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteVesselArrivalFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${VesselArrivalId}${OrderUrl.DeleteVesselArrivalFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////warehousing-service////////////////////////////////////////////////////////////////
  async AddWarehousing(ProformId: number, data: IAddWarehousing) {
    if (!checkActionPermission(GuardPermissions.AddWarehousingPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddWarehousing}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetWarehousing(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetWarehousingPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetWarehousing>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetWarehousing}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateWarehousing(proformId: number, data: IAddWarehousing) {
    if (!checkActionPermission(GuardPermissions.UpdateWarehousingPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${proformId}${OrderUrl.UpdateWarehousing}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteWarehousing(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteWarehousingPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteWarehousing}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////argiculture-service////////////////////////////////////////////////////////////////
  async AddArgiculture(ProformId: number, data: IAddArgiculture) {
    if (!checkActionPermission(GuardPermissions.AddArgiculturePath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddArgiculture}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetArgiculture(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetArgiculturePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetArgiculture>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetArgiculture}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateArgiculture(proformId: number, data: IAddArgiculture) {
    if (!checkActionPermission(GuardPermissions.UpdateArgiculturePath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${proformId}${OrderUrl.UpdateArgiculture}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteArgiculture(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteArgiculturePath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteArgiculture}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////originFinancial-service////////////////////////////////////////////////////////////////
  async AddOriginFinancial(ProformId: number, data: IAddOriginFinancial) {
    if (!checkActionPermission(GuardPermissions.AddOriginFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddOriginFinancial}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetOriginFinancial(OriginFinancialId: number) {
    if (!checkActionPermission(GuardPermissions.GetOriginFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetOriginFinancial>(
        OrderUrl.Order.concat(
          `${OriginFinancialId}${OrderUrl.GetOriginFinancial}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAlllOriginFinancial(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetAlllOriginFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetAlllOriginFinancial>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetAlllOriginFinancial}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateOriginFinancial(
    OriginFinancialId: number,
    data: IAddOriginFinancial
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateOriginFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(
          `${OriginFinancialId}${OrderUrl.UpdateOriginFinancial}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteOriginFinancial(OriginFinancialId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteOriginFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(
          `${OriginFinancialId}${OrderUrl.DeleteOriginFinancial}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////rates-service////////////////////////////////////////////////////////////////
  async AddRate(ProformId: number, data: IAddRate) {
    if (!checkActionPermission(GuardPermissions.AddRatePath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddRate}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetRate(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetRatePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetRate>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetRate}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateRate(proformId: number, data: IAddRate) {
    if (!checkActionPermission(GuardPermissions.UpdateRatePath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${proformId}${OrderUrl.UpdateRate}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteRate(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteRatePath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteRate}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////insurance-service////////////////////////////////////////////////////////////////
  async AddInsuranceForm(ProformId: number, data: IAddInsuranceForm) {
    if (!checkActionPermission(GuardPermissions.AddInsuranceFormPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddInsuranceForm}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetInsuranceForm(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetInsuranceFormPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetInsuranceForm>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetInsuranceForm}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInsuranceForm(proformId: number, data: IAddInsuranceForm) {
    if (!checkActionPermission(GuardPermissions.UpdateInsuranceFormPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${proformId}${OrderUrl.UpdateInsuranceForm}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInsuranceForm(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInsuranceFormPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteInsuranceForm}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddInsuranceFileDetail(
    InsuranceFormId: number,
    data: IAddInsuranceFileDetail
  ) {
    if (!checkActionPermission(GuardPermissions.AddInsuranceFileDetailPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(
          `${InsuranceFormId}${OrderUrl.AddInsuranceFileDetail}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewInsuranceFile(InsuranceFileId: number, data: INewInsuranceFile) {
    if (!checkActionPermission(GuardPermissions.NewInsuranceFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${InsuranceFileId}${OrderUrl.NewInsuranceFile}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetInsuranceFile(InsuranceFileId: number) {
    if (!checkActionPermission(GuardPermissions.GetInsuranceFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetInsuranceFile[]>(
        OrderUrl.Order.concat(`${InsuranceFileId}${OrderUrl.GetInsuranceFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetInsuranceFileDetail(InsuranceFileId: number) {
    if (!checkActionPermission(GuardPermissions.GetInsuranceFileDetailPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetInsuranceFileDetail>(
        OrderUrl.Order.concat(
          `${InsuranceFileId}${OrderUrl.GetInsuranceFileDetail}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllInsuranceFileDetails(InsuranceFormId: number) {
    if (
      !checkActionPermission(GuardPermissions.GetAllInsuranceFileDetailsPath)
    ) {
      return undefined;
    }
    try {
      const response = await axios.get<IDataModel<IGetInsuranceFileDetail[]>>(
        OrderUrl.Order.concat(
          `${InsuranceFormId}${OrderUrl.GetAllInsuranceFileDetails}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInsuranceFileDetail(
    InsuranceFileId: number,
    data: IAddInsuranceFileDetail
  ) {
    if (
      !checkActionPermission(GuardPermissions.UpdateInsuranceFileDetailPath)
    ) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(
          `${InsuranceFileId}${OrderUrl.UpdateInsuranceFileDetail}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInsuranceFile(InsuranceFileId: number, data: INewInsuranceFile) {
    if (!checkActionPermission(GuardPermissions.UpdateInsuranceFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${InsuranceFileId}${OrderUrl.UpdateInsuranceFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInsuranceFileDetail(InsuranceFileId: number) {
    if (
      !checkActionPermission(GuardPermissions.DeleteInsuranceFileDetailPath)
    ) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(
          `${InsuranceFileId}${OrderUrl.DeleteInsuranceFileDetail}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInsuranceFile(InsuranceFileId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInsuranceFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${InsuranceFileId}${OrderUrl.DeleteInsuranceFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////inspection-service////////////////////////////////////////////////////////////////
  async AddInspecionForm(ProformId: number, data: IAddInspecionForm) {
    if (!checkActionPermission(GuardPermissions.AddInspecionFormPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddInspecionForm}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewInspecionFormFile(
    InspecionFormId: number,
    data: INewInspecionFormFile
  ) {
    if (!checkActionPermission(GuardPermissions.NewInspecionFormFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${InspecionFormId}${OrderUrl.NewInspecionFormFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetInspecionFormFile(InspecionFormId: number) {
    if (!checkActionPermission(GuardPermissions.GetInspecionFormFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetInspecionFormFile[]>(
        OrderUrl.Order.concat(
          `${InspecionFormId}${OrderUrl.GetInspecionFormFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllInspectionForms(InspecionFormId: number) {
    if (!checkActionPermission(GuardPermissions.GetAllInspectionFormsPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IDataModel<IGetAllInspectionForms[]>>(
        OrderUrl.Order.concat(
          `${InspecionFormId}${OrderUrl.GetAllInspectionForms}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetInspecionForm(InspecionFormId: number) {
    if (!checkActionPermission(GuardPermissions.GetInspecionFormPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetInspecionForm>(
        OrderUrl.Order.concat(`${InspecionFormId}${OrderUrl.GetInspecionForm}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInspecionForm(InspectionFormId: number, data: IAddInspecionForm) {
    if (!checkActionPermission(GuardPermissions.UpdateInspecionFormPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(
          `${InspectionFormId}${OrderUrl.UpdateInspecionForm}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInspecionFormFile(
    InspecionFormId: number,
    data: IUpdateInspecionFormFile
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateInspecionFormFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${InspecionFormId}${OrderUrl.UpdateInspecionFormFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInspecionForm(InspectionFormId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInspecionFormPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(
          `${InspectionFormId}${OrderUrl.DeleteInspecionForm}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInspecionFormFile(InspecionFormId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInspecionFormFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${InspecionFormId}${OrderUrl.DeleteInspecionFormFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////Sell-service////////////////////////////////////////////////////////////////
  async AddSell(ProformId: number, data: IAddEditSell) {
    if (!checkActionPermission(GuardPermissions.AddSellPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddSell}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAlllSell(ProformId: number, queries?: string) {
    if (!checkActionPermission(GuardPermissions.GetAlllSellPath)) {
      return undefined;
    }
    let endPoint: string = OrderUrl.GetAlllSell;
    if (queries) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        ITotalDataModel<IDataModel<IGetAlllSell[]>>
      >(OrderUrl.Order.concat(`${ProformId}${endPoint}`));
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateSell(sellId: number, data: IAddEditSell) {
    if (!checkActionPermission(GuardPermissions.UpdateSellPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<number>(
        OrderUrl.Order.concat(`${sellId}${OrderUrl.UpdateSell}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteSell(SellId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteSellPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${SellId}${OrderUrl.DeleteSell}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////customFormality-service////////////////////////////////////////////////////////////////
  async AddCustomFormality(ProformId: number, data: IAddCustomFormality) {
    if (!checkActionPermission(GuardPermissions.AddCustomFormalityPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddCustomFormality}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewCustomFormalityFile(
    CustomFormalityId: number,
    data: INewCustomFormalityFile
  ) {
    if (!checkActionPermission(GuardPermissions.NewCustomFormalityFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${CustomFormalityId}${OrderUrl.NewCustomFormalityFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetCustomFormalityFile(CustomFormalityId: number) {
    if (!checkActionPermission(GuardPermissions.GetCustomFormalityFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetCustomFormalityFile[]>(
        OrderUrl.Order.concat(
          `${CustomFormalityId}${OrderUrl.GetCustomFormalityFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllCustomFormalitys(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetAllCustomFormalitysPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IDataModel<IGetAllCustomFormalitys[]>>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetAllCustomFormalitys}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetCustomFormality(CustomFormalityId: number) {
    if (!checkActionPermission(GuardPermissions.GetCustomFormalityPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetCustomFormality>(
        OrderUrl.Order.concat(
          `${CustomFormalityId}${OrderUrl.GetCustomFormality}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateCustomFormality(
    CustomFormalityId: number,
    data: IAddCustomFormality
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateCustomFormalityPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(
          `${CustomFormalityId}${OrderUrl.UpdateCustomFormality}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateCustomFormalityFile(
    CustomFormalityId: number,
    data: IUpdateCustomFormalityFile
  ) {
    if (
      !checkActionPermission(GuardPermissions.UpdateCustomFormalityFilePath)
    ) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${CustomFormalityId}${OrderUrl.UpdateCustomFormalityFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteCustomFormality(CustomFormalityId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteCustomFormalityPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(
          `${CustomFormalityId}${OrderUrl.DeleteCustomFormality}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteCustomFormalityFile(CustomFormalityId: number) {
    if (
      !checkActionPermission(GuardPermissions.DeleteCustomFormalityFilePath)
    ) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${CustomFormalityId}${OrderUrl.DeleteCustomFormalityFile}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////Bank-service////////////////////////////////////////////////////////////////
  async AddBankF(ProformId: number, data: IAddBankF) {
    if (!checkActionPermission(GuardPermissions.AddBankFPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddBankF}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetBankF(BankFId: number) {
    if (!checkActionPermission(GuardPermissions.GetBankFPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetAllBankFs>(
        OrderUrl.Order.concat(`${BankFId}${OrderUrl.GetBankF}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllBankFs(ProformId: number, queries?: string) {
    if (!checkActionPermission(GuardPermissions.GetAllBankFsPath)) {
      return undefined;
    }
    let endPoint: string = OrderUrl.GetAllBankFs;
    if (queries) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IGetAllBankFs[]>>(
        OrderUrl.Order.concat(`${ProformId}${endPoint}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateBankF(BankFId: number, data: IAddBankF) {
    if (!checkActionPermission(GuardPermissions.UpdateBankFPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${BankFId}${OrderUrl.UpdateBankF}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteBankF(BankFId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteBankFPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${BankFId}${OrderUrl.DeleteBankF}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////shipment-service////////////////////////////////////////////////////////////////
  async AddShipment(ProformId: number, data: IAddShipment) {
    if (!checkActionPermission(GuardPermissions.AddShipmentPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddShipment}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetShipment(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetShipmentPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetShipment>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetShipment}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateShipment(proformId: number, data: IAddShipment) {
    if (!checkActionPermission(GuardPermissions.UpdateShipmentPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${proformId}${OrderUrl.UpdateShipment}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteShipment(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteShipmentPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteShipment}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddShipmentFileDetail(ShipmentId: number, shippingDocId: number) {
    if (!checkActionPermission(GuardPermissions.AddShipmentFileDetailPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ShipmentId}${OrderUrl.AddShipmentFileDetail}`),
        { shippingDocId }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewShipmentFile(ShipmentFileId: number, data: INewShipmentFile) {
    if (!checkActionPermission(GuardPermissions.NewShipmentFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${ShipmentFileId}${OrderUrl.NewShipmentFile}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetShipmentFile(ShipmentFileId: number) {
    if (!checkActionPermission(GuardPermissions.GetShipmentFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetShipmentFile[]>(
        OrderUrl.Order.concat(`${ShipmentFileId}${OrderUrl.GetShipmentFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetShipmentFileDetail(ShipmentFileId: number) {
    if (!checkActionPermission(GuardPermissions.GetShipmentFileDetailPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetShipmentFileDetail>(
        OrderUrl.Order.concat(
          `${ShipmentFileId}${OrderUrl.GetShipmentFileDetail}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllShipmentFileDetails(ShipmentId: number, queries?: string) {
    if (
      !checkActionPermission(GuardPermissions.GetAllShipmentFileDetailsPath)
    ) {
      return undefined;
    }
    let endPoint: string = OrderUrl.GetAllShipmentFileDetails;
    if (queries) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IGetShipmentFileDetail[]>>(
        OrderUrl.Order.concat(`${ShipmentId}${endPoint}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateShipmentFileDetail(
    ShipmentFileId: number,
    shippingDocId: number
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateShipmentFileDetailPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(
          `${ShipmentFileId}${OrderUrl.UpdateShipmentFileDetail}`
        ),
        { shippingDocId }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateShipmentFile(ShipmentFileId: number, data: INewShipmentFile) {
    if (!checkActionPermission(GuardPermissions.UpdateShipmentFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(
          `${ShipmentFileId}${OrderUrl.UpdateShipmentFile}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteShipmentFileDetail(ShipmentFileId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteShipmentFileDetailPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(
          `${ShipmentFileId}${OrderUrl.DeleteShipmentFileDetail}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteShipmentFile(ShipmentFileId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteShipmentFilePath)) {
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${ShipmentFileId}${OrderUrl.DeleteShipmentFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////POD-service////////////////////////////////////////////////////////////////
  async AddPODFinancial(ProformId: number, data: IAddPODFinancial) {
    if (!checkActionPermission(GuardPermissions.AddPODFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddPODFinancial}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetPODFinancial(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetPODFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetPODFinancial>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetPODFinancial}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetPODFinancialResult(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetPODFinancialResultPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetPODFinancialResult>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetPODFinancialResult}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdatePODFinancial(ProformId: number, data: IAddPODFinancial) {
    if (!checkActionPermission(GuardPermissions.UpdatePODFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.UpdatePODFinancial}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeletePODFinancial(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.DeletePODFinancialPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeletePODFinancial}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddPODFinancialOther(ProformId: number, data: IAddPODFinancialOther) {
    if (!checkActionPermission(GuardPermissions.AddPODFinancialOtherPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddPODFinancialOther}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllPODFinancialOthers(ProformId: number) {
    if (!checkActionPermission(GuardPermissions.GetAllPODFinancialOthersPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetAllPODFinancialOthers>(
        OrderUrl.Order.concat(
          `${ProformId}${OrderUrl.GetAllPODFinancialOthers}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetPODFinancialOther(PODFinancialOtherId: number) {
    if (!checkActionPermission(GuardPermissions.GetPODFinancialOtherPath)) {
      return undefined;
    }
    try {
      const response = await axios.get<IGetPODFinancialOther>(
        OrderUrl.Order.concat(
          `${PODFinancialOtherId}${OrderUrl.GetPODFinancialOther}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdatePODFinancialOther(
    PODFinancialOtherId: number,
    data: IAddPODFinancialOther
  ) {
    if (!checkActionPermission(GuardPermissions.UpdatePODFinancialOtherPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(
          `${PODFinancialOtherId}${OrderUrl.UpdatePODFinancialOther}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeletePODFinancialOther(PODFinancialOtherId: number) {
    if (!checkActionPermission(GuardPermissions.DeletePODFinancialOtherPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(
          `${PODFinancialOtherId}${OrderUrl.DeletePODFinancialOther}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
