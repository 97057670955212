import { Button, Col, Divider, Flex, Row, Skeleton, Spin } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { ViewVesselArriveContainer } from "../style";
import { OrderService } from "src/services/Order/order.service";
import {
  IGetVesselArrival,
  IGetVesselArrivalFile,
} from "src/services/Order/models/result.models";
import { AxiosRequestConfig } from "axios";
import { DocumentService } from "src/services/Document/document.service";
import downloadZipFile from "src/helper/downloadZipFile";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment-jalaali";

export const ViewVesselArrive: FC = () => {
  const { words, isRtl } = useLanguage();
  const { orderId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [vesselArriveInfo, setVesselArriveInfo] = useState<IGetVesselArrival>();
  const [getFileLoading, setGetFileLoading] = useState<boolean>(false);

  const getVesselArriveInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetVesselArrival } = new OrderService();
      const result = await GetVesselArrival(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setVesselArriveInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const GetFile = async (file: IGetVesselArrivalFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    setGetFileLoading(true);
    try {
      const { GetVesselArrivalFile } = new OrderService();
      const result = await GetVesselArrivalFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
      setGetFileLoading(false);
    }
  };

  useEffect(() => {
    getVesselArriveInfo();
  }, [getVesselArriveInfo]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">
        {words.orders.vesselArrive.vesselArriveViewInfo}
      </div>
      <Row
        className="w-full bg-[#f2f9f9] py-[24px] px-[16px]"
        gutter={[16, 24]}
      >
        <Col span={6} className="!font-medium">
          {words.orders.vesselArrive.eta}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.vesselArrive.arrivalDate}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.vesselArrive.berthingDate}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.vesselArrive.dischargeOperation}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.vesselArrive.deliveryOrder}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Divider dashed className="!border-[#BFE2E0]" />
        <div className="flex gap-[16px]">
          <div className="text-[14px] w-[150px]">
            {words.orders.vesselArrive.vesselArriveDocument}
          </div>
          <Skeleton.Image className="!w-[66px] !h-[66px]" />
        </div>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma
        headerName={words.orders.vesselArrive.viewVesselArriveInfo}
      />
      <Flex justify="center">
        <ViewVesselArriveContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">
                {words.orders.vesselArrive.vesselArriveViewInfo}
              </div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={6} className="!font-medium">
                  {words.orders.vesselArrive.eta}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {vesselArriveInfo?.eta
                    ? moment(vesselArriveInfo?.eta as string).format(
                        "jYYYY-jMM-jDD"
                      )
                    : "---"}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.vesselArrive.arrivalDate}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {vesselArriveInfo?.arrivalDate
                    ? moment(vesselArriveInfo?.arrivalDate as string).format(
                        "jYYYY-jMM-jDD"
                      )
                    : "---"}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.vesselArrive.berthingDate}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {vesselArriveInfo?.berthingDate
                    ? moment(vesselArriveInfo?.berthingDate as string).format(
                        "jYYYY-jMM-jDD"
                      )
                    : "---"}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.vesselArrive.dischargeOperation}
                </Col>
                <Col
                  span={6}
                  className="!font-normal text-[#4B4B4B]"
                  dir={isRtl ? "rtl" : "ltr"}
                >
                  <Flex gap={8}>
                    <span>
                      {vesselArriveInfo?.dischargeStartDate
                        ? moment(
                            vesselArriveInfo?.dischargeStartDate as string
                          ).format("jYYYY-jMM-jDD")
                        : "---"}
                    </span>
                    {vesselArriveInfo?.dischargeEndDate ||
                    vesselArriveInfo?.dischargeStartDate ? (
                      <span>{words.global.until}</span>
                    ) : (
                      ""
                    )}
                    <span>
                      {vesselArriveInfo?.dischargeEndDate
                        ? moment(
                            vesselArriveInfo?.dischargeEndDate as string
                          ).format("jYYYY-jMM-jDD")
                        : "---"}
                    </span>
                  </Flex>
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.vesselArrive.deliveryOrder}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {vesselArriveInfo?.deliveryDate
                    ? moment(vesselArriveInfo?.deliveryDate as string).format(
                        "jYYYY-jMM-jDD"
                      )
                    : "---"}
                </Col>
                <Divider dashed className="!border-[#BFE2E0]" />
                <Col span={24}>
                  <div className="flex gap-[16px]">
                    <div className="text-[14px] w-[150px]">
                      {words.orders.vesselArrive.vesselArriveDocument}
                    </div>

                    <div className="flex gap-[16px] ">
                      <Button
                        className={
                          vesselArriveInfo?.hasFile ? "" : "cursor-default"
                        }
                        onClick={
                          vesselArriveInfo?.hasFile
                            ? () => downloadAttachFile(vesselArriveInfo?.id)
                            : () => {}
                        }
                      >
                        {(vesselArriveInfo?.hasFile && progress > 0) ||
                        getFileLoading ? (
                          <div className="relative w-[66px] h-[66px] flex justify-center items-center">
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="large"
                            />
                            <span className="absolute top-[38%] left-[40%] ">
                              {progress}
                            </span>
                          </div>
                        ) : (
                          <img
                            src={
                              vesselArriveInfo?.hasFile
                                ? "/assets/images/fileUploaded.svg"
                                : "/assets/images/fileNotUpload.svg"
                            }
                            alt="fileUploaded"
                          />
                        )}
                      </Button>
                      <div className="flex flex-col gap-[6px] justify-center">
                        <div className="text-[14px]">
                          {vesselArriveInfo?.hasFile
                            ? words.orders.origin.fileUploaded
                            : words.orders.origin.fileNotUploaded}
                        </div>
                        <div className="text-[10px]">
                          {vesselArriveInfo?.hasFile
                            ? words.orders.origin.dlFile
                            : words.orders.origin.cantDlFile}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ViewVesselArriveContainer>
      </Flex>
    </div>
  );
};
