import { FC } from "react";
import { VesselArrive } from "src/modules/VesselArrive";
import { VesselArriveProvider } from "src/modules/VesselArrive/context";

export const VesselArrivePage: FC = () => {
  return (
    <VesselArriveProvider>
      <VesselArrive />
    </VesselArriveProvider>
  );
};
