import { FC } from "react";
import { OriginFinancial } from "src/modules/OriginFinancial";
import { OriginFinancialProvider } from "src/modules/OriginFinancial/context";

export const OriginFinancialPage: FC = () => {
  return (
    <OriginFinancialProvider>
      <OriginFinancial />
    </OriginFinancialProvider>
  );
};
