import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Flex,
  Form,
  GetProp,
  Row,
  Spin,
  UploadProps,
  message,
} from "antd";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { VipContainer } from "./style";
import { FormItem } from "src/components/UiKit/FormItem";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { ROUTES } from "src/models/enums/routes";
import { Upload, UploadFile } from "antd/lib";
import { UploadChangeParam } from "antd/es/upload";
import { dummyRequest } from "src/helper/dummyRequest";
import TextArea from "antd/es/input/TextArea";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import { UseVip } from "./context";
import { IAddVip } from "src/services/Order/models/args.models";
import { JalaliLocaleListener } from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";
import { S2 } from "src/components/UiKit/Typography";
import { LoadingOutlined } from "@ant-design/icons";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const Vip: FC = () => {
  const {
    value: { editMode, file, hasFile, loading, progress },
    func: { update, add, getVipInfo, deleteVip },
    dispatch: { setFile, setEditMode },
    form: { form },
  } = UseVip()!;
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const values = Form.useWatch([], form);

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setFile(info.file);
    }
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
      setFile(undefined);
    }

    return isJpgOrPng;
  };
  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };
  const onClickReset = () => {
    form?.resetFields();
    setFile(undefined);
  };

  const onFinish = (values: IAddVip) => {
    if (editMode) {
      update(values);
    } else {
      add(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getVipInfo();
  }, [getVipInfo, isEdited]);

  return (
    <div>
      <HeaderProforma
        headerName={
          editMode ? words.orders.vip.editVipInfo : words.orders.vip.VipInfo
        }
      />
      <Flex justify="center">
        <VipContainer>
          <Form form={form} onFinish={onFinish}>
            <Col span={24}>
              <S2 className="py-[16px]">
                {editMode
                  ? words.orders.editInfo
                  : words.orders.vip.enterVipInfo}
              </S2>
            </Col>
            <Col span={24} className="py-[16px]">
              <Row gutter={24}>
                <Col span={8}>
                  <ConfigProvider locale={fa_IR}>
                    <JalaliLocaleListener />
                    <FormItem
                      name={"date"}
                      label={words.orders.vip.issueDate}
                      rules={[{ required: true }]}
                    >
                      <DatePicker placeholder={words.global.insert} />
                    </FormItem>
                  </ConfigProvider>
                </Col>
                <Col span={16}>
                  <div className="uploadHolder">
                    <div className="p-x[12px] flex flex-col justify-center">
                      <div className="text-[14px]">
                        {words.global.fileUpload}
                      </div>
                      <div className="text-[10px]">
                        {words.global.fileUploadInfo}
                      </div>
                    </div>
                    <div
                      className={
                        file || hasFile ? "w-[66px] h-[66px]" : `fileHolder`
                      }
                    >
                      <Upload
                        name="file"
                        onChange={handleChange}
                        customRequest={dummyRequest}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="relative w-[66px] h-[66px] flex justify-center items-center">
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="large"
                              className="flex justify-center items-center"
                            />
                            <span className="absolute top-[38%] left-[40%] ">
                              {progress}
                            </span>
                          </div>
                        ) : (
                          <Button
                            type={file || hasFile ? "text" : "default"}
                            icon={
                              file || hasFile ? (
                                <img
                                  src={"/assets/images/fileUploaded.svg"}
                                  alt="fileUploaded"
                                />
                              ) : (
                                <span className="material-icons flex justify-center items-center text-[20px]">
                                  attach_file
                                </span>
                              )
                            }
                            className="text-[10px]"
                          >
                            {file || hasFile ? "" : words.global.add}
                          </Button>
                        )}
                      </Upload>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <FormItem
                    name={"remarks"}
                    label={words.orders.vip.description}
                  >
                    <TextArea
                      className="!min-h-[90px]"
                      placeholder={words.global.description}
                      // onKeyDown={() => setPressKeyboard(true)}
                      onPressEnter={form?.submit}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider dashed className="!border-[#BFE2E0]" />
              <div
                className={`flex py-[24px]  ${
                  editMode ? "justify-between" : "justify-end"
                }`}
              >
                {editMode ? (
                  <div className="!min-w-[85px]">
                    <DeletePopconfirm
                      onConfirm={() => {
                        deleteVip();
                      }}
                      endPointName={words.orders.vip.deleteVipInfo}
                      className="!min-w-[85px]"
                    >
                      <Button danger className="!min-w[85px]">
                        {words.global.delete}
                      </Button>
                    </DeletePopconfirm>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex gap-[16px] justify-end borderTop">
                  {editMode ? (
                    <div className="!min-w-[85px] h-[100%]">
                      <Button type="default" onClick={onClickCancel}>
                        {words.global.cancel}
                      </Button>
                    </div>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] h-[100%]">
                        <Button
                          type="default"
                          disabled={!file && isDisabledResetForm}
                        >
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  )}
                  <Button
                    className="!min-w[85px]"
                    type="primary"
                    onClick={() => {
                      form?.submit();
                    }}
                    loading={loading}
                    disabled={isDisabledResetForm}
                  >
                    {editMode ? words.global.saveEdit : words.global.add}
                  </Button>
                </div>
              </div>
            </Col>
          </Form>
        </VipContainer>
      </Flex>
    </div>
  );
};
