import { FC } from "react";
import { Agriculture } from "src/modules/Agriculture";
import { AgricultureProvider } from "src/modules/Agriculture/context";

export const AgriculturePage: FC = () => {
  return (
    <AgricultureProvider>
      <Agriculture />
    </AgricultureProvider>
  );
};
