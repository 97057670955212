import { Col, Row } from "antd";
import styled from "styled-components";

export const ShipmentContainer = styled(Col)`
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .ant-tabs {
    width: 100%;
  }
  .ant-tabs-tab-active {
    div {
      div {
        color: #006b65 !important;
      }
    }
  }
  .wrapper {
    display: flex;
    width: 100% !important;
    .ant-select-selector {
      border-radius: 0px 12px 12px 0px !important;
      border-left: 1px solid var(--color-Light-Mode-Border-5, #cacaca);
    }
    .ant-input-number {
      border-radius: 12px 0px 0px 12px !important;
    }
  }
`;

export const AddShipmentFormContainer = styled(Row)`
  .ant-radio-group {
    label {
      padding: unset !important;
      margin-inline-end: 32px;
    }
  }
  /* .ant-form-item-label {
    label {
      width: 100px !important;
    }
  } */
`;
export const UploadFileFormContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .uploadHolder {
    display: flex;
    width: 100%;
    height: 82px;
    padding: 8px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px dashed var(--color-Stroke-footer-buttons, #c9e9e6);
    .ant-form-item {
      margin-bottom: unset !important;
    }
  }
  .fileHolder {
    display: flex;
    width: 66px;
    height: 66px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px dashed var(--color-Primary-Primary-700, #006b65);
    .ant-form-item {
      width: 100% !important;
      height: 100% !important;
      margin-bottom: unset !important;
      .ant-row {
        width: 100% !important;
        height: 100% !important;
        .ant-col {
          width: 100% !important;
          height: 100% !important;

          .ant-form-item-control-input {
            width: 100% !important;
            height: 100% !important;
          }
          .ant-form-item-explain {
            width: 200px;
            position: relative;
            top: 13px;
            right: -20px;
          }
          .ant-form-item-control-input-content {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
  .ant-upload-rtl {
    width: 100% !important;
    height: 100% !important;
  }
  .ant-upload {
    width: 100% !important;
    height: 100% !important;
  }
  .ant-btn-default {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: unset !important;
  }
  .ant-btn-text {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: unset !important;
  }

  .ant-btn-icon {
    margin: unset !important;
  }
  .ant-upload-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
  .ant-btn-default:hover {
    border: unset !important;
  }
  .ant-form-item-explain-error {
    width: 200px;
  }
  .uploadContainer {
    .ant-btn {
      padding: unset !important;
    }
  }
`;
export const UploadFileTableContainer = styled(Row)`
  .tableHolder {
    display: flex;
    padding: 12px 12px 24px 12px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    background: var(--color-Base-Color-White, #fff);
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
`;

export const ViewShipmentContainer = styled(Col)`
  padding: 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .tableHolder {
    display: flex;
    padding: 12px;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .infoHolder {
    border-radius: 8px;
    background: var(--color-Light-Mode-BG-Background-1, #f2f9f9);
    padding: 24px 16px;
  }
`;
