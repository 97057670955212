export enum ROUTES {
  home = "/",
  login = "/login",
  design = "/design-system",
  suppliers = "/suppliers",
  basicInformation = "/basic-information",
  editSupplier = "/edit-supplier",
  viewSuppliers = "/view-suppliers",
  inspectionCompanies = "/inspection-companies",
  viewInspectionCompanies = "/view-inspection-companies",
  editInspectionCompanies = "/edit-inspection-companies",
  insuranceCompanies = "/insurance-companies",
  viewInsuranceCompanies = "/view-insurance-companies",
  editInsuranceCompanies = "/edit-insurance-companies",
  transportCompanies = "/transport-companies",
  viewTransportCompanies = "/view-transport-companies",
  editTransportCompany = "/edit-transport-company",
  operatingBanks = "/operating-banks",
  viewOperatingBank = "/view-operating-banks",
  editOperatingBank = "/edit-operating-banks",
  clearanceWorkers = "/clearance-workers",
  viewClearanceWorkers = "/view-clearance-workers",
  editClearanceWorkers = "/edit-clearance-workers",
  averagePrice = "/average-price",
  ports = "/ports",
  InspectionInfoDocs = "/inspection-info",
  countries = "/countries",
  warehouses = "/warehouses",
  viewWarehouses = "/view-warehouses",
  editWarehouses = "/edit-warehouses",
  products = "/products",
  unitsOfMeasurement = "/units-of-measurement",
  currencies = "/currencies",
  vessels = "/vessels",
  settings = "/settings",
  CreateRole = "/settings/create-role",
  AssignActionToRole = "/settings/assign-action-to-role",
  CreateUser = "/settings/create-user",
  AssignRoleToUser = "/settings/assign-role-to-user",
  orders = "/orders",
  orderManagement = "/order-management",
  ViewOrder = "/view-order",
  origin = "/origin",
  viewOrigin = "/view-origin",
  vip = "/vip",
  viewVip = "/view-vip",
  addOrder = "/add-order",
  viewAddOrder = "/view-add-order",
  vesselArrive = "/vessel-arrive",
  viewVesselArrive = "/view-vessel-arrive",
  warehousing = "/warehousing",
  viewWarehousing = "/view-warehousing",
  customDeclaration = "/custom-declaration",
  viewCustomDeclaration = "/view-custom-declaration",
  Agriculture = "/agriculture",
  viewAgriculture = "/view-agriculture",
  originFinancial = "/origin-financial",
  viewOriginFinancial = "/view-origin-financial",
  rates = "/rates",
  viewRates = "/view-rates",
  insurance = "/insurance",
  viewInsurance = "/view-insurance",
  inspectionDocument = "/inspection-document",
  viewInspection = "/view-inspection",
  selling = "/selling",
  sellingView = "/view-selling",
  customsFormalities = "/custom-formalities",
  viewCustomsFormalities = "/view-customs-formalities",
  bankingOperations = "/banking-operations",
  viewBankingOperations = "/view-banking-operations",
  shipment = "/shipment",
  viewShipment = "/view-shipment",
  podFinancial = "/pod-financial",
  viewPOD = "/view-pod-financial",
  customOperation = "/custom-operation",
  bankingDoc = "/banking-Doc",
  shippingDoc = "/shipping-Doc",
}
