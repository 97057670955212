import { Col, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import useLanguage from "src/store/language";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import { getRowKey } from "src/helper/getRowKey";
import { useBankingDOC } from "../context";
import { InspectionDocTableContainer } from "../style";
import { IGetAllBankDocs } from "src/services/BaseInfo/models/result.models";
import { S2 } from "src/components/UiKit/Typography";

export const BankingDOCsTable: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    dispatch: { setEditMode, setProductId, setPagination },
    value: { dataSource, productLoading, pagination },
    func: { deleteBankingDOC, getBankingDOCTableData },
  } = useBankingDOC()!;
  const onChangeParams = useChangeParams();

  useEffect(() => {
    getBankingDOCTableData();
  }, [getBankingDOCTableData]);
  const columns: ColumnsType<IGetAllBankDocs> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "56px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.basicInformation.bankingDOC.documentTitle,
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: words.global.tools,
      key: "id",
      align: "center",
      width: "152px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          {/* <Guard action={UpdateBankingDOCPath}> */}
          <span
            onClick={() => {
              setEditMode(true);
              form?.setFieldsValue(record);
              setProductId(record.id);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          {/* </Guard> */}
          {/* <Guard action={DeleteBankingDOCPath}> */}
          <DPopconfirm
            deleteClassName
            onConfirm={() => deleteBankingDOC(record.id)}
            description={words.basicInformation.bankingDOC.deleteBankingDOC}
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
              delete_forever
            </span>
          </DPopconfirm>
          {/* </Guard> */}
        </div>
      ),
    },
  ];
  return (
    <InspectionDocTableContainer>
      <Col span={24}>
        <S2 className="py-[16px]">
          {words.basicInformation.bankingDOC.bankingDOCTable}
        </S2>
      </Col>
      <Col span={24}>
        <div className="tableHolder">
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={productLoading}
            scroll={{ x: "auto" }}
            rowKey={getRowKey}
            className="w-[100%]"
            pagination={{
              position: ["bottomCenter"],
              total: pagination.total,
              current: pagination.current,
              pageSize: pagination.pageSize,
              onChange: (page, pageSize) => {
                onChangeParams("Limit", pageSize.toString());
                onChangeParams(
                  "Offset",
                  pageSize !== pagination.pageSize ? "1" : page.toString()
                );
                setPagination((prev) => ({
                  ...prev,
                  current: pageSize !== prev.pageSize ? 1 : page,
                  pageSize,
                }));
              },
            }}
          />
        </div>
      </Col>
    </InspectionDocTableContainer>
  );
};
