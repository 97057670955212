import {
  BankPageGuard,
  CountryPageGuard,
  CurrencyPageGuard,
  CustomBrokerPageGuard,
  InspectionPageGuard,
  InsurancePageGuard,
  PortPageGuard,
  ProductPageGuard,
  SupplierPageGuard,
  TransportPageGuard,
  UnitPageGuard,
  VesselPageGuard,
  WareHousePageGuard,
} from "src/components/Guard/permissions/baseInfoPagePath";
import {
  ArgiculturePagePath,
  BankingOperationPagePath,
  CustomDeclarationPagePath,
  CustomFormalitysPagePath,
  InspectionPagePath,
  InsurancePagePath,
  OrderManagementPagePath,
  OrderPagePath,
  OrderRegisterPagePath,
  OriginFinancialPagePath,
  OriginPagePath,
  PodPagePath,
  RatesPagePath,
  sellingPagePath,
  ShipmentPagePath,
  VesselArrivePagePath,
  ViewInsurancePagePath,
  ViewPodPagePath,
  ViewShipmentPagePath,
  VipPagePath,
  warehousingPagePath,
} from "src/components/Guard/permissions/orderPagePath";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { ROUTES } from "src/models/enums/routes";
import { AllApiPath } from "src/services/BaseInfo/guardPath";
import {
  GetAllBankFsPath,
  GetAllCustomFormalitysPath,
  GetAllInspectionFormsPath,
  GetAllInsuranceFileDetailsPath,
  GetAlllOriginFinancialPath,
  GetAlllSellPath,
  GetAllShipmentFileDetailsPath,
  GetArgiculturePath,
  GetCustomDeclarationFilePath,
  GetCustomDeclarationPath,
  GetCustomFormalityFilePath,
  GetInspecionFormFilePath,
  GetInsuranceFilePath,
  GetInsuranceFormPath,
  GetOrderRegisterPath,
  GetOriginFilePath,
  GetOriginPath,
  GetProformPath,
  GetRatePath,
  GetShipmentFilePath,
  GetShipmentPath,
  GetVesselArrivalFilePath,
  GetVesselArrivalPath,
  GetVipFilePath,
  GetVipPath,
  GetWarehousingPath,
} from "src/services/Order/guardPath";
import {
  AddUserRolePath,
  AssignRoleToActionPath,
  RolesPath,
  newUserPath,
} from "src/services/UserManagement/guardPath";

import { create } from "zustand";

type PageTitleKeys = keyof typeof ROUTES;
export type PageTitleKeysType = (typeof ROUTES)[PageTitleKeys];
export type IRoutesAccessed = Record<PageTitleKeys, boolean>;
interface IStore {
  routes: IRoutesAccessed;
  setRoutes: (routes: Partial<Record<PageTitleKeys, boolean>>) => void;
}

const useRoutesAccessStore = () => {
  const { checkRoutePermission, checkActionPermission } = useCheckPermission();

  const store = create<IStore>((set) => ({
    routes: {
      averagePrice: false,
      basicInformation: checkRoutePermission(AllApiPath),
      clearanceWorkers: checkRoutePermission(CustomBrokerPageGuard),
      countries: checkRoutePermission(CountryPageGuard),
      currencies: checkRoutePermission(CurrencyPageGuard),
      design: true,
      editClearanceWorkers: true,
      editInspectionCompanies: true,
      editInsuranceCompanies: true,
      editOperatingBank: true,
      editSupplier: true,
      editTransportCompany: true,
      editWarehouses: true,
      home: true,
      inspectionCompanies: checkRoutePermission(InspectionPageGuard),
      insuranceCompanies: checkRoutePermission(InsurancePageGuard),
      login: true,
      operatingBanks: checkRoutePermission(BankPageGuard),
      ports: checkRoutePermission(PortPageGuard),
      products: checkRoutePermission(ProductPageGuard),
      suppliers: checkRoutePermission(SupplierPageGuard),
      transportCompanies: checkRoutePermission(TransportPageGuard),
      unitsOfMeasurement: checkRoutePermission(UnitPageGuard),
      vessels: checkRoutePermission(VesselPageGuard),
      viewClearanceWorkers: true,
      viewInspectionCompanies: true,
      viewInsuranceCompanies: true,
      viewOperatingBank: true,
      viewSuppliers: true,
      viewTransportCompanies: true,
      viewWarehouses: true,
      warehouses: checkRoutePermission(WareHousePageGuard),
      CreateRole: checkRoutePermission([RolesPath]),
      AssignActionToRole: checkRoutePermission([
        RolesPath,
        AssignRoleToActionPath,
      ]),
      CreateUser: checkRoutePermission([newUserPath]),
      AssignRoleToUser: checkRoutePermission([AddUserRolePath]),
      settings: true,
      orders: checkRoutePermission(OrderPagePath),
      orderManagement: checkRoutePermission(OrderManagementPagePath),
      ViewOrder: checkActionPermission(GetProformPath),
      origin: checkRoutePermission(OriginPagePath),
      viewOrigin: checkRoutePermission([GetOriginPath, GetOriginFilePath]),
      vip: checkRoutePermission(VipPagePath),
      viewVip: checkRoutePermission([GetVipPath, GetVipFilePath]),
      addOrder: checkRoutePermission(OrderRegisterPagePath),
      viewAddOrder: checkActionPermission(GetOrderRegisterPath),
      vesselArrive: checkRoutePermission(VesselArrivePagePath),
      viewVesselArrive: checkRoutePermission([
        GetVesselArrivalPath,
        GetVesselArrivalFilePath,
      ]),
      warehousing: checkRoutePermission(warehousingPagePath),
      viewWarehousing: checkActionPermission(GetWarehousingPath),
      customDeclaration: checkRoutePermission(CustomDeclarationPagePath),
      viewCustomDeclaration: checkRoutePermission([
        GetCustomDeclarationPath,
        GetCustomDeclarationFilePath,
      ]),
      Agriculture: checkRoutePermission(ArgiculturePagePath),
      viewAgriculture: checkActionPermission(GetArgiculturePath),
      originFinancial: checkRoutePermission(OriginFinancialPagePath),
      viewOriginFinancial: checkActionPermission(GetAlllOriginFinancialPath),
      rates: checkRoutePermission(RatesPagePath),
      viewRates: checkActionPermission(GetRatePath),
      insurance: checkRoutePermission(InsurancePagePath),
      viewInsurance: checkRoutePermission(ViewInsurancePagePath),
      inspectionDocument: checkRoutePermission(InspectionPagePath),
      viewInspection: checkRoutePermission([
        GetAllInspectionFormsPath,
        GetInspecionFormFilePath,
      ]),
      selling: checkRoutePermission(sellingPagePath),
      sellingView: checkActionPermission(GetAlllSellPath),
      customsFormalities: checkRoutePermission(CustomFormalitysPagePath),
      viewCustomsFormalities: checkRoutePermission([
        GetAllCustomFormalitysPath,
        GetCustomFormalityFilePath,
      ]),
      bankingOperations: checkRoutePermission(BankingOperationPagePath),
      viewBankingOperations: checkActionPermission(GetAllBankFsPath),
      shipment: checkRoutePermission(ShipmentPagePath),
      viewShipment: checkRoutePermission(ViewShipmentPagePath),
      podFinancial: checkRoutePermission(PodPagePath),
      viewPOD: checkRoutePermission(ViewPodPagePath),
      InspectionInfoDocs: true,
      customOperation: true,
      bankingDoc: true,
      shippingDoc: true,
    },
    setRoutes: (newRoutes) =>
      set((state) => ({ routes: { ...state.routes, ...newRoutes } })),
  }));

  return store.getState();
};

export default useRoutesAccessStore;
