import { IPath } from "../Identity/models";

export const AllOrderPath: IPath[] = [
  {
    path: "api/Order/AddProform",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{proformId}/UpdateProform",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeleteProform",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddOrigin",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetOrigin",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateOrigin",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeleteOrigin",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/GetAllProform",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/GetProform",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{OriginId}/NewOriginFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{OriginId}/GetOriginFile",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{OriginId}/UpdateOriginFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{OriginId}/DeleteOriginFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/AddVip",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{VipId}/NewVipFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{VipId}/GetVipFile",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/GetVip",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateVip",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{VipId}/UpdateVipFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/DeleteVip",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{VipId}/DeleteVipFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/AddOrderRegister",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetOrderRegister",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateOrderRegister",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeleteOrderRegister",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddVesselArrival",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{VesselArrivalId}/NewVesselArrivalFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{VesselArrivalId}/GetVesselArrivalFile",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/GetVesselArrival",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateVesselArrival",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{VesselArrivalId}/UpdateVesselArrivalFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/DeleteVesselArrival",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{VesselArrivalId}/DeleteVesselArrivalFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/AddWarehousing",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetWarehousing",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateWarehousing",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeleteWarehousing",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddCustomDeclaration",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{CustomDeclarationId}/NewCustomDeclarationFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{CustomDeclarationId}/GetCustomDeclarationFile",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/GetCustomDeclaration",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateCustomDeclaration",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{CustomDeclarationId}/UpdateCustomDeclarationFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/DeleteCustomDeclaration",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{CustomDeclarationId}/DeleteCustomDeclarationFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/AddArgiculture",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetArgiculture",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateArgiculture",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeleteArgiculture",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddRate",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetRate",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateRate",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeleteRate",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddSell",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{SellId}/GetSell",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/GetAlllSell",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{SellId}/UpdateSell",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{SellId}/DeleteSell",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddOriginFinancial",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{OriginFinancialId}/GetOriginFinancial",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/GetAlllOriginFinancial",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{OriginFinancialId}/UpdateOriginFinancial",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{OriginFinancialId}/DeleteOriginFinancial",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddInspecionForm",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{InspecionFormId}/NewInspecionFormFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{InspecionFormId}/GetInspecionFormFile",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{InspecionFormId}/GetAllInspectionForms",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{InspectionFormId}/GetInspecionForm",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{InspectionFormId}/UpdateInspecionForm",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{InspecionFormId}/UpdateInspecionFormFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{InspectionFormId}/DeleteInspecionForm",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{InspecionFormId}/DeleteInspecionFormFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/AddCustomFormality",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{CustomFormalityId}/NewCustomFormalityFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{CustomFormalityId}/GetCustomFormalityFile",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/GetAllCustomFormalitys",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{CustomFormalityId}/GetCustomFormality",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{CustomFormalityId}/UpdateCustomFormality",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{CustomFormalityId}/UpdateCustomFormalityFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{CustomFormalityId}/DeleteCustomFormality",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{CustomFormalityId}/DeleteCustomFormalityFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/AddInsuranceForm",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetInsuranceForm",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateInsuranceForm",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeleteInsuranceForm",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{InsuranceFormId}/AddInsuranceFileDetail",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{InsuranceFileId}/NewInsuranceFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{InsuranceFileId}/GetInsuranceFile",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{InsuranceFileId}/GetInsuranceFileDetail",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{InsuranceFileId}/UpdateInsuranceFileDetail",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{InsuranceFileId}/UpdateInsuranceFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{InsuranceFileId}/DeleteInsuranceFileDetail",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{InsuranceFileId}/DeleteInsuranceFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/AddBankF",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetAllBankFs",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{BankFId}/GetBankF",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{BankFId}/UpdateBankF",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{BankFId}/DeleteBankF",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddShipment",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetShipment",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{proformId}/UpdateShipment",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeleteShipment",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{InsuranceFormId}/GetAllInsuranceFileDetails",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ShipmentId}/AddShipmentFileDetail",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ShipmentFileId}/NewShipmentFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ShipmentFileId}/GetShipmentFile",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ShipmentFileId}/GetShipmentFileDetail",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ShipmentId}/GetAllShipmentFileDetails",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ShipmentFileId}/UpdateShipmentFileDetail",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ShipmentFileId}/UpdateShipmentFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ShipmentFileId}/DeleteShipmentFileDetail",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ShipmentFileId}/DeleteShipmentFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Order/{ProformId}/GetProformMain",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/AddPODFinancial",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetPODFinancial",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/GetPODFinancialResult",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{ProformId}/UpdatePODFinancial",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{ProformId}/DeletePODFinancial",
    httpMethod: "DELETE",
  },
  {
    path: "api/Order/{ProformId}/AddPODFinancialOther",
    httpMethod: "POST",
  },
  {
    path: "api/Order/{ProformId}/GetAllPODFinancialOthers",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{PODFinancialOtherId}/GetPODFinancialOther",
    httpMethod: "GET",
  },
  {
    path: "api/Order/{PODFinancialOtherId}/UpdatePODFinancialOther",
    httpMethod: "PUT",
  },
  {
    path: "api/Order/{PODFinancialOtherId}/DeletePODFinancialOther",
    httpMethod: "DELETE",
  },
];

export const AddProformPath: IPath = {
  path: "api/Order/AddProform",
  httpMethod: "POST",
};
export const UpdateProformPath: IPath = {
  path: "api/Order/{proformId}/UpdateProform",
  httpMethod: "PUT",
};
export const DeleteProformPath: IPath = {
  path: "api/Order/{ProformId}/DeleteProform",
  httpMethod: "DELETE",
};
export const AddOriginPath: IPath = {
  path: "api/Order/{ProformId}/AddOrigin",
  httpMethod: "POST",
};
export const GetOriginPath: IPath = {
  path: "api/Order/{ProformId}/GetOrigin",
  httpMethod: "GET",
};
export const UpdateOriginPath: IPath = {
  path: "api/Order/{proformId}/UpdateOrigin",
  httpMethod: "PUT",
};
export const DeleteOriginPath: IPath = {
  path: "api/Order/{ProformId}/DeleteOrigin",
  httpMethod: "DELETE",
};
export const GetAllProformPath: IPath = {
  path: "api/Order/GetAllProform",
  httpMethod: "GET",
};
export const GetProformPath: IPath = {
  path: "api/Order/{ProformId}/GetProform",
  httpMethod: "GET",
};
export const NewOriginFilePath: IPath = {
  path: "api/Order/{OriginId}/NewOriginFile",
  httpMethod: "PATCH",
};
export const GetOriginFilePath: IPath = {
  path: "api/Order/{OriginId}/GetOriginFile",
  httpMethod: "GET",
};
export const UpdateOriginFilePath: IPath = {
  path: "api/Order/{OriginId}/UpdateOriginFile",
  httpMethod: "PATCH",
};
export const DeleteOriginFilePath: IPath = {
  path: "api/Order/{OriginId}/DeleteOriginFile",
  httpMethod: "PATCH",
};
export const AddVipPath: IPath = {
  path: "api/Order/{ProformId}/AddVip",
  httpMethod: "POST",
};
export const NewVipFilePath: IPath = {
  path: "api/Order/{VipId}/NewVipFile",
  httpMethod: "PATCH",
};
export const GetVipFilePath: IPath = {
  path: "api/Order/{VipId}/GetVipFile",
  httpMethod: "GET",
};
export const GetVipPath: IPath = {
  path: "api/Order/{ProformId}/GetVip",
  httpMethod: "GET",
};
export const UpdateVipPath: IPath = {
  path: "api/Order/{proformId}/UpdateVip",
  httpMethod: "PUT",
};
export const UpdateVipFilePath: IPath = {
  path: "api/Order/{VipId}/UpdateVipFile",
  httpMethod: "PATCH",
};
export const DeleteVipPath: IPath = {
  path: "api/Order/{ProformId}/DeleteVip",
  httpMethod: "DELETE",
};
export const DeleteVipFilePath: IPath = {
  path: "api/Order/{VipId}/DeleteVipFile",
  httpMethod: "PATCH",
};
export const AddOrderRegisterPath: IPath = {
  path: "api/Order/{ProformId}/AddOrderRegister",
  httpMethod: "POST",
};
export const GetOrderRegisterPath: IPath = {
  path: "api/Order/{ProformId}/GetOrderRegister",
  httpMethod: "GET",
};
export const UpdateOrderRegisterPath: IPath = {
  path: "api/Order/{proformId}/UpdateOrderRegister",
  httpMethod: "PUT",
};
export const DeleteOrderRegisterPath: IPath = {
  path: "api/Order/{ProformId}/DeleteOrderRegister",
  httpMethod: "DELETE",
};
export const AddVesselArrivalPath: IPath = {
  path: "api/Order/{ProformId}/AddVesselArrival",
  httpMethod: "POST",
};
export const NewVesselArrivalFilePath: IPath = {
  path: "api/Order/{VesselArrivalId}/NewVesselArrivalFile",
  httpMethod: "PATCH",
};
export const GetVesselArrivalFilePath: IPath = {
  path: "api/Order/{VesselArrivalId}/GetVesselArrivalFile",
  httpMethod: "GET",
};
export const GetVesselArrivalPath: IPath = {
  path: "api/Order/{ProformId}/GetVesselArrival",
  httpMethod: "GET",
};
export const UpdateVesselArrivalPath: IPath = {
  path: "api/Order/{proformId}/UpdateVesselArrival",
  httpMethod: "PUT",
};
export const UpdateVesselArrivalFilePath: IPath = {
  path: "api/Order/{VesselArrivalId}/UpdateVesselArrivalFile",
  httpMethod: "PATCH",
};
export const DeleteVesselArrivalPath: IPath = {
  path: "api/Order/{ProformId}/DeleteVesselArrival",
  httpMethod: "DELETE",
};
export const DeleteVesselArrivalFilePath: IPath = {
  path: "api/Order/{VesselArrivalId}/DeleteVesselArrivalFile",
  httpMethod: "PATCH",
};
export const AddWarehousingPath: IPath = {
  path: "api/Order/{ProformId}/AddWarehousing",
  httpMethod: "POST",
};
export const GetWarehousingPath: IPath = {
  path: "api/Order/{ProformId}/GetWarehousing",
  httpMethod: "GET",
};
export const UpdateWarehousingPath: IPath = {
  path: "api/Order/{proformId}/UpdateWarehousing",
  httpMethod: "PUT",
};
export const DeleteWarehousingPath: IPath = {
  path: "api/Order/{ProformId}/DeleteWarehousing",
  httpMethod: "DELETE",
};
export const AddCustomDeclarationPath: IPath = {
  path: "api/Order/{ProformId}/AddCustomDeclaration",
  httpMethod: "POST",
};
export const NewCustomDeclarationFilePath: IPath = {
  path: "api/Order/{CustomDeclarationId}/NewCustomDeclarationFile",
  httpMethod: "PATCH",
};
export const GetCustomDeclarationFilePath: IPath = {
  path: "api/Order/{CustomDeclarationId}/GetCustomDeclarationFile",
  httpMethod: "GET",
};
export const GetCustomDeclarationPath: IPath = {
  path: "api/Order/{ProformId}/GetCustomDeclaration",
  httpMethod: "GET",
};
export const UpdateCustomDeclarationPath: IPath = {
  path: "api/Order/{proformId}/UpdateCustomDeclaration",
  httpMethod: "PUT",
};
export const UpdateCustomDeclarationFilePath: IPath = {
  path: "api/Order/{CustomDeclarationId}/UpdateCustomDeclarationFile",
  httpMethod: "PATCH",
};
export const DeleteCustomDeclarationPath: IPath = {
  path: "api/Order/{ProformId}/DeleteCustomDeclaration",
  httpMethod: "DELETE",
};
export const DeleteCustomDeclarationFilePath: IPath = {
  path: "api/Order/{CustomDeclarationId}/DeleteCustomDeclarationFile",
  httpMethod: "PATCH",
};
export const AddArgiculturePath: IPath = {
  path: "api/Order/{ProformId}/AddArgiculture",
  httpMethod: "POST",
};
export const GetArgiculturePath: IPath = {
  path: "api/Order/{ProformId}/GetArgiculture",
  httpMethod: "GET",
};
export const UpdateArgiculturePath: IPath = {
  path: "api/Order/{proformId}/UpdateArgiculture",
  httpMethod: "PUT",
};
export const DeleteArgiculturePath: IPath = {
  path: "api/Order/{ProformId}/DeleteArgiculture",
  httpMethod: "DELETE",
};
export const AddRatePath: IPath = {
  path: "api/Order/{ProformId}/AddRate",
  httpMethod: "POST",
};
export const GetRatePath: IPath = {
  path: "api/Order/{ProformId}/GetRate",
  httpMethod: "GET",
};
export const UpdateRatePath: IPath = {
  path: "api/Order/{proformId}/UpdateRate",
  httpMethod: "PUT",
};
export const DeleteRatePath: IPath = {
  path: "api/Order/{ProformId}/DeleteRate",
  httpMethod: "DELETE",
};
export const AddSellPath: IPath = {
  path: "api/Order/{ProformId}/AddSell",
  httpMethod: "POST",
};
export const GetSellPath: IPath = {
  path: "api/Order/{SellId}/GetSell",
  httpMethod: "GET",
};
export const GetAlllSellPath: IPath = {
  path: "api/Order/{ProformId}/GetAlllSell",
  httpMethod: "GET",
};
export const UpdateSellPath: IPath = {
  path: "api/Order/{SellId}/UpdateSell",
  httpMethod: "PUT",
};
export const DeleteSellPath: IPath = {
  path: "api/Order/{SellId}/DeleteSell",
  httpMethod: "DELETE",
};
export const AddOriginFinancialPath: IPath = {
  path: "api/Order/{ProformId}/AddOriginFinancial",
  httpMethod: "POST",
};
export const GetOriginFinancialPath: IPath = {
  path: "api/Order/{OriginFinancialId}/GetOriginFinancial",
  httpMethod: "GET",
};
export const GetAlllOriginFinancialPath: IPath = {
  path: "api/Order/{ProformId}/GetAlllOriginFinancial",
  httpMethod: "GET",
};
export const UpdateOriginFinancialPath: IPath = {
  path: "api/Order/{OriginFinancialId}/UpdateOriginFinancial",
  httpMethod: "PUT",
};
export const DeleteOriginFinancialPath: IPath = {
  path: "api/Order/{OriginFinancialId}/DeleteOriginFinancial",
  httpMethod: "DELETE",
};
export const AddInspecionFormPath: IPath = {
  path: "api/Order/{ProformId}/AddInspecionForm",
  httpMethod: "POST",
};
export const NewInspecionFormFilePath: IPath = {
  path: "api/Order/{InspecionFormId}/NewInspecionFormFile",
  httpMethod: "PATCH",
};
export const GetInspecionFormFilePath: IPath = {
  path: "api/Order/{InspecionFormId}/GetInspecionFormFile",
  httpMethod: "GET",
};
export const GetAllInspectionFormsPath: IPath = {
  path: "api/Order/{InspecionFormId}/GetAllInspectionForms",
  httpMethod: "GET",
};
export const GetInspecionFormPath: IPath = {
  path: "api/Order/{InspectionFormId}/GetInspecionForm",
  httpMethod: "GET",
};
export const UpdateInspecionFormPath: IPath = {
  path: "api/Order/{InspectionFormId}/UpdateInspecionForm",
  httpMethod: "PUT",
};
export const UpdateInspecionFormFilePath: IPath = {
  path: "api/Order/{InspecionFormId}/UpdateInspecionFormFile",
  httpMethod: "PATCH",
};
export const DeleteInspecionFormPath: IPath = {
  path: "api/Order/{InspectionFormId}/DeleteInspecionForm",
  httpMethod: "DELETE",
};
export const DeleteInspecionFormFilePath: IPath = {
  path: "api/Order/{InspecionFormId}/DeleteInspecionFormFile",
  httpMethod: "PATCH",
};
export const AddCustomFormalityPath: IPath = {
  path: "api/Order/{ProformId}/AddCustomFormality",
  httpMethod: "POST",
};
export const NewCustomFormalityFilePath: IPath = {
  path: "api/Order/{CustomFormalityId}/NewCustomFormalityFile",
  httpMethod: "PATCH",
};
export const GetCustomFormalityFilePath: IPath = {
  path: "api/Order/{CustomFormalityId}/GetCustomFormalityFile",
  httpMethod: "GET",
};
export const GetAllCustomFormalitysPath: IPath = {
  path: "api/Order/{ProformId}/GetAllCustomFormalitys",
  httpMethod: "GET",
};
export const GetCustomFormalityPath: IPath = {
  path: "api/Order/{CustomFormalityId}/GetCustomFormality",
  httpMethod: "GET",
};
export const UpdateCustomFormalityPath: IPath = {
  path: "api/Order/{CustomFormalityId}/UpdateCustomFormality",
  httpMethod: "PUT",
};
export const UpdateCustomFormalityFilePath: IPath = {
  path: "api/Order/{CustomFormalityId}/UpdateCustomFormalityFile",
  httpMethod: "PATCH",
};
export const DeleteCustomFormalityPath: IPath = {
  path: "api/Order/{CustomFormalityId}/DeleteCustomFormality",
  httpMethod: "DELETE",
};
export const DeleteCustomFormalityFilePath: IPath = {
  path: "api/Order/{CustomFormalityId}/DeleteCustomFormalityFile",
  httpMethod: "PATCH",
};
export const AddInsuranceFormPath: IPath = {
  path: "api/Order/{ProformId}/AddInsuranceForm",
  httpMethod: "POST",
};
export const GetInsuranceFormPath: IPath = {
  path: "api/Order/{ProformId}/GetInsuranceForm",
  httpMethod: "GET",
};
export const UpdateInsuranceFormPath: IPath = {
  path: "api/Order/{proformId}/UpdateInsuranceForm",
  httpMethod: "PUT",
};
export const DeleteInsuranceFormPath: IPath = {
  path: "api/Order/{ProformId}/DeleteInsuranceForm",
  httpMethod: "DELETE",
};
export const AddInsuranceFileDetailPath: IPath = {
  path: "api/Order/{InsuranceFormId}/AddInsuranceFileDetail",
  httpMethod: "POST",
};
export const NewInsuranceFilePath: IPath = {
  path: "api/Order/{InsuranceFileId}/NewInsuranceFile",
  httpMethod: "PATCH",
};
export const GetInsuranceFilePath: IPath = {
  path: "api/Order/{InsuranceFileId}/GetInsuranceFile",
  httpMethod: "GET",
};
export const GetInsuranceFileDetailPath: IPath = {
  path: "api/Order/{InsuranceFileId}/GetInsuranceFileDetail",
  httpMethod: "GET",
};
export const UpdateInsuranceFileDetailPath: IPath = {
  path: "api/Order/{InsuranceFileId}/UpdateInsuranceFileDetail",
  httpMethod: "PUT",
};
export const UpdateInsuranceFilePath: IPath = {
  path: "api/Order/{InsuranceFileId}/UpdateInsuranceFile",
  httpMethod: "PATCH",
};
export const DeleteInsuranceFileDetailPath: IPath = {
  path: "api/Order/{InsuranceFileId}/DeleteInsuranceFileDetail",
  httpMethod: "DELETE",
};
export const DeleteInsuranceFilePath: IPath = {
  path: "api/Order/{InsuranceFileId}/DeleteInsuranceFile",
  httpMethod: "PATCH",
};
export const AddBankFPath: IPath = {
  path: "api/Order/{ProformId}/AddBankF",
  httpMethod: "POST",
};
export const GetAllBankFsPath: IPath = {
  path: "api/Order/{ProformId}/GetAllBankFs",
  httpMethod: "GET",
};
export const GetBankFPath: IPath = {
  path: "api/Order/{BankFId}/GetBankF",
  httpMethod: "GET",
};
export const UpdateBankFPath: IPath = {
  path: "api/Order/{BankFId}/UpdateBankF",
  httpMethod: "PUT",
};
export const DeleteBankFPath: IPath = {
  path: "api/Order/{BankFId}/DeleteBankF",
  httpMethod: "DELETE",
};
export const AddShipmentPath: IPath = {
  path: "api/Order/{ProformId}/AddShipment",
  httpMethod: "POST",
};
export const GetShipmentPath: IPath = {
  path: "api/Order/{ProformId}/GetShipment",
  httpMethod: "GET",
};
export const UpdateShipmentPath: IPath = {
  path: "api/Order/{proformId}/UpdateShipment",
  httpMethod: "PUT",
};
export const DeleteShipmentPath: IPath = {
  path: "api/Order/{ProformId}/DeleteShipment",
  httpMethod: "DELETE",
};
export const GetAllInsuranceFileDetailsPath: IPath = {
  path: "api/Order/{InsuranceFormId}/GetAllInsuranceFileDetails",
  httpMethod: "GET",
};
export const AddShipmentFileDetailPath: IPath = {
  path: "api/Order/{ShipmentId}/AddShipmentFileDetail",
  httpMethod: "POST",
};
export const NewShipmentFilePath: IPath = {
  path: "api/Order/{ShipmentFileId}/NewShipmentFile",
  httpMethod: "PATCH",
};
export const GetShipmentFilePath: IPath = {
  path: "api/Order/{ShipmentFileId}/GetShipmentFile",
  httpMethod: "GET",
};
export const GetShipmentFileDetailPath: IPath = {
  path: "api/Order/{ShipmentFileId}/GetShipmentFileDetail",
  httpMethod: "GET",
};
export const GetAllShipmentFileDetailsPath: IPath = {
  path: "api/Order/{ShipmentId}/GetAllShipmentFileDetails",
  httpMethod: "GET",
};
export const UpdateShipmentFileDetailPath: IPath = {
  path: "api/Order/{ShipmentFileId}/UpdateShipmentFileDetail",
  httpMethod: "PUT",
};
export const UpdateShipmentFilePath: IPath = {
  path: "api/Order/{ShipmentFileId}/UpdateShipmentFile",
  httpMethod: "PATCH",
};
export const DeleteShipmentFileDetailPath: IPath = {
  path: "api/Order/{ShipmentFileId}/DeleteShipmentFileDetail",
  httpMethod: "DELETE",
};
export const DeleteShipmentFilePath: IPath = {
  path: "api/Order/{ShipmentFileId}/DeleteShipmentFile",
  httpMethod: "PATCH",
};
export const GetProformMainPath: IPath = {
  path: "api/Order/{ProformId}/GetProformMain",
  httpMethod: "GET",
};
export const AddPODFinancialPath: IPath = {
  path: "api/Order/{ProformId}/AddPODFinancial",
  httpMethod: "POST",
};
export const GetPODFinancialPath: IPath = {
  path: "api/Order/{ProformId}/GetPODFinancial",
  httpMethod: "GET",
};
export const GetPODFinancialResultPath: IPath = {
  path: "api/Order/{ProformId}/GetPODFinancialResult",
  httpMethod: "GET",
};
export const UpdatePODFinancialPath: IPath = {
  path: "api/Order/{ProformId}/UpdatePODFinancial",
  httpMethod: "PUT",
};
export const DeletePODFinancialPath: IPath = {
  path: "api/Order/{ProformId}/DeletePODFinancial",
  httpMethod: "DELETE",
};
export const AddPODFinancialOtherPath: IPath = {
  path: "api/Order/{ProformId}/AddPODFinancialOther",
  httpMethod: "POST",
};
export const GetAllPODFinancialOthersPath: IPath = {
  path: "api/Order/{ProformId}/GetAllPODFinancialOthers",
  httpMethod: "GET",
};
export const GetPODFinancialOtherPath: IPath = {
  path: "api/Order/{PODFinancialOtherId}/GetPODFinancialOther",
  httpMethod: "GET",
};
export const UpdatePODFinancialOtherPath: IPath = {
  path: "api/Order/{PODFinancialOtherId}/UpdatePODFinancialOther",
  httpMethod: "PUT",
};
export const DeletePODFinancialOtherPath: IPath = {
  path: "api/Order/{PODFinancialOtherId}/DeletePODFinancialOther",
  httpMethod: "DELETE",
};
