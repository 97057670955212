import { FC } from "react";
import { BankingDOC } from "src/modules/BankingDOC";
import { BankingDOCProvider } from "src/modules/BankingDOC/context";

export const BankingDOCPage: FC = () => {
  return (
    <BankingDOCProvider>
      <BankingDOC />
    </BankingDOCProvider>
  );
};
