import { Button, Col, Divider, Form, InputNumber, Row } from "antd";
import { FC, useEffect, useMemo } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { S2 } from "src/components/UiKit/Typography";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { usePOD } from "../../context";
import useLanguage from "src/store/language";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { IAddPODFinancial } from "src/services/Order/models/args.models";
import { AddPODFormContainer } from "../../style";
import { BasicInfoFormTable } from "./Table";
import { inputNumberFormatter } from "src/helper/inputNumberFormatter";

export const BasicInfoForm: FC = () => {
  const {
    form: { basicInfoForm },
    value: { editMode, backEditMode, loadings },
    dispatch: { setEditMode },
    func: { addForm, updateForm, getPODFinancialForm, getPODFinancial },
  } = usePOD()!;
  const { words } = useLanguage();
  const values = Form.useWatch([], basicInfoForm);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };

  const onClickReset = () => {
    basicInfoForm?.resetFields();
  };

  const onFinish = (values: IAddPODFinancial) => {
    if (editMode || backEditMode) {
      updateForm(values);
    } else {
      addForm(values);
    }
  };

  const [search] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) {
      Promise.allSettled([getPODFinancialForm(), getPODFinancial()]);
    }
  }, [getPODFinancialForm, isEdited, getPODFinancial]);

  return (
    <Form form={basicInfoForm} onFinish={onFinish}>
      <AddPODFormContainer gutter={[0, 24]}>
        <Col span={24}>
          <S2 className="py-[16px]">
            {editMode ? words.orders.pod.editPodInfo : words.orders.pod.podInfo}
          </S2>
        </Col>
        <Col span={24} className="w-full">
          <Row gutter={[24, 0]} className="pt-[16px]">
            <Col span={8}>
              <FormItem name={"bankFx"} label={words.orders.pod.FX}>
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name={"bankFee"} label={words.orders.pod.bankFee}>
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name={"importDuty"} label={words.orders.pod.importDuty}>
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"warehousing"}
                label={words.orders.pod.warehousing}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name={"standardRate"} label={words.orders.pod.standard}>
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name={"flagFee"} label={words.orders.pod.flag}>
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                name={"orderRegisterFee"}
                label={words.orders.pod.orderRegistration}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"insurancePolicyFee"}
                label={words.orders.pod.insurance}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div
            className={`flex${editMode ? "justify-between" : "justify-end"}`}
          >
            <div className="flex gap-[16px] justify-end borderTop">
              {editMode ? (
                <div className="!min-w-[85px] h-[100%]">
                  <Button type="default" onClick={onClickCancel}>
                    {words.global.cancel}
                  </Button>
                </div>
              ) : (
                <DPopconfirm
                  description={words.global.descriptionPopConfirm}
                  onConfirm={onClickReset}
                >
                  <div className="!min-w-[85px] h-[100%]">
                    <Button type="default" disabled={isDisabledResetForm}>
                      {words.global.reset}
                    </Button>
                  </div>
                </DPopconfirm>
              )}
              <Button
                className="!min-w-[85px]"
                type="primary"
                onClick={() => {
                  basicInfoForm?.submit();
                }}
                loading={
                  editMode || backEditMode
                    ? loadings.updateForm
                    : loadings.addForm
                }
                disabled={isDisabledResetForm}
              >
                {editMode || backEditMode
                  ? words.global.saveEdit
                  : words.global.add}
              </Button>
            </div>
          </div>
        </Col>
        <Divider dashed className="!border-[#BFE2E0]" />
        <Col span={24}>
          <BasicInfoFormTable />
        </Col>
      </AddPODFormContainer>
    </Form>
  );
};
