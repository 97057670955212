import { FC } from "react";
import { UploadFileForm } from "./UploadFileForm";
import { UploadFileTable } from "./Table";

export const UploadFile: FC = () => {
  return (
    <div className="flex gap-[24px] flex-col">
      <UploadFileForm />
      <UploadFileTable />
    </div>
  );
};
