import { FC } from "react";
import { Insurance } from "src/modules/Insurance";
import { InsuranceProvider } from "src/modules/Insurance/context";

export const InsurancePage: FC = () => {
  return (
    <InsuranceProvider>
      <Insurance />
    </InsuranceProvider>
  );
};
