import { FC, useEffect, useState } from "react";
import { Col, Divider, Row, Table } from "antd";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { ViewOriginFinancialContainer } from "../../style";
import { usePayment } from "../../models";
import { HeaderCards } from "./cards";
import { B2, S2 } from "src/components/UiKit/Typography";
import { P3 } from "src/components/UiKit/Typography/P3";
import { useViewColumns } from "./columns";
import { getRowKey } from "src/helper/getRowKey";
import { OrderService } from "src/services/Order/order.service";
import {
  IGetAlllOriginFinancial,
  IOriginFinancials,
} from "src/services/Order/models/result.models";
import { useParams } from "react-router-dom";

export const ViewOriginFinancial: FC = () => {
  const { words } = useLanguage();
  const [Columns] = useViewColumns();
  const { orderId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [originFinancial, setOriginFinancial] = useState<IOriginFinancials[]>(
    []
  );
  const [allOriginFinancial, setAllOriginFinancial] =
    useState<IGetAlllOriginFinancial>();

  const getAlllOriginFinancial = async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetAlllOriginFinancial } = new OrderService();
      const result = await GetAlllOriginFinancial(parseInt(orderId));
      if (result && result.status === 200) {
        setOriginFinancial(result.data.originFinancials);
        setAllOriginFinancial(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const paymentOptions = usePayment(allOriginFinancial);

  useEffect(() => {
    getAlllOriginFinancial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <HeaderProforma
        headerName={words.orders.originFinancial.viewOriginFinancialHeader}
      />
      <Row justify={"center"}>
        <ViewOriginFinancialContainer span={22}>
          <Row gutter={[16, 24]} justify={"space-around"}>
            {paymentOptions.map((item, index) => (
              <Col span={4}>
                <HeaderCards amount={item.amount} payment={item.value} />
              </Col>
            ))}
            <Col span={24}>
              <Divider dashed className="my-[16px] !border-[#BFE2E0]" />
            </Col>
            <Col span={18}>
              <S2 className="py-[8px]">{words.orders.originFinancial.table}</S2>
            </Col>
            <Col span={6}>
              <div className="totalHolder">
                <P3 className="!text-[#044440]">USD</P3>
                <B2 className="text-[#044440]">
                  {allOriginFinancial?.total.toLocaleString("en-US")}
                </B2>
                <Divider
                  type="vertical"
                  className="!border-[#CACACA] border-s-[2px] rounded h-[24px]"
                />
                <B2 className="!text-[#008F87]">
                  {words.orders.originFinancial.total}
                </B2>
              </div>
            </Col>
            <Col span={24} className="tableHolder">
              <Table
                columns={Columns}
                loading={loading}
                scroll={{ x: "auto" }}
                rowKey={getRowKey}
                className="w-[100%]"
                dataSource={originFinancial}
                pagination={false}
              />
            </Col>
          </Row>
        </ViewOriginFinancialContainer>
      </Row>
    </div>
  );
};
