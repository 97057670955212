import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import { FC, useEffect, useMemo } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { S2 } from "src/components/UiKit/Typography";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useShipment } from "../context";
import useLanguage from "src/store/language";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { IAddShipment } from "src/services/Order/models/args.models";
import en_US from "antd/lib/locale/en_US";
import { AddShipmentFormContainer } from "../style";
import { inputNumberFormatter } from "src/helper/inputNumberFormatter";

export const AddShipmentForm: FC = () => {
  const {
    form: { addShipmentForm },
    value: {
      editMode,
      backEditMode,
      loadings,
      allVessel,
      allPort,
      allCurrency,
      allUnit,
    },
    dispatch: { setEditMode },
    func: { addForm, updateForm, deleteShipmentForm, getShipmentFormInfo },
  } = useShipment()!;
  const { words } = useLanguage();
  const values = Form.useWatch([], addShipmentForm);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { RangePicker } = DatePicker;

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };

  const onClickReset = () => {
    addShipmentForm?.resetFields();
  };

  const onFinish = (values: IAddShipment) => {
    if (editMode || backEditMode) {
      updateForm(values);
    } else {
      addForm(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getShipmentFormInfo();
  }, [getShipmentFormInfo, isEdited]);

  useEffect(() => {
    if (values?.cpa === false) {
      addShipmentForm?.setFieldsValue({
        demurageCurrencyId: null,
        demurageRate: undefined,
        dispatchCurrencyId: null,
        dispatchRate: undefined,
      });
    }
  }, [values, addShipmentForm]);
  return (
    <ConfigProvider locale={en_US}>
      <Form form={addShipmentForm} onFinish={onFinish}>
        <AddShipmentFormContainer gutter={[0, 24]}>
          <Col span={24}>
            <S2 className="py-[16px]">
              {editMode
                ? words.orders.shipment.editShipmentInfo
                : words.orders.shipment.shipmentInfo}
            </S2>
          </Col>
          <Col span={24} className="w-full">
            <Row gutter={[24, 0]} className="pt-[16px]">
              <Col span={8}>
                <FormItem
                  name={"vesselId"}
                  label={words.orders.shipment.vesselName}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={words.global.select}
                    optionLabelProp="label"
                    showSearch
                    optionFilterProp="label"
                    loading={loadings.allVessel}
                    options={allVessel?.map((item, index) => ({
                      label: item.vesselName,
                      value: item.id,
                    }))}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"voyageNo"}
                  label={words.orders.shipment.voyageNo}
                >
                  <Input placeholder={words.global.insert} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"portOfLoadingId"}
                  label={words.orders.shipment.landingPort}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={words.global.select}
                    optionLabelProp="label"
                    showSearch
                    optionFilterProp="label"
                    loading={loadings.allPort}
                    options={allPort.map((item, index) => ({
                      label: item.portName,
                      value: item.id,
                    }))}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <div className="flex gap-[16px] h-full flex-col">
                  <Typography className="h-[23px] flex items-center">
                    {words.orders.shipment.CPA}
                  </Typography>
                  <FormItem
                    name={"cpa"}
                    className="items-center flex h-[23px]"
                    initialValue={true}
                  >
                    <Radio.Group>
                      <Radio value={true}>{words.orders.shipment.has}</Radio>
                      <Radio value={false}>
                        {words.orders.shipment.hasNot}
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </div>
              </Col>
              <Col span={8}>
                <div className="wrapper !w-full">
                  <FormItem
                    name={"demurageCurrencyId"}
                    label={words.orders.shipment.demurrageRate}
                    className="w-[70%]"
                  >
                    <Select
                      disabled={values?.cpa === false}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.orders.originFinancial.currency}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={loadings.allCurrency}
                      options={allCurrency.map((item, index) => ({
                        label: item.shortName,
                        value: item.id,
                      }))}
                    />
                  </FormItem>
                  <FormItem name={"demurageRate"} label=" " className="w-full">
                    <InputNumber
                      disabled={values?.cpa === false}
                      placeholder={
                        values?.cpa === false
                          ? words.orders.shipment.notCharter
                          : words.global.insert
                      }
                      onPressEnter={addShipmentForm?.submit}
                      changeOnWheel={false}
                      controls={false}
                      className="w-full"
                      formatter={inputNumberFormatter}
                    />
                  </FormItem>
                </div>
              </Col>
              <Col span={8}>
                <div className="wrapper !w-full">
                  <FormItem
                    name={"dispatchCurrencyId"}
                    label={words.orders.shipment.dispatchRate}
                    className="w-[80%]"
                  >
                    <Select
                      disabled={values?.cpa === false}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.orders.originFinancial.currency}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={loadings.allCurrency}
                      options={allCurrency.map((item, index) => ({
                        label: item.shortName,
                        value: item.id,
                      }))}
                    />
                  </FormItem>
                  <FormItem name={"dispatchRate"} label=" " className="w-full">
                    <InputNumber
                      disabled={values?.cpa === false}
                      placeholder={
                        values?.cpa === false
                          ? words.orders.shipment.notCharter
                          : words.global.insert
                      }
                      onPressEnter={addShipmentForm?.submit}
                      changeOnWheel={false}
                      controls={false}
                      className="w-full"
                      formatter={inputNumberFormatter}
                    />
                  </FormItem>
                </div>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"berthingLineUp"}
                  label={words.orders.shipment.berthingLineUp}
                >
                  <DatePicker placeholder={words.global.select} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"loadingOperation"}
                  label={words.orders.shipment.loadingOperation}
                >
                  <RangePicker
                    placeholder={[words.global.start, words.global.endDate]}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"reciveDraftDate"}
                  label={words.orders.shipment.receivingDraftsDocuments}
                >
                  <DatePicker placeholder={words.global.select} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"sendDraftDate"}
                  label={words.orders.shipment.sendingDraftsDocuments}
                >
                  <DatePicker placeholder={words.global.select} />
                </FormItem>
              </Col>
              <Col span={8}>
                <div className="wrapper !w-full">
                  <FormItem
                    name={"unitId"}
                    label={words.orders.shipment.weight}
                    rules={[{ required: true }]}
                    className="w-[80%]"
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.unit}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={loadings.allUnit}
                      options={allUnit.map((item, index) => ({
                        label: item.shortName,
                        value: item.id,
                      }))}
                    />
                  </FormItem>
                  <FormItem
                    name={"quality"}
                    label=" "
                    className="w-full"
                    rules={[{ required: true }]}
                    required={false}
                  >
                    <InputNumber
                      placeholder={words.global.insert}
                      onPressEnter={addShipmentForm?.submit}
                      changeOnWheel={false}
                      controls={false}
                      className="w-full"
                      formatter={inputNumberFormatter}
                    />
                  </FormItem>
                </div>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"portOfDischargeId"}
                  label={words.orders.shipment.portDischarge}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={words.global.select}
                    optionLabelProp="label"
                    showSearch
                    optionFilterProp="label"
                    loading={loadings.allPort}
                    options={allPort.map((item, index) => ({
                      label: item.portName,
                      value: item.id,
                    }))}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name={"blDate"} label={words.orders.shipment.BLDate}>
                  <DatePicker placeholder={words.global.select} />
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Divider dashed className="!border-[#BFE2E0]" />
            <div
              className={`flex py-[24px]  ${
                editMode ? "justify-between" : "justify-end"
              }`}
            >
              {editMode ? (
                <div>
                  <DeletePopconfirm
                    endPointName={words.orders.shipment.deleteShipmentInfo}
                    onConfirm={() => {
                      deleteShipmentForm();
                    }}
                  >
                    <Button danger className="!min-w[85px]">
                      {words.global.delete}
                    </Button>
                  </DeletePopconfirm>
                </div>
              ) : (
                ""
              )}
              <div className="flex gap-[16px] justify-end borderTop">
                {editMode ? (
                  <div className="!min-w-[85px] h-[100%]">
                    <Button type="default" onClick={onClickCancel}>
                      {words.global.cancel}
                    </Button>
                  </div>
                ) : (
                  <DPopconfirm
                    description={words.global.descriptionPopConfirm}
                    onConfirm={onClickReset}
                  >
                    <div className="!min-w-[85px] h-[100%]">
                      <Button type="default" disabled={isDisabledResetForm}>
                        {words.global.reset}
                      </Button>
                    </div>
                  </DPopconfirm>
                )}
                <Button
                  className="!min-w-[85px]"
                  type="primary"
                  onClick={() => {
                    addShipmentForm?.submit();
                  }}
                  // loading={loading}
                  disabled={isDisabledResetForm}
                >
                  {editMode || backEditMode
                    ? words.global.saveEdit
                    : words.global.next}
                </Button>
              </div>
            </div>
          </Col>
        </AddShipmentFormContainer>
      </Form>
    </ConfigProvider>
  );
};
