import "./styles/global.style.css";
import { Routers } from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AppContextProviders } from "./components/AppContextProviders";
import { useEffect } from "react";
import Api from "./constants/allApiPath.json";
import { USER_Language } from "./constants";
const App: React.FC = (): JSX.Element => {
  const queryClient = new QueryClient();

  // useEffect(() => {
  //   const a = Api.filter((i) => i.path.includes("api/BaseInfo/"));
  //   console.log({ a });
  // }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem(USER_Language);
    if (!savedLanguage) {
      localStorage.setItem(USER_Language, "Fa");
    }
  }, []);

  return (
    <AppContextProviders>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        <Routers />
      </QueryClientProvider>
    </AppContextProviders>
  );
};
export default App;
