import { FC, useCallback, useEffect, useState } from "react";
import { ViewViewWarehousingContainer } from "../style";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Col, Flex, Row, Skeleton } from "antd";
import { P2, S3 } from "src/components/UiKit/Typography";
import { OrderService } from "src/services/Order/order.service";
import { useParams } from "react-router-dom";
import { IGetWarehousing } from "src/services/Order/models/result.models";
import moment from "moment-jalaali";

export const ViewWarehousing: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [warehousingInfo, setWarehousingInfo] = useState<IGetWarehousing>();

  const getWarehousing = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetWarehousing } = new OrderService();
      const result = await GetWarehousing(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setWarehousingInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    getWarehousing();
  }, [getWarehousing]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">
        {words.orders.warehousing.viewAWarehousing}
      </div>
      <Row
        className="w-full bg-[#f2f9f9] py-[24px] px-[16px]"
        gutter={[16, 24]}
      >
        <Col span={6}>
          <S3> {words.basicInformation.warehouses.warehouse}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.warehousing.receiptNo}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.warehousing.date}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.warehousing.weight}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma
        headerName={words.orders.warehousing.viewAWarehousingHeader}
      />
      <Flex justify="center">
        <ViewViewWarehousingContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">
                {words.orders.warehousing.viewAWarehousing}
              </div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={6}>
                  <S3> {words.basicInformation.warehouses.warehouse}</S3>
                </Col>
                <Col span={6}>
                  <P2>{warehousingInfo?.warehouseName}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.warehousing.receiptNo}</S3>
                </Col>
                <Col span={6}>
                  <P2>{warehousingInfo?.receiptNo}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.warehousing.date}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {moment(warehousingInfo?.receiptDate).format(
                      "jYYYY-jMM-jDD"
                    )}
                  </P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.warehousing.weight}</S3>
                </Col>
                <Col span={6}>
                  <Flex gap={8}>
                    <P2>
                      {warehousingInfo?.receiptWeight.toLocaleString("en-US")}
                    </P2>
                    <P2>{warehousingInfo?.unitOfMeasureName}</P2>
                  </Flex>
                </Col>
              </Row>
            </>
          )}
        </ViewViewWarehousingContainer>
      </Flex>
    </div>
  );
};
