import { FC, useEffect, useMemo } from "react";
import { AgricultureContainer } from "./style";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Flex,
  Form,
  Row,
} from "antd";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";

import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import { IAddArgiculture } from "src/services/Order/models/args.models";
import fa_IR from "antd/lib/locale/fa_IR";
import { JalaliLocaleListener } from "antd-jalali";
import { useAgriculture } from "./context";
import { S2 } from "src/components/UiKit/Typography";

export const Agriculture: FC = () => {
  const {
    func: { add, update, getAgricultureInfo, deleteAgriculture },
    value: { editMode, loading, deleteLoading },
    dispatch: { setEditMode },
    form: { form },
  } = useAgriculture()!;
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const values = Form.useWatch([], form);

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };
  const onClickReset = () => {
    form?.resetFields();
  };

  const onFinish = (values: IAddArgiculture) => {
    if (editMode) {
      update(values);
    } else {
      add(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getAgricultureInfo();
  }, [getAgricultureInfo, isEdited]);
  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.agriculture.editAgricultureHeaderInfo
            : words.orders.agriculture.agricultureHeaderInfo
        }
      />
      <Flex justify="center">
        <AgricultureContainer gutter={24}>
          <Form form={form} onFinish={onFinish}>
            <Col span={24}>
              <S2 className="py-[16px]">
                {editMode
                  ? words.orders.editInfo
                  : words.orders.agriculture.agricultureInfo}
              </S2>
            </Col>
            <Col span={24} className="py-[16px]">
              <Row gutter={[24, 24]}>
                <Col span={8}>
                  <ConfigProvider locale={fa_IR}>
                    <JalaliLocaleListener />
                    <FormItem
                      name={"final"}
                      label={
                        words.orders.agriculture
                          .ministryOfAgricultureFinalApproval
                      }
                    >
                      <DatePicker placeholder={words.global.insert} />
                    </FormItem>
                  </ConfigProvider>
                </Col>
                <Col span={8}>
                  <ConfigProvider locale={fa_IR}>
                    <JalaliLocaleListener />
                    <FormItem
                      name={"tradingBbk"}
                      label={words.orders.agriculture.tenderingInBBK}
                    >
                      <DatePicker placeholder={words.global.insert} />
                    </FormItem>
                  </ConfigProvider>
                </Col>
                <Col span={8}>
                  <ConfigProvider locale={fa_IR}>
                    <JalaliLocaleListener />
                    <FormItem
                      name={"letter"}
                      label={
                        words.orders.agriculture
                          .ministryOfAgricultureApprovalLetter
                      }
                    >
                      <DatePicker placeholder={words.global.insert} />
                    </FormItem>
                  </ConfigProvider>
                </Col>
                <Col span={24} className="h-[80px]"></Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider dashed className="!border-[#BFE2E0]" />
              <div
                className={`flex py-[24px]  ${
                  editMode ? "justify-between" : "justify-end"
                }`}
              >
                {editMode ? (
                  <div className="!min-w-[85px]">
                    <DeletePopconfirm
                      endPointName={
                        words.orders.agriculture.deleteAgricultureInfo
                      }
                      onConfirm={() => {
                        deleteAgriculture();
                      }}
                      className="!min-w-[85px]"
                    >
                      <Button
                        danger
                        className="!min-w-[85px]"
                        loading={deleteLoading}
                      >
                        {words.global.delete}
                      </Button>
                    </DeletePopconfirm>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex gap-[16px] justify-end borderTop">
                  {editMode ? (
                    <div className="!min-w-[85px] h-[100%]">
                      <Button type="default" onClick={onClickCancel}>
                        {words.global.cancel}
                      </Button>
                    </div>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] h-[100%]">
                        <Button type="default" disabled={isDisabledResetForm}>
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  )}
                  <Button
                    className="!min-w-[85px]"
                    type="primary"
                    onClick={() => {
                      form?.submit();
                    }}
                    disabled={isDisabledResetForm}
                    loading={loading}
                  >
                    {editMode ? words.global.saveEdit : words.global.add}
                  </Button>
                </div>
              </div>
            </Col>
          </Form>
        </AgricultureContainer>
      </Flex>
    </div>
  );
};
