import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { TableData } from "../models";
import { Form, FormInstance, message } from "antd";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import useLanguage from "src/store/language";
import { IGetAllInspectionDocs } from "src/services/BaseInfo/models/result.models";

interface IContextInspectionDoc {
  value: {
    tableData: IGetAllInspectionDocs[];
    editMode: boolean;
    dataSource: TableData[];
    pagination: ITablePagination;
    productLoading: boolean;
    productId: number;
  };
  dispatch: {
    setTableData: Dispatch<SetStateAction<IGetAllInspectionDocs[]>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setProductId: Dispatch<SetStateAction<number>>;
  };
  func: {
    getInspectionDocTableData: () => void;
    deleteInspectionDoc: (id: number) => void;
  };
  form: { form: FormInstance | undefined };
}

const defaultCtxValue: IContextInspectionDoc = {
  value: {
    tableData: [],
    editMode: false,
    dataSource: [],
    pagination: { current: 0, pageSize: 0, total: 0 },
    productId: -1,
    productLoading: false,
  },
  dispatch: {
    setTableData: () => {},
    setEditMode: () => {},
    setPagination: () => {},
    setProductId: () => {},
  },
  func: {
    deleteInspectionDoc: (id: number) => {},
    getInspectionDocTableData: () => {},
  },
  form: { form: undefined },
};

export const InspectionDocContext =
  createContext<IContextInspectionDoc>(defaultCtxValue);

export const InspectionDocProvider: FC<PropsWithChildren> = ({ children }) => {
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const [tableData, setTableData] = useState<IGetAllInspectionDocs[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<IGetAllInspectionDocs[]>([]);
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  const [productId, setProductId] = useState<number>(-1);
  ///////////Functions////////////////////////////////////////////////////////////////
  const getInspectionDocTableData = useCallback(async () => {
    setProductLoading(true);
    const params =
      searchParams.toString() ||
      new URLSearchParams({
        Offset: pagination.current.toString(),
        Limit: pagination.pageSize.toString(),
      }).toString();
    try {
      const { GetAllInspectionDocs } = new BaseInfoService();
      const result = await GetAllInspectionDocs(params);
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const deleteInspectionDoc = async (id: number) => {
    setProductLoading(true);
    try {
      const { DeleteInspectionDoc } = new BaseInfoService();
      const result = await DeleteInspectionDoc(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.inspectionInfoDocs
            .deleteInspectionInfoDocsMessage
        );
        getInspectionDocTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };
  const contextValue: IContextInspectionDoc = {
    value: {
      tableData,
      editMode,
      dataSource,
      pagination,
      productId,
      productLoading,
    },
    dispatch: { setTableData, setEditMode, setPagination, setProductId },
    func: { deleteInspectionDoc, getInspectionDocTableData },
    form: { form },
  };
  return (
    <InspectionDocContext.Provider value={contextValue}>
      {children}
    </InspectionDocContext.Provider>
  );
};

export const useInspectionDoc = () => useContext(InspectionDocContext);
