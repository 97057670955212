import { Col, Flex, Row, Table } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { ViewBankingOperationsContainer } from "../../style";
import { T1 } from "src/components/UiKit/Typography";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import { IGetAllBankFs } from "src/services/Order/models/result.models";
import { OrderService } from "src/services/Order/order.service";
import { useParams, useSearchParams } from "react-router-dom";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import moment from "moment-jalaali";

export const ViewBankingOperations: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();

  const [allBankFs, setAllBankFs] = useState<IGetAllBankFs[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const onChangeParams = useChangeParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  ////////////////////////////////////////////////////////////////functions////////////////////////////////
  const getAllCustomFormalitys = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetAllBankFs } = new OrderService();
      const result = await GetAllBankFs(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setAllBankFs(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    Promise.allSettled([getAllCustomFormalitys()]);
  }, [getAllCustomFormalitys]);

  const Columns: ColumnsType<IGetAllBankFs> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.orders.bankingOperation.titleDocument,
      dataIndex: "bankDocTitle",
      key: "bankDocTitle",
      align: "center",
      width: "292px",
    },
    {
      title: words.orders.bankingOperation.date,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "292px",
      render: (text, record) =>
        record.date
          ? moment(record.date as string).format("jYYYY-jMM-jDD")
          : "---",
    },
  ];

  return (
    <div>
      <HeaderProforma
        headerName={words.orders.bankingOperation.viewBankingOperationHeader}
      />
      <Row className="w-full" justify={"center"}>
        <ViewBankingOperationsContainer span={13}>
          <Flex vertical gap={32}>
            <div className="px-[12px]">
              <T1>{words.orders.bankingOperation.viewBankingOperation}</T1>
            </div>
            <Row>
              <Col span={24} className="tableHolder">
                <Table
                  columns={Columns}
                  loading={loading}
                  scroll={{ x: "auto" }}
                  rowKey={getRowKey}
                  className="w-[100%]"
                  dataSource={allBankFs}
                  pagination={{
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                    total: pagination.total,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    onChange: (page: number, pageSize) => {
                      onChangeParams("Limit", pageSize.toString());
                      onChangeParams(
                        "Offset",
                        pageSize !== pagination.pageSize
                          ? "1"
                          : pageSize.toString()
                      );
                      setPagination((prev) => ({
                        ...prev,
                        current: pageSize !== prev.pageSize ? 1 : page,
                        pageSize,
                      }));
                    },
                  }}
                />
              </Col>
            </Row>
          </Flex>
        </ViewBankingOperationsContainer>
      </Row>
    </div>
  );
};
