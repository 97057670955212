import { FC, useEffect, useMemo } from "react";
import { AddProformaContainer } from "./style";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useOrder } from "../context";
import en_US from "antd/lib/locale/en_US";
import { JalaliLocaleListener } from "antd-jalali";
import { S2 } from "src/components/UiKit/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { inputNumberFormatter } from "src/helper/inputNumberFormatter";

export const AddProforma: FC = () => {
  const {
    value: {
      editMode,
      bank,
      bankLoading,
      currency,
      currencyLoading,
      product,
      productLoading,
      unit,
      unitLoading,
      addLoading,
    },
    dispatch: { setEditMode },
    func: {
      getBank,
      getCurrency,
      getProducts,
      getUnit,
      getProform,
      addProforma,
      updateProforma,
    },
    form: { form },
  } = useOrder()!;
  const { words } = useLanguage();
  const { Option } = Select;
  const values = Form.useWatch([], form);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    if (editMode) {
      navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      return;
    }

    setEditMode(false);
  };

  const onClickReset = () => {
    form?.resetFields();
  };

  const cfrValue = useMemo(() => {
    return +values?.freightUnitPmt + +values?.fobUnitPmt;
  }, [values]);
  useEffect(() => {
    if (cfrValue) {
      form?.setFieldValue("CFR", cfrValue.toString());
    }
  }, [cfrValue, form]);

  const onFinish = (values: any) => {
    if (editMode) {
      updateProforma(values);
    } else {
      addProforma(values);
    }
  };

  useEffect(() => {
    Promise.allSettled([getCurrency(), getProducts(), getUnit(), getBank()]);
  }, [getCurrency, getProducts, getUnit, getBank, editMode]);

  useEffect(() => {
    getProform();
  }, [getProform]);

  // useEffect(() => {
  //   form?.setFieldValue("standard", true);
  // }, [form, values]);
  return (
    <AddProformaContainer>
      <Form form={form} onFinish={onFinish}>
        <Col span={24}>
          <S2 className="py-[16px]">
            {editMode ? words.orders.editInfo : words.orders.newOrderInfo}
          </S2>
        </Col>
        <Col span={24} className="w-full">
          <Row gutter={[24, 24]} className="pt-[16px]">
            <Col span={8}>
              <FormItem
                name={"piNo"}
                label={words.orders.proformaNo}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onPressEnter={form?.submit}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <ConfigProvider locale={en_US}>
                <JalaliLocaleListener />
                <FormItem
                  name={"piDate"}
                  label={words.orders.proformaDate}
                  rules={[{ required: true }]}
                >
                  <DatePicker placeholder={words.global.insert} />
                </FormItem>
              </ConfigProvider>
            </Col>
            <Col span={8}>
              <FormItem
                name={"currencyId"}
                label={words.orders.currency}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={words.global.select}
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                  loading={currencyLoading}
                >
                  {currency?.map((item, index) => (
                    <Option key={index} value={item.id} label={item.shortName}>
                      <Space>{item.shortName}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"productId"}
                label={words.orders.product}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={words.global.select}
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                  loading={productLoading}
                >
                  {product?.map((item, index) => (
                    <Option key={index} value={item.id} label={item.name}>
                      <Space>{item.name}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <div className="wrapper !w-full">
                <FormItem
                  name={"unitOfMeasurementId"}
                  label={words.orders.weight}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={words.global.unit}
                    optionLabelProp="label"
                    showSearch
                    optionFilterProp="label"
                    loading={unitLoading}
                  >
                    {unit?.map((item, index) => (
                      <Option
                        key={index}
                        value={item.id}
                        label={item.shortName}
                      >
                        <Space>{item.shortName}</Space>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  name={"weight"}
                  label=" "
                  className="w-full"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <InputNumber
                    placeholder={words.orders.productWeight}
                    onPressEnter={form?.submit}
                    changeOnWheel={false}
                    controls={false}
                    className="w-full"
                  />
                </FormItem>
              </div>
            </Col>
            <Col span={8}>
              <FormItem
                name={"operationBankId"}
                label={words.orders.bank}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={words.global.select}
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                  loading={bankLoading}
                >
                  {bank?.map((item, index) => (
                    <Option key={index} value={item.id} label={item.bankName}>
                      <Space>{item.bankName}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"freightUnitPmt"}
                label={words.orders.freightPerTon}
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  onPressEnter={form?.submit}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"fobUnitPmt"}
                label={words.orders.FOB}
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  onPressEnter={form?.submit}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                  formatter={inputNumberFormatter}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name={"CFR"} label={words.orders.CFR}>
                <Input placeholder={words.orders.systemCalc} disabled />
              </FormItem>
            </Col>
            <Col span={16}>
              <div className="flex gap-[8px] items-center h-full ">
                <Typography className="h-full flex items-center mb-[24px]">
                  {words.orders.standard}
                </Typography>
                <FormItem
                  name={"standard"}
                  className="h-full items-center flex "
                  initialValue={true}
                >
                  <Radio.Group>
                    <Radio value={true}>{words.orders.has}</Radio>
                    <Radio value={false}>{words.orders.hasNot}</Radio>
                  </Radio.Group>
                </FormItem>
              </div>
            </Col>
            <Col span={8}>
              <FormItem
                name={"standardNo"}
                label={words.orders.standardNo}
                rules={[{ required: values?.standard === true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onPressEnter={form?.submit}
                  disabled={values?.standard === false}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Divider dashed className="!border-[#BFE2E0]" />
          <div className="flex gap-[16px] justify-end borderTop">
            {editMode ? (
              <Button
                type="default"
                className="!min-w[85px]"
                onClick={onClickCancel}
              >
                {words.global.cancel}
              </Button>
            ) : (
              <DPopconfirm
                description={words.global.descriptionPopConfirm}
                onConfirm={onClickReset}
              >
                <Button
                  type="default"
                  className="!min-w[85px]"
                  disabled={isDisabledResetForm}
                >
                  {words.global.reset}
                </Button>
              </DPopconfirm>
            )}
            <Button
              className="!min-w[85px]"
              type="primary"
              onClick={() => {
                form?.submit();
              }}
              loading={addLoading}
            >
              {editMode ? words.global.saveEdit : words.global.add}
            </Button>
          </div>
        </Col>
      </Form>
    </AddProformaContainer>
  );
};
