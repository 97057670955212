import { App, Col, Flex, Row, Skeleton, Spin, Table } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { ViewInsuranceContainer } from "../../style";
import { P2, S3, T1 } from "src/components/UiKit/Typography";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import {
  IGetInsuranceFile,
  IGetInsuranceFileDetail,
  IGetInsuranceForm,
} from "src/services/Order/models/result.models";
import { AxiosRequestConfig } from "axios";
import { OrderService } from "src/services/Order/order.service";
import downloadZipFile from "src/helper/downloadZipFile";
import { DocumentService } from "src/services/Document/document.service";
import { useParams, useSearchParams } from "react-router-dom";
import { docModalProps } from "src/helper/docModalProps";
import { LoadingOutlined } from "@ant-design/icons";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import moment from "moment-jalaali";

export const ViewInsurance: FC = () => {
  const { modal } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const onChangeParams = useChangeParams();
  const [insuranceFormInfo, setInsuranceFormInfo] =
    useState<IGetInsuranceForm>();
  const [progress, setProgress] = useState<number>(0);
  const [allInsuranceInfoLoading, setAllInsuranceInfoLoading] =
    useState<boolean>(false);
  const [allInsuranceInfo, setAllInsuranceInfo] = useState<
    IGetInsuranceFileDetail[]
  >([]);
  const [dlFileId, setDlFileId] = useState<number>();
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  const getInsuranceFormInfo = useCallback(async () => {
    if (!orderId) return undefined;
    try {
      const { GetInsuranceForm } = new OrderService();
      const result = await GetInsuranceForm(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setInsuranceFormInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const getAllInsuranceInfo = useCallback(async () => {
    if (!insuranceFormInfo) return undefined;
    setAllInsuranceInfoLoading(true);
    try {
      const { GetAllInsuranceFileDetails } = new OrderService();
      const result = await GetAllInsuranceFileDetails(insuranceFormInfo?.id);
      if (result && result.status === 200 && result.data) {
        setAllInsuranceInfo(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllInsuranceInfoLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceFormInfo?.id]);

  const GetFile = async (file: IGetInsuranceFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    try {
      const { GetInsuranceFile } = new OrderService();
      const result = await GetInsuranceFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      modal.confirm(docModalProps(words.orders.insurance.viewInsurance));
      console.log(words.orders.inspection.inspection);
    } finally {
      setProgress(0);
    }
  };

  const clause = (id: number | undefined) => {
    let clauseName;
    switch (id) {
      case 1:
        clauseName = "A";
        break;
      case 2:
        clauseName = "B";
        break;
      case 3:
        clauseName = "C";
        break;
    }
    return clauseName;
  };
  const Columns: ColumnsType<IGetInsuranceFileDetail> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "60px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.orders.insurance.title,
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "135px",
    },
    {
      title: words.orders.insurance.number,
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "135px",
      render: (text, record) => (record.number ? record.number : "---"),
    },
    {
      title: words.orders.insurance.date,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "255px",
      render: (text, record) =>
        record.date ? moment(record.date).format("jYYYY-jMM-jDD") : "---",
    },
    {
      title: words.global.tools,
      key: "id",
      width: "130px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              downloadAttachFile(record?.insuranceFileId);
              setDlFileId(record.insuranceFileId);
            }}
            className="material-icons text-[16px] !text-[#02514C] cursor-pointer"
          >
            {progress > 0 && record.insuranceFileId === dlFileId ? (
              <div className="relative w-[16px] h-[16px] flex justify-center items-center">
                <Spin indicator={<LoadingOutlined spin />} size="large" />
                <span className="absolute top-[20%] left-[5%] ">
                  {progress}
                </span>
              </div>
            ) : (
              "file_download"
            )}
          </span>
        </div>
      ),
    },
  ];
  const loadingMode = (
    <Flex vertical gap={32}>
      <div className="px-[12px]">
        <T1>{words.orders.insurance.viewInsurance}</T1>
      </div>
      <Row className="infoHolder" gutter={[0, 24]}>
        <Col span={6}>
          <S3> {words.orders.insurance.insuranceCo}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.insurance.insuranceNo}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.insurance.insuranceDate}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.insurance.insuranceClause}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
      </Row>
      <div className="px-[12px]">
        <T1>{words.orders.insurance.tableDesc}</T1>
      </div>
      <Row>
        <Col span={24} className="tableHolder">
          <Table
            columns={Columns}
            loading={allInsuranceInfoLoading}
            scroll={{ x: "auto" }}
            rowKey={getRowKey}
            className="w-[100%]"
            dataSource={allInsuranceInfo}
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: pagination.total,
              current: pagination.current,
              pageSize: pagination.pageSize,
              onChange: (page: number, pageSize) => {
                onChangeParams("Limit", pageSize.toString());
                onChangeParams(
                  "Offset",
                  pageSize !== pagination.pageSize ? "1" : pageSize.toString()
                );
                setPagination((prev) => ({
                  ...prev,
                  current: pageSize !== prev.pageSize ? 1 : page,
                  pageSize,
                }));
              },
            }}
          />
        </Col>
      </Row>
    </Flex>
  );

  useEffect(() => {
    Promise.allSettled([getInsuranceFormInfo(), getAllInsuranceInfo()]);
  }, [getInsuranceFormInfo, getAllInsuranceInfo]);
  return (
    <div>
      <HeaderProforma headerName={words.orders.insurance.viewInsuranceHeader} />
      <Row className="w-full" justify={"center"}>
        <ViewInsuranceContainer span={20}>
          {allInsuranceInfoLoading ? (
            loadingMode
          ) : (
            <Flex vertical gap={32}>
              <div className="px-[12px]">
                <T1>{words.orders.insurance.viewInsurance}</T1>
              </div>
              <Row className="infoHolder" gutter={[0, 24]}>
                <Col span={6}>
                  <S3> {words.orders.insurance.insuranceCo}</S3>
                </Col>
                <Col span={6}>
                  <P2>{insuranceFormInfo?.insuranceName}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.insurance.insuranceNo}</S3>
                </Col>
                <Col span={6}>
                  <P2>{insuranceFormInfo?.insuranceNo}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.insurance.insuranceDate}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {moment(insuranceFormInfo?.date).format("jYYYY-jMM-jDD")}
                  </P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.insurance.insuranceClause}</S3>
                </Col>
                <Col span={6}>
                  <P2>{clause(insuranceFormInfo?.insuranceClause)}</P2>
                </Col>
              </Row>
              <div className="px-[12px]">
                <T1>{words.orders.insurance.tableDesc}</T1>
              </div>
              <Row>
                <Col span={24} className="tableHolder">
                  <Table
                    columns={Columns}
                    loading={allInsuranceInfoLoading}
                    scroll={{ x: "auto" }}
                    rowKey={getRowKey}
                    className="w-[100%]"
                    dataSource={allInsuranceInfo}
                    pagination={{
                      position: ["bottomCenter"],
                      showSizeChanger: true,
                      total: pagination.total,
                      current: pagination.current,
                      pageSize: pagination.pageSize,
                      onChange: (page: number, pageSize) => {
                        onChangeParams("Limit", pageSize.toString());
                        onChangeParams(
                          "Offset",
                          pageSize !== pagination.pageSize
                            ? "1"
                            : pageSize.toString()
                        );
                        setPagination((prev) => ({
                          ...prev,
                          current: pageSize !== prev.pageSize ? 1 : page,
                          pageSize,
                        }));
                      },
                    }}
                  />
                </Col>
              </Row>
            </Flex>
          )}
        </ViewInsuranceContainer>
      </Row>
    </div>
  );
};
