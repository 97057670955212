import { FC, useCallback, useEffect, useState } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Col, Flex, Row, Skeleton } from "antd";
import { P2, S3, T1 } from "src/components/UiKit/Typography";
import { OrderService } from "src/services/Order/order.service";
import { useParams } from "react-router-dom";
import { IGetRate } from "src/services/Order/models/result.models";
import { ViewViewRatesContainer } from "../style";

export const ViewRates: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [ratesInfo, setRatesInfo] = useState<IGetRate>();

  const getRateInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetRate } = new OrderService();
      const result = await GetRate(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setRatesInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    getRateInfo();
  }, [getRateInfo]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">
        <T1>{words.orders.rates.viewRates}</T1>
      </div>
      <Row
        className="w-full bg-[#f2f9f9] py-[24px] px-[16px]"
        gutter={[16, 24]}
      >
        <Col span={6}>
          <S3> {words.orders.rates.fXPurchaseRate}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6}>
          <S3> {words.orders.rates.eTSFXRate}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6}>
          <S3> {words.orders.rates.CBI}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6}>
          <S3> {words.orders.rates.bankFeeFactor}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6}>
          <S3> {words.orders.rates.customsTariff}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6}>
          <S3> {words.orders.rates.approvedFreightCost}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6}>
          <S3> {words.orders.rates.newOrderFactor}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6}>
          <S3> {words.orders.rates.insuranceRate}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={6}>
          <S3> {words.orders.rates.freeDollarRate}</S3>
        </Col>
        <Col span={6}>
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma headerName={words.orders.rates.viewRatesHeader} />
      <Flex justify="center">
        <ViewViewRatesContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">
                <T1>{words.orders.rates.viewRates}</T1>
              </div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={6}>
                  <S3> {words.orders.rates.fXPurchaseRate}</S3>
                </Col>
                <Col span={6}>
                  <div className="flex gap-[4px]">
                    <P2>{ratesInfo?.fxPurchase.toLocaleString("en-US")}</P2>
                    <P2>{words.orders.rates.rial}</P2>
                  </div>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.rates.eTSFXRate}</S3>
                </Col>
                <Col span={6}>
                  <div className="flex gap-[4px]">
                    <P2>{ratesInfo?.ets.toLocaleString("en-US")}</P2>
                    <P2>{words.orders.rates.rial}</P2>
                  </div>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.rates.CBI}</S3>
                </Col>
                <Col span={6}>
                  <P2>{ratesInfo?.cbi.toLocaleString("en-US")}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.rates.bankFeeFactor}</S3>
                </Col>
                <Col span={6}>
                  <P2>{ratesInfo?.bankFee.toLocaleString("en-US")}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.rates.customsTariff}</S3>
                </Col>
                <Col span={6}>
                  <P2>{ratesInfo?.tariff.toLocaleString("en-US")}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.rates.approvedFreightCost}</S3>
                </Col>
                <Col span={6}>
                  <P2>{ratesInfo?.freightCost.toLocaleString("en-US")}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.rates.newOrderFactor}</S3>
                </Col>
                <Col span={6}>
                  <P2>{ratesInfo?.orderFee.toLocaleString("en-US")}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.rates.insuranceRate}</S3>
                </Col>
                <Col span={6}>
                  <P2>{ratesInfo?.insuranceRate.toLocaleString("en-US")}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.rates.freeDollarRate}</S3>
                </Col>
                <Col span={6}>
                  <div className="flex gap-[4px]">
                    <P2>{ratesInfo?.dollarRate.toLocaleString("en-US")}</P2>
                    <P2>{words.orders.rates.rial}</P2>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ViewViewRatesContainer>
      </Flex>
    </div>
  );
};
