import { Row } from "antd";
import { styled } from "styled-components";

export const LoginContainer = styled(Row)`
  display: flex;
  padding: 32px 32px 48px 32px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 32px;
  background: rgba(31, 31, 31, 0.8);

  /* 3 */
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
  .logo {
    display: flex;
    padding-top: 24px;
    justify-content: center;
    align-items: flex-start;
    img {
      height: 103px;
      /* width: 386px; */
    }
  }
  /* background: ${({ theme }) => theme.colors.white}; */

  .ant-input-prefix {
    margin-inline-end: -6px;
  }
  .input {
    padding-left: -100px !important;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    background: rgba(0, 140, 132, 0.4) !important;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 0px;
    height: 56px;
    color: ${({ theme }) => theme.colors.white};
    input::placeholder {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  .inputPassword {
    background: rgba(0, 39, 143, 0.4);
    border: 2px solid ${({ theme }) => theme.colors.white};
    background: rgba(0, 140, 132, 0.4) !important;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 8px;
    height: 56px;
    input {
      background: transparent;
      width: 100%;
      display: block;
      color: ${({ theme }) => theme.colors.white};
    }
    input::placeholder {
      color: ${({ theme }) => theme.colors.white};
    }
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  .input::placeholder {
    color: ${({ theme }) => theme.colors.white};
  }
  .button {
    width: 100%;
    color: #024a94;
    height: 51px;
    margin-top: 8px;
  }
  .captcha {
    width: 16vw;
    height: 57px;
    border-radius: 8px;
    border: 2px solid var(--color-Base-Color-White, #fff);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white} !important;
  }
`;
