import { Col, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import useLanguage from "src/store/language";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { getRowKey } from "src/helper/getRowKey";
import { UseOriginFinancial } from "../context";
import { IOriginFinancials } from "src/services/Order/models/result.models";
import { OriginFinancialTableContainer } from "../style";
import moment from "moment";
import dayjs from "dayjs";

export const OriginFinancialTable: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    dispatch: { setEditMode, setOriginFinancialId },
    value: { originFinancial, loading },
    func: { getAlllOriginFinancial, deleteOriginFinancial },
  } = UseOriginFinancial()!;

  const paymentOptionName = (key: number) => {
    let paymentOptName = "";
    switch (key) {
      case 0:
        paymentOptName = words.orders.originFinancial.commodityValue;
        break;
      case 1:
        paymentOptName = words.orders.originFinancial.freightCost;
        break;
      case 2:
        paymentOptName = words.orders.originFinancial.demurrage;
        break;
      case 3:
        paymentOptName = words.orders.originFinancial.fee;
        break;
      case 4:
        paymentOptName = words.orders.originFinancial.other;
        break;
    }
    return paymentOptName;
  };
  useEffect(() => {
    getAlllOriginFinancial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Columns: ColumnsType<IOriginFinancials> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.orders.originFinancial.payment,
      dataIndex: "payment",
      key: "payment",
      align: "center",
      width: "143px",
      render: (text, record) => paymentOptionName(record.payment),
    },
    {
      title: words.orders.originFinancial.paymentDate,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "158px",
      render: (text, record) => moment(record.date).format("YYYY-MM-DD"),
    },
    {
      title: words.orders.originFinancial.currency,
      dataIndex: "currencyName",
      key: "currencyName",
      align: "center",
      width: "109px",
    },
    {
      title: words.orders.originFinancial.amount,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "109px",
      render: (text, record) => record.amount.toLocaleString("en-US"),
    },
    {
      title: words.orders.originFinancial.rate,
      dataIndex: "rate",
      key: "rate",
      align: "center",
      width: "109px",
      render: (text, record) => record.rate.toLocaleString("en-US"),
    },
    {
      title: words.orders.originFinancial.CBI,
      dataIndex: "",
      key: "",
      align: "center",
      width: "109px",
      render: (text, record) => +(+record?.amount / +record?.rate)?.toFixed(6),
    },
    {
      title: words.orders.originFinancial.desc,
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "109px",
      render: (text, record) => (
        <Tooltip title={record.description}>
          <span className="material-icons text-[16px] text-[#02514C] cursor-pointer flex justify-center items-center">
            message
          </span>
        </Tooltip>
      ),
    },
    {
      title: words.global.tools,
      key: "id",
      width: "80px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setEditMode(true);
              setOriginFinancialId(record.id);
              const newData = { ...record, date: dayjs(record.date) };
              form?.setFieldsValue(newData);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>

          <DPopconfirm
            deleteClassName
            description={words.orders.originFinancial.deleteDesc}
            onConfirm={() => deleteOriginFinancial(record.id)}
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];

  return (
    <OriginFinancialTableContainer>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={loading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={originFinancial}
          pagination={false}
        />
      </Col>
    </OriginFinancialTableContainer>
  );
};
