import { FC } from "react";
import { BankingDOCHeader } from "./components/Header";
import { BankingDOCContainer } from "./style";
import { BankingDOCsTable } from "./components/Table";
import { AddNewBankingDOC } from "./components/AddNew";

export const BankingDOC: FC = () => {
  return (
    <div>
      <BankingDOCHeader />
      <div className="flex justify-center">
        <BankingDOCContainer>
          <AddNewBankingDOC />
          <BankingDOCsTable />
        </BankingDOCContainer>
      </div>
    </div>
  );
};
