import { FC } from "react";
import { AddOrder } from "src/modules/Add Order";
import { AddOrderProvider } from "src/modules/Add Order/context";

export const AddOrderPages: FC = () => {
  return (
    <AddOrderProvider>
      <AddOrder />
    </AddOrderProvider>
  );
};
