import { FC } from "react";
import { Col, Flex } from "antd";
import useLanguage from "src/store/language";
import { OrderManagementDetailsContainer } from "../style";
import { T2 } from "src/components/UiKit/Typography";
import { IGetProformMain } from "src/services/Order/models/result.models";
interface IProps {
  proformMain: IGetProformMain | null;
}
const OrderManagementDetails: FC<IProps> = ({ proformMain }) => {
  const { words } = useLanguage();

  return (
    <OrderManagementDetailsContainer gutter={24}>
      <Col span={6}>
        <Flex className="card">
          <span className="material-icons text-[#008F87] text-[24px]">
            store
          </span>
          <Flex vertical className="px-[16px] gap-[16px] w-full">
            <T2>{words.orders.rialProfit}</T2>
            <Flex justify="space-between">
              <T2 className="!text-[#008F87] text-[14px] flex justify-center items-center">
                RIAL
              </T2>
              <T2
                className="text-[16px] flex justify-center items-center !text-[#8E8E8E]"
                dir="ltr"
              >
                {proformMain?.profitRial.toLocaleString("en-US")}
              </T2>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col span={6}>
        <Flex className="card">
          <span className="material-icons text-[#008F87] text-[24px]">
            store
          </span>

          <Flex vertical className="px-[16px] gap-[16px] w-full">
            <T2>{words.orders.foreignProfit}</T2>
            <Flex justify="space-between">
              <T2 className="!text-[#008F87] text-[14px] flex justify-center items-center">
                USD
              </T2>
              <T2
                className="text-[16px] flex justify-center items-center !text-[#8E8E8E]"
                dir="ltr"
              >
                {proformMain?.profitUsd.toLocaleString("en-US")}
              </T2>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col span={6}>
        <Flex className="card">
          <span className="material-icons text-[#008F87] text-[24px]">
            monetization_on
          </span>
          <Flex vertical className="px-[16px] gap-[16px] w-full">
            <T2>{words.orders.currencyProfit}</T2>
            <Flex justify="space-between">
              <T2 className="!text-[#008F87] text-[14px] flex justify-center items-center">
                USD
              </T2>
              <T2
                className="text-[16px] flex justify-center items-center !text-[#8E8E8E]"
                dir="ltr"
              >
                {proformMain?.profitFx.toLocaleString("en-US")}
              </T2>
            </Flex>
          </Flex>
        </Flex>
      </Col>
    </OrderManagementDetailsContainer>
  );
};

export default OrderManagementDetails;
