import { App, Col, Flex, Row, Skeleton, Spin, Table } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { ViewShipmentContainer } from "../../style";
import { P2, S3, T1 } from "src/components/UiKit/Typography";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import {
  IGetShipment,
  IGetShipmentFile,
  IGetShipmentFileDetail,
} from "src/services/Order/models/result.models";
import { AxiosRequestConfig } from "axios";
import { OrderService } from "src/services/Order/order.service";
import downloadZipFile from "src/helper/downloadZipFile";
import { DocumentService } from "src/services/Document/document.service";
import { useParams, useSearchParams } from "react-router-dom";
import { docModalProps } from "src/helper/docModalProps";
import { LoadingOutlined } from "@ant-design/icons";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import moment from "moment-jalaali";

export const ViewShipment: FC = () => {
  const { modal } = App.useApp();
  const { words, isRtl } = useLanguage();
  const { orderId } = useParams();
  const [shipmentFormInfo, setShipmentFormInfo] = useState<IGetShipment>();
  const [progress, setProgress] = useState<number>(0);
  const [allShipmentInfo, setAllShipmentInfo] = useState<
    IGetShipmentFileDetail[]
  >([]);
  const [dlFileId, setDlFileId] = useState<number>();
  const [shipmentFormInfoLoading, setShipmentFormInfoLoading] =
    useState<boolean>(false);
  const [allShipmentInfoLoading, setAllShipmentInfoLoading] =
    useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  const onChangeParams = useChangeParams();
  const getShipmentFormInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setShipmentFormInfoLoading(true);
    try {
      const { GetShipment } = new OrderService();
      const result = await GetShipment(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setShipmentFormInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShipmentFormInfoLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const getAllShipmentInfo = useCallback(async () => {
    if (!shipmentFormInfo?.shipmentId) return undefined;
    setAllShipmentInfoLoading(true);
    try {
      const { GetAllShipmentFileDetails } = new OrderService();
      const result = await GetAllShipmentFileDetails(
        shipmentFormInfo?.shipmentId
      );
      if (result && result.status === 200 && result.data) {
        setAllShipmentInfo(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllShipmentInfoLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentFormInfo?.shipmentId]);

  const GetFile = async (file: IGetShipmentFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    try {
      const { GetShipmentFile } = new OrderService();
      const result = await GetShipmentFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      modal.confirm(docModalProps(words.orders.shipment.shipment));
    } finally {
      setProgress(0);
    }
  };

  const Columns: ColumnsType<IGetShipmentFileDetail> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "60px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.orders.shipment.doc,
      dataIndex: "shippingDocName",
      key: "shippingDocName",
      align: "center",
      width: "487px",
    },
    {
      title: words.global.tools,
      key: "id",
      width: "130px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              downloadAttachFile(record?.shipmentFileId);
              setDlFileId(record.shipmentFileId);
            }}
            className="material-icons text-[16px] !text-[#02514C] cursor-pointer"
          >
            {progress > 0 && record.shipmentFileId === dlFileId ? (
              <div className="relative w-[16px] h-[16px] flex justify-center items-center">
                <Spin indicator={<LoadingOutlined spin />} size="large" />
                <span className="absolute top-[20%] ">{progress}</span>
              </div>
            ) : (
              "file_download"
            )}
          </span>
        </div>
      ),
    },
  ];
  const loadingMode = (
    <Flex vertical gap={32}>
      <div className="px-[12px]">
        <T1>{words.orders.shipment.viewShipment}</T1>
      </div>
      <Row className="infoHolder" gutter={[0, 24]}>
        <Col span={6}>
          <S3> {words.orders.shipment.vesselName}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.voyageNo}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.landingPort}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.CPA}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.demurrageRate}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.dispatchRate}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.berthingLineUp}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.loadingOperation}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.receivingDraftsDocuments}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.sendingDraftsDocuments}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.weight}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.portDischarge}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
        <Col span={6}>
          <S3> {words.orders.shipment.date}</S3>
        </Col>
        <Col span={6}>
          <P2>
            <Skeleton.Input active className="!h-[22px]" />
          </P2>
        </Col>
      </Row>
      <div className="px-[12px]">
        <T1>{words.orders.shipment.tableDesc}</T1>
      </div>
      <Row>
        <Col span={24} className="tableHolder">
          <Table
            columns={Columns}
            loading={allShipmentInfoLoading}
            scroll={{ x: "auto" }}
            rowKey={getRowKey}
            className="w-[100%]"
            dataSource={allShipmentInfo}
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: pagination.total,
              current: pagination.current,
              pageSize: pagination.pageSize,
              onChange: (page: number, pageSize) => {
                onChangeParams("Limit", pageSize.toString());
                onChangeParams(
                  "Offset",
                  pageSize !== pagination.pageSize ? "1" : pageSize.toString()
                );
                setPagination((prev) => ({
                  ...prev,
                  current: pageSize !== prev.pageSize ? 1 : page,
                  pageSize,
                }));
              },
            }}
          />
        </Col>
      </Row>
    </Flex>
  );

  useEffect(() => {
    Promise.allSettled([getShipmentFormInfo(), getAllShipmentInfo()]);
  }, [getShipmentFormInfo, getAllShipmentInfo]);
  return (
    <div>
      <HeaderProforma headerName={words.orders.shipment.viewShipmentHeader} />
      <Row className="w-full" justify={"center"}>
        <ViewShipmentContainer span={20}>
          {shipmentFormInfoLoading ? (
            loadingMode
          ) : (
            <Flex vertical gap={32}>
              <div className="px-[12px]">
                <T1>{words.orders.shipment.viewShipment}</T1>
              </div>
              <Row className="infoHolder" gutter={[0, 24]}>
                <Col span={6}>
                  <S3> {words.orders.shipment.vesselName}</S3>
                </Col>
                <Col span={6}>
                  <P2>{shipmentFormInfo?.vesselName}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.voyageNo}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {shipmentFormInfo?.voyageNo
                      ? shipmentFormInfo?.voyageNo
                      : "---"}
                  </P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.landingPort}</S3>
                </Col>
                <Col span={6}>
                  <P2>{shipmentFormInfo?.portOfLoadingName}</P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.CPA}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {shipmentFormInfo?.cpa
                      ? words.orders.shipment.has
                      : words.orders.shipment.hasNot}
                  </P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.demurrageRate}</S3>
                </Col>
                <Col span={6}>
                  {shipmentFormInfo?.demurageRate &&
                  shipmentFormInfo?.demurageCurrancyName ? (
                    <Flex gap={8}>
                      <P2>{shipmentFormInfo?.demurageRate}</P2>
                      <P2>{shipmentFormInfo?.demurageCurrancyName}</P2>
                    </Flex>
                  ) : (
                    "---"
                  )}
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.dispatchRate}</S3>
                </Col>
                <Col span={6}>
                  {shipmentFormInfo?.dispatchRate &&
                  shipmentFormInfo?.dispatchCurrencyName ? (
                    <Flex gap={8}>
                      <P2>{shipmentFormInfo?.dispatchRate}</P2>
                      <P2>{shipmentFormInfo?.dispatchCurrencyName}</P2>
                    </Flex>
                  ) : (
                    "---"
                  )}
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.berthingLineUp}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {shipmentFormInfo?.berthingLineUp
                      ? moment(shipmentFormInfo?.berthingLineUp).format(
                          "jYYYY-jMM-jDD"
                        )
                      : "---"}
                  </P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.loadingOperation}</S3>
                </Col>
                <Col span={6} dir={isRtl ? "rtl" : "ltr"}>
                  {shipmentFormInfo?.loadingOperationStart &&
                  shipmentFormInfo?.loadingOperationEnd ? (
                    <Flex gap={8}>
                      <span>
                        {moment(shipmentFormInfo?.loadingOperationStart).format(
                          "jYYYY-jMM-jDD"
                        )}
                      </span>
                      <span>{words.global.until}</span>
                      <span>
                        {moment(shipmentFormInfo?.loadingOperationEnd).format(
                          "jYYYY-jMM-jDD"
                        )}
                      </span>
                    </Flex>
                  ) : (
                    "---"
                  )}
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.receivingDraftsDocuments}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {shipmentFormInfo?.reciveDraftDate
                      ? moment(shipmentFormInfo?.reciveDraftDate).format(
                          "jYYYY-jMM-jDD"
                        )
                      : "---"}
                  </P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.sendingDraftsDocuments}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {shipmentFormInfo?.sendDraftDate
                      ? moment(shipmentFormInfo?.sendDraftDate).format(
                          "jYYYY-jMM-jDD"
                        )
                      : "---"}
                  </P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.weight}</S3>
                </Col>
                <Col span={6}>
                  <Flex gap={8}>
                    <P2>
                      {shipmentFormInfo?.quality
                        ? shipmentFormInfo?.quality
                        : "---"}
                    </P2>
                    <P2>
                      {shipmentFormInfo?.unitName
                        ? shipmentFormInfo?.unitName
                        : "---"}
                    </P2>
                  </Flex>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.portDischarge}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {shipmentFormInfo?.portOfDischargeName
                      ? shipmentFormInfo?.portOfDischargeName
                      : "---"}
                  </P2>
                </Col>
                <Col span={6}>
                  <S3> {words.orders.shipment.date}</S3>
                </Col>
                <Col span={6}>
                  <P2>
                    {shipmentFormInfo?.blDate
                      ? moment(shipmentFormInfo?.blDate).format("jYYYY-jMM-jDD")
                      : "---"}
                  </P2>
                </Col>
              </Row>
              <div className="px-[12px]">
                <T1>{words.orders.shipment.tableDesc}</T1>
              </div>
              <Row>
                <Col span={24} className="tableHolder">
                  <Table
                    columns={Columns}
                    loading={allShipmentInfoLoading}
                    scroll={{ x: "auto" }}
                    rowKey={getRowKey}
                    className="w-[100%]"
                    dataSource={allShipmentInfo}
                    pagination={{
                      position: ["bottomCenter"],
                      showSizeChanger: true,
                      total: pagination.total,
                      current: pagination.current,
                      pageSize: pagination.pageSize,
                      onChange: (page: number, pageSize) => {
                        onChangeParams("Limit", pageSize.toString());
                        onChangeParams(
                          "Offset",
                          pageSize !== pagination.pageSize
                            ? "1"
                            : pageSize.toString()
                        );
                        setPagination((prev) => ({
                          ...prev,
                          current: pageSize !== prev.pageSize ? 1 : page,
                          pageSize,
                        }));
                      },
                    }}
                  />
                </Col>
              </Row>
            </Flex>
          )}
        </ViewShipmentContainer>
      </Row>
    </div>
  );
};
