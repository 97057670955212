export enum IInsuranceTabs {
  basicInfo = "1",
  addFile = "2",
}

interface IInsuranceClause {
  id: number;
  name: string;
}
export const InsuranceClause: IInsuranceClause[] = [
  {
    id: 1,
    name: "A",
  },
  {
    id: 2,
    name: "B",
  },
  {
    id: 3,
    name: "C",
  },
];
