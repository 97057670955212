import { FC } from "react";
import { ShippingDocHeader } from "./components/Header";
import { ShippingDocsContainer } from "./style";
import { ShippingDocsTable } from "./components/Table";
import { AddNewShippingDoc } from "./components/AddNew";

export const ShippingDoc: FC = () => {
  return (
    <div>
      <ShippingDocHeader />
      <div className="flex justify-center">
        <ShippingDocsContainer>
          <AddNewShippingDoc />
          <ShippingDocsTable />
        </ShippingDocsContainer>
      </div>
    </div>
  );
};
