import JSZip from "jszip";
import saveAs from "jszip/vendor/FileSaver";
import { getFileExtension } from "./getFileExtension";
import { Buffer } from "buffer";
interface IProps {
  buffer: Buffer;
  type: string;
  RequestZipFile: string;
}
const downloadZipFile = ({ buffer, type, RequestZipFile }: IProps) => {
  if (type.includes("zip")) {
    const blob = new Blob([buffer]);
    const zip = new JSZip();
    zip
      .loadAsync(blob, {
        decodeFileName: (byte) => {
          return byte.toString();
        },
      })
      .then((zipFile) => {
        zipFile.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, "zipFile");
        });
      });
  } else {
    let raw = Buffer.from(buffer).toString("base64");
    const bs = "data:" + type + ";base64," + raw;
    const el = document.createElement("a");
    el.href = bs;
    // const extension = getFileExtension(type);
    el.download = RequestZipFile;
    el.click();
  }
};
export default downloadZipFile;
