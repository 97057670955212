import { Button, Divider, Form, Input, Modal, ModalProps } from "antd";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { FormItem } from "../FormItem";
import { H6, S2 } from "../Typography";
import useLanguage from "src/store/language";
export interface IProps extends ModalProps {
  open: boolean;
  title: string;
  label: string;
  handleOk: (values: string) => void;
  confirmLoading: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  confirmLOading: boolean;
}
interface IFormValues {
  inspectionDocId: string;
}
export const DataEntryModal: FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const values = Form.useWatch([]);
  const { words } = useLanguage();
  const {
    open,
    title,
    label,
    confirmLoading,
    setIsModalOpen,
    handleOk,
    confirmLOading,
  } = props;
  const [submittable, setSubmittable] = useState<boolean>(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    form?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <Modal
      title={
        <div className="mb-[32px]">
          <H6 className="pt-[8px] pb-[16px]">{title}</H6>
          <Divider className="border-[#BFE2E0]" />
        </div>
      }
      open={open}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      closable={false}
      width={580}
      footer={
        <div className="flex justify-center content-center gap-[16px] footerHolder">
          <Button
            type="primary"
            className="!min-w-[155px]"
            onClick={form.submit}
            rootClassName={submittable ? "disable" : "enable"}
            loading={confirmLOading}
          >
            <span className="material-icons text-[#FFFFFF] text-[24px]">
              check_circle_outline
            </span>
            <S2 className="!text-[#FFFFFF]">{words.global.save}</S2>
          </Button>
          <Button className="!min-w-[155px]" onClick={handleCancel}>
            <span className="material-icons text-[24px]">highlight_off</span>
            <S2>{words.global.cancel}</S2>
          </Button>
        </div>
      }
      afterClose={() => {
        form.resetFields();
      }}
    >
      <DataEntryModalContainer>
        <Form
          form={form}
          onFinish={(value: IFormValues) => {
            handleOk(value?.inspectionDocId);
          }}
          className="form"
        >
          <FormItem
            name={"inspectionDocId"}
            label={label}
            rules={[{ required: true }]}
            required={false}
            className="!mb-0 w-[75%]"
          >
            <Input placeholder={words.global.insert} />
          </FormItem>
        </Form>
      </DataEntryModalContainer>
    </Modal>
  );
};

const DataEntryModalContainer = styled.div`
  .form {
    display: flex;
    padding: 24px 25px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    background: var(--color-Base-Color-White, #fff);
    border-radius: 12px;
    border: 1px solid var(--color-Stroke-footer-buttons, #bfe2e0);
    margin-bottom: 32px;
  }
`;
