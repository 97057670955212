import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body * :not(.material-icons , .material-symbols-outlined){
  font-family: vazir, DM Sans !important;
}
/* .selectOption{
    .ant-select-selector{
        background: #f4f7fe !important; 
  border-color: #f4f7fe !important;
  border-radius: 12px !important;
  min-height: 48px;
  display: flex;
  align-items: center;
    }
   input{
    margin-top: 8px !important;
   }
}

.ant-input-disabled{
  border-color: #F3F4F5 !important;
}
.ant-picker-disabled {
    background: #dadeec !important;
    border-color: #dadeec !important;
  }
.ant-divider-with-text::before{
  border-block-start-color:#2C73FC !important ;
}
.unset-modal-padding{
  .ant-modal-content{
    padding: unset !important;
  }
}

.ant-modal-wrap .ant-modal.confirm-modal .ant-modal-content .ant-modal-footer {
  width: 100%;
  display: inline-flex;
  align-items: center;
}
.ant-select-arrow {
    margin-top: unset !important;
  } */
  /* width */
  ::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FEF6F1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#FAD1B8 ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#F06614;
}
.ant-btn{
  min-width: 85px;
}
.ant-pagination-item-active{
  border-color:#008C84 !important;
  a{  color: #008C84 !important;
}
}
.ant-pagination{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn-dashed{
  color: #008C84 !important;
  border-color: #008C84 !important;
}
.ant-form-item{
  margin-bottom: 24px !important;
}
.ant-form{
  width: 100%;
}
.ant-table-expanded-row-fixed{
  background-color: #F2F9F9;
}
.ant-picker{
  width: 100% !important;
}
.ant-btn-default:disabled{
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border-color: ${({ theme }) => theme.colors.gray[100]};
  color: ${({ theme }) => theme.colors.gray[400]} ;
}
.ant-modal-content{
  border-radius: 20px !important;
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
}

.descHolder{
  width: 30%;
  .ant-popconfirm-message-text{
    width: 100%;
  }
.ant-popconfirm-description{
  display: flex;
  padding: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--color-Stroke-footer-buttons, #bfe2e0);}
  .ant-popconfirm-buttons{
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    gap:12px  }
  .ant-btn{
    width: 80px;
    height: 40px;
  }
  .ant-btn-dangerous{
    padding: unset;
  }
  .ant-popconfirm-inner-content{
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
}
.swal2-html-container{
    direction: ${(props) => (props.theme.isRtl ? "rtl" : "ltr")};
  }
  .ant-input-password:focus-within{
box-shadow: none;
border-color: #f5f5f5 !important;
background-color:#f5f5f5 !important;
}
input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-background-clip: unset !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: unset !important;
  }
  .docModalProp{
    .ant-modal-confirm-btns{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    }
    .ant-btn-default {
      height: 56px;
      border-radius: 8px;
      background: var(--color-Gray-Gray-100, #F5F5F5);
      padding: unset !important;
      border-radius: 8px;
      border: unset !important;
    }
    .ant-btn-default:hover {
      height: 56px;
      border-radius: 8px;
      background:  #E1E1E1 !important;
      padding: unset !important;
      border-radius: 8px;
      border: unset !important;
    }
    .ant-modal-confirm-content{
      font-family: "Vazir FD";
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;
