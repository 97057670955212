import { FC, useEffect, useMemo } from "react";
import { AddOrderContainer } from "./style";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { FormItem } from "src/components/UiKit/FormItem";
import { FXReserve } from "./model";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import { UseAddOrder } from "./context";
import { IAddOrderRegister } from "src/services/Order/models/args.models";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { S2 } from "src/components/UiKit/Typography";

export const AddOrder: FC = () => {
  const {
    func: { add, update, getOrderInfo, deleteOrder },
    value: { editMode, loading },
    dispatch: { setEditMode },
    form: { form },
  } = UseAddOrder()!;
  const { words } = useLanguage();
  const { Option } = Select;
  const values = Form.useWatch([], form);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };

  const onClickReset = () => {
    form?.resetFields();
  };

  const onFinish = (values: IAddOrderRegister) => {
    if (editMode) {
      update(values);
    } else {
      add(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getOrderInfo();
  }, [getOrderInfo, isEdited]);
  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.addOrder.editOrderHeaderInfo
            : words.orders.addOrder.addOrderHeaderInfo
        }
      />
      <Flex justify="center">
        <AddOrderContainer>
          <Form form={form} onFinish={onFinish}>
            <Col span={24}>
              <S2 className="py-[16px]">
                {editMode
                  ? words.orders.addOrder.editOrderInfo
                  : words.orders.addOrder.addOrderInfo}
              </S2>
            </Col>
            <Col span={24} className="w-full">
              <Row gutter={[24, 24]} className="py-[16px]">
                <Col span={12}>
                  <ConfigProvider locale={fa_IR}>
                    <JalaliLocaleListener />
                    <FormItem
                      name={"registerDate"}
                      label={words.orders.addOrder.issueDate}
                      rules={[{ required: true }]}
                    >
                      <DatePickerJalali placeholder={words.global.insert} />
                    </FormItem>
                  </ConfigProvider>
                </Col>
                <Col span={12}>
                  <ConfigProvider locale={fa_IR}>
                    <FormItem
                      name={"validatyDate"}
                      label={words.orders.addOrder.validityDate}
                      rules={[{ required: true }]}
                    >
                      <DatePickerJalali placeholder={words.global.insert} />
                    </FormItem>
                  </ConfigProvider>
                </Col>
                <Col span={12}>
                  <FormItem
                    name={"fxReserve"}
                    label={words.orders.addOrder.currencySupply}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                    >
                      {FXReserve?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.name}>
                          <Space>{item.name}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    name={"registerNo"}
                    label={words.orders.addOrder.registrationNumber}
                    rules={[
                      { required: true },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (value.toString().length !== 8) {
                            return Promise.reject(
                              new Error(words.orders.addOrder["8Digits"])
                            );
                          } else return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      placeholder={words.global.insert}
                      onPressEnter={form?.submit}
                      changeOnWheel={false}
                      controls={false}
                      className="w-full"
                      stringMode
                    />
                  </FormItem>
                </Col>
                <Col span={24} className="h-[104px]"></Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider dashed className="!border-[#BFE2E0]" />
              <div
                className={`flex py-[24px]  ${
                  editMode ? "justify-between" : "justify-end"
                }`}
              >
                {editMode ? (
                  <div>
                    <DeletePopconfirm
                      endPointName={words.orders.addOrder.deleteOrderInfo}
                      onConfirm={() => {
                        deleteOrder();
                      }}
                    >
                      <Button danger className="!min-w[85px]">
                        {words.global.delete}
                      </Button>
                    </DeletePopconfirm>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex gap-[16px] justify-end borderTop">
                  {editMode ? (
                    <div className="!min-w-[85px] h-[100%]">
                      <Button type="default" onClick={onClickCancel}>
                        {words.global.cancel}
                      </Button>
                    </div>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] h-[100%]">
                        <Button type="default" disabled={isDisabledResetForm}>
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  )}
                  <Button
                    className="!min-w-[85px]"
                    type="primary"
                    onClick={() => {
                      form?.submit();
                    }}
                    loading={loading}
                    disabled={isDisabledResetForm}
                  >
                    {editMode ? words.global.saveEdit : words.global.add}
                  </Button>
                </div>
              </div>
            </Col>
          </Form>
        </AddOrderContainer>
      </Flex>
    </div>
  );
};
