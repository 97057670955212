import { App, Form, UploadFile } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { IAddCustomFormality } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import { FormInstance } from "antd/lib";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IGetAllCustomOperations } from "src/services/BaseInfo/models/result.models";
import { OrderUrl } from "src/services/Order/urls";
import { RcFile } from "antd/es/upload";
import { uploadFile } from "src/helper/uploadZipFile";
import {
  IGetAllCustomFormalitys,
  IGetCustomFormalityFile,
} from "src/services/Order/models/result.models";
import { format } from "date-fns";
import { AxiosRequestConfig } from "axios";
import { DocumentService } from "src/services/Document/document.service";
import downloadZipFile from "src/helper/downloadZipFile";
import { docModalProps } from "src/helper/docModalProps";
import { ITablePagination } from "src/models/interfaces/pagination";

interface ILoadings {
  form: boolean;
  getAll: boolean;
  addDoc: boolean;
  delete: boolean;
  allCustomFormalitys: boolean;
  add: boolean;
  update: boolean;
  allCustomOperations: boolean;
  addCustomOperation: boolean;
  downloadAttachFile: boolean;
}
interface IContext {
  value: {
    editMode: boolean;
    loadings: ILoadings;
    hasFile: boolean;
    file: UploadFile | undefined;
    progress: number;
    tableEditMode: boolean;
    allCustomFormalitys: IGetAllCustomFormalitys[];
    customsFormalitiesFormId: number | undefined;
    openModal: boolean;
    allCustomOperations: IGetAllCustomOperations[];
    pagination: ITablePagination;
    uploadProgress: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setFile: Dispatch<SetStateAction<UploadFile | undefined>>;
    setTableEditMode: Dispatch<SetStateAction<boolean>>;
    setCustomsFormalitiesFormId: Dispatch<SetStateAction<number | undefined>>;
    setHasFile: Dispatch<SetStateAction<boolean>>;
    setOpenMOdal: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    deleteCustomFormality: (id: number) => void;
    add: (values: IAddCustomFormality) => void;
    update: (values: IAddCustomFormality) => void;
    getAllCustomFormalitys: () => void;
    AddCustomOperation: (value: string) => void;
    downloadAttachFile: (id?: number) => void;
    getCustomFormality: (id: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
  };
}
export const CustomsFormalitiesContext = createContext<IContext | undefined>(
  undefined
);

export const CustomsFormalitiesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { message, modal } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [allCustomOperations, setAllCustomOperations] = useState<
    IGetAllCustomOperations[]
  >([]);
  const [allCustomFormalitys, setAllCustomFormalitys] = useState<
    IGetAllCustomFormalitys[]
  >([]);
  const [file, setFile] = useState<UploadFile>();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [tableEditMode, setTableEditMode] = useState<boolean>(false);
  const [customsFormalitiesFormId, setCustomsFormalitiesFormId] = useState<
    number | undefined
  >();
  const [openModal, setOpenMOdal] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  const [loadings, setLoadings] = useState<ILoadings>({
    form: false,
    getAll: false,
    addDoc: false,
    delete: false,
    allCustomFormalitys: false,
    add: false,
    update: false,
    allCustomOperations: false,
    addCustomOperation: false,
    downloadAttachFile: false,
  });
  ////////////////////////////////functions////////////////////////////////
  const getAllCustomOperations = useCallback(async () => {
    setLoadings((prev) => ({ ...prev, allCustomOperations: true }));
    try {
      const { GetAllCustomOperations } = new BaseInfoService();
      const response = await GetAllCustomOperations();
      if (response && response.status === 200 && response.data) {
        setAllCustomOperations(response.data.records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadings((prev) => ({ ...prev, allCustomOperations: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.NewCustomFormalityFile}`
        ),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setUploadProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const add = async (values: IAddCustomFormality) => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, add: true }));
    try {
      const { AddCustomFormality } = new OrderService();
      const newValues = {
        ...values,
        date: values.date
          ? format(new Date(values.date as string), "yyyy-MM-dd")
          : null,
      };
      const result = await AddCustomFormality(parseInt(orderId), newValues);
      if (result && result.status === 200 && result.data) {
        if (file) {
          const resultFile = await newFile(result.data);
          if (resultFile) {
            message.success(
              words.orders.customsFormalities.customsFormalitiesMessage
            );
            getAllCustomFormalitys();
            form.resetFields();
            setHasFile(false);
            setFile(undefined);
          }
        } else {
          message.success(
            words.orders.customsFormalities.customsFormalitiesMessage
          );
          getAllCustomFormalitys();
          form.resetFields();
          setHasFile(false);
          setFile(undefined);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, add: false }));
    }
  };

  const updateFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.UpdateCustomFormalityFile}`
        ),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setUploadProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (values: IAddCustomFormality) => {
    if (!customsFormalitiesFormId) return undefined;
    setLoadings((prev) => ({ ...prev, update: true }));
    try {
      const { UpdateCustomFormality } = new OrderService();
      const newValues = {
        ...values,
        date: values.date
          ? format(new Date(values.date as string), "yyyy-MM-dd")
          : null,
      };
      const result = await UpdateCustomFormality(
        customsFormalitiesFormId,
        newValues
      );
      if (result && result.status === 200) {
        if (file) {
          let resultFile;
          if (hasFile) {
            resultFile = await updateFile(customsFormalitiesFormId);
          } else {
            resultFile = await newFile(customsFormalitiesFormId);
          }
          if (resultFile) {
            message.success(
              words.orders.customsFormalities.updateCustomsFormalitiesMessage
            );
            getAllCustomFormalitys();
            form.resetFields();
            setHasFile(false);
            setFile(undefined);
            setTableEditMode(false);
          }
        } else {
          message.success(
            words.orders.customsFormalities.updateCustomsFormalitiesMessage
          );
          getAllCustomFormalitys();
          form.resetFields();
          setHasFile(false);
          setFile(undefined);
          setTableEditMode(false);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, update: false }));
    }
  };

  const getAllCustomFormalitys = useCallback(async () => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, allCustomFormalitys: true }));
    try {
      const { GetAllCustomFormalitys } = new OrderService();
      const result = await GetAllCustomFormalitys(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setAllCustomFormalitys(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, allCustomFormalitys: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const getCustomFormality = async (id: number) => {
    if (!id) return undefined;
    try {
      const { GetCustomFormality } = new OrderService();
      const result = await GetCustomFormality(id);
      if (result && result.status === 200 && result.data) {
        setHasFile(result.data.hasFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const deleteCustomFormality = async (id: number) => {
    if (!id) return undefined;
    setLoadings((prev) => ({ ...prev, delete: true }));
    try {
      const { DeleteCustomFormality } = new OrderService();
      const result = await DeleteCustomFormality(id);
      if (result && result.status === 200) {
        message.success(
          words.orders.customsFormalities.deleteCustomsFormalitiesMessage
        );
        getAllCustomFormalitys();
      }
    } catch (err) {
    } finally {
      setLoadings((prev) => ({ ...prev, delete: true }));
    }
  };

  const AddCustomOperation = useCallback(async (value: string) => {
    setLoadings((prev) => ({ ...prev, addCustomOperation: true }));
    try {
      const { AddCustomOperation } = new BaseInfoService();
      const result = await AddCustomOperation({ title: value });
      if (result && result.status === 200) {
        message.success(
          words.orders.customsFormalities.addCustomsFormalitiesDocMessage
        );
        setOpenMOdal(false);
        getAllCustomOperations();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, addCustomOperation: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetFile = async (file: IGetCustomFormalityFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    setLoadings((prev) => ({ ...prev, downloadAttachFile: true }));
    try {
      const { GetCustomFormalityFile } = new OrderService();
      const result = await GetCustomFormalityFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      modal.confirm(
        docModalProps(words.orders.customsFormalities.customsFormalities)
      );
      console.log(words.orders.customsFormalities.customsFormalities);
    } finally {
      setProgress(0);
      setLoadings((prev) => ({ ...prev, downloadAttachFile: false }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  useEffect(() => {
    Promise.allSettled([getAllCustomFormalitys(), getAllCustomOperations()]);
  }, [getAllCustomOperations, getAllCustomFormalitys]);
  const defaultValue: IContext = {
    value: {
      editMode,
      loadings,
      file,
      hasFile,
      progress,
      tableEditMode,
      allCustomFormalitys,
      customsFormalitiesFormId,
      openModal,
      allCustomOperations,
      pagination,
      uploadProgress,
    },
    dispatch: {
      setEditMode,
      setFile,
      setTableEditMode,
      setCustomsFormalitiesFormId,
      setHasFile,
      setOpenMOdal,
      setPagination,
    },
    func: {
      add,
      deleteCustomFormality,
      getAllCustomFormalitys,
      update,
      AddCustomOperation,
      downloadAttachFile,
      getCustomFormality,
    },
    form: { form },
  };

  return (
    <CustomsFormalitiesContext.Provider value={defaultValue}>
      {children}
    </CustomsFormalitiesContext.Provider>
  );
};

export const UseCustomsFormalities = () =>
  useContext(CustomsFormalitiesContext);
