import { FC } from "react";
import { Vip } from "src/modules/Vip";
import { VipContextProvider } from "src/modules/Vip/context";

export const VipPages: FC = () => {
  return (
    <VipContextProvider>
      <Vip />
    </VipContextProvider>
  );
};
