import { FC } from "react";
import { BankingOperations } from "src/modules/BankingOperations";
import { BankingOperationsProvider } from "src/modules/BankingOperations/context";

export const BankingOperationsPage: FC = () => {
  return (
    <BankingOperationsProvider>
      <BankingOperations />
    </BankingOperationsProvider>
  );
};
