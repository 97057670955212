import { FC, useCallback, useEffect, useState } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Button, Col, Divider, Flex, Row, Skeleton, Spin } from "antd";
import { useParams } from "react-router-dom";
import {
  IGetCustomDeclaration,
  IGetCustomDeclarationFile,
} from "src/services/Order/models/result.models";
import { OrderService } from "src/services/Order/order.service";
import { AxiosRequestConfig } from "axios";
import { DocumentService } from "src/services/Document/document.service";
import downloadZipFile from "src/helper/downloadZipFile";
import dayjs from "dayjs";
import { ViewCustomDeclarationContainer } from "../style";
import { LoadingOutlined } from "@ant-design/icons";

export const ViewCustomDeclaration: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [customDeclarationInfo, setCustomDeclarationInfo] =
    useState<IGetCustomDeclaration>();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [getFileLoading, setGetFileLoading] = useState<boolean>(false);

  const getCustomDeclarationInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetCustomDeclaration } = new OrderService();
      const result = await GetCustomDeclaration(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setCustomDeclarationInfo(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const GetFile = async (file: IGetCustomDeclarationFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    setGetFileLoading(true);
    try {
      const { GetCustomDeclarationFile } = new OrderService();
      const result = await GetCustomDeclarationFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
      setGetFileLoading(false);
    }
  };

  useEffect(() => {
    getCustomDeclarationInfo();
  }, [getCustomDeclarationInfo]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">
        {words.orders.customDeclaration.viewCustomDeclarationInformation}
      </div>
      <Row className="proformaDataHolder" gutter={[16, 24]}>
        <Col span={8} className="!font-medium">
          {words.orders.customDeclaration.customsDeclarationNo}
        </Col>
        <Col span={16} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Col span={8} className="!font-medium">
          {words.orders.customDeclaration.customsDeclarationDate}
        </Col>
        <Col span={16} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input active className="!h-[22px]" />
        </Col>
        <Divider dashed className="!border-[#BFE2E0]" />
        <Col span={24}>
          <div className="flex gap-[16px]">
            <div className="text-[14px] w-[150px]">
              {words.orders.customDeclaration.customDeclarationDocument}
            </div>
            <div className="flex gap-[16px]">
              <div>
                <img src="/assets/images/fileUploaded.svg" alt="fileUploaded" />
              </div>
              <div className="flex flex-col gap-[6px] justify-center">
                <div className="text-[14px]">
                  {words.orders.origin.fileUploaded}
                </div>
                <div className="text-[10px]">{words.orders.origin.dlFile}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma
        headerName={words.orders.customDeclaration.viewCustomDeclarationInfo}
      />
      <Flex justify="center">
        <ViewCustomDeclarationContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">
                {
                  words.orders.customDeclaration
                    .viewCustomDeclarationInformation
                }
              </div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={8} className="!font-medium">
                  {words.orders.customDeclaration.customsDeclarationNo}
                </Col>
                <Col span={16} className="!font-normal text-[#4B4B4B]">
                  {customDeclarationInfo?.declarationNo}
                </Col>
                <Col span={8} className="!font-medium">
                  {words.orders.customDeclaration.customsDeclarationDate}
                </Col>
                <Col span={16} className="!font-normal text-[#4B4B4B]">
                  {customDeclarationInfo?.declarationDate
                    ? dayjs(customDeclarationInfo?.declarationDate).format(
                        "YYYY-MM-DD"
                      )
                    : undefined}
                </Col>
                <Divider dashed className="!border-[#BFE2E0]" />
                <Col span={24}>
                  <div className="flex gap-[16px]">
                    <div className="text-[14px] w-[150px]">
                      {words.orders.origin.originalDocuments}
                    </div>

                    {
                      <div className="flex gap-[16px] ">
                        <Button
                          className={
                            customDeclarationInfo?.hasFile
                              ? ""
                              : "cursor-default"
                          }
                          onClick={
                            customDeclarationInfo?.hasFile
                              ? () =>
                                  downloadAttachFile(customDeclarationInfo?.id)
                              : () => {}
                          }
                          // loading={
                          //   customDeclarationInfo?.hasFile
                          //     ? progress > 0
                          //       ? true
                          //       : false
                          //     : false
                          // }
                        >
                          {(customDeclarationInfo?.hasFile && progress > 0) ||
                          getFileLoading ? (
                            <div className="relative w-[66px] h-[66px] flex justify-center items-center">
                              <Spin
                                indicator={<LoadingOutlined spin />}
                                size="large"
                              />
                              <span className="absolute top-[38%] left-[40%] ">
                                {progress}
                              </span>
                            </div>
                          ) : (
                            <img
                              src={
                                customDeclarationInfo?.hasFile
                                  ? "/assets/images/fileUploaded.svg"
                                  : "/assets/images/fileNotUpload.svg"
                              }
                              alt="fileUploaded"
                            />
                          )}
                        </Button>
                        <div className="flex flex-col gap-[6px] justify-center">
                          <div className="text-[14px]">
                            {customDeclarationInfo?.hasFile
                              ? words.orders.origin.fileUploaded
                              : words.orders.origin.fileNotUploaded}
                          </div>
                          <div className="text-[10px]">
                            {customDeclarationInfo?.hasFile
                              ? words.orders.origin.dlFile
                              : words.orders.origin.cantDlFile}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ViewCustomDeclarationContainer>
      </Flex>
    </div>
  );
};
