import { Col, Table } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { S2 } from "src/components/UiKit/Typography";
import useLanguage from "src/store/language";
import { ColumnsType } from "antd/es/table";
import { IGetPODFinancial } from "src/services/Order/models/result.models";
import { FinancialTableContainer } from "../style";
import { OrderService } from "src/services/Order/order.service";
import { useNavigate, useParams } from "react-router-dom";
import { uniqueId } from "lodash";
import { ROUTES } from "src/models/enums/routes";
interface IData {
  title: string;
  amount: number | undefined;
  systemCalc: number | undefined | string;
}
export const FinancialTable: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [podFinancial, setPodFinancial] = useState<IGetPODFinancial>();
  const navigate = useNavigate();

  const getPODFinancial = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetPODFinancial } = new OrderService();
      const result = await GetPODFinancial(+orderId);
      if (result && result.status === 200 && result.data) {
        setPodFinancial(result.data);
      }
    } catch (err) {
      navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const data: IData[] = useMemo(() => {
    return [
      {
        title: words.orders.pod.FX,
        amount: podFinancial?.bankFx,
        systemCalc: podFinancial?.calcBankFx,
      },
      {
        title: words.orders.pod.bankFee,
        amount: podFinancial?.bankFee,
        systemCalc: podFinancial?.calcBankFee,
      },
      {
        title: words.orders.pod.importDuty,
        amount: podFinancial?.importDuty,
        systemCalc: podFinancial?.calcImportDuty,
      },
      {
        title: words.orders.pod.warehousing,
        amount: podFinancial?.warehousing,
        systemCalc: "---",
      },
      {
        title: words.orders.pod.standard,
        amount: podFinancial?.standardRate,
        systemCalc: podFinancial?.calcStandardRate,
      },
      {
        title: words.orders.pod.flag,
        amount: podFinancial?.flagFee,
        systemCalc: podFinancial?.calcFlagFee,
      },
      {
        title: words.orders.pod.orderRegistration,
        amount: podFinancial?.orderRegisterFee,
        systemCalc: podFinancial?.calcOrderRegisterFee,
      },
      {
        title: words.orders.pod.insurance,
        amount: podFinancial?.insurancePolicyFee,
        systemCalc: podFinancial?.calcInsurancePolicyFee,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podFinancial]);

  const Columns: ColumnsType<IData> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.orders.pod.title,
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "227px",
    },
    {
      title: words.orders.pod.amount,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "225px",
      render: (text: number) => text?.toLocaleString("en-US"),
    },
    {
      title: words.orders.pod.systemCalculation,
      dataIndex: "systemCalc",
      key: "systemCalc",
      align: "center",
      width: "240px",
      render: (text: number) => text?.toLocaleString("en-US"),
    },
    {
      title: words.orders.pod.contradiction,
      key: "difference",
      align: "center",
      width: "240px",
      render: (name, record, index) => {
        if (
          record.amount === undefined ||
          record.systemCalc === undefined ||
          record.systemCalc === "---"
        )
          return "---";
        const calc = +record.amount - +record.systemCalc;
        return (
          <span className={`${calc > 0 ? "danger" : "success"}`}>
            {calc.toLocaleString("en-US")}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    getPODFinancial();
  }, [getPODFinancial]);
  return (
    <FinancialTableContainer gutter={[0, 24]}>
      <Col span={24}>
        <S2>{words.orders.pod.tableDesc}</S2>
      </Col>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={loading}
          scroll={{ x: "auto" }}
          rowKey={() => uniqueId()}
          className="w-[100%]"
          dataSource={data}
          pagination={false}
        />
      </Col>
    </FinancialTableContainer>
  );
};
