import { Row } from "antd";
import styled from "styled-components";

export const CreateUserContainer = styled(Row)`
  .header {
    border-bottom: 1px solid var(--shadow, #dadeec);
    display: flex;
    padding: 0 24px 24px 24px;
    align-items: center;
    gap: 10px;
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  .content {
    border-radius: 20px;
    background: var(--0, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 24px 32px;
  }
  .title {
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  .input-password {
    /* background: ${({ theme }) => theme.gra}; */
    border-color: #f4f7fe;
    border-radius: 12px;
    min-height: 48px;
    input {
      background: transparent;
      width: 100%;
      display: block;
      color: #000;
    }
  }
`;
