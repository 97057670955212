import { Col, Divider, Popover, Row, Table } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import { IGetPODFinancialResult } from "src/services/Order/models/result.models";
import { FinancialTableContainer } from "../style";
import { P2, S2, T2 } from "src/components/UiKit/Typography";
import { useParams } from "react-router-dom";
import { OrderService } from "src/services/Order/order.service";
interface IData {
  title: string;
  amount: number | undefined;
  costPerKG: number | undefined;
}
export const FinalTable: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [podFinancial, setPodFinancial] = useState<IGetPODFinancialResult>();

  const getPODFinancial = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetPODFinancialResult } = new OrderService();
      const result = await GetPODFinancialResult(+orderId);
      if (result && result.status === 200 && result.data) {
        setPodFinancial(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const data: IData[] = useMemo(() => {
    return [
      {
        title: words.orders.pod.FX,
        amount: podFinancial?.bankFx,
        costPerKG: podFinancial?.calcBankFx,
      },
      {
        title: words.orders.pod.bankFee,
        amount: podFinancial?.bankFee,
        costPerKG: podFinancial?.calcBankFee,
      },
      {
        title: words.orders.pod.importDuty,
        amount: podFinancial?.importDuty,
        costPerKG: podFinancial?.calcImportDuty,
      },
      {
        title: words.orders.pod.warehousing,
        amount: podFinancial?.warehousing,
        costPerKG: podFinancial?.calcWarehousing,
      },
      {
        title: words.orders.pod.standard,
        amount: podFinancial?.standardRate,
        costPerKG: podFinancial?.calcStandardRate,
      },
      {
        title: words.orders.pod.flag,
        amount: podFinancial?.flagFee,
        costPerKG: podFinancial?.calcFlagFee,
      },
      {
        title: words.orders.pod.orderRegistration,
        amount: podFinancial?.orderRegisterFee,
        costPerKG: podFinancial?.calcOrderRegisterFee,
      },
      {
        title: words.orders.pod.insurance,
        amount: podFinancial?.insurancePolicyFee,
        costPerKG: podFinancial?.calcInsurancePolicyFee,
      },
      {
        title: words.orders.pod.total,
        amount: podFinancial?.other,
        costPerKG: podFinancial?.calcOther,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podFinancial]);

  const Columns: ColumnsType<IData> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.orders.pod.descripting,
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "227px",
    },
    {
      title: words.orders.pod.amountOfFee,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "225px",
      render: (text: number) => text?.toLocaleString("en-US"),
    },
    {
      title: words.orders.pod.costPerKG,
      dataIndex: "costPerKG",
      key: "costPerKG",
      align: "center",
      width: "240px",
      // render: (text: number) =>
      //   text === 0
      //     ? "0"
      //     : +(+text.toString().replaceAll("e-8", "")).toLocaleString("en-US", {
      //         maximumFractionDigits: 6,
      //       }),
    },
  ];

  useEffect(() => {
    getPODFinancial();
  }, [getPODFinancial]);
  const calcTotal = podFinancial?.calcTotal.toLocaleString("en-US", {
    maximumFractionDigits: 6,
  });
  return (
    <FinancialTableContainer gutter={[0, 24]}>
      <Col span={24} className="py-[8px]">
        <Row gutter={12} justify={"end"}>
          <Col span={8}>
            <S2 className="py-[8px] w-[150px]">
              {words.orders.pod.completedPriceTable}
            </S2>
          </Col>
          <Col span={8}>
            <div className="totalHolder">
              <T2 className="!text-[#008F87] w-[130px]">
                {words.orders.pod.totalExpenses}
              </T2>
              <Divider
                type="vertical"
                className="!border-[#CACACA] border-s-[2px] rounded h-[24px]"
              />
              <T2 className="text-[#044440] ">
                {podFinancial?.total.toLocaleString("en-US", {
                  maximumFractionDigits: 6,
                })}
              </T2>
              <P2 className="text-[#044440]">{words.global.rial}</P2>
            </div>
          </Col>
          <Col span={8}>
            <div className="totalHolder">
              <T2 className="!text-[#008F87] w-[170px]">
                {words.orders.pod.pricePerKG}
              </T2>
              <Divider
                type="vertical"
                className="!border-[#CACACA] border-s-[2px] rounded h-[24px]"
              />
              <T2 className="text-[#044440]">
                {calcTotal && calcTotal?.toString()?.length > 9 ? (
                  <Popover content={calcTotal}>
                    <span dir="ltr">
                      {calcTotal.toString().substring(0, 9).concat("...")}
                    </span>
                  </Popover>
                ) : (
                  podFinancial?.calcTotal.toLocaleString("en-US", {
                    maximumFractionDigits: 6,
                  })
                )}
              </T2>
              <P2 className="text-[#044440]">{words.global.rial}</P2>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={loading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={data}
          pagination={false}
        />
      </Col>
    </FinancialTableContainer>
  );
};
