import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { FC, useEffect, useMemo } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { S2 } from "src/components/UiKit/Typography";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { UseInsurance } from "../context";
import useLanguage from "src/store/language";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { InsuranceClause } from "../models";
import { IAddInsuranceForm } from "src/services/Order/models/args.models";

export const AddInsuranceForm: FC = () => {
  const {
    form: { addInsuranceForm },
    value: { editMode, insuranceCo, insuranceCoLoading, backEditMode },
    dispatch: { setEditMode },
    func: { addForm, updateForm, deleteInsuranceForm, getInsuranceFormInfo },
  } = UseInsurance()!;
  const { words } = useLanguage();
  const { Option } = Select;
  const values = Form.useWatch([], addInsuranceForm);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };

  const onClickReset = () => {
    addInsuranceForm?.resetFields();
  };

  const onFinish = (values: IAddInsuranceForm) => {
    if (editMode || backEditMode) {
      updateForm(values);
    } else {
      addForm(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getInsuranceFormInfo();
  }, [getInsuranceFormInfo, isEdited]);

  return (
    <Form form={addInsuranceForm} onFinish={onFinish}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <S2 className="py-[16px]">
            {editMode
              ? words.orders.insurance.editInsuranceInfo
              : words.orders.insurance.insuranceInfo}
          </S2>
        </Col>
        <Col span={24} className="w-full">
          <Row gutter={[24, 24]} className="py-[16px]">
            <Col span={12}>
              <FormItem
                name={"insuranceId"}
                label={words.orders.insurance.insuranceCo}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={words.global.select}
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                  loading={insuranceCoLoading}
                >
                  {insuranceCo?.map((item, index) => (
                    <Option key={index} value={item.id} label={item.name}>
                      <Space>{item.name}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name={"insuranceNo"}
                label={words.orders.insurance.insuranceNo}
                rules={[{ required: true }]}
              >
                <Input placeholder={words.global.insert} />
              </FormItem>
            </Col>
            <Col span={12}>
              <ConfigProvider locale={fa_IR}>
                <JalaliLocaleListener />
                <FormItem
                  name={"date"}
                  label={words.orders.insurance.insuranceDate}
                  rules={[{ required: true }]}
                >
                  <DatePickerJalali placeholder={words.global.insert} />
                </FormItem>
              </ConfigProvider>
            </Col>
            <Col span={12}>
              <FormItem
                name={"insuranceClause"}
                label={words.orders.insurance.insuranceClause}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={words.global.select}
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                >
                  {InsuranceClause?.map((item, index) => (
                    <Option key={index} value={item.id} label={item.name}>
                      <Space>{item.name}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={24} className="h-[104px]"></Col>
          </Row>
        </Col>
        <Col span={24}>
          <Divider dashed className="!border-[#BFE2E0]" />
          <div
            className={`flex py-[24px]  ${
              editMode ? "justify-between" : "justify-end"
            }`}
          >
            {editMode ? (
              <div>
                <DeletePopconfirm
                  endPointName={words.orders.insurance.deleteInsuranceInfo}
                  onConfirm={() => {
                    deleteInsuranceForm();
                  }}
                >
                  <Button danger className="!min-w[85px]">
                    {words.global.delete}
                  </Button>
                </DeletePopconfirm>
              </div>
            ) : (
              ""
            )}
            <div className="flex gap-[16px] justify-end borderTop">
              {editMode ? (
                <div className="!min-w-[85px] h-[100%]">
                  <Button type="default" onClick={onClickCancel}>
                    {words.global.cancel}
                  </Button>
                </div>
              ) : (
                <DPopconfirm
                  description={words.global.descriptionPopConfirm}
                  onConfirm={onClickReset}
                >
                  <div className="!min-w-[85px] h-[100%]">
                    <Button type="default" disabled={isDisabledResetForm}>
                      {words.global.reset}
                    </Button>
                  </div>
                </DPopconfirm>
              )}
              <Button
                className="!min-w-[85px]"
                type="primary"
                onClick={() => {
                  addInsuranceForm?.submit();
                }}
                // loading={loading}
                disabled={isDisabledResetForm}
              >
                {editMode || backEditMode
                  ? words.global.saveEdit
                  : words.global.next}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
