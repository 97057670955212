import { FC } from "react";
import { OriginFinancialForm } from "./components/OriginFinancialForm";
import { OriginFinancialContainer } from "./style";
import { Row } from "antd";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { UseOriginFinancial } from "./context";
import { OriginFinancialTable } from "./components/OriginFinancialTable";

export const OriginFinancial: FC = () => {
  const {
    value: { editMode },
  } = UseOriginFinancial()!;
  const { words } = useLanguage();

  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.originFinancial.editOriginFinancialHeaderInfo
            : words.orders.originFinancial.originFinancialHeaderInfo
        }
      />
      <Row justify={"center"}>
        <OriginFinancialContainer span={22}>
          <OriginFinancialForm />
          <OriginFinancialTable />
        </OriginFinancialContainer>
      </Row>
    </div>
  );
};
