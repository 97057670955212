import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
} from "antd";
import { JalaliLocaleListener } from "antd-jalali";
import TextArea from "antd/es/input/TextArea";
import { FC, useEffect, useMemo } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { P2, S2, T2 } from "src/components/UiKit/Typography";
import { IAddPODFinancialOther } from "src/services/Order/models/args.models";
import { OtherInfoContainer } from "../../style";
import fa_IR from "antd/lib/locale/fa_IR";
import { usePOD } from "../../context";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { OtherInfoTable } from "./Table";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { IPODTabs } from "../../models";
import { Dayjs } from "dayjs";
import moment from "moment-jalaali";
import { omit } from "lodash";
interface IFormValue {
  date: Dayjs;
  amount: number;
  description: string;
  remarks: string;
}
export const OtherInfo: FC = () => {
  const {
    value: { editMode, podFinancialOther, tableEditMode, podOtherId, loadings },
    dispatch: { setEditMode, setActiveKey, setTableEditMode },
    form: { OtherInfoForm },
    func: { addOther, updatePODOther },
  } = usePOD()!;
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const values = Form.useWatch([], OtherInfoForm);
  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    if (tableEditMode) {
      setTableEditMode(false);
      OtherInfoForm?.resetFields();
      return;
    }
    setEditMode(false);
    OtherInfoForm?.resetFields();
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };
  const onClickBack = () => {
    setActiveKey(IPODTabs.basicInfo);
  };

  const onClickReset = () => {
    OtherInfoForm?.resetFields();
  };

  const CBIValue = useMemo(() => {
    return +values?.amount / +values?.rate;
  }, [values]);

  useEffect(() => {
    if (CBIValue) {
      OtherInfoForm?.setFieldValue("CBI", CBIValue.toString());
    }
  }, [CBIValue, OtherInfoForm]);

  const onFinish = (values: IFormValue) => {
    const newValue: IAddPODFinancialOther = {
      date: moment(
        (values.date as Dayjs).format("YYYY-MM-DD"),
        "jYYYY-jMM-jDD"
      ).format("YYYY-MM-DD"),
      ...omit(values, "date"),
    };
    if ((editMode || tableEditMode) && podOtherId) {
      updatePODOther(newValue);
    } else {
      addOther(newValue);
    }
  };

  return (
    <Form
      form={OtherInfoForm}
      onFinish={onFinish}
      className="flex gap-[24px] flex-col"
    >
      <OtherInfoContainer>
        <Col span={24}>
          <S2 className="pt-[24px] pb-[16px]">
            {editMode
              ? words.orders.pod.editPodOtherHeaderInfo
              : words.orders.pod.podInfo}
          </S2>
        </Col>
        <Col span={24} className="w-full">
          <Row gutter={[24, 0]} className="py-[16px]">
            <Col span={8}>
              <FormItem
                name={"description"}
                label={words.orders.pod.descripting}
                rules={[{ required: true }]}
              >
                <Input placeholder={words.global.insert} />
              </FormItem>
            </Col>
            <Col span={8}>
              <ConfigProvider locale={fa_IR}>
                <JalaliLocaleListener />
                <FormItem
                  name={"date"}
                  label={words.orders.pod.date}
                  rules={[{ required: true }]}
                >
                  <DatePicker placeholder={words.global.select} />
                </FormItem>
              </ConfigProvider>
            </Col>
            <Col span={8}>
              <FormItem
                name={"amount"}
                label={words.orders.pod.amountOther}
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem name={"remarks"} label={words.orders.pod.remarks}>
                <TextArea
                  className="!min-h-[90px]"
                  placeholder={words.global.description}
                  onPressEnter={OtherInfoForm?.submit}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="h-[104px] flex items-center justify-end">
          {editMode || tableEditMode ? (
            <div className="!min-w-[85px] h-[100%] gap-[16px] flex">
              <Button type="default" onClick={onClickCancel}>
                {words.global.cancel}
              </Button>
              <Button
                className="!min-w-[85px]"
                type="primary"
                onClick={() => {
                  OtherInfoForm?.submit();
                }}
                loading={loadings.updatePODOther}
                disabled={isDisabledResetForm}
              >
                {words.global.saveEdit}
              </Button>
            </div>
          ) : (
            <div className="flex gap-[16px] !w-full">
              <div className="flex justify-between !w-full">
                <Button type="default" onClick={onClickBack}>
                  {words.global.back}
                </Button>
                <DPopconfirm
                  description={words.global.descriptionPopConfirm}
                  onConfirm={onClickReset}
                >
                  <div className="!min-w-[85px] h-[100%]">
                    <Button type="default" disabled={isDisabledResetForm}>
                      {words.global.reset}
                    </Button>
                  </div>
                </DPopconfirm>
              </div>
              <div className="flex justify-between !w-full">
                <Button
                  className="!min-w-[85px]"
                  type="primary"
                  onClick={() => {
                    OtherInfoForm?.submit();
                  }}
                  disabled={isDisabledResetForm}
                  loading={loadings.addForm}
                >
                  {words.global.add}
                </Button>
                <Button
                  type="primary"
                  ghost
                  className="!min-w-[85px]"
                  onClick={() => {
                    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
                  }}
                >
                  {words.global.end}
                </Button>
              </div>
            </div>
          )}
        </Col>
        <Col span={24} className="py-[8px]">
          <Row>
            <Col span={18}>
              <S2 className="py-[8px]">{words.orders.pod.total}</S2>
            </Col>
            <Col span={6}>
              <div className="totalHolder">
                <T2 className="!text-[#008F87]">{words.orders.pod.total}</T2>
                <Divider
                  type="vertical"
                  className="!border-[#CACACA] border-s-[2px] rounded h-[24px]"
                />
                <T2 className="text-[#044440]">
                  {podFinancialOther?.total.toLocaleString("en-US") || 0}
                </T2>
                <P2 className="text-[#044440]">{words.global.rial}</P2>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <OtherInfoTable />
        </Col>
      </OtherInfoContainer>
    </Form>
  );
};
