import { App, Form } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { IAddBankF } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import { FormInstance } from "antd/lib";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IGetAllBankDocs } from "src/services/BaseInfo/models/result.models";
import { format } from "date-fns";
import { IGetAllBankFs } from "src/services/Order/models/result.models";
import { ITablePagination } from "src/models/interfaces/pagination";

interface ILoadings {
  delete: boolean;
  allBankFs: boolean;
  add: boolean;
  update: boolean;
  allBankDocs: boolean;
  addBankDoc: boolean;
}
interface IContext {
  value: {
    editMode: boolean;
    loadings: ILoadings;
    pagination: ITablePagination;
    tableEditMode: boolean;
    allBankFs: IGetAllBankFs[];
    bankId: number | undefined;
    openModal: boolean;
    allBankDocs: IGetAllBankDocs[];
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setTableEditMode: Dispatch<SetStateAction<boolean>>;
    setBankId: Dispatch<SetStateAction<number | undefined>>;
    setOpenMOdal: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    deleteBankF: (id: number) => void;
    add: (values: IAddBankF) => void;
    update: (values: IAddBankF) => void;
    getAllBankFs: () => void;
    addBankDoc: (value: string) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
  };
}
export const BankingOperationsContext = createContext<IContext | undefined>(
  undefined
);

export const BankingOperationsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [allBankDocs, setAllBankDocs] = useState<IGetAllBankDocs[]>([]);
  const [allBankFs, setAllBankFs] = useState<IGetAllBankFs[]>([]);
  const [tableEditMode, setTableEditMode] = useState<boolean>(false);
  const [bankId, setBankId] = useState<number | undefined>();
  const [openModal, setOpenMOdal] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  const [loadings, setLoadings] = useState<ILoadings>({
    delete: false,
    allBankFs: false,
    add: false,
    update: false,
    allBankDocs: false,
    addBankDoc: false,
  });
  ////////////////////////////////functions////////////////////////////////
  const getAllBankDocs = useCallback(async () => {
    setLoadings((prev) => ({ ...prev, allCustomOperations: true }));
    try {
      const { GetAllBankDocs } = new BaseInfoService();
      const response = await GetAllBankDocs();
      if (response && response.status === 200 && response.data) {
        setAllBankDocs(response.data.records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadings((prev) => ({ ...prev, allCustomOperations: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add = async (values: IAddBankF) => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, add: true }));
    try {
      const { AddBankF } = new OrderService();
      const newValues = {
        ...values,
        date: values.date
          ? format(new Date(values.date as string), "yyyy-MM-dd")
          : null,
      };
      const result = await AddBankF(parseInt(orderId), newValues);
      if (result && result.status === 200 && result.data) {
        message.success(words.orders.bankingOperation.bankingOperationMessage);
        getAllBankFs();
        form.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, add: false }));
    }
  };

  const update = async (values: IAddBankF) => {
    if (!bankId) return undefined;
    setLoadings((prev) => ({ ...prev, update: true }));
    try {
      const { UpdateBankF } = new OrderService();
      const newValues = {
        ...values,
        date: values.date
          ? format(new Date(values.date as string), "yyyy-MM-dd")
          : null,
      };
      const result = await UpdateBankF(bankId, newValues);
      if (result && result.status === 200) {
        message.success(
          words.orders.bankingOperation.updateBankingOperationMessage
        );
        getAllBankFs();
        form.resetFields();
        setTableEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, update: false }));
    }
  };

  const getAllBankFs = useCallback(async () => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, allBankFs: true }));
    try {
      const { GetAllBankFs } = new OrderService();
      const result = await GetAllBankFs(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setAllBankFs(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, allBankFs: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const deleteBankF = async (id: number) => {
    if (!id) return undefined;
    setLoadings((prev) => ({ ...prev, delete: true }));
    try {
      const { DeleteBankF } = new OrderService();
      const result = await DeleteBankF(id);
      if (result && result.status === 200) {
        message.success(
          words.orders.bankingOperation.deleteBankingOperationMessage
        );
        getAllBankFs();
      }
    } catch (err) {
    } finally {
      setLoadings((prev) => ({ ...prev, delete: true }));
    }
  };

  const addBankDoc = useCallback(async (value: string) => {
    setLoadings((prev) => ({ ...prev, AddBankDoc: true }));
    try {
      const { AddBankDoc } = new BaseInfoService();
      const result = await AddBankDoc({ title: value });
      if (result && result.status === 200) {
        message.success(
          words.orders.bankingOperation.addBankingOperationDocMessage
        );
        setOpenMOdal(false);
        getAllBankDocs();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, AddBankDoc: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  useEffect(() => {
    Promise.allSettled([getAllBankFs(), getAllBankDocs()]);
  }, [getAllBankDocs, getAllBankFs]);

  const defaultValue: IContext = {
    value: {
      editMode,
      loadings,
      tableEditMode,
      allBankFs,
      bankId,
      openModal,
      allBankDocs,
      pagination,
    },
    dispatch: {
      setEditMode,
      setTableEditMode,
      setBankId,
      setOpenMOdal,
      setPagination,
    },
    func: {
      add,
      deleteBankF,
      getAllBankFs,
      update,
      addBankDoc,
    },
    form: { form },
  };

  return (
    <BankingOperationsContext.Provider value={defaultValue}>
      {children}
    </BankingOperationsContext.Provider>
  );
};

export const useBankingOperations = () => useContext(BankingOperationsContext);
