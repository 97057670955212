import { Col, Divider, Row, Table } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import {
  IGetAllPODFinancialOthers,
  IGetPODFinancialOther,
} from "src/services/Order/models/result.models";
import { FinancialTableContainer } from "../style";
import { P2, S2, T2 } from "src/components/UiKit/Typography";
import { OrderService } from "src/services/Order/order.service";
import { useParams } from "react-router-dom";
import moment from "moment-jalaali";

export const OtherInfoTable: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [podFinancialOther, setPodFinancialOther] =
    useState<IGetAllPODFinancialOthers>();

  const getAllPODFinancialOther = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetAllPODFinancialOthers } = new OrderService();
      const result = await GetAllPODFinancialOthers(+orderId);
      if (result && result.status === 200 && result.data) {
        setPodFinancialOther(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const Columns: ColumnsType<IGetPODFinancialOther> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.orders.pod.title,
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "274px",
    },
    {
      title: words.orders.pod.date,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "274px",
      render: (text) => (text ? moment(text).format("jYYYY.jMM.jDD") : "--"),
    },
    {
      title: words.orders.pod.amountOther,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "255px",
      render: (amount: number) => amount?.toLocaleString("en-US"),
    },
  ];

  useEffect(() => {
    getAllPODFinancialOther();
  }, [getAllPODFinancialOther]);
  return (
    <FinancialTableContainer gutter={[0, 24]}>
      <Col span={24} className="py-[8px]">
        <Row>
          <Col span={18}>
            <S2 className="py-[8px]">{words.orders.pod.total}</S2>
          </Col>
          <Col span={6}>
            <div className="totalHolder">
              <T2 className="!text-[#008F87]">{words.orders.pod.total}</T2>
              <Divider
                type="vertical"
                className="!border-[#CACACA] border-s-[2px] rounded h-[24px]"
              />
              <T2 className="text-[#044440]">
                {podFinancialOther?.total.toLocaleString("en-US")}
              </T2>
              <P2 className="text-[#044440]">{words.global.rial}</P2>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={loading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={podFinancialOther?.podFinancials}
          pagination={false}
        />
      </Col>
    </FinancialTableContainer>
  );
};
