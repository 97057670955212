import { App, Form, UploadFile } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fixNumbers } from "src/helper/fixNumber";
import {
  IAddInsuranceFileDetail,
  IAddInsuranceForm,
} from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { FormInstance } from "antd/lib";
import { IInsuranceTabs } from "./models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAllInsuranceResult } from "src/services/BaseInfo/models/result.models";
import moment from "moment";
import { uploadFile } from "src/helper/uploadZipFile";
import { OrderUrl } from "src/services/Order/urls";
import { RcFile } from "antd/es/upload";
import {
  IGetInsuranceFile,
  IGetInsuranceFileDetail,
} from "src/services/Order/models/result.models";
import { ROUTES } from "src/models/enums/routes";
import { docModalProps } from "src/helper/docModalProps";
import { AxiosRequestConfig } from "axios";
import { DocumentService } from "src/services/Document/document.service";
import downloadZipFile from "src/helper/downloadZipFile";
import { ITablePagination } from "src/models/interfaces/pagination";

interface IContext {
  value: {
    editMode: boolean;
    loading: boolean;
    deleteLoading: boolean;
    activeKey: string;
    insuranceCoLoading: boolean;
    insuranceCo: IAllInsuranceResult[];
    hasFile: boolean;
    file: UploadFile | undefined;
    progress: number;
    tableEditMode: boolean;
    allInsuranceInfo: IGetInsuranceFileDetail[];
    allInsuranceInfoLoading: boolean;
    backEditMode: boolean;
    pagination: ITablePagination;
    uploadProgress: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setActiveKey: Dispatch<SetStateAction<string>>;
    setFile: Dispatch<SetStateAction<UploadFile | undefined>>;
    setTableEditMode: Dispatch<SetStateAction<boolean>>;
    setInsuranceFileId: Dispatch<SetStateAction<number | undefined>>;
    setHasFile: Dispatch<SetStateAction<boolean>>;
    setBackEditMode: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    deleteInsuranceForm: () => void;
    addForm: (values: IAddInsuranceForm) => void;
    updateForm: (values: IAddInsuranceForm) => void;
    getInsuranceFormInfo: () => void;
    addFileDetail: (values: IAddInsuranceFileDetail) => void;
    updateFileDetail: (values: IAddInsuranceFileDetail) => void;
    deleteInsuranceDetail: (id: number) => void;
    getAllInsuranceInfo: () => void;
    downloadAttachFile: (id?: number) => void;
    getInsuranceFileDetail: (id: number) => void;
  };
  form: {
    uploadFileForm: FormInstance<any> | undefined;
    addInsuranceForm: FormInstance<any> | undefined;
  };
}
export const InsuranceContext = createContext<IContext | undefined>(undefined);

export const InsuranceProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message, modal } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [uploadFileForm] = Form.useForm();
  const [addInsuranceForm] = Form.useForm();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(IInsuranceTabs.basicInfo);
  const [insuranceCo, setInsuranceCo] = useState<IAllInsuranceResult[]>([]);
  const [insuranceCoLoading, setInsuranceCoLoading] = useState<boolean>(false);
  const [file, setFile] = useState<UploadFile>();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [tableEditMode, setTableEditMode] = useState<boolean>(false);
  const [insuranceFormId, setInsuranceFormId] = useState<number>();
  const [allInsuranceInfo, setAllInsuranceInfo] = useState<
    IGetInsuranceFileDetail[]
  >([]);
  const [allInsuranceInfoLoading, setAllInsuranceInfoLoading] =
    useState<boolean>(false);
  const [insuranceFileId, setInsuranceFileId] = useState<number>();
  const [backEditMode, setBackEditMode] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  ////////////////////////////////functions////////////////////////////////
  const getInsurance = useCallback(async () => {
    setInsuranceCoLoading(true);
    try {
      const { GetAllInsurance } = new BaseInfoService();
      const response = await GetAllInsurance();
      if (response && response.status === 200 && response.data) {
        setInsuranceCo(response.data.records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setInsuranceCoLoading(false);
    }
  }, []);

  const getInsuranceFileDetail = async (id: number) => {
    try {
      const { GetInsuranceFileDetail } = new OrderService();
      const result = await GetInsuranceFileDetail(id);
      if (result && result.status === 200 && result.data) {
        setHasFile(result.data.hasFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getAllInsuranceInfo = useCallback(async () => {
    if (!insuranceFormId) return undefined;
    setAllInsuranceInfoLoading(true);
    try {
      const { GetAllInsuranceFileDetails } = new OrderService();
      const result = await GetAllInsuranceFileDetails(insuranceFormId);
      if (result && result.status === 200 && result.data) {
        setAllInsuranceInfo(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllInsuranceInfoLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceFormId]);

  const getInsuranceFormInfo = useCallback(async () => {
    if (!orderId) return undefined;
    try {
      const { GetInsuranceForm } = new OrderService();
      const result = await GetInsuranceForm(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        const newData = {
          ...result.data,
          date: result.data.date ? dayjs(result.data.date) : undefined,
        };
        addInsuranceForm.setFieldsValue(newData);
        setInsuranceFormId(result.data.id);
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const addForm = async (values: IAddInsuranceForm) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddInsuranceForm } = new OrderService();
      const newValues = {
        ...values,
        date: format(new Date(values.date), "yyyy-MM-dd"),
      };
      const result = await AddInsuranceForm(parseInt(orderId), newValues);
      if (result && result.status === 200 && result.data) {
        // message.success(words.orders.insurance.insuranceMessage);
        setInsuranceFormId(result.data);
        setActiveKey(IInsuranceTabs.addFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateForm = async (values: IAddInsuranceForm) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { UpdateInsuranceForm } = new OrderService();
      const newValues = {
        ...values,
        date: fixNumbers(format(new Date(values.date), "yyyy-MM-dd")),
      };
      const result = await UpdateInsuranceForm(parseInt(orderId), newValues);
      if (result && result.status === 200) {
        message.success(words.orders.insurance.updateInsuranceMessage);
        setActiveKey(IInsuranceTabs.addFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const newFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(`${id}${OrderUrl.NewInsuranceFile}`),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setUploadProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
  const addFileDetail = async (values: IAddInsuranceFileDetail) => {
    if (!insuranceFormId) return undefined;
    setLoading(true);
    try {
      const { AddInsuranceFileDetail } = new OrderService();
      const newValues = {
        ...values,
        date: values.date
          ? moment(
              (values?.date as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
      };
      const result = await AddInsuranceFileDetail(insuranceFormId, newValues);
      if (result && result.status === 200 && result.data) {
        if (file) {
          const resultFile = await newFile(result.data);
          if (resultFile) {
            message.success(words.orders.insurance.insuranceFileMessage);
            uploadFileForm.resetFields();
            setFile(undefined);
            getAllInsuranceInfo();
          }
        } else {
          message.success(words.orders.insurance.insuranceFileMessage);
          uploadFileForm.resetFields();
          getAllInsuranceInfo();
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.UpdateInsuranceFile}`
        ),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setUploadProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateFileDetail = async (values: IAddInsuranceFileDetail) => {
    if (!insuranceFileId) return undefined;
    setLoading(true);
    try {
      const { UpdateInsuranceFileDetail } = new OrderService();
      const newValues = {
        ...values,
        date: values.date
          ? moment(
              (values?.date as Dayjs).format("jYYYY-jMM-jDD"),
              "jYYYY-jMM-jDD"
            ).format("YYYY-MM-DD")
          : null,
      };
      const result = await UpdateInsuranceFileDetail(
        insuranceFileId,
        newValues
      );
      if (result && result.status === 200) {
        if (file) {
          let resultFile;
          if (!hasFile) {
            resultFile = await newFile(insuranceFileId);
          } else {
            resultFile = await updateFile(insuranceFileId);
          }
          if (resultFile) {
            message.success(words.orders.insurance.updateInsuranceFileMessage);
            getAllInsuranceInfo();
            uploadFileForm.resetFields();
            setFile(undefined);
            setHasFile(false);
            setTableEditMode(false);
          }
        } else {
          message.success(words.orders.insurance.updateInsuranceFileMessage);
          getAllInsuranceInfo();
          uploadFileForm.resetFields();
          setFile(undefined);
          setHasFile(false);
          setTableEditMode(false);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteInsuranceForm = async () => {
    if (!orderId) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteInsuranceForm } = new OrderService();
      const result = await DeleteInsuranceForm(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.insurance.deleteInsuranceMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
      // modal.confirm(docModalProps("اطلاعات بازرسی"));
    } finally {
      setDeleteLoading(false);
    }
  };

  const deleteInsuranceDetail = async (id: number) => {
    if (!id) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteInsuranceFileDetail } = new OrderService();
      const result = await DeleteInsuranceFileDetail(id);
      if (result && result.status === 200) {
        message.success(words.orders.insurance.deleteInsuranceMessage);
        getAllInsuranceInfo();
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const GetFile = async (file: IGetInsuranceFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    try {
      const { GetInsuranceFile } = new OrderService();
      const result = await GetInsuranceFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      modal.confirm(docModalProps(words.orders.insurance.insurance));
      console.log(words.orders.inspection.inspection);
    } finally {
      setProgress(0);
    }
  };

  const [search] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  useEffect(() => {
    getInsurance();
  }, [getInsurance]);

  const defaultValue: IContext = {
    value: {
      deleteLoading,
      editMode,
      loading,
      activeKey,
      insuranceCo,
      insuranceCoLoading,
      file,
      hasFile,
      progress,
      tableEditMode,
      allInsuranceInfo,
      allInsuranceInfoLoading,
      backEditMode,
      pagination,
      uploadProgress,
    },
    dispatch: {
      setEditMode,
      setActiveKey,
      setFile,
      setTableEditMode,
      setInsuranceFileId,
      setHasFile,
      setBackEditMode,
      setPagination,
    },
    func: {
      addForm,
      deleteInsuranceForm,
      updateForm,
      getInsuranceFormInfo,
      addFileDetail,
      updateFileDetail,
      deleteInsuranceDetail,
      getAllInsuranceInfo,
      downloadAttachFile,
      getInsuranceFileDetail,
    },
    form: { addInsuranceForm, uploadFileForm },
  };

  return (
    <InsuranceContext.Provider value={defaultValue}>
      {children}
    </InsuranceContext.Provider>
  );
};

export const UseInsurance = () => useContext(InsuranceContext);
