import { Row } from "antd";
import styled from "styled-components";

export const AddProformaContainer = styled(Row)`
  display: flex;
  width: 92%;
  padding: 24px 32px;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .wrapper {
    display: flex;
    width: 100% !important;
    .ant-select-selector {
      border-radius: 0px 12px 12px 0px !important;
      border-left: 1px solid var(--color-Light-Mode-Border-5, #cacaca);
    }
    .ant-input-number {
      border-radius: 12px 0px 0px 12px !important;
    }
  }
`;

export const OrdersTableContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 97%;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};

  /* Table */
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  .orderManagementHolder {
    border-radius: 4px;
    border: 1px solid var(--color-Primary-Primary-500, #33a39d);
    background: var(--color-Primary-Primary-100, #e5f3f3);
    display: flex;
    padding: 6px 8px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    width: 95px;
    cursor: pointer;
  }
  .deleteHolder {
    display: flex;
    padding: 6px 8px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid var(--color-Danger-Danger-600, #f64c4c);
    background: var(--color-Danger-Danger-200, #ffebee);
    width: 53px;
    cursor: pointer;
  }
`;
