import { App, Form } from "antd";
import { RcFile } from "antd/es/upload";
import { FormInstance, UploadFile } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fixNumbers } from "src/helper/fixNumber";
import { uploadFile } from "src/helper/uploadZipFile";
import { ROUTES } from "src/models/enums/routes";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import {
  IAllCountryResult,
  IAllCurrencyResult,
  IAllInspectionResult,
  IAllSupplierResult,
} from "src/services/BaseInfo/models/result.models";
import { IAddOrigin } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import { OrderUrl } from "src/services/Order/urls";
import useLanguage from "src/store/language";
import dayjs from "dayjs";
import { format } from "date-fns";
interface IContext {
  value: {
    editMode: boolean;
    supplierLoading: boolean;
    supplier: IAllSupplierResult[];
    countryLoading: boolean;
    country: IAllCountryResult[];
    currency: IAllCurrencyResult[];
    inspection: IAllInspectionResult[];
    currencyLoading: boolean;
    inspectionLoading: boolean;
    checked: boolean;
    deleteLoading: boolean;
    loading: boolean;
    hasFile: boolean;
    file: UploadFile[];
    progress: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setSupplierLoading: Dispatch<SetStateAction<boolean>>;
    setCountryLoading: Dispatch<SetStateAction<boolean>>;
    setSupplier: Dispatch<SetStateAction<IAllSupplierResult[]>>;
    setCountry: Dispatch<SetStateAction<IAllCountryResult[]>>;
    setCurrencyLoading: Dispatch<SetStateAction<boolean>>;
    setInspectionLoading: Dispatch<SetStateAction<boolean>>;
    setCurrency: Dispatch<SetStateAction<IAllCurrencyResult[]>>;
    setInspection: Dispatch<SetStateAction<IAllInspectionResult[]>>;
    setChecked: Dispatch<SetStateAction<boolean>>;
    setHasFile: Dispatch<SetStateAction<boolean>>;
    setFile: Dispatch<SetStateAction<UploadFile[]>>;
  };
  func: {
    getSupplier: () => void;
    getCountry: () => {};
    getCurrency: () => void;
    getInspection: () => void;
    deleteOrigin: () => void;
    add: (values: IAddOrigin) => void;
    update: (values: IAddOrigin) => void;
    getOriginInfo: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}

export const OriginContext = createContext<IContext | undefined>(undefined);

export const OriginContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [supplier, setSupplier] = useState<IAllSupplierResult[]>([]);
  const [supplierLoading, setSupplierLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<IAllCountryResult[]>([]);
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [currency, setCurrency] = useState<IAllCurrencyResult[]>([]);
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(false);
  const [inspection, setInspection] = useState<IAllInspectionResult[]>([]);
  const [inspectionLoading, setInspectionLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [originId, setOriginId] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [file, setFile] = useState<UploadFile[]>([]);
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  ////////////////////////////////Functions////////////////////////////////
  const getSupplier = useCallback(async () => {
    setSupplierLoading(true);
    try {
      const { GetAllSupplier } = new BaseInfoService();
      const result = await GetAllSupplier();
      if (result && result.status === 200) {
        setSupplier(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSupplierLoading(false);
    }
  }, []);

  const getCountry = useCallback(async () => {
    setCountryLoading(true);
    try {
      const { GetAllCountry } = new BaseInfoService();
      const result = await GetAllCountry();
      if (result && result.status === 200) {
        setCountry(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCountryLoading(false);
    }
  }, []);

  const getCurrency = useCallback(async () => {
    setCurrencyLoading(true);
    try {
      const { GetAllCurrency } = new BaseInfoService();
      const result = await GetAllCurrency();
      if (result && result.status === 200) {
        setCurrency(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrencyLoading(false);
    }
  }, []);

  const getInspection = useCallback(async () => {
    setInspectionLoading(true);
    try {
      const { GetAllInspection } = new BaseInfoService();
      const result = await GetAllInspection();
      if (result && result.status === 200) {
        setInspection(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setInspectionLoading(false);
    }
  }, []);

  const deleteOrigin = async () => {
    if (!orderId) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteOrigin } = new OrderService();
      const result = await DeleteOrigin(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.origin.deleteOriginMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const newOriginFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(`${id}${OrderUrl.NewOriginFile}`),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file[0].originFileObj as RcFile,
        setProgress: setProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const add = async (values: IAddOrigin) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddOrigin } = new OrderService();
      const newValues = {
        ...values,
        warehousing: checked,
        contractDate: fixNumbers(
          format(new Date(values.contractDate), "yyyy-MM-dd")
        ),
      };
      const result = await AddOrigin(newValues, parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setOriginId(result.data);
      }
      if (file.length > 0 && result) {
        const resultFile = await newOriginFile(result.data);
        if (resultFile) {
          navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          message.success(words.orders.origin.addOriginMessage);
        }
      } else {
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
        message.success(words.orders.origin.addOriginMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateOriginFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(`${id}${OrderUrl.UpdateOriginFile}`),
        extraData: { name: "testUpdate" },
        method: "PATCH",
        newFile: file[0].originFileObj as RcFile,
        setProgress: setProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (values: IAddOrigin) => {
    if (!orderId || !originId) return undefined;
    setLoading(true);
    try {
      const { UpdateOrigin } = new OrderService();
      const newValues = {
        ...values,
        warehousing: checked,
        contractDate: format(new Date(values.contractDate), "yyyy-MM-dd"),
      };
      const result = await UpdateOrigin(newValues, parseInt(orderId));
      if (result && result.status === 200) {
        if (file) {
          let resultFile;
          if (hasFile) {
            resultFile = await updateOriginFile(originId);
          } else {
            resultFile = await newOriginFile(originId);
          }
          if (resultFile) {
            navigate(ROUTES.orderManagement.concat(`/${orderId}`));
            message.success(words.orders.origin.updateOriginMessage);
          }
        } else {
          navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          message.success(words.orders.origin.updateOriginMessage);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getOriginInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetOrigin } = new OrderService();
      const result = await GetOrigin(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        const newData = {
          ...result.data,
          contractDate: result.data.contractDate
            ? dayjs(result.data.contractDate)
            : undefined,
        };
        setChecked(result.data.warehousing);
        setHasFile(result.data.hasFile);
        form.setFieldsValue(newData);
        form.setFieldValue("warehousing", checked);
        setOriginId(result.data.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  useEffect(() => {
    getSupplier();
    getCountry();
    getCurrency();
    getInspection();
  }, [getCountry, getCurrency, getInspection, getSupplier]);
  const ContextValue: IContext = {
    value: {
      supplier,
      supplierLoading,
      country,
      countryLoading,
      currency,
      currencyLoading,
      inspectionLoading,
      inspection,
      editMode,
      file,
      checked,
      deleteLoading,
      loading,
      hasFile,
      progress,
    },
    dispatch: {
      setSupplier,
      setSupplierLoading,
      setCountry,
      setCountryLoading,
      setCurrency,
      setCurrencyLoading,
      setInspection,
      setInspectionLoading,
      setEditMode,
      setFile,
      setChecked,
      setHasFile,
    },
    func: {
      getSupplier,
      getCountry,
      getCurrency,
      getInspection,
      deleteOrigin,
      add,
      update,
      getOriginInfo,
    },
    form: { form },
  };
  return (
    <OriginContext.Provider value={ContextValue}>
      {children}
    </OriginContext.Provider>
  );
};

export const useOrigin = () => useContext(OriginContext);
