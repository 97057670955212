import { FC, useEffect, useMemo } from "react";
import { RatesContainer } from "./style";
import { Button, Col, Divider, Flex, Form, InputNumber, Row } from "antd";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { FormItem } from "src/components/UiKit/FormItem";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import { IAddRate } from "src/services/Order/models/args.models";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { O2, S2, S3 } from "src/components/UiKit/Typography";
import { UseRates } from "./context";
import { inputNumberFormatter } from "src/helper/inputNumberFormatter";

export const Rates: FC = () => {
  const {
    func: { add, update, getRateInfo, deleteRate },
    value: { editMode, loading },
    dispatch: { setEditMode },
    form: { form },
  } = UseRates()!;
  const { words } = useLanguage();
  const values = Form.useWatch([], form);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };

  const onClickReset = () => {
    form?.resetFields();
  };

  const onFinish = (values: IAddRate) => {
    if (editMode) {
      update(values);
    } else {
      add(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getRateInfo();
  }, [getRateInfo, isEdited]);
  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.rates.editRatesHeaderInfo
            : words.orders.rates.ratesHeaderInfo
        }
      />
      <Form form={form} onFinish={onFinish}>
        <Flex justify="center">
          <Row justify={"center"}>
            <RatesContainer span={22}>
              <Row>
                <Col span={24}>
                  <S2 className="py-[16px] mb-[24px]">
                    {editMode
                      ? words.orders.rates.editRatesInfo
                      : words.orders.rates.ratesInfo}
                  </S2>
                </Col>
                <Col span={24} className="w-full">
                  <Row gutter={[24, 24]} className="py-[16px]">
                    <Col span={8}>
                      <FormItem
                        name={"fxPurchase"}
                        label={
                          <div className="flex justify-center items-center gap-[4px]">
                            <S3>{words.orders.rates.fXPurchaseRate}</S3>
                            <div className="flex">
                              <S3>{"("}</S3>
                              <S3>{words.orders.rates.rial}</S3>
                              <S3>{")"}</S3>
                            </div>
                          </div>
                        }
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        name={"ets"}
                        label={
                          <div className="flex justify-center items-center gap-[4px]">
                            <S3>{words.orders.rates.eTSFXRate}</S3>
                            <div className="flex">
                              <S3>{"("}</S3>
                              <S3>{words.orders.rates.rial}</S3>
                              <S3>{")"}</S3>
                            </div>
                          </div>
                        }
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        name={"cbi"}
                        label={
                          <div className="flex justify-center items-center gap-[4px]">
                            <S3>{words.orders.rates.CBI}</S3>
                            <div className="flex">
                              <O2>{"("}</O2>
                              <O2>{words.orders.rates.fX}</O2>
                              <O2>{")"}</O2>
                            </div>
                          </div>
                        }
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        name={"bankFee"}
                        label={words.orders.rates.bankFeeFactor}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        name={"tariff"}
                        label={words.orders.rates.customsTariff}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        name={"freightCost"}
                        label={words.orders.rates.approvedFreightCost}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        name={"orderFee"}
                        label={words.orders.rates.newOrderFactor}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        name={"insuranceRate"}
                        label={words.orders.rates.insuranceRate}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        name={"dollarRate"}
                        label={
                          <div className="flex justify-center items-center gap-[4px]">
                            <S3>{words.orders.rates.freeDollarRate}</S3>
                            <div className="flex">
                              <S3>{"("}</S3>
                              <S3>{words.orders.rates.rial}</S3>
                              <S3>{")"}</S3>
                            </div>
                          </div>
                        }
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          placeholder={words.global.insert}
                          onPressEnter={form?.submit}
                          changeOnWheel={false}
                          controls={false}
                          className="w-full"
                          formatter={inputNumberFormatter}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Divider dashed className="!border-[#BFE2E0] !mt-0" />
                  <div
                    className={`flex py-[24px]  ${
                      editMode ? "justify-between" : "justify-end"
                    }`}
                  >
                    {editMode ? (
                      <div>
                        <DeletePopconfirm
                          endPointName={words.orders.rates.deleteRatesInfo}
                          onConfirm={() => {
                            deleteRate();
                          }}
                        >
                          <Button danger className="!min-w[85px]">
                            {words.global.delete}
                          </Button>
                        </DeletePopconfirm>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="flex gap-[16px] justify-end borderTop">
                      {editMode ? (
                        <div className="!min-w-[85px] h-[100%]">
                          <Button type="default" onClick={onClickCancel}>
                            {words.global.cancel}
                          </Button>
                        </div>
                      ) : (
                        <DPopconfirm
                          description={words.global.descriptionPopConfirm}
                          onConfirm={onClickReset}
                        >
                          <div className="!min-w-[85px] h-[100%]">
                            <Button
                              type="default"
                              disabled={isDisabledResetForm}
                            >
                              {words.global.reset}
                            </Button>
                          </div>
                        </DPopconfirm>
                      )}
                      <Button
                        className="!min-w-[85px]"
                        type="primary"
                        onClick={() => {
                          form?.submit();
                        }}
                        loading={loading}
                        disabled={isDisabledResetForm}
                      >
                        {editMode ? words.global.saveEdit : words.global.add}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </RatesContainer>
          </Row>
        </Flex>
      </Form>
    </div>
  );
};
