import { FC, useState } from "react";
import { ViewPODContainer } from "./style";
import { Col, Row } from "antd";
import useLanguage from "src/store/language";
import { FinancialTable } from "./components/FinancialTable";
import { OtherInfoTable } from "./components/OtherInfoTable";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { FinalTable } from "./components/FinalTable";

export const ViewPOD: FC = () => {
  const { words } = useLanguage();
  const [activeDivIndex, setActiveDivIndex] = useState<number>(1);

  const handleDivClick = (index: number) => {
    setActiveDivIndex(index);
  };
  const showTable = (id: number | undefined) => {
    switch (id) {
      case 1:
        return <FinancialTable />;

      case 2:
        return <OtherInfoTable />;

      case 3:
        return <FinalTable />;
    }
  };
  return (
    <div>
      <HeaderProforma headerName={words.orders.pod.viewPodHeader} />
      <Row justify={"center"}>
        <ViewPODContainer span={22}>
          <Row className="w-full" gutter={[0, 24]}>
            <Col span={24}>
              <div className="boxHolder">
                <div
                  className={
                    activeDivIndex === 1
                      ? `activeBoxContainer`
                      : `deActiveBoxContainer`
                  }
                  onClick={() => {
                    handleDivClick(1);
                  }}
                >
                  {words.orders.pod.tableDesc}
                </div>
                <div
                  className={
                    activeDivIndex === 2
                      ? `activeBoxContainer`
                      : `deActiveBoxContainer`
                  }
                  onClick={() => {
                    handleDivClick(2);
                  }}
                >
                  {words.orders.pod.tableDescOther}
                </div>
                <div
                  className={
                    activeDivIndex === 3
                      ? `activeBoxContainer`
                      : `deActiveBoxContainer`
                  }
                  onClick={() => {
                    handleDivClick(3);
                  }}
                >
                  {words.orders.pod.finalTable}
                </div>
              </div>
            </Col>
            <Col span={24}>{showTable(activeDivIndex)}</Col>
          </Row>
        </ViewPODContainer>
      </Row>
    </div>
  );
};
