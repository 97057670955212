import { FC, useCallback, useEffect, useState } from "react";
import { Col, Row, Table } from "antd";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { ViewSellingContainer } from "../../style";

import { T1 } from "src/components/UiKit/Typography";

import { useViewColumns } from "./columns";
import { getRowKey } from "src/helper/getRowKey";
import { OrderService } from "src/services/Order/order.service";
import { IGetAlllSell } from "src/services/Order/models/result.models";
import { useParams, useSearchParams } from "react-router-dom";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useChangeParams } from "src/hook/auth/useParamsChange";

export const ViewSelling: FC = () => {
  const { words } = useLanguage();
  const [Columns] = useViewColumns();
  const { orderId } = useParams();
  const onChangeParams = useChangeParams();

  const [loading, setLoading] = useState<boolean>(false);

  const [allSell, setAllSell] = useState<IGetAlllSell[]>([]);
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  const getAllSell = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetAlllSell } = new OrderService();
      const result = await GetAlllSell(parseInt(orderId));
      if (result && result.status === 200) {
        setAllSell(result.data.records.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.records.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    getAllSell();
  }, [getAllSell]);
  return (
    <div>
      <HeaderProforma headerName={words.orders.selling.sellView} />
      <Row justify={"center"}>
        <ViewSellingContainer span={22}>
          <Row gutter={[16, 24]} justify={"space-around"}>
            <Col span={24}>
              <T1>{words.orders.selling.sellInformation}</T1>
            </Col>
            <Col span={24} className="tableHolder">
              <Table
                columns={Columns}
                loading={loading}
                scroll={{ x: "auto" }}
                rowKey={getRowKey}
                className="w-[100%]"
                dataSource={allSell}
                pagination={{
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                  total: pagination.total,
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  onChange: (page: number, pageSize) => {
                    onChangeParams("Limit", pageSize.toString());
                    onChangeParams(
                      "Offset",
                      pageSize !== pagination.pageSize
                        ? "1"
                        : pageSize.toString()
                    );
                    setPagination((prev) => ({
                      ...prev,
                      current: pageSize !== prev.pageSize ? 1 : page,
                      pageSize,
                    }));
                  },
                }}
              />
            </Col>
          </Row>
        </ViewSellingContainer>
      </Row>
    </div>
  );
};
