import { FC } from "react";
import { InspectionDocument } from "src/modules/InspectionDocument";
import { InspectionProvider } from "src/modules/InspectionDocument/context";

export const InspectionDocumentPage: FC = () => {
  return (
    <InspectionProvider>
      <InspectionDocument />
    </InspectionProvider>
  );
};
