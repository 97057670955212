import { Col, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import useLanguage from "src/store/language";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { getRowKey } from "src/helper/getRowKey";
import { useSelling } from "../context";
import { IGetAlllSell } from "src/services/Order/models/result.models";
import { SellingTableContainer } from "../style";
import { useChangeParams } from "src/hook/auth/useParamsChange";

export const SellingTable: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    dispatch: { setTableEditMode, setSellingId, setPagination },
    value: { allSelling, getLoading, pagination },
    func: { getAlllSelling, deleteSell },
  } = useSelling()!;
  const onChangeParams = useChangeParams();

  useEffect(() => {
    getAlllSelling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Columns: ColumnsType<IGetAlllSell> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.orders.selling.rate,
      dataIndex: "rate",
      key: "rate",
      align: "center",
      width: "268px",
      render: (text: number) => text.toLocaleString("en-US"),
    },
    {
      title: words.orders.selling.weight,
      dataIndex: "weight",
      key: "weight",
      align: "center",
      width: "268px",
      render: (text: number) => text.toLocaleString("en-US"),
    },
    {
      title: words.orders.selling.transport,
      dataIndex: "transportName",
      key: "transportName",
      align: "center",
      width: "134px",
    },
    {
      title: words.orders.selling.amount,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "134px",
      render: (text, record) => record.amount.toLocaleString("en-US"),
    },

    {
      title: words.global.tools,
      key: "id",
      width: "80px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setTableEditMode(true);
              setSellingId(record.id);
              form?.setFieldsValue(record);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>

          <DPopconfirm
            deleteClassName
            description={words.orders.selling.deleteDesc}
            onConfirm={() => deleteSell(record.id)}
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];

  return (
    <SellingTableContainer>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={getLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={allSelling}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: pagination.total,
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page: number, pageSize) => {
              onChangeParams("Limit", pageSize.toString());
              onChangeParams(
                "Offset",
                pageSize !== pagination.pageSize ? "1" : pageSize.toString()
              );
              setPagination((prev) => ({
                ...prev,
                current: pageSize !== prev.pageSize ? 1 : page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </SellingTableContainer>
  );
};
