import { App, Form, GetProp } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IAddShipment } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import dayjs, { Dayjs } from "dayjs";
import { FormInstance, UploadProps } from "antd/lib";
import { IShipmentTabs } from "./models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import {
  IAllCurrencyResult,
  IAllPortResult,
  IAllUnitResult,
  IAllVesselResult,
  IGetAllShippingDocs,
} from "src/services/BaseInfo/models/result.models";
import { uploadFile } from "src/helper/uploadZipFile";
import { OrderUrl } from "src/services/Order/urls";
import {
  IGetShipmentFile,
  IGetShipmentFileDetail,
} from "src/services/Order/models/result.models";
import { ROUTES } from "src/models/enums/routes";
import { docModalProps } from "src/helper/docModalProps";
import { AxiosRequestConfig } from "axios";
import { DocumentService } from "src/services/Document/document.service";
import downloadZipFile from "src/helper/downloadZipFile";
import { ITablePagination } from "src/models/interfaces/pagination";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

interface ILoadings {
  delete: boolean;
  addForm: boolean;
  updateForm: boolean;
  addShippingDoc: boolean;
  allVessel: boolean;
  allPort: boolean;
  allCurrency: boolean;
  allUnit: boolean;
  allShipmentInfo: boolean;
  updateFileDetail: boolean;
  deleteShipmentForm: boolean;
  deleteShipmentFileDetail: boolean;
  getAllShippingDocs: boolean;
  downloadAttachFile: boolean;
}
interface IContext {
  value: {
    editMode: boolean;
    activeKey: string;
    allVessel: IAllVesselResult[];
    hasFile: boolean;
    file: FileType | undefined;
    progress: number;
    tableEditMode: boolean;
    allShipmentInfo: IGetShipmentFileDetail[];
    backEditMode: boolean;
    openModal: boolean;
    loadings: ILoadings;
    allPort: IAllPortResult[];
    allCurrency: IAllCurrencyResult[];
    allUnit: IAllUnitResult[];
    allShippingDocs: IGetAllShippingDocs[];
    pagination: ITablePagination;
    uploadProgress: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setActiveKey: Dispatch<SetStateAction<string>>;
    setFile: Dispatch<SetStateAction<FileType | undefined>>;
    setTableEditMode: Dispatch<SetStateAction<boolean>>;
    setShipmentFileId: Dispatch<SetStateAction<number | undefined>>;
    setHasFile: Dispatch<SetStateAction<boolean>>;
    setBackEditMode: Dispatch<SetStateAction<boolean>>;
    setOpenMOdal: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    deleteShipmentForm: () => void;
    addForm: (values: IAddShipment) => void;
    updateForm: (values: IAddShipment) => void;
    getShipmentFormInfo: () => void;
    addFileDetail: (id: number) => void;
    updateFileDetail: (id: number) => void;
    deleteShipmentFileDetail: (id: number) => void;
    getAllShipmentInfo: () => void;
    downloadAttachFile: (id?: number) => void;
    getShipmentFileDetail: (id: number) => void;
    addShippingDoc: (value: string) => void;
  };
  form: {
    uploadFileForm: FormInstance<any> | undefined;
    addShipmentForm: FormInstance<any> | undefined;
  };
}
export const ShipmentContext = createContext<IContext | undefined>(undefined);

export const ShipmentProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message, modal } = App.useApp();
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [uploadFileForm] = Form.useForm();
  const [addShipmentForm] = Form.useForm();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(IShipmentTabs.basicInfo);
  const [allVessel, setAllVessel] = useState<IAllVesselResult[]>([]);
  const [allPort, setAllPort] = useState<IAllPortResult[]>([]);
  const [allCurrency, setAllCurrency] = useState<IAllCurrencyResult[]>([]);
  const [allUnit, setAllUnit] = useState<IAllUnitResult[]>([]);
  const [file, setFile] = useState<FileType>();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [tableEditMode, setTableEditMode] = useState<boolean>(false);
  const [shipmentFormId, setShipmentFormId] = useState<number>();
  const [allShipmentInfo, setAllShipmentInfo] = useState<
    IGetShipmentFileDetail[]
  >([]);
  const [shipmentFileId, setShipmentFileId] = useState<number>();
  const [backEditMode, setBackEditMode] = useState<boolean>(false);
  const [openModal, setOpenMOdal] = useState<boolean>(false);
  const [allShippingDocs, setAllShippingDocs] = useState<IGetAllShippingDocs[]>(
    []
  );
  let [searchParams] = useSearchParams();
  const initialPagination = {
    current: searchParams.get("Offset") || 1,
    pageSize: searchParams.get("Limit") || 5,
  };
  const [pagination, setPagination] = useState<ITablePagination>({
    current: +initialPagination.current,
    pageSize: +initialPagination.pageSize,
  });
  const [loadings, setLoadings] = useState<ILoadings>({
    delete: false,
    addForm: false,
    updateForm: false,
    addShippingDoc: false,
    allVessel: false,
    allPort: false,
    allCurrency: false,
    allUnit: false,
    allShipmentInfo: false,
    updateFileDetail: false,
    deleteShipmentForm: false,
    deleteShipmentFileDetail: false,
    getAllShippingDocs: false,
    downloadAttachFile: false,
  });
  ////////////////////////////////functions////////////////////////////////
  const getVessel = useCallback(async () => {
    setLoadings((prev) => ({ ...prev, allVessel: true }));
    try {
      const { GetAllVessel } = new BaseInfoService();
      const response = await GetAllVessel();
      if (response && response.status === 200 && response.data) {
        setAllVessel(response.data.records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadings((prev) => ({ ...prev, allVessel: false }));
    }
  }, []);

  const getPort = useCallback(async () => {
    setLoadings((prev) => ({ ...prev, allPort: true }));
    try {
      const { GetAllPort } = new BaseInfoService();
      const response = await GetAllPort();
      if (response && response.status === 200 && response.data) {
        setAllPort(response.data.records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadings((prev) => ({ ...prev, allPort: false }));
    }
  }, []);

  const getCurrency = useCallback(async () => {
    setLoadings((prev) => ({ ...prev, allCurrency: true }));
    try {
      const { GetAllCurrency } = new BaseInfoService();
      const response = await GetAllCurrency();
      if (response && response.status === 200 && response.data) {
        setAllCurrency(response.data.records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadings((prev) => ({ ...prev, allCurrency: false }));
    }
  }, []);

  const getUnit = useCallback(async () => {
    setLoadings((prev) => ({ ...prev, allUnit: true }));
    try {
      const { GetAllUnit } = new BaseInfoService();
      const response = await GetAllUnit();
      if (response && response.status === 200 && response.data) {
        setAllUnit(response.data.records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadings((prev) => ({ ...prev, allUnit: false }));
    }
  }, []);

  const addForm = async (values: IAddShipment) => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, addForm: true }));
    try {
      const { AddShipment } = new OrderService();
      const newValues = {
        ...values,
        berthingLineUp: values.berthingLineUp
          ? (values?.berthingLineUp as Dayjs).format("YYYY-MM-DD")
          : null,
        loadingOperationStart:
          values.loadingOperation && values?.loadingOperation[0]
            ? (values?.loadingOperation[0] as Dayjs).format("YYYY-MM-DD")
            : null,
        loadingOperationEnd: values?.loadingOperation
          ? (values?.loadingOperation[1] as Dayjs).format("YYYY-MM-DD")
          : null,
        reciveDraftDate: values.reciveDraftDate
          ? (values?.reciveDraftDate as Dayjs).format("YYYY-MM-DD")
          : null,
        sendDraftDate: values.sendDraftDate
          ? (values?.sendDraftDate as Dayjs).format("YYYY-MM-DD")
          : null,
        blDate: values.blDate
          ? (values?.blDate as Dayjs).format("YYYY-MM-DD")
          : null,
      };
      const result = await AddShipment(parseInt(orderId), newValues);
      if (result && result.status === 200 && result.data) {
        message.success(words.orders.shipment.shipmentMessage);
        setShipmentFormId(result.data);
        setActiveKey(IShipmentTabs.addFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, addForm: false }));
    }
  };

  const updateForm = async (values: IAddShipment) => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, updateForm: true }));
    try {
      const { UpdateShipment } = new OrderService();
      const newValues = {
        ...values,
        berthingLineUp: values.berthingLineUp
          ? (values?.berthingLineUp as Dayjs).format("YYYY-MM-DD")
          : null,
        loadingOperationStart:
          values.loadingOperation && values?.loadingOperation[0]
            ? (values?.loadingOperation[0] as Dayjs).format("YYYY-MM-DD")
            : null,
        loadingOperationEnd: values?.loadingOperation
          ? (values?.loadingOperation[1] as Dayjs).format("YYYY-MM-DD")
          : null,
        reciveDraftDate: values.reciveDraftDate
          ? (values?.reciveDraftDate as Dayjs).format("YYYY-MM-DD")
          : null,
        sendDraftDate: values.sendDraftDate
          ? (values?.sendDraftDate as Dayjs).format("YYYY-MM-DD")
          : null,
        blDate: values.blDate
          ? (values?.blDate as Dayjs).format("YYYY-MM-DD")
          : null,
      };
      const result = await UpdateShipment(parseInt(orderId), newValues);
      if (result && result.status === 200) {
        message.success(words.orders.shipment.updateShipmentMessage);
        setActiveKey(IShipmentTabs.addFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, updateForm: false }));
    }
  };

  const getAllShipmentInfo = useCallback(async () => {
    if (!shipmentFormId) return undefined;
    setLoadings((prev) => ({ ...prev, allShipmentInfo: true }));
    try {
      const { GetAllShipmentFileDetails } = new OrderService();
      const result = await GetAllShipmentFileDetails(shipmentFormId);
      if (result && result.status === 200 && result.data) {
        setAllShipmentInfo(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, allShipmentInfo: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentFormId]);

  const getShipmentFormInfo = useCallback(async () => {
    if (!orderId) return undefined;
    try {
      const { GetShipment } = new OrderService();
      const result = await GetShipment(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        const newData = {
          ...result.data,
          berthingLineUp: result.data.berthingLineUp
            ? dayjs(result.data.berthingLineUp)
            : null,
          loadingOperation: result.data.loadingOperationStart
            ? [
                dayjs(result.data.loadingOperationStart),
                dayjs(result.data.loadingOperationEnd),
              ]
            : [],
          reciveDraftDate: result.data.reciveDraftDate
            ? dayjs(result.data.reciveDraftDate)
            : null,
          sendDraftDate: result.data.sendDraftDate
            ? dayjs(result.data.sendDraftDate)
            : null,
          blDate: result.data.blDate ? dayjs(result.data.blDate) : null,
        };
        addShipmentForm.setFieldsValue(newData);
        setShipmentFormId(result.data.shipmentId);
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const newFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(`${id}${OrderUrl.NewShipmentFile}`),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file,
        setProgress: setUploadProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.UpdateShipmentFile}`
        ),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file,
        setProgress: setUploadProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const addFileDetail = async (id: number) => {
    if (!shipmentFormId) return undefined;
    setLoadings((prev) => ({ ...prev, addShippingDoc: true }));
    try {
      const { AddShipmentFileDetail } = new OrderService();

      const result = await AddShipmentFileDetail(shipmentFormId, id);
      if (result && result.status === 200 && result.data) {
        if (file) {
          const resultFile = await newFile(result.data);
          if (resultFile) {
            message.success(words.orders.shipment.shipmentFileMessage);
            uploadFileForm.resetFields();
            setFile(undefined);
            getAllShipmentInfo();
          }
        } else {
          message.success(words.orders.shipment.shipmentFileMessage);
          uploadFileForm.resetFields();
          getAllShipmentInfo();
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, addShippingDoc: false }));
    }
  };

  const updateFileDetail = async (id: number) => {
    if (!shipmentFileId) return undefined;
    setLoadings((prev) => ({ ...prev, updateFileDetail: true }));
    try {
      const { UpdateShipmentFileDetail } = new OrderService();

      const result = await UpdateShipmentFileDetail(shipmentFileId, id);
      if (result && result.status === 200) {
        if (file) {
          let resultFile;
          if (!hasFile) {
            resultFile = await newFile(shipmentFileId);
          } else {
            resultFile = await updateFile(shipmentFileId);
          }
          if (resultFile) {
            message.success(words.orders.shipment.updateShipmentFileMessage);
            getAllShipmentInfo();
            uploadFileForm.resetFields();
            setFile(undefined);
            setHasFile(false);
            setTableEditMode(false);
          }
        } else {
          message.success(words.orders.shipment.updateShipmentFileMessage);
          getAllShipmentInfo();
          uploadFileForm.resetFields();
          setFile(undefined);
          setHasFile(false);
          setTableEditMode(false);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, updateFileDetail: false }));
    }
  };

  const deleteShipmentForm = async () => {
    if (!orderId) return undefined;
    setLoadings((prev) => ({ ...prev, deleteShipmentForm: true }));
    try {
      const { DeleteShipment } = new OrderService();
      const result = await DeleteShipment(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.shipment.deleteShipmentMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
      // modal.confirm(docModalProps("اطلاعات بازرسی"));
    } finally {
      setLoadings((prev) => ({ ...prev, deleteShipmentForm: false }));
    }
  };

  const deleteShipmentFileDetail = async (id: number) => {
    if (!id) return undefined;
    setLoadings((prev) => ({ ...prev, deleteShipmentFileDetail: true }));
    try {
      const { DeleteShipmentFileDetail } = new OrderService();
      const result = await DeleteShipmentFileDetail(id);
      if (result && result.status === 200) {
        message.success(words.orders.shipment.deleteShipmentMessage);
        getAllShipmentInfo();
      }
    } catch (err) {
    } finally {
      setLoadings((prev) => ({ ...prev, deleteShipmentFileDetail: false }));
    }
  };

  const GetFile = async (file: IGetShipmentFile) => {
    if (!file.uniqueSystemName) return undefined;

    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { GetFile } = new DocumentService();
      const result = await GetFile(file.uniqueSystemName, config);
      if (result)
        downloadZipFile({
          ...result,
          RequestZipFile: file.fileOrginalName,
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (id?: number) => {
    if (!id) return undefined;
    setLoadings((prev) => ({ ...prev, downloadAttachFile: true }));
    try {
      const { GetShipmentFile } = new OrderService();
      const result = await GetShipmentFile(id);
      if (result && result.data) {
        await GetFile(result.data[0]);
      }
    } catch (err) {
      modal.confirm(docModalProps(words.orders.shipment.shipment));
    } finally {
      setProgress(0);
      setLoadings((prev) => ({ ...prev, downloadAttachFile: false }));
    }
  };

  const getAllShippingDocs = useCallback(async () => {
    setLoadings((prev) => ({ ...prev, getAllShippingDocs: true }));
    try {
      const { GetAllShippingDocs } = new BaseInfoService();
      const response = await GetAllShippingDocs();
      if (response && response.status === 200 && response.data) {
        setAllShippingDocs(response.data.records);
        setPagination((prev) => ({
          ...prev,
          total: response.data.count,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadings((prev) => ({ ...prev, getAllShippingDocs: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addShippingDoc = useCallback(async (value: string) => {
    setLoadings((prev) => ({ ...prev, addShippingDoc: true }));
    try {
      const { AddShippingDoc } = new BaseInfoService();
      const result = await AddShippingDoc({ title: value });
      if (result && result.status === 200) {
        message.success(words.orders.shipment.addShippingDocMessage);
        setOpenMOdal(false);
        getAllShippingDocs();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, addShippingDoc: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///////////////////////hasFile/////////////////////////////////////////
  const getShipmentFileDetail = async (id: number) => {
    try {
      const { GetShipmentFileDetail } = new OrderService();
      const result = await GetShipmentFileDetail(id);
      if (result && result.status === 200 && result.data) {
        setHasFile(result.data.hasFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const [search] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  useEffect(() => {
    Promise.allSettled([
      getVessel(),
      getPort(),
      getCurrency(),
      getUnit(),
      getAllShippingDocs(),
    ]);
  }, [getVessel, getPort, getCurrency, getUnit, getAllShippingDocs]);

  const defaultValue: IContext = {
    value: {
      editMode,
      activeKey,
      allVessel,
      file,
      hasFile,
      progress,
      tableEditMode,
      allShipmentInfo,
      backEditMode,
      openModal,
      loadings,
      allPort,
      allCurrency,
      allUnit,
      allShippingDocs,
      pagination,
      uploadProgress,
    },
    dispatch: {
      setEditMode,
      setActiveKey,
      setFile,
      setTableEditMode,
      setShipmentFileId,
      setHasFile,
      setBackEditMode,
      setOpenMOdal,
      setPagination,
    },
    func: {
      addForm,
      deleteShipmentForm,
      updateForm,
      getShipmentFormInfo,
      addFileDetail,
      updateFileDetail,
      deleteShipmentFileDetail,
      getAllShipmentInfo,
      downloadAttachFile,
      getShipmentFileDetail,
      addShippingDoc,
    },
    form: { addShipmentForm, uploadFileForm },
  };

  return (
    <ShipmentContext.Provider value={defaultValue}>
      {children}
    </ShipmentContext.Provider>
  );
};

export const useShipment = () => useContext(ShipmentContext);
