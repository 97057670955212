import { Navigate, NonIndexRouteObject, RouteObject } from "react-router-dom";
import {
  AddOrderPages,
  AgriculturePage,
  AssignActionToRolePage,
  AssignRoleToUserPage,
  AveragePricePage,
  BankingDOCPage,
  BankingOperationsPage,
  ClearanceWorkersPage,
  CountriesPage,
  CreateRolePage,
  CreateUserPage,
  CurrenciesPage,
  CustomDeclarationPage,
  CustomOperationPage,
  CustomsFormalitiesPage,
  DesignSystemPage,
  EditClearanceWorkersPage,
  EditInspectionCompaniesPage,
  EditInsuranceCompaniesPage,
  EditOperatingBankPage,
  EditSupplierPage,
  EditTransportCompanyPage,
  EditWarehousesPage,
  HomePage,
  InspectionCompaniesPages,
  InspectionDocumentPage,
  InspectionInfoDocsPage,
  InsuranceCompaniesPages,
  InsurancePage,
  OperatingBanksPage,
  OrderManagementPages,
  OrdersPages,
  OriginFinancialPage,
  OriginPages,
  PODfinancialPage,
  PortsPage,
  ProductsPage,
  RatesPage,
  ShipmentPage,
  ShippingDocPage,
  SuppliersPages,
  TransportCompaniesPage,
  UnitsOfMeasurementPage,
  VesselsPage,
  ViewAddOrderPages,
  ViewAgriculturePage,
  ViewBankingOperationsPage,
  ViewClearanceWorkersPage,
  ViewCustomsFormalitiesPage,
  ViewInspectionCompaniesPages,
  ViewInspectionPage,
  ViewInsuranceCompaniesPage,
  ViewInsurancePage,
  ViewOperatingBankPage,
  ViewOrderPages,
  ViewOriginFinancialPage,
  ViewPODPage,
  ViewRatesPage,
  ViewShipmentPage,
  ViewSuppliersPage,
  ViewTransportCompaniesPage,
  ViewWarehousesPage,
  ViewWarehousingPage,
  VipPages,
  WarehousesPage,
  WarehousingPage,
} from "src/pages/private";
import { ROUTES } from "../../models/enums/routes";
import { AdminPanelLayout } from "src/templates/mainLayout";
import useRoutesAccessStore from "src/store/routesAccess";
import { ViewOriginPages } from "src/pages/private/ViewOrigin";
import { ViewVipPages } from "src/pages/private/ViewVip";
import { routesKeys } from "src/constants/routesKeys";
import { VesselArrivePage } from "src/pages/private/VesselArrive";
import { ViewVesselArrivePage } from "src/pages/private/ViewVesselArrive";
import { ViewCustomDeclaration } from "src/modules/CustomDeclaration/components/ViewCustomDeclaration";
import { SellingPage } from "src/pages/private/Selling";
import { ViewSellingPage } from "src/pages/private/ViewSelling";

interface IRouteObject extends NonIndexRouteObject {
  access: boolean | undefined;
}
export const usePrivateRoutes = () => {
  const { routes } = useRoutesAccessStore();
  const orderId = `/:${routesKeys.orderId}`;

  const privateRoutes: RouteObject[] = [
    {
      path: ROUTES.home,
      element: <AdminPanelLayout />,
    },
  ];

  const childrenRoutes: RouteObject[] = [
    {
      path: ROUTES.home,
      element: <HomePage />,
    },
    {
      path: ROUTES.login,
      element: <Navigate to={ROUTES.home} />,
    },
    {
      path: ROUTES.home,
      element: <HomePage />,
    },
    {
      path: ROUTES.design,
      element: <DesignSystemPage />,
    },
    {
      path: ROUTES.insuranceCompanies,
      element: <InsuranceCompaniesPages />,
    },
    {
      path: ROUTES.viewInsuranceCompanies,
      element: <ViewInsuranceCompaniesPage />,
    },
    {
      path: ROUTES.editInsuranceCompanies,
      element: <EditInsuranceCompaniesPage />,
    },
    {
      path: ROUTES.transportCompanies,
      element: <TransportCompaniesPage />,
    },
    {
      path: ROUTES.viewTransportCompanies,
      element: <ViewTransportCompaniesPage />,
    },
    {
      path: ROUTES.editTransportCompany,
      element: <EditTransportCompanyPage />,
    },
    {
      path: ROUTES.operatingBanks,
      element: <OperatingBanksPage />,
    },
    {
      path: ROUTES.viewOperatingBank,
      element: <ViewOperatingBankPage />,
    },
    {
      path: ROUTES.editOperatingBank,
      element: <EditOperatingBankPage />,
    },
    {
      path: ROUTES.clearanceWorkers,
      element: <ClearanceWorkersPage />,
    },
    {
      path: ROUTES.viewClearanceWorkers,
      element: <ViewClearanceWorkersPage />,
    },
    {
      path: ROUTES.editClearanceWorkers,
      element: <EditClearanceWorkersPage />,
    },
    {
      path: ROUTES.warehouses,
      element: <WarehousesPage />,
    },
    {
      path: ROUTES.viewWarehouses,
      element: <ViewWarehousesPage />,
    },
    {
      path: ROUTES.editWarehouses,
      element: <EditWarehousesPage />,
    },
  ];
  const childRouteWithAccess: IRouteObject[] = [
    {
      path: ROUTES.products,
      element: <ProductsPage />,
      access: routes.products,
    },
    {
      path: ROUTES.ports,
      element: <PortsPage />,
      access: routes.ports,
    },
    {
      path: ROUTES.suppliers,
      element: <SuppliersPages />,
      access: routes.suppliers,
    },
    {
      path: ROUTES.editSupplier,
      element: <EditSupplierPage />,
      access: routes.editSupplier,
    },
    {
      path: ROUTES.viewSuppliers,
      element: <ViewSuppliersPage />,
      access: routes.viewSuppliers,
    },
    {
      path: ROUTES.countries,
      element: <CountriesPage />,
      access: routes.countries,
    },
    {
      path: ROUTES.averagePrice,
      element: <AveragePricePage />,
      access: routes.averagePrice,
    },
    {
      path: ROUTES.unitsOfMeasurement,
      element: <UnitsOfMeasurementPage />,
      access: routes.unitsOfMeasurement,
    },
    {
      path: ROUTES.currencies,
      element: <CurrenciesPage />,
      access: routes.currencies,
    },
    {
      path: ROUTES.vessels,
      element: <VesselsPage />,
      access: routes.vessels,
    },
    {
      path: ROUTES.inspectionCompanies,
      element: <InspectionCompaniesPages />,
      access: routes.inspectionCompanies,
    },
    {
      path: ROUTES.viewInspectionCompanies,
      element: <ViewInspectionCompaniesPages />,
      access: routes.viewInspectionCompanies,
    },
    {
      path: ROUTES.editInspectionCompanies,
      element: <EditInspectionCompaniesPage />,
      access: routes.editInspectionCompanies,
    },
    {
      path: ROUTES.CreateRole,
      element: <CreateRolePage />,
      access: routes.CreateRole,
    },
    {
      path: ROUTES.AssignActionToRole,
      element: <AssignActionToRolePage />,
      access: routes.AssignActionToRole,
    },
    {
      path: ROUTES.CreateUser,
      element: <CreateUserPage />,
      access: routes.CreateUser,
    },
    {
      path: ROUTES.AssignRoleToUser,
      element: <AssignRoleToUserPage />,
      access: routes.AssignRoleToUser,
    },
    {
      path: ROUTES.orders,
      element: <OrdersPages />,
      access: routes.orders,
    },
    {
      path: ROUTES.orders.concat(orderId),
      element: <OrdersPages />,
      access: routes.orders,
    },
    {
      path: ROUTES.orderManagement.concat(orderId),
      element: <OrderManagementPages />,
      access: routes.orderManagement,
    },
    {
      path: ROUTES.ViewOrder.concat(orderId),
      element: <ViewOrderPages />,
      access: routes.ViewOrder,
    },
    {
      path: ROUTES.origin.concat(orderId),
      element: <OriginPages />,
      access: routes.origin,
    },
    {
      path: ROUTES.viewOrigin.concat(orderId),
      element: <ViewOriginPages />,
      access: routes.viewOrigin,
    },
    {
      path: ROUTES.vip.concat(orderId),
      element: <VipPages />,
      access: routes.vip,
    },
    {
      path: ROUTES.viewVip.concat(orderId),
      element: <ViewVipPages />,
      access: routes.viewVip,
    },
    {
      path: ROUTES.addOrder.concat(orderId),
      element: <AddOrderPages />,
      access: routes.addOrder,
    },
    {
      path: ROUTES.viewAddOrder.concat(orderId),
      element: <ViewAddOrderPages />,
      access: routes.viewAddOrder,
    },
    {
      path: ROUTES.vesselArrive.concat(orderId),
      element: <VesselArrivePage />,
      access: routes.vesselArrive,
    },
    {
      path: ROUTES.viewVesselArrive.concat(orderId),
      element: <ViewVesselArrivePage />,
      access: routes.viewVesselArrive,
    },
    {
      path: ROUTES.warehousing.concat(orderId),
      element: <WarehousingPage />,
      access: routes.warehousing,
    },
    {
      path: ROUTES.viewWarehousing.concat(orderId),
      element: <ViewWarehousingPage />,
      access: routes.viewWarehousing,
    },
    {
      path: ROUTES.customDeclaration.concat(orderId),
      element: <CustomDeclarationPage />,
      access: routes.customDeclaration,
    },
    {
      path: ROUTES.viewCustomDeclaration.concat(orderId),
      element: <ViewCustomDeclaration />,
      access: routes.viewCustomDeclaration,
    },
    {
      path: ROUTES.Agriculture.concat(orderId),
      element: <AgriculturePage />,
      access: routes.Agriculture,
    },
    {
      path: ROUTES.viewAgriculture.concat(orderId),
      element: <ViewAgriculturePage />,
      access: routes.viewAgriculture,
    },
    {
      path: ROUTES.originFinancial.concat(orderId),
      element: <OriginFinancialPage />,
      access: routes.originFinancial,
    },
    {
      path: ROUTES.viewOriginFinancial.concat(orderId),
      element: <ViewOriginFinancialPage />,
      access: routes.viewOriginFinancial,
    },
    {
      path: ROUTES.rates.concat(orderId),
      element: <RatesPage />,
      access: routes.rates,
    },
    {
      path: ROUTES.viewRates.concat(orderId),
      element: <ViewRatesPage />,
      access: routes.viewRates,
    },
    {
      path: ROUTES.insurance.concat(orderId),
      element: <InsurancePage />,
      access: routes.insurance,
    },
    {
      path: ROUTES.viewInsurance.concat(orderId),
      element: <ViewInsurancePage />,
      access: routes.viewInsurance,
    },
    {
      path: ROUTES.inspectionDocument.concat(orderId),
      element: <InspectionDocumentPage />,
      access: routes.inspectionDocument,
    },
    {
      path: ROUTES.viewInspection.concat(orderId),
      element: <ViewInspectionPage />,
      access: routes.viewInspection,
    },
    {
      path: ROUTES.selling.concat(orderId),
      element: <SellingPage />,
      access: routes.selling,
    },
    {
      path: ROUTES.sellingView.concat(orderId),
      element: <ViewSellingPage />,
      access: routes.sellingView,
    },
    {
      path: ROUTES.customsFormalities.concat(orderId),
      element: <CustomsFormalitiesPage />,
      access: routes.customsFormalities,
    },
    {
      path: ROUTES.viewCustomsFormalities.concat(orderId),
      element: <ViewCustomsFormalitiesPage />,
      access: routes.viewCustomsFormalities,
    },
    {
      path: ROUTES.bankingOperations.concat(orderId),
      element: <BankingOperationsPage />,
      access: routes.bankingOperations,
    },
    {
      path: ROUTES.viewBankingOperations.concat(orderId),
      element: <ViewBankingOperationsPage />,
      access: routes.viewBankingOperations,
    },
    {
      path: ROUTES.shipment.concat(orderId),
      element: <ShipmentPage />,
      access: routes.shipment,
    },
    {
      path: ROUTES.viewShipment.concat(orderId),
      element: <ViewShipmentPage />,
      access: routes.viewShipment,
    },
    {
      path: ROUTES.podFinancial.concat(orderId),
      element: <PODfinancialPage />,
      access: routes.podFinancial,
    },
    {
      path: ROUTES.viewPOD.concat(orderId),
      element: <ViewPODPage />,
      access: routes.viewPOD,
    },
    {
      path: ROUTES.InspectionInfoDocs,
      element: <InspectionInfoDocsPage />,
      access: routes.InspectionInfoDocs,
    },
    {
      path: ROUTES.customOperation,
      element: <CustomOperationPage />,
      access: routes.customOperation,
    },
    {
      path: ROUTES.bankingDoc,
      element: <BankingDOCPage />,
      access: routes.bankingDoc,
    },
    {
      path: ROUTES.shippingDoc,
      element: <ShippingDocPage />,
      access: routes.shippingDoc,
    },
  ];
  childRouteWithAccess.forEach((route) => {
    if (route.access) {
      childrenRoutes.push(route);
    }
  });
  privateRoutes[0].children = childrenRoutes;
  return privateRoutes;
};
