import { Row } from "antd";
import styled from "styled-components";

export const AddOrderContainer = styled(Row)`
  display: flex;
  width: 62%;
  padding: 24px 32px;
  gap: 24px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
`;
export const ViewViewAddOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 16px 16px 32px 16px;
  gap: 32px;
  border-radius: 16px;
  background: var(--color-Base-Color-White, #fff);

  /* Body box */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .proformaDataHolder {
    display: flex;
    width: 100%;
    padding: 24px 16px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
    background: var(--color-Light-Mode-BG-Background-1, #f2f9f9);
  }
`;
