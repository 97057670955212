import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  UploadProps,
  message,
} from "antd";
import { FC, useEffect, useMemo } from "react";
import { UseInsurance } from "../../context";
import { GetProp } from "antd/lib";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { ROUTES } from "src/models/enums/routes";
import { Upload, UploadFile } from "antd/lib";
import { UploadChangeParam } from "antd/es/upload";
import { dummyRequest } from "src/helper/dummyRequest";
import TextArea from "antd/es/input/TextArea";
import { IAddInsuranceFileDetail } from "src/services/Order/models/args.models";
import { JalaliLocaleListener } from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";
import { S2 } from "src/components/UiKit/Typography";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadFileFormContainer } from "../../style";
import { IInsuranceTabs } from "../../models";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const UploadFileForm: FC = () => {
  const {
    value: { editMode, file, hasFile, loading, uploadProgress, tableEditMode },
    func: { addFileDetail, updateFileDetail, getAllInsuranceInfo },
    dispatch: {
      setFile,
      setEditMode,
      setActiveKey,
      setTableEditMode,
      setHasFile,
      setBackEditMode,
    },
    form: { uploadFileForm },
  } = UseInsurance()!;
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const values = Form.useWatch([], uploadFileForm);

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setFile(info.file);
    }
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
      setFile(undefined);
    }

    return isJpgOrPng;
  };
  const onClickCancel = () => {
    if (tableEditMode) {
      setTableEditMode(false);
      uploadFileForm?.resetFields();
      setFile(undefined);
      setHasFile(false);
      return;
    }
    setEditMode(false);
    setTableEditMode(false);
    setFile(undefined);
    setHasFile(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };
  const onClickReset = () => {
    uploadFileForm?.resetFields();
    setFile(undefined);
    setHasFile(false);
  };
  const onClickBack = () => {
    setActiveKey(IInsuranceTabs.basicInfo);
    setBackEditMode(true);
  };

  const onFinish = (values: IAddInsuranceFileDetail) => {
    if (tableEditMode) {
      updateFileDetail(values);
    } else {
      addFileDetail(values);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getAllInsuranceInfo();
  }, [getAllInsuranceInfo, isEdited]);

  return (
    <UploadFileFormContainer>
      <Form form={uploadFileForm} onFinish={onFinish}>
        <Col span={24}>
          <S2 className="py-[16px] mb-[24px]">
            {editMode
              ? words.orders.insurance.insuranceUploadEditInfo
              : words.orders.insurance.insuranceUploadInfo}
          </S2>
        </Col>
        <Col span={24} className="py-[16px] mb-[24px]">
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name={"title"}
                label={words.orders.insurance.title}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  // onKeyDown={() => setPressKeyboard(true)}
                  onPressEnter={uploadFileForm?.submit}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <div className="uploadHolder">
                <div className="p-x[12px] flex flex-col justify-center gap-[12px]">
                  <div className="text-[14px]">{words.global.fileUpload}</div>
                  <div className="text-[10px]">
                    {words.global.fileUploadInfo}
                  </div>
                </div>
                <div
                  className={
                    file || hasFile ? "w-[66px] h-[66px]" : `fileHolder`
                  }
                >
                  <Upload
                    name="file"
                    onChange={handleChange}
                    customRequest={dummyRequest}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="relative w-[66px] h-[66px] flex justify-center items-center">
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="large"
                          className="flex justify-center items-center"
                        />
                        <span className="absolute top-[38%] left-[40%] ">
                          {uploadProgress}
                        </span>
                      </div>
                    ) : (
                      <Button
                        type={file || hasFile ? "text" : "default"}
                        icon={
                          file || hasFile ? (
                            <img
                              src={"/assets/images/fileUploaded.svg"}
                              alt="fileUploaded"
                            />
                          ) : (
                            <span className="material-icons flex justify-center items-center text-[20px]">
                              attach_file
                            </span>
                          )
                        }
                        className="text-[10px]"
                      >
                        {file || hasFile ? "" : words.global.add}
                      </Button>
                    )}
                  </Upload>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <FormItem name={"number"} label={words.orders.insurance.number}>
                <Input
                  placeholder={words.global.insert}
                  onPressEnter={uploadFileForm?.submit}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <ConfigProvider locale={fa_IR}>
                <JalaliLocaleListener />
                <FormItem name={"date"} label={words.orders.insurance.date}>
                  <DatePicker placeholder={words.global.select} />
                </FormItem>
              </ConfigProvider>
            </Col>
            <Col span={24}>
              <FormItem
                name={"description"}
                label={words.orders.insurance.description}
              >
                <TextArea
                  className="!min-h-[90px]"
                  placeholder={words.global.description}
                  // onKeyDown={() => setPressKeyboard(true)}
                  onPressEnter={uploadFileForm?.submit}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="flex items-center !w-[100%] !h-[104px] ">
            {editMode || tableEditMode ? (
              <Row className="!w-full">
                <Col span={24}>
                  <div className="flex justify-end gap-[16px] h-full">
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                      disabled={tableEditMode}
                    >
                      <div className="!min-w-[85px] !h-[100%]">
                        <Button
                          type="default"
                          disabled={!file && isDisabledResetForm}
                          onClick={() => tableEditMode && onClickCancel()}
                        >
                          {tableEditMode
                            ? words.global.cancel
                            : words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      onClick={() => {
                        uploadFileForm?.submit();
                      }}
                      loading={loading}
                      disabled={isDisabledResetForm}
                    >
                      {tableEditMode ? words.global.saveEdit : words.global.add}
                    </Button>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row gutter={16} className="!w-full ">
                <Col span={12}>
                  <div className="flex justify-between h-full">
                    <div className="min-w-[85px]">
                      <Button type="default" onClick={onClickBack}>
                        {words.global.back}
                      </Button>
                    </div>
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] !h-[100%]">
                        <Button
                          type="default"
                          disabled={!file && isDisabledResetForm}
                        >
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="flex justify-between">
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      onClick={() => {
                        uploadFileForm?.submit();
                      }}
                      loading={loading}
                      disabled={isDisabledResetForm}
                    >
                      {words.global.add}
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      className="!min-w-[85px]"
                      onClick={() => {
                        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
                      }}
                    >
                      {words.global.end}
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
        <Divider dashed className="!border-[#BFE2E0] !mt-0 " />
      </Form>
    </UploadFileFormContainer>
  );
};
