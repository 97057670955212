import { App, Form } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fixNumbers } from "src/helper/fixNumber";
import { ROUTES } from "src/models/enums/routes";
import { IAddOrderRegister } from "src/services/Order/models/args.models";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import { format } from "date-fns";
import dayjs from "dayjs";
import { FormInstance } from "antd/lib";

interface IContext {
  value: { editMode: boolean; loading: boolean; deleteLoading: boolean };
  dispatch: { setEditMode: Dispatch<SetStateAction<boolean>> };
  func: {
    deleteOrder: () => void;
    add: (values: IAddOrderRegister) => void;
    update: (values: IAddOrderRegister) => void;
    getOrderInfo: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}
export const AddOrderContext = createContext<IContext | undefined>(undefined);

export const AddOrderProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  ////////////////////////////////functions////////////////////////////////
  const add = async (values: IAddOrderRegister) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddOrderRegister } = new OrderService();
      const newValues = {
        ...values,
        registerDate: format(new Date(values.registerDate), "yyyy-MM-dd"),
        validatyDate: format(new Date(values.validatyDate), "yyyy-MM-dd"),
      };
      const result = await AddOrderRegister(newValues, parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        message.success(words.orders.addOrder.addOrderMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const update = async (values: IAddOrderRegister) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { UpdateOrderRegister } = new OrderService();
      const newValues = {
        ...values,
        registerDate: fixNumbers(
          format(new Date(values.registerDate), "yyyy-MM-dd")
        ),
        validatyDate: fixNumbers(
          format(new Date(values.validatyDate), "yyyy-MM-dd")
        ),
      };
      const result = await UpdateOrderRegister(newValues, parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.addOrder.updateOrderMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getOrderInfo = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetOrderRegister } = new OrderService();
      const result = await GetOrderRegister(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        const newData = {
          ...result.data,
          registerDate: result.data.registerDate
            ? dayjs(result.data.registerDate)
            : undefined,
          validatyDate: result.data.validatyDate
            ? dayjs(result.data.validatyDate)
            : undefined,
        };
        form.setFieldsValue(newData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const deleteOrder = async () => {
    if (!orderId) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteOrderRegister } = new OrderService();
      const result = await DeleteOrderRegister(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(words.orders.addOrder.deleteOrderMessage);
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  const defaultValue: IContext = {
    value: { deleteLoading, editMode, loading },
    dispatch: { setEditMode },
    func: { add, deleteOrder, getOrderInfo, update },
    form: { form },
  };

  return (
    <AddOrderContext.Provider value={defaultValue}>
      {children}
    </AddOrderContext.Provider>
  );
};

export const UseAddOrder = () => useContext(AddOrderContext);
