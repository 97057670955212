import { Button, Col, Divider, Form, Row, Typography, message } from "antd";
import { FC, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { useInspectionDoc } from "../context";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddInspectionDoc } from "src/services/BaseInfo/models/args.models";
import { Guard } from "src/components/Guard";
import { AddNewInspectionDocContainer } from "../style";
import { DInput } from "src/components/UiKit/DInput";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { S2 } from "src/components/UiKit/Typography";

export const AddNewInspectionDoc: FC = () => {
  const { words } = useLanguage();
  const {
    dispatch: { setEditMode },
    form: { form },
    value: { editMode, productId },
    func: { getInspectionDocTableData },
  } = useInspectionDoc();
  const [loading, setLoading] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);

  const onFinishAddInspectionDoc = async (values: IAddInspectionDoc) => {
    setLoading(true);
    try {
      const { AddInspectionDoc } = new BaseInfoService();
      const result = await AddInspectionDoc(values);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.inspectionInfoDocs.addInspectionInfoDocsMessage
        );
        getInspectionDocTableData();
        form?.resetFields();
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onFinishUpdateInspectionDoc = async (values: IAddInspectionDoc) => {
    setLoading(true);
    try {
      const { UpdateInspectionDoc } = new BaseInfoService();
      const result = await UpdateInspectionDoc(values, productId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.inspectionInfoDocs
            .updateInspectionInfoDocsMessage
        );
        getInspectionDocTableData();
        form?.resetFields();
        setPressKeyboard(false);
        setEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    // <Guard action={[UpdateInspectionDocPath, AddInspectionDocPath]} multiPath>
    <Form
      form={form}
      onFinish={(values) => {
        editMode
          ? onFinishUpdateInspectionDoc(values)
          : onFinishAddInspectionDoc(values);
      }}
    >
      <AddNewInspectionDocContainer>
        <Col span={24}>
          <S2>
            {
              words.basicInformation.inspectionInfoDocs
                .inspectionInfoDocsInformation
            }
          </S2>
        </Col>
        <Col span={24}>
          <Row gutter={24} className="productHolder">
            <Col span={8}>
              <FormItem
                name={"title"}
                label={
                  words.basicInformation.inspectionInfoDocs
                    .inspectionInfoDocsName
                }
                rules={[{ required: true }]}
              >
                <DInput
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={16}>
              <div className="flex gap-[16px] justify-end h-[100%] items-center">
                {editMode ? (
                  <Button
                    className="!min-w[85px]"
                    type="primary"
                    disabled={!pressKeyboard}
                    onClick={form?.submit}
                    loading={loading}
                  >
                    {words.global.saveEdit}
                  </Button>
                ) : (
                  <Button
                    className="!min-w[85px]"
                    type="primary"
                    disabled={!pressKeyboard}
                    onClick={form?.submit}
                    loading={loading}
                  >
                    {words.global.add}
                  </Button>
                )}

                {editMode ? (
                  <Button
                    type="default"
                    className="!min-w[85px]"
                    onClick={() => {
                      setEditMode(false);
                      form?.resetFields();
                      setPressKeyboard(false);
                    }}
                  >
                    {words.global.cancel}
                  </Button>
                ) : (
                  <DPopconfirm
                    description={words.global.descriptionPopConfirm}
                    onConfirm={() => {
                      setPressKeyboard(false);
                      form?.resetFields();
                    }}
                  >
                    <Button
                      type="default"
                      className="!min-w[85px]"
                      disabled={!pressKeyboard}
                    >
                      {words.global.reset}
                    </Button>
                  </DPopconfirm>
                )}
              </div>
            </Col>
            <Divider />
          </Row>
        </Col>
      </AddNewInspectionDocContainer>
    </Form>
    // </Guard>
  );
};
