import { FC } from "react";
import { Shipment } from "src/modules/Shipment";
import { ShipmentProvider } from "src/modules/Shipment/context";

export const ShipmentPage: FC = () => {
  return (
    <ShipmentProvider>
      <Shipment />
    </ShipmentProvider>
  );
};
