import { create } from "zustand";
import En from "../constants/languages/en.json";
import Fa from "../constants/languages/fa.json";
import { ILanguages, IWords } from "src/models/languages";
import { USER_Language } from "src/constants";

interface IStore {
  words: IWords;
  currentLanguage: ILanguages;
  isRtl: boolean;
  setIsRtl: (value: boolean) => void;
  setCurrentLanguage: (value: "En" | "Fa") => void;
  setWords: (value: IWords) => void;
}
const savedLanguage =
  (localStorage.getItem(USER_Language) as ILanguages) || "Fa";

const useLanguage = create<IStore>((set) => ({
  currentLanguage: savedLanguage,
  isRtl: savedLanguage === "Fa",
  words: savedLanguage === "Fa" ? Fa : En,
  setCurrentLanguage: (newLang) =>
    set({
      currentLanguage: newLang,
      isRtl: newLang === "Fa",
      words: newLang === "En" ? En : Fa,
    }),
  setIsRtl: (newValue) =>
    set({
      isRtl: newValue,
      words: newValue ? Fa : En,
      currentLanguage: newValue ? "Fa" : "En",
    }),
  setWords: (newWords) => set({ words: newWords }),
}));

export default useLanguage;
