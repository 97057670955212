import { FC } from "react";
import { Selling } from "src/modules/Selling";
import { SellingProvider } from "src/modules/Selling/context";

export const SellingPage: FC = () => {
  return (
    <SellingProvider>
      <Selling />
    </SellingProvider>
  );
};
