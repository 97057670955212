import { FC, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { WarehousingContainer } from "./style";
import { ROUTES } from "src/models/enums/routes";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";
import { FormItem } from "src/components/UiKit/FormItem";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useWarehousing } from "./context";
import { S2 } from "src/components/UiKit/Typography";
import { IAddWarehousing } from "src/services/Order/models/args.models";
import { inputNumberFormatter } from "src/helper/inputNumberFormatter";

export const Warehousing: FC = () => {
  const {
    value: {
      editMode,
      unit,
      unitLoading,
      warehouse,
      warehouseLoading,
      addLoading,
    },
    dispatch: { setEditMode },
    func: {
      getUnit,
      getWarehouse,
      add,
      update,
      deleteWarehousing,
      getWarehousing,
    },
    form: { form },
  } = useWarehousing()!;

  const { words } = useLanguage();
  const { Option } = Select;
  const values = Form.useWatch([], form);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement.concat(`/${orderId}`));
  };

  const onClickReset = () => {
    form?.resetFields();
  };

  const onFinish = (values: IAddWarehousing) => {
    if (editMode) {
      update(values);
    } else {
      add(values);
    }
  };

  useEffect(() => {
    Promise.allSettled([getUnit(), getWarehouse()]);
  }, [getUnit, getWarehouse]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");

  useEffect(() => {
    if (isEdited) getWarehousing();
  }, [getWarehousing, isEdited]);

  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.warehousing.editWarehousingHeaderInfo
            : words.orders.warehousing.warehousingHeaderInfo
        }
      />
      <Flex justify="center">
        <WarehousingContainer>
          <Form form={form} onFinish={onFinish}>
            <Col span={24}>
              <S2 className="py-[16px]">
                {editMode
                  ? words.orders.warehousing.editWarehousingInfo
                  : words.orders.warehousing.warehousingInfo}
              </S2>
            </Col>
            <Col span={24} className="w-full">
              <Row gutter={[24, 24]} className="py-[16px]">
                <Col span={12}>
                  <FormItem
                    name={"receiptNo"}
                    label={words.orders.warehousing.receiptNo}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={words.global.insert}
                      onPressEnter={form?.submit}
                      className="w-full"
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <ConfigProvider locale={fa_IR}>
                    <JalaliLocaleListener />
                    <FormItem
                      name={"receiptDate"}
                      label={words.orders.warehousing.date}
                      rules={[{ required: true }]}
                    >
                      <DatePickerJalali placeholder={words.global.select} />
                    </FormItem>
                  </ConfigProvider>
                </Col>
                <Col span={12}>
                  <div className="wrapper !w-full">
                    <FormItem
                      name={"unitOfMeasurmentId"}
                      label={words.orders.warehousing.weight}
                      rules={[{ required: true }]}
                      className="w-[70%]"
                    >
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        placeholder={words.global.unit}
                        optionLabelProp="label"
                        showSearch
                        optionFilterProp="label"
                        loading={unitLoading}
                      >
                        {unit?.map((item, index) => (
                          <Option
                            key={index}
                            value={item.id}
                            label={item.shortName}
                          >
                            <Space>{item.shortName}</Space>
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem
                      name={"receiptWeight"}
                      label=" "
                      rules={[{ required: true }]}
                      required={false}
                      className="w-full"
                    >
                      <InputNumber
                        placeholder={words.global.insert}
                        onPressEnter={form?.submit}
                        changeOnWheel={false}
                        controls={false}
                        className="w-full"
                        formatter={inputNumberFormatter}
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col span={12}>
                  <FormItem
                    name={"warehouseId"}
                    label={words.basicInformation.warehouses.warehouse}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={warehouseLoading}
                    >
                      {warehouse?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.name}>
                          <Space>{item.name}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={24} className="h-[104px]"></Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider dashed className="!border-[#BFE2E0]" />
              <div
                className={`flex py-[24px]  ${
                  editMode ? "justify-between" : "justify-end"
                }`}
              >
                {editMode ? (
                  <div>
                    <DeletePopconfirm
                      endPointName={
                        words.orders.warehousing.deleteWarehousingInfo
                      }
                      onConfirm={() => {
                        deleteWarehousing();
                      }}
                    >
                      <Button danger className="!min-w[85px]">
                        {words.global.delete}
                      </Button>
                    </DeletePopconfirm>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex gap-[16px] justify-end borderTop">
                  {editMode ? (
                    <div className="!min-w-[85px] h-[100%]">
                      <Button type="default" onClick={onClickCancel}>
                        {words.global.cancel}
                      </Button>
                    </div>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] h-[100%]">
                        <Button type="default" disabled={isDisabledResetForm}>
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  )}
                  <Button
                    className="!min-w-[85px]"
                    type="primary"
                    onClick={() => {
                      form?.submit();
                    }}
                    disabled={isDisabledResetForm}
                    loading={addLoading}
                  >
                    {editMode ? words.global.saveEdit : words.global.add}
                  </Button>
                </div>
              </div>
            </Col>
          </Form>
        </WarehousingContainer>
      </Flex>
    </div>
  );
};
