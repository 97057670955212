import { Row, Tabs } from "antd";
import { FC } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { P1 } from "src/components/UiKit/Typography";
import useLanguage from "src/store/language";
import { ShipmentContainer } from "./style";
import { IShipmentTabs } from "./models";
import { TabsProps } from "antd/lib";
import { useShipment } from "./context";
import { UploadFile } from "./components/UploadFile";
import { AddShipmentForm } from "./components/AddShipmentForm";

export const Shipment: FC = () => {
  const {
    value: { editMode, activeKey },
    dispatch: { setActiveKey },
  } = useShipment()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: IShipmentTabs.basicInfo,
      label: (
        <P1 className="!text-[#8E8E8E]">{words.orders.shipment.basicInfo}</P1>
      ),
      children: <AddShipmentForm />,
    },
    {
      key: IShipmentTabs.addFile,
      label: (
        <P1 className="!text-[#8E8E8E]">{words.orders.shipment.fileUpload}</P1>
      ),
      children: <UploadFile />,
    },
  ];

  const onChange = (key: string) => {
    if (editMode) setActiveKey(key);
  };
  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.shipment.editShipmentHeaderInfo
            : words.orders.shipment.shipmentHeaderInfo
        }
      />
      <Row justify={"center"}>
        <ShipmentContainer span={18}>
          <Tabs
            items={items}
            onChange={onChange}
            activeKey={activeKey}
            centered
          />
        </ShipmentContainer>
      </Row>
    </div>
  );
};
