import { Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { IOriginFinancials } from "src/services/Order/models/result.models";
import useLanguage from "src/store/language";

export const useViewColumns = () => {
  const { words } = useLanguage();
  const paymentOptionName = (key: number) => {
    let paymentOptName = "";
    switch (key) {
      case 0:
        paymentOptName = words.orders.originFinancial.commodityValue;
        break;
      case 1:
        paymentOptName = words.orders.originFinancial.freightCost;
        break;
      case 2:
        paymentOptName = words.orders.originFinancial.demurrage;
        break;
      case 3:
        paymentOptName = words.orders.originFinancial.fee;
        break;
      case 4:
        paymentOptName = words.orders.originFinancial.other;
        break;
    }
    return paymentOptName;
  };
  const Columns: ColumnsType<IOriginFinancials> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.orders.originFinancial.payment,
      dataIndex: "payment",
      key: "payment",
      align: "center",
      width: "143px",
      render: (text, record) => paymentOptionName(record.payment),
    },
    {
      title: words.orders.originFinancial.paymentDate,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "158px",
      render: (text, record) => moment(record.date).format("YYYY-MM-DD"),
    },
    {
      title: words.orders.originFinancial.currency,
      dataIndex: "currencyName",
      key: "currencyName",
      align: "center",
      width: "125.6px",
    },
    {
      title: words.orders.originFinancial.amount,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "125.6px",
      render: (text, record) => record.amount.toLocaleString("en-US"),
    },
    {
      title: words.orders.originFinancial.rate,
      dataIndex: "rate",
      key: "rate",
      align: "center",
      width: "125.6px",
      render: (text, record) => record.rate.toLocaleString("en-US"),
    },
    {
      title: words.orders.originFinancial.CBI,
      dataIndex: "",
      key: "",
      align: "center",
      width: "125.6px",
      render: (text, record) =>
        (+record?.amount / +record?.rate)?.toLocaleString("en-US"),
    },
    {
      title: words.orders.originFinancial.desc,
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "125.6px",
      render: (text, record) => (
        <Tooltip title={record.description}>
          <span className="material-icons text-[16px] text-[#02514C] cursor-pointer flex justify-center items-center">
            message
          </span>
        </Tooltip>
      ),
    },
  ];
  return [Columns];
};
