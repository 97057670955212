import { Col, Table } from "antd";
import { FC, useState } from "react";
import { S2 } from "src/components/UiKit/Typography";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ColumnsType } from "antd/es/table";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import dayjs from "dayjs";
import { useBankingOperations } from "../context";
import { UploadFileTableContainer } from "../style";
import { IGetAllBankFs } from "src/services/Order/models/result.models";
import { useChangeParams } from "src/hook/auth/useParamsChange";

export const UploadFileTable: FC = () => {
  const {
    form: { form },
    dispatch: { setTableEditMode, setBankId, setPagination },
    value: { allBankFs, loadings, pagination },
    func: { deleteBankF },
  } = useBankingOperations()!;
  const { words } = useLanguage();
  const onChangeParams = useChangeParams();

  const Columns: ColumnsType<IGetAllBankFs> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.orders.bankingOperation.operationTitle,
      dataIndex: "bankDocTitle",
      key: "bankDocTitle",
      align: "center",
      width: "150px",
    },
    {
      title: words.orders.bankingOperation.date,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "255px",
      render: (text, record) =>
        record.date ? dayjs(record.date).format("YYYY-MM-DD") : "---",
    },
    {
      title: words.global.tools,
      key: "id",
      width: "130px",
      align: "center",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setTableEditMode(true);
              setBankId(record.id);
              const newData = {
                ...record,
                date: record.date ? dayjs(record.date) : undefined,
              };
              form?.setFieldsValue(newData);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <DPopconfirm
            deleteClassName
            description={words.orders.bankingOperation.deleteDesc}
            onConfirm={() => deleteBankF(record.id)}
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[8px]">
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];

  return (
    <UploadFileTableContainer gutter={[0, 24]}>
      <Col span={24}>
        <S2>{words.orders.bankingOperation.tableDesc}</S2>
      </Col>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={loadings.allBankFs}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={allBankFs}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: pagination.total,
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page: number, pageSize) => {
              onChangeParams("Limit", pageSize.toString());
              onChangeParams(
                "Offset",
                pageSize !== pagination.pageSize ? "1" : pageSize.toString()
              );
              setPagination((prev) => ({
                ...prev,
                current: pageSize !== prev.pageSize ? 1 : page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </UploadFileTableContainer>
  );
};
