import { Card, Col, Row, Spin } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagement } from "../hook/managementHook";
import { ManagementOrderContentContainer } from "../style";
import { useNavigate } from "react-router-dom";
import { C1, O1, T1 } from "src/components/UiKit/Typography";
import { IGetProformMain } from "src/services/Order/models/result.models";
import { Guard } from "src/components/Guard";

interface IProps {
  proformMain: IGetProformMain | null;
  dataLoading: boolean;
}
export const ManagementOrderContent: FC<IProps> = ({
  proformMain,
  dataLoading,
}) => {
  const { words } = useLanguage();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const { orderInformationManagement } = useManagement();

  return (
    <ManagementOrderContentContainer className="mt-[10px] px-[24px]">
      <Col span={24} className="mt-[20px]">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <div className="cardTitleHolder">
              <T1>{words.orders.orderCardTitle}</T1>
            </div>
            {dataLoading ? (
              <div className="w-full flex items-center justify-center h-[392px]">
                <Spin size="large" />
              </div>
            ) : (
              <Row gutter={[16, 24]} className="cardBody">
                {orderInformationManagement.map((i) => {
                  const active = proformMain?.flags.includes(i.flag);
                  return (
                    <Guard action={i.guard}>
                      <Col span={4} key={i.id}>
                        <Card
                          bordered={false}
                          className={`card ${active && "active-border"}`}
                        >
                          <div className="flex justify-center gap-[8px] items-center border-0 border-b-[1px] border-solid border-[#E1E1E1] pb-[12px]">
                            <C1 className="text-[11px] text-[#1F1F1F] font-bold">
                              {i.name}
                            </C1>
                          </div>
                          {active ? (
                            <div className="flex justify-evenly items-center pt-[12px] ">
                              <O1
                                className="text-[10px] !text-[#3B82F6] cursor-pointer"
                                onClick={() => {
                                  navigate(i.endPointView);
                                }}
                              >
                                {words.orders.view}
                              </O1>
                              <div className="border border-solid border-[#E1E1E1] !w-[1px] !h-[18px]"></div>
                              <O1
                                className="text-[10px] font-normal !text-[#0C9D61] cursor-pointer"
                                onClick={() => {
                                  navigate(
                                    i.endPointAdd.concat("?editMode=true")
                                  );
                                }}
                              >
                                {words.orders.edit}
                              </O1>
                            </div>
                          ) : (
                            <div className="flex justify-center items-center pt-[12px] px[8px]">
                              <O1
                                className="text-[10px] font-normal !text-[#007E77] cursor-pointer "
                                onClick={() => {
                                  navigate(i.endPointAdd);
                                }}
                              >
                                {words.orders.add}
                              </O1>
                            </div>
                          )}
                        </Card>
                      </Col>
                    </Guard>
                  );
                })}
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </ManagementOrderContentContainer>
  );
};
