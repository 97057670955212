import { App, Form } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { OrderService } from "src/services/Order/order.service";
import useLanguage from "src/store/language";
import { IAddCustomDeclaration } from "src/services/Order/models/args.models";
import { FormInstance, UploadFile } from "antd/lib";
import { uploadFile } from "src/helper/uploadZipFile";
import { OrderUrl } from "src/services/Order/urls";
import { RcFile } from "antd/es/upload";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment-jalaali";

interface IContext {
  value: {
    editMode: boolean;
    deleteLoading: boolean;
    loading: boolean;
    hasFile: boolean;
    file: UploadFile | undefined;
    progress: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setHasFile: Dispatch<SetStateAction<boolean>>;
    setFile: Dispatch<SetStateAction<UploadFile | undefined>>;
  };
  func: {
    deleteCustomDeclaration: () => void;
    add: (values: IAddCustomDeclaration) => void;
    update: (values: IAddCustomDeclaration) => void;
    getCustomDeclarationInfo: () => void;
  };
  form: { form: FormInstance<any> | undefined };
}
export const CustomDeclarationContext = createContext<IContext | undefined>(
  undefined
);

export const CustomDeclarationContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [customDeclarationId, setCustomDeclarationId] = useState<number>();
  const [file, setFile] = useState<UploadFile>();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  ////////////////////////////////functions////////////////////////////////
  const deleteCustomDeclaration = async () => {
    if (!orderId) return undefined;
    setDeleteLoading(true);
    try {
      const { DeleteCustomDeclaration } = new OrderService();
      const result = await DeleteCustomDeclaration(parseInt(orderId));
      if (result && result.status === 200) {
        message.success(
          words.orders.customDeclaration.deleteCustomDeclarationMessage
        );
        navigate(ROUTES.orderManagement.concat(`/${orderId}`));
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const newCustomDeclarationFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.NewCustomDeclarationFile}`
        ),
        extraData: { name: "test" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const add = async (values: IAddCustomDeclaration) => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { AddCustomDeclaration } = new OrderService();
      const newValues = {
        ...values,
        declarationDate: moment(
          (values.declarationDate as Dayjs).format("YYYY-MM-DD"),
          "jYYYY-jMM-jDD"
        ).format("YYYY-MM-DD"),
      };
      const result = await AddCustomDeclaration(newValues, parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setCustomDeclarationId(result.data);
        if (file) {
          const resultFile = await newCustomDeclarationFile(result.data);
          if (resultFile) {
            navigate(ROUTES.orderManagement.concat(`/${orderId}`));
            message.success(
              words.orders.customDeclaration.addCustomDeclarationMessage
            );
          }
        } else {
          navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          message.success(
            words.orders.customDeclaration.addCustomDeclarationMessage
          );
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateCustomDeclarationFile = async (id: number) => {
    try {
      const result = await uploadFile({
        callbackUrl: OrderUrl.Order.concat(
          `${id}${OrderUrl.UpdateCustomDeclarationFile}`
        ),
        extraData: { name: "testUpdate" },
        method: "PATCH",
        newFile: file?.originFileObj as RcFile,
        setProgress: setProgress,
      });
      return result;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (values: IAddCustomDeclaration) => {
    if (!orderId || !customDeclarationId) return undefined;
    setLoading(true);
    try {
      const { UpdateCustomDeclaration } = new OrderService();

      const newValues = {
        ...values,
        declarationDate: moment(
          (values.declarationDate as Dayjs).format("YYYY-MM-DD"),
          "jYYYY-jMM-jDD"
        ).format("YYYY-MM-DD"),
      };

      const result = await UpdateCustomDeclaration(
        newValues,
        parseInt(orderId)
      );
      if (result && result.status === 200) {
        if (file) {
          let resultFile;
          if (hasFile) {
            resultFile = await updateCustomDeclarationFile(customDeclarationId);
          } else {
            resultFile = await newCustomDeclarationFile(customDeclarationId);
          }
          if (resultFile) {
            navigate(ROUTES.orderManagement.concat(`/${orderId}`));
            message.success(
              words.orders.customDeclaration.updateCustomDeclarationMessage
            );
          }
        } else {
          navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          message.success(
            words.orders.customDeclaration.updateCustomDeclarationMessage
          );
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getCustomDeclarationInfo = useCallback(async () => {
    if (!orderId) return undefined;
    try {
      const { GetCustomDeclaration } = new OrderService();
      const result = await GetCustomDeclaration(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        const newData = {
          ...result.data,
          declarationDate: result.data.declarationDate
            ? dayjs(result.data.declarationDate)
            : undefined,
        };
        setHasFile(result.data.hasFile);
        form.setFieldsValue(newData);
        setCustomDeclarationId(result.data.id);
      }
    } catch (err) {
      console.log(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const isEdited = search.get("editMode");
  useEffect(() => {
    setEditMode(!!isEdited);
    return () => {
      setEditMode(false);
    };
  }, [isEdited]);

  const ContextValue: IContext = {
    value: { deleteLoading, editMode, file, hasFile, loading, progress },
    dispatch: { setEditMode, setFile, setHasFile },
    func: { add, deleteCustomDeclaration, getCustomDeclarationInfo, update },
    form: { form },
  };
  return (
    <CustomDeclarationContext.Provider value={ContextValue}>
      {children}
    </CustomDeclarationContext.Provider>
  );
};

export const UseCustomDeclaration = () => useContext(CustomDeclarationContext);
