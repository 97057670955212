import { FC } from "react";
import useLanguage from "src/store/language";
import { IPODTabs } from "./models";
import { P1 } from "src/components/UiKit/Typography";
import { Row, Tabs, TabsProps } from "antd";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { PODContainer } from "./style";
import { usePOD } from "./context";
import { BasicInfoForm } from "./components/BasicInfo";
import { OtherInfo } from "./components/OtherInfo";

export const PODfinancial: FC = () => {
  const {
    value: { editMode, activeKey },
    dispatch: { setActiveKey },
  } = usePOD()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: IPODTabs.basicInfo,
      label: <P1 className="!text-[#8E8E8E]">{words.orders.pod.basicInfo}</P1>,
      children: <BasicInfoForm />,
    },
    {
      key: IPODTabs.other,
      label: <P1 className="!text-[#8E8E8E]">{words.orders.pod.other}</P1>,
      children: <OtherInfo />,
    },
  ];

  const onChange = (key: string) => {
    // if (editMode)
    setActiveKey(key);
  };
  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.pod.editPodHeaderInfo
            : words.orders.pod.podHeaderInfo
        }
      />
      <Row justify={"center"}>
        <PODContainer span={18}>
          <Tabs
            items={items}
            onChange={onChange}
            activeKey={activeKey}
            centered
          />
        </PODContainer>
      </Row>
    </div>
  );
};
